/* All these settings prevent analysis errors but mean
 * deviation from coding standard XXX */
/*jshint forin: false */
/*jshint nonew: false */
/* global tcgDOM, saveAs, _, tcgTarget, $, tcgDget */
/*jshint browser: true */

(function(tcgDOM, saveAs, _, tcgTarget, $, tcgDget) { // jshint ignore: line
    'use strict';

    /**
     * @class
     * @augments tcgDget
     * @param {Object} param
     */
    /**
     * @lends tcgDget.idgLogin.prototype
     */
    tcgDget.idgBasketExport = tcgDget.extend({
        _init_: function() {
            var self = this;

            self.objName = 'idgBasketExport';
            self.setDgetMode('single');


            self.listen('cartSelector:share', function(event) {
                var data = event.messageData;

                if (!data) { // solr not yet responded
                    return false;
                }

                var url = 'mailto:{mailTo}?subject={subject}&body={body}';

                // var loc =
                // location.protocol+'//'+location.hostname+(location.port?":"+location.port:"")+location.pathname+(location.search?location.search:"");
                var loc = location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '') +
                        location.pathname + '?' + data.id,
                    subject = $('#subjectSendAsEmail').text().replace('{title}', data.title),
                    body = $('#bodySendAsEmail').text().replace('{link}', loc);

                if (self.formData) {
                    url = url.replace('{mailTo}', self.formData.mailTo)
                        .replace('{subject}', encodeURIComponent(subject))
                        .replace('{body}', encodeURIComponent(body));
                } else {
                    url = 'mailto:?subject=' + encodeURIComponent(subject) + '&body=' + encodeURIComponent(body);
                }

                window.location.href = url;

                var anker = $('a', $('#mailToData')).attr('href', url);
                $(anker).click();
            });

            $('#cartSend').click(function() { // one cart
                if ($(this).hasClass('disabled')) {
                    return;
                }
                self.sendCart = localStorage.curCart;
                // self.fire('messageBox', 'popupSendAsEmail');
                self.cartShare({
                    'cartID': localStorage.curCart,
                    'title': $('.name', $('[data-cartid="' + localStorage.curCart + '"]')).text()
                });
            });

            $('#cartsSend').click(function() { // all carts
                if ($(this).hasClass('disabled')) {
                    return;
                }
                self.sendCart = null;
                self.cartShare({
                    cartID: null,
                    'title': ''
                });
            });

            $('#cartsList').click(function() {
                if ($(this).hasClass('disabled')) {
                    return;
                }

                self.cartList();
            });

            $('#cartList').click(function() { // one cart
                if ($(this).hasClass('disabled')) {
                    return;
                }
                self.cartList(localStorage.curCart, $('.name', $('[data-cartid="' + localStorage.curCart + '"]'))
                    .text());
            });


            self.listen('cartExport:list', function(event) {
                self.cartExport(event.messageData, 'csv');
            });

            self.listen('popupSendAsEmail:send', function(event) {
                var shareData = event.messageData;
                shareData.cartID = self.sendCart;
                self.formData = shareData;
                self.cartShare(shareData);
            });

            $('#cartPDF').click(function() { // one cart
                if ($(this).hasClass('disabled')) {
                    return;
                }
                self.cartPrint({
                    'cartID': localStorage.curCart,
                    'title': $('.name', $('[data-cartid="' + localStorage.curCart + '"]')).text()
                });
            });

            $('#cartsPDF').click(function() { // all carts
                self.cartPrint({
                    // 'cartID': localStorage.curCart,
                    // 'title': $('.name', $('[data-cartid="'+localStorage.curCart+'"]')).text()
                });
            });

            self.listen('cartExport:single', function(event) {
                self.cartList(localStorage.curCart, $('.name', $('[data-cartid="' + localStorage.curCart + '"]'))
                .text(), event.messageData);
            });

            // load basket from solr
            self.listen('basket:loaded', function(event) {
                var data = event.messageData;
                self.mergeBasket(data.response);
            });

            if (typeof tcgTarget === 'string') {
                self.loadBasket(tcgTarget);
            }
        },


        cartShare: function(shareData) {
            var self = this,
                data = {};

            if (typeof shareData.cartID === 'string') { // single cart
                data.id = shareData.cartID;
            }

            self.fire('cart', {
                'service': 'share',
                'message': 'cartSelector:share',
                'title': shareData.title ? shareData.title : $('[name="title"]',
                    $('#popupSendAsEmail')).attr('placeholder'),
                'mailFrom': shareData.mailFrom ? shareData.mailFrom : $('[name="mailFrom"]', $('#popupSendAsEmail'))
                    .attr('placeholder'),
                'mailto': shareData.mailTo ? shareData.mailTo : $('[name="mailTo"]', $('#popupSendAsEmail'))
                    .attr('placeholder'),
                'pData': data
            });
        },

        cartPrint: function(printData) {
            var self = this;

            self.fire('print', {
                'service': 'pdf',
                'message': 'print:done',
                'pData': printData
            });
        },


        cartList: function(cartID, name, cartItemId) {
            var self = this,
                pData = {};
            if (typeof cartID === 'string') {
                pData.cartID = cartID;
            }

            var fileName = 'basket.csv';

            if (typeof name === 'string') {
                fileName = name + '.csv';
            }

            self.fire('cartItem', {
                'service': 'list',
                // 'message': 'cartExport:list',
                'pData': pData,
                'message': function(items) {
                    var data = {};

                    if (cartItemId) {
                        items = _.filter(items, function(item) {
                            return item.id === cartItemId;
                        });
                    }

                    console.log('items', items);

                    _.each(items, function(item) {
                        if (_.isNull(item.deleted) || _.isUndefined(item.deleted)) {
                            switch (item.objectType) {
                                case 'cfgResult':
                                    _.each(item.products, function(product) {
                                        addData(data, product, item.qty);
                                    });
                                    break;
                                default:
                                    addData(data, item, 1);
                                    break;
                            }
                            // if (typeof data[item.matnr]=='undefined') {
                            //     if (item.objectType == "cfgResult") {

                            //     }
                            //     else { // normal article
                            //         data[item.matnr]={
                            //             'matnr': item.matnr,
                            //             'qty': item.qty?item.qty:0,
                            //             'title': item.title?item.title:'unknown',
                            //         };
                            //     }
                            // }
                            // else {
                            //     data[item.matnr].qty+=item.qty;
                            // }
                        }
                    });

                    console.log('excel data', data);

                    var sCSV = 'matnr;qty;title\r\n';
                    for (var matnr in data) {
                        sCSV += data[matnr].matnr + ';' +
                            data[matnr].qty + ';' +
                            data[matnr].title.replace(/\;/g, ',') +
                            '\r\n';
                    }
                    var blob = new Blob([sCSV], { type: 'text/plain;charset=utf-8' });
                    saveAs(blob, fileName);
                }

            });

            function addData(data, item, qtyCfg) {
                if (!item.matnr) {
                    addWithoutMatnr(data, item, qtyCfg);
                } else if (typeof data[item.matnr] === 'undefined') {
                    data[item.matnr] = {
                        'matnr': item.matnr,
                        'qty': item.qty ? item.qty * qtyCfg : 0,
                        'title': item.title ? item.title : 'unknown'
                    };
                } else {
                    data[item.matnr].qty += item.qty * qtyCfg;
                }
            }

            function addWithoutMatnr(data, item, qtyCfg) {
                if (typeof data[item.title] === 'undefined') {
                    data[item.title] = {
                        'matnr': item.matnr,
                        'qty': item.qty ? item.qty * qtyCfg : 0,
                        'title': item.title ? item.title : 'unknown'
                    };
                } else {
                    data[item.title].qty += item.qty * qtyCfg;
                }
            }
        },

        loadBasket: function(id) {
            var self = this,
                pData = {
                    'q': 'id:' + tcgDOM.domSOLR.escape(id)
                };

            self.fire('solr:load', {
                'core': 'basket',
                'service': 'selectBasket',
                'message': 'basket:loaded',
                'pData': pData
            });
        }, // loadBasket

        mergeBasket: function(response) {
            var self = this,
                solrBaskets = [],
                updateBaskets = [];

            if (response.docs.length === 0) {
                return;
            }

            _.each(response.docs[0].basket, function(basket) {
                solrBaskets.push(JSON.parse(basket));
            });


            // get current carts
            self.fire('cart', {
                'service': 'list',
                'message': function(carts) {
                    for (var i = 0; i < solrBaskets.length; i++) {
                        var solrCarts = solrBaskets[i][0],
                            solrCartItems = solrBaskets[i][1];

                        _.each(solrCarts, function(solrCart) { // jshint ignore: line
                            // check if solrCart already exisits
                            var cart = _.findWhere(carts, { 'id': solrCart.id }),
                                updateBasket = {
                                    'action': 'add',
                                    'cart': solrCart,
                                    'cartItems': _.where(solrCartItems, { 'cartID': solrCart.id })
                                };
                            if (cart) {
                                updateBasket.action = 'replace';
                            }

                            updateBaskets.push(updateBasket);
                        }); // jshint ignore: line
                    } // loop solrBaskets

                    _.each(updateBaskets, function(basket) {
                        if (basket.action === 'replace') {
                            self.fire('cart', {
                                'service': 'delete',
                                'message': function() { // cart is deleted

                                    // delete cartItems
                                    self.fire('cartItem', {
                                        'service': 'delete',
                                        'message': function() { // cart items are deleted
                                            // insert cart
                                            self.insertBasket(basket.cart, basket.cartItems);
                                        },
                                        'pData': {
                                            'cartID': basket.cart.id
                                        }
                                    });
                                },
                                'pData': {
                                    'id': basket.cart.id
                                }
                            });
                        } // delete cart/cartItems
                        else { // add cart/cartItems
                            self.insertBasket(basket.cart, basket.cartItems);
                        }
                    }); // loop baskets

                    self.fire('cartSelector:update');
                },
                'pData': {}
            });
        }, // mergeBasket

        insertBasket: function(cart, cartItems) {
            var self = this;

            self.fire('cart', {
                'service': 'insert',
                'message': function() { // cart inserted
                    // insert cart items
                    _.each(cartItems, function(cartItem) {
                        self.fire('cartItem', {
                            'service': 'insert',
                            'pData': cartItem
                        });
                    });
                },
                'pData': cart
            });
        }
    });


/*
    the contents of document.ready() should be placed in the
    startup of the catalog page, there is the only place to handle
    the correct startup sequence, otherwise it's not guaranteed
    that all initializations are correctly done!
*/


    $(document).ready(function() {
        $('[data-tcgDget="idgBasketExport"]').each(function() {
            new tcgDget.idgBasketExport($(this));  // no need to store the object it's an ecatUI job
        });
    });


    /**
     * $Log: idgBasketExport.js,v $
     * Revision 1.12  2016-02-16 08:18:44  ml
     * csv export for single list
     *
     * Revision 1.11  2015-11-27 11:55:44  pr
     * export config items
     *
     * Revision 1.10  2015-06-16 14:07:01  pr
     * prevent create PDF from empty list
     *
     * Revision 1.9  2015-06-15 12:36:00  ml
     * empty mail to
     *
     * Revision 1.8  2015-05-19 17:20:38  ml
     * multi basket print
     *
     * Revision 1.7  2015-05-05 19:37:30  ml
     * simple print steps
     *
     * Revision 1.6  2015-04-30 21:17:22  ml
     * PDF modules added
     *
     * Revision 1.5  2015-04-28 09:41:39  ml
     * delete, send email add to baskte stuff
     *
     * Revision 1.4  2015-04-27 19:52:55  ml
     * delete/undelete function
     *
     * Revision 1.3  2015-04-26 12:06:38  ml
     * remove dialog
     *
     * Revision 1.2  2015-04-20 14:44:29  pr
     * send as email
     *
     * Revision 1.1  2015-04-20 11:46:05  ml
     * Basket List export
     *
     **/
})(tcgDOM, saveAs, _, tcgTarget, $, tcgDget);
