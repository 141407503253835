// jscs:disable maximumNumberOfLines
/* All these settings prevent analysis errors but mean
 * deviation from coding standard XXX */
/*jshint nonew: false */
/*jshint maxcomplexity: 15 */
/*jshint browser: true */
/* global VivaUtils, CommUtils, AppData, EventService, _, $, tcgDget */

(function(VivaUtils, CommUtils, AppData, EventService, _, $, tcgDget) { // jshint ignore:line
    'use strict';

    /**
     * @class
     * @augments tcgDget
     * @param {Object} param
     */
    /**
     * @lends tcgDget.idgLogin.prototype
     */
    tcgDget.idgBasket = tcgDget.extend({
        families: {},

        availabilityText: {
            '*': 'unknown {1}'
        },

        _init_: function() {
            var self = this;

            self.objName = 'idgBasket';
            self.setDgetMode('single');


            // ABC translation - init
            $('li', 'ul[data-template="availabilityDefinition"]').each(function() {
                self.availabilityText[$(this).attr('data-availability')] = $(this).text();
            });


            self.listen('basket:select', function(event) {
                var basket = event.messageData;

                self.loadBasketItems(basket);
            });

            self.listen('basket:items', function(event) {
                var items = event.messageData;

                self.renderBasketItems(items);
                self.basketItems = items;

                self.fire('loading', null);
            });

            $(window).bind('login:update', function(event, messageData) {
                self.bAuthenticated = messageData.status;

                if (self.basketItems && self.basketItems.length > 0) {
                    self.renderBasketItems(self.basketItems);
                }
            });
        },

        loadBasketItems: function(basket) {
            var self = this;

            self.fire('cartItem', {
                'service': 'list',
                'message': 'basket:items',
                'pData': {
                    'cartID': basket
                }
            });
        },

        updateBasketItem: function(item) {
            // update isVIVA string
            if (window.IsVIVA.isValidMaterialNumber(item.matnr) && _.isString(item.isVIVA)) {
                var isVIVANew = window.IsVIVA.updateIsVIVAMaterialNumber(item.isVIVA, item.matnr);
                if (isVIVANew !== item.isVIVA) { // need update in basket
                    item.isVIVA = isVIVANew;
                    window.BasketService.updateBasketItem(item);
                    return true;
                }
            }
            return false;
        },


        renderBasketItems: function(items) {
            var self = this;

            $(self.myDOM).html('');
            self.families = {};

            for (var i = 0; i < items.length; i++) {
                var item = items[i];

                if (!_.has(item, 'family')) {
                    item.family = 'noFamily';
                }

                // check family already in list
                if (!_.has(self.families, item.family)) { // add family block
                    self.families[item.family] = {
                        'dom': self.cloneTemplate($('[data-template="family"]'), item),
                        'articleTypes': {},
                        'articleTypeNames': []
                    };
                    $(self.myDOM).append($(self.families[item.family].dom));
                }

                // add item to family in specific group
                var groupDetail = $('[data-ArticleType*="' + item.ArtType_s + '"]', $(self.families[item.family].dom));
                if ($(groupDetail).size() === 0) {
                    groupDetail = $('[data-ArticleType="*"]', $(self.families[item.family].dom));
                }

                if ($(groupDetail).size() === 0) {
                    error; // jshint ignore: line
                }

                if (typeof item.ArtTypeName_s === 'string' && item.ArtTypeName_s !== 'N/A') {
                    $('h3:first', $(groupDetail)).text(item.ArtTypeName_s);
                }

                var templ = $('[data-template="' + item.objectType + '"]');
                if ($(templ).size() === 0) {
                    templ = $('[data-template="basketItem"]');
                }


                // ABC translation (viva special)
                if (typeof item.deliveryTimeCode === 'string') {
                    item.abcMark = item.deliveryTimeCode;
                }

                // ABC translation
                if (item.abcMark) { // set availability text
                    item.abcText = self.availabilityText[item.abcMark];
                    if (typeof item.abcText === 'undefined') {
                        item.abcText = self.availabilityText['*'].replace('{1}', item.abcMark);
                    }
                }

                // isVIVA
                self.updateBasketItem(item);

                self.renderBasketItem(item);
            } // all items

            // FIXME use strict equality, if possible (verify, before fixing!!)
            // jshint -W116
            if (items.length == 0) {
                // jshint +W116
                $(self.myDOM).append(self.cloneTemplate($('[data-template="basketEmpty"]')));

                $('#cartPDF').addClass('disabled');
                $('#cartSend').addClass('disabled');
                $('#cartList').addClass('disabled');
            } else {
                $('#cartPDF').removeClass('disabled');
                $('#cartSend').removeClass('disabled');
                $('#cartList').removeClass('disabled');
            }


            // hide empty stuff
            $('.groupDetail', $(self.myDOM)).each(function() {
                if ($('.articleList>li', $(this)).size() === 0) {
                    $(this).hide();
                }
            });

            self.bindClickEvents();

            self.fire('price:update');
            EventService.fire('basket:rendered');
            window.setTimeout(function() {
                self.fire('price:initPrices');
            }, 500);
        },

        renderBasketItem: function(item) {
            var self = this;

            if (!_.has(item, 'family')) {
                item.family = 'noFamily';
            }

            // check family already in list
            if (!_.has(self.families, item.family)) { // add family block
                self.families[item.family] = {
                    'dom': self.cloneTemplate($('[data-template="family"]'), item),
                    'articleTypes': {},
                    'articleTypeNames': []
                };
                $(self.myDOM).append($(self.families[item.family].dom));
            }

            // add item to family in specific group
            var groupDetail = $('[data-ArticleType*="' + item.ArtType_s + '"]', $(self.families[item.family].dom));
            if ($(groupDetail).size() === 0) {
                groupDetail = $('[data-ArticleType="*"]', $(self.families[item.family].dom));
            }

            if ($(groupDetail).size() === 0) {
                error; // jshint ignore: line
            }

            if (typeof item.ArtTypeName_s === 'string' && item.ArtTypeName_s !== 'N/A') {
                $('h3:first', $(groupDetail)).text(item.ArtTypeName_s);
            }

            var basketItemTemplate = item.objectType;
            if (_.has(item, 'useBasketItemTemplate')) {
                basketItemTemplate = item.useBasketItemTemplate;
                if ($('[data-template="' + basketItemTemplate + '"]').size() === 0) {
                    basketItemTemplate = item.objectType;
                }
            }
            var templ = $('[data-template="' + basketItemTemplate + '"]');
            if ($(templ).size() === 0) {
                templ = $('[data-template="basketItem"]');
            }


            // ABC translation (viva special)
            if (typeof item.deliveryTimeCode === 'string') {
                item.abcMark = item.deliveryTimeCode;
            }

            // ABC translation
            if (item.abcMark) { // set availability text
                item.abcText = self.availabilityText[item.abcMark];
                if (typeof item.abcText === 'undefined') {
                    item.abcText = self.availabilityText['*'].replace('{1}', item.abcMark);
                }
            }

            // isVIVA
            self.updateBasketItem(item);

            self.prepareTemplate(item, templ, groupDetail);
        },

        prepareTemplate: function(item, template, groupDetail) {
            var self = this,
                domItem = self.cloneTemplate($(template), item, 'article');

            if (item.deleted) {
                $(domItem).addClass('deleted');
            }

            if (item.hidePrice) {
                $('.totalPrice', $(domItem)).remove();
            }

            $('ul.articleList', $(groupDetail)).append($(domItem));

            if (!self.families[item.family].articleTypes[item.ArtType_s]) { // update typeNames
                self.families[item.family].articleTypes[item.ArtType_s] = true;
                // self.families[item.family].articleTypeNames.push(item.ArtTypeName_s);
                // write to display
                $('h3:first', $(groupDetail)).text(item.articleTypeName_s);
            }

            if (item.hideBasicTypeCode) {
                var articleConfig = $('.articleConfiguration', domItem);
                if ($(articleConfig).size()) {
                    $(articleConfig).children().empty();
                    $(articleConfig).text(item.coding);
                }
            }
        },

        bindClickEvents: function() {
            var self = this;

            $('a[data-action="delete"]', $(self.myDOM)).click(function(e) {
                var domArticle = $(this).closest('.articleItem'),
                    article = $(domArticle).data('article');
                if (confirm(dict('tecton_configDeleteArticle').replace('{a}', article.title))) { // jshint ignore: line
                    $(domArticle).slideUp('fast', function() {
                        $(domArticle).addClass('deleted').removeClass('selected');
                        $(domArticle).slideDown('slow');

                        // window.setTimeout(function() {
                        //     $(domArticle).remove();
                        // },300);
                    });

                    self.fire('cartItem', {
                        'service': 'delete',
                        'message': 'cartSelector:updateCounter',
                        'pData': {
                            'id': article.id
                        }
                    });
                    e.preventDefault();
                }
                return false;
            });

            $('a[data-action="undelete"]', $(self.myDOM)).click(function(e) {
                var domArticle = $(this).closest('.articleItem'),
                    article = $(domArticle).data('article');
                $(domArticle).slideUp('fast', function() {
                    $(domArticle).removeClass('deleted');
                    $(domArticle).slideDown('slow');
                });
                self.fire('cartItem', {
                    'service': 'undelete',
                    'message': 'cartSelector:updateCounter',
                    'pData': {
                        'id': article.id
                    }
                });
                e.preventDefault();
            });

            $('a[data-action="change"],.basketQty', $(self.myDOM)).click(function() {
                var domArticle = $(this).closest('.articleItem');

                if ($('a[data-action="change"]', $(domArticle)).hasClass('disabled')) {
                    return false;
                }

                self.changeQty(domArticle);
                return false;
            });

            $('a[data-action="move"]', $(self.myDOM)).click(function() {
                var domArticle = $(this).closest('.articleItem');

                $('.domCartSelector', $(domArticle)).val(localStorage.curCart);

                $('.chooseCart', $(domArticle)).toggleClass('off');

                $('.domCartSelector', $(domArticle)).change(function() {
                    self.changeBasket(domArticle, $(this).val());

                    $('.chooseCart', $(domArticle)).toggleClass('off');
                    $('.domCartSelector', $(domArticle)).unbind('change');

                    self.fire('cartSelector:itemMove', {
                        'to': $(this).val()
                    });
                    $(domArticle).slideUp('slow', function() {
                        var domList = $(domArticle).closest('.articleList');
                        $(domArticle).remove();

                        if ($('li', $(domList)).size() === 0) {
                            var domItem = $(domList).closest('.groupItem');

                            $(domList).closest('.groupDetail').slideUp('slow', function() {
                                if ($('.groupDetail:visible', $(domItem)).size() === 0) {
                                    $(domItem).slideUp('slow', function() {
                                        self.updateMenuItems();
                                    });
                                }
                            });
                        }
                    });
                });

                return false;
            });

            $('a[data-action="export"]', $(self.myDOM)).click(function() {
                var domArticle = $(this).closest('.articleItem'),
                    article = $(domArticle).data('article');

                self.fire('cartExport:single', article.id);
            });

            $('.articleOpenClose', $(self.myDOM)).click(function() {
                var domArticle = $(this).closest('.articleItem'),
                    item = $(domArticle).data('article'),
                    templateName = 'articleDetail';

                if (_.has(item, 'useBasketItemDetailTemplate')) {
                    templateName = item.useBasketItemDetailTemplate;
                } else {
                    switch (item.objectType) {
                        case 'cfgResult':
                            templateName = 'cfgResultDetail';
                            break;
                    }
                }

                if ($(domArticle).children('.articleDetail').size() === 0) {
                    var domArticleDetail = self.cloneTemplate($('[data-template="' + templateName + '"]'), {
                        'text': 'loading ...'
                    });

                    $(domArticle).append($(domArticleDetail));
                    new tcgDget.idgArticleDetail($(domArticleDetail));
                }

                if ($(domArticle).hasClass('selected')) {
                    $('.articleDetail', $(domArticle)).slideUp('slow', function() {
                        $(domArticle).toggleClass('selected');
                    });
                    $(this).children('.articleShowDetails').text(dict('articleShowDetails')); // jshint ignore: line
                } else {
                    $('.articleDetail', $(domArticle)).hide();
                    $(domArticle).toggleClass('selected');
                    $('.articleDetail', $(domArticle)).slideDown('slow', function() { });
                    $(this).children('.articleShowDetails').text(dict('articleHideDetails')); // jshint ignore: line
                }

                self.fire('basket:table:update', item);
            });
        },

        updateMenuItems: function() {
            var self = this;
            if ($('.groupDetail:visible').size() === 0) {
                $(self.myDOM).append(self.cloneTemplate($('[data-template="basketEmpty"]')));

                $('#cartPDF').addClass('disabled');
                $('#cartSend').addClass('disabled');
                $('#cartList').addClass('disabled');
            } else {
                $('#cartPDF').removeClass('disabled');
                $('#cartSend').removeClass('disabled');
                $('#cartList').removeClass('disabled');
            }
        },


        changeBasket: function(domArticle, basket) {
            var self = this,
                article = $(domArticle).data('article');

            self.fire('cartItem', {
                'service': 'update',
                'pData': {
                    'id': article.id,
                    'cartID': basket
                }
            });
        },

        changeQty: function(domArticle) {
            var self = this,
                article = $(domArticle).data('article'),
                domQtyBlock = $('.basketQty:first', domArticle);

            if ($('.qty', $(domQtyBlock)).hasClass('off')) { // end edit update db
                $('.edit', $(domQtyBlock)).unbind('blur');
                article.qty = $('.edit', $(domQtyBlock)).val();

                // FIXME use strict equality, if possible (verify, before fixing!!)
                // jshint -W116
                if (article.qty == '') {
                    // jshint +W116
                    article.qty = 1;
                }

                $('.qty', $(domQtyBlock)).text(article.qty);

                $('.qty', $(domQtyBlock)).removeClass('off');
                $('.edit', $(domQtyBlock)).addClass('off');

                $('.unit', $(domQtyBlock)).removeClass('off');
                $(domQtyBlock).removeClass('editMode');

                self.fire('cartItem', {
                    'service': 'update',
                    'pData': {
                        'id': article.id,
                        'qty': +article.qty
                    }
                });

                window.setTimeout(function() {
                    $('a[data-action="change"]', $(domArticle)).removeClass('disabled');
                }, 500);
            } else { // start edit
                $('a[data-action="change"]', $(domArticle)).addClass('disabled');
                var wEdit = $('.qty', $(domQtyBlock)).width() + $('.unit', $(domQtyBlock)).width();
                $('.qty', $(domQtyBlock)).addClass('off');
                $('.unit', $(domQtyBlock)).addClass('off');

                $('.edit', $(domQtyBlock)).width(wEdit);
                $('.edit', $(domQtyBlock)).removeClass('off').focus();
                $('.edit', $(domQtyBlock)).select();

                $(domQtyBlock).addClass('editMode');

                $('.edit', $(domQtyBlock)).blur(function() {
                    self.changeQty(domArticle);
                }).keypress(function(event) {
                    if (event.which === 13) {
                        $(this).blur();
                    }
                });
            }

            self.fire('price:recalculate');
        }
    });


    /*
        the contents of document.ready() should be placed in the
        startup of the catalog page, there is the only place to handle
        the correct startup sequence, otherwise it's not guaranteed
        that all initializations are correctly done!
    */

    $(document).ready(function() {
        $('[data-tcgDget="idgBasket"]').each(function() {
            new tcgDget.idgBasket($(this)); // no need to store the object it's an ecatUI job
        });
    });
})(VivaUtils, CommUtils, AppData, EventService, _, $, tcgDget);
