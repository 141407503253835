/**
 * merkliste
 * $Id: domCart.js,v 1.12 2015-12-15 14:11:14 ml Exp $
 **/
/* All these settings prevent analysis errors but mean
 * deviation from coding standard XXX */
/*jshint browser: true */
/*jshint forin: false */
/*jshint maxcomplexity: 14 */
/*jshint nonew: false */
/* global tcgDOM:false , tcgConfig:false, _:false, $:false */

(function(tcgDOM, tcgConfig, _, $, document, window) { // jshint ignore: line
    'use strict';

    /**
     * @class
     * @augments tcgDget
     * @param {Object} param
     */
    /**
     * @lends tcgDget.idgOrderStatus.prototype
     */
    tcgDOM.domCart = tcgDOM.extend({
        db: null,
        dirty: false,
        dbName: 'cartDB',


        _init_: function() {
            var self = this;

            self.objName = 'domCart';


            self.db = _.load(self.dbName);
            if (self.db == null) { // init db
                self.db = {
                    carts: [],
                    items: []
                };
            }


            self.listen('cart', function(event) {
                event.messageData.result = self.dbDispatch(self.db.carts, event.messageData);
                self.dbOK(event.fired + ':' + event.messageData.service);
            });
            self.listen('cartItem', function(event) {
                event.messageData.result = self.dbDispatch(self.db.items, event.messageData);
                self.dbOK(event.fired + ':' + event.messageData.service);

                if (event.messageData && event.messageData.service === 'update') {
                    self.fire('price:render', { config: event.messageData.pData, element: event.messageData.element });
                }
            });


            $(window).unload({ self: self }, function(event) {
                var selfInFfWrong = event.data.self;
                if (selfInFfWrong.dirty) {
                    _.save(selfInFfWrong.db, selfInFfWrong.dbName);
                    selfInFfWrong.dirty = false;
                }
            });

            window.setInterval(function() {
                if (self.dirty) {
                    _.save(self.db, self.dbName);
                    self.dirty = false;
                    self.dbOK('save');
                } else {
                    self.dbOK('idle');
                }
            }, 10000);
        },


        dbDispatch: function(collection, cmd) {
            var self = this,
                ret = null;

            switch (cmd.service) {
                case 'update/insert':
                    ret = self.dbAddUpdate(collection, cmd.pData);
                    break;
                case 'insert':
                    ret = self.dbAdd(collection, cmd.pData);
                    break;
                case 'remove':
                    ret = self.dbRemove(collection, cmd.pData);
                    break;
                case 'delete':
                    ret = self.dbDel(collection, cmd.pData);
                    break;
                case 'undelete':
                    ret = self.dbUndel(collection, cmd.pData);
                    break;
                case 'update':
                    ret = self.dbUpd(collection, cmd.pData);
                    break;
                case 'list':
                    ret = self.dbList(collection, cmd.pData);
                    break;
                case 'count':
                    ret = self.dbCount(collection, cmd.pData);
                    break;
                case 'sum':
                    ret = self.dbSum(collection, cmd.pData, cmd.key);
                    break;
                case 'copy':
                    ret = self.dbCopy(collection, cmd.pData);
                    break;
                case 'share':
                    ret = self.dbShare(collection, cmd);
                    break;
            }

            switch (typeof cmd.message) {
                case 'string':
                    self.fire(cmd.message, ret);
                    break;
                case 'function':
                    cmd.message(ret, cmd);
                    break;
            }

            return ret;
        },


        /**
         * Add item if not in basked, update qty if in list
         * @param collection
         * @param data
         * @returns
         */
        dbAddUpdate: function(collection, data) {
            var self = this,
                search = {
                    'cartID': data.cartID
                };

            switch (data.objectType) {
                case 'cfgResult':
                    search.coding = data.coding;
                    if (data.key) {
                        search.key = data.key;
                    }
                    break;
                case 'vivaArticle':
                    search.isVIVA = data.isVIVA;
                    break;
                default:
                    search.matnr = data.matnr;
            }

            var item = _(collection).where(search);
            if (_.isEmpty(item)) {
                data.create = data.modify = new Date();
                self.dirty = true;
                return _.insert(collection, _.clone(data));
            }
            self.dirty = true;
            item[0].modify = new Date();
            item[0].qty += data.qty;
            return item[0];
        },


        dbAdd: function(collection, data) {
            var self = this;
            data.create = data.modify = new Date();
            self.dirty = true;
            return _.insert(collection, data);
        },

        dbRemove: function(collection, data) {
            var self = this;
            self.dirty = true;

            return _.removeWhere(collection, data);
        },
        dbDel: function(collection, data) {
            var self = this;
            self.dirty = true;
            return _.update(collection, data.id, { 'deleted': new Date() });
        },
        dbUndel: function(collection, data) {
            var self = this;
            self.dirty = true;
            return _.update(collection, data.id, { 'deleted': null });
        },

        dbUpd: function(collection, data) {
            var self = this;
            data.modify = new Date();
            self.dirty = true;
            return _.update(collection, data.id, data);
        },

        dbList: function(collection, data) {
            if (_.isEmpty(data)) { return collection; }
            return _(collection).where(data);
        },

        filterDeleted: function(collection) {
            return _(collection).filter(function(obj) {
                return _.isNull(obj.deleted) || _.isUndefined(obj.deleted);
            });
        },

        dbCount: function(collection, data) {
            var self = this;


            if (_.isEmpty(data)) { return self.filterDeleted(collection).length; }
            return _(self.filterDeleted(collection)).where(data).length;
        },

        dbSum: function(collection, data, key) {
            var self = this,
                col = self.filterDeleted(collection);
            if (!_.isEmpty(data)) { col = _(col).where(data); }

            return _.reduce(col, function(memo, obj) {
                if (typeof obj[key] === 'number') {
                    return memo + obj[key];
                }
                return memo;
            }, 0);
        },


        dbCopy: function(collection, data) {
            var self = this;

            if (collection === self.db.carts) { // copy cart
                var cart = _.clone(_.findWhere(collection, {
                    'id': data.id
                }));

                for (var key in data) {    // overwrite new attribs
                    cart[key] = data[key];
                }
                delete cart.id;    // we get an new id from db

                cart = _.insert(collection, cart);

                var items = _(self.db.items).where({
                    'cartID': data.id
                });
                for (var i = 0; i < items.length; i++) {
                    if (typeof items[i].deleted !== 'undefined') {
                        continue;
                    }

                    var item = _.clone(items[i]);
                    delete item.id;
                    item.cartID = cart.id;
                    _.insert(self.db.items, item);
                }

                cart.count = items.length;
                self.dirty = true;
                return cart;
            } // copy cart
        },

        dbShare: function(collection, cmd) {
            var self = this;

            if (_.isEmpty(cmd.pData)) {  // share all carts
                self.shareCarts(self.db.carts, self.db.items, cmd);
            } else {
                self.shareCarts(
                    _.where(self.db.carts, cmd.pData),
                    _.where(self.db.items, { 'cartID': cmd.pData.id }),
                    cmd
                );
            }
        },


        dbOK: function(service) {
            $('#cartStatus').text(service + ': OK');
        },

        dbError: function(t, error) {
            $('#cartStatus').text(error.code + ': ' + error.message);
        },

        /**
         *
         */
        shareCarts: function(carts, cartItems, shareData) {
            var self = this;
            if (_.size(carts) === 1) {
                shareData.guuid = carts[0].id;
            } else {
                shareData.guuid = _.createId(carts, {});
            }
            self.fire('solr:save', {
                'core': 'basket',
                'service': 'updateBasket',
                'message': shareData.message,
                'pData': {
                    'objectType': 'basket',
                    'basket': [carts, cartItems],
                    'id': shareData.guuid,
                    'uid': shareData.mailFrom,
                    'title': shareData.title
                }
            }); // fire 'solr:save'
        } // shareCarts
    });


    tcgDOM.domCart.solrSpecial = '\\+-&|!(){}[]^~*?:"; ';

    tcgDOM.domCart.escape = function(q) {
        var ret = '';
        for (var i = 0; i < q.length; i++) {
            var c = q.charAt(i);
            ret += tcgDOM.domCart.solrSpecial.indexOf(c) !== -1 ? '\\' + c : c;
        }
        return ret;
    };


/*
    the contents of document.ready() should be placed in the
    startup of the catalog page, there is the only place to handle
    the correct startup sequence, otherwise it's not guaranteed
    that all initializations are correctly done!
*/

    tcgDOM.domCart.tcgInit = function() {
        $('[data-tcgDOM="domCart"]').each(function() {
            if ($(this).data(tcgConfig.namespace) == null) {
                new tcgDOM.domCart($(this));  // no need to store the object it's an dom job
            }
        });
    };

    $(window).bind('tcg:initialize', function() {
        tcgDOM.domCart.tcgInit();
    });

    $(document).ready(function() {
        tcgDOM.domCart.tcgInit();
    });


    /**
     * $Log: domCart.js,v $
     * Revision 1.12  2015-12-15 14:11:14  ml
     * init bug fixed
     *
     * Revision 1.11  2015-09-29 07:29:15  ml
     * config result object handling
     *
     * Revision 1.10  2015-06-15 07:24:25  ml
     * basket update/insert fix
     *
     * Revision 1.9  2015-05-26 09:25:56  ml
     * fixed sum
     *
     * Revision 1.8  2015-05-13 11:59:55  ml
     * fixed delete cart, added sum function
     *
     * Revision 1.7  2015-04-28 09:41:39  ml
     * delete, send email add to baskte stuff
     *
     * Revision 1.6  2015-04-27 19:52:55  ml
     * delete/undelete function
     *
     * Revision 1.5  2015-04-27 13:28:02  ml
     * browser support stuff, ff fixes
     *
     * Revision 1.4  2015-04-20 11:14:18  ml
     * message as function
     *
     * Revision 1.3  2015-04-17 15:08:52  pr
     * dbName, share cart
     *
     * Revision 1.2  2015-04-09 19:42:44  ml
     * intermediate checkin
     *
     * Revision 1.1  2015-04-06 19:27:07  ml
     * intermediate checkin
     *
     **/
})(tcgDOM, tcgConfig, _, $, document, window);
