/* All these settings prevent analysis errors but mean
 * deviation from coding standard XXX */
/* global tcgConfig, _, $, tcgDOM */
/*jshint browser: true */
/*jshint nonew: false */

(function(tcgConfig, _, $, tcgDOM) {
    'use strict';


    /**
     * @class
     * @augments tcgDOM
     * @param {Object} param
     */
    /**
     * @lends tcgDOM.idgLogin.prototype
     */
    tcgDOM.domCartSelector = tcgDOM.extend({
        _init_: function() {
            var self = this;

            self.objName = 'domCartSelector';
            $(self.myDOM).addClass('attached');

            self.defaultName = $('option:first', $(self.myDOM)).text();
            self.newListText = $('option:last', $(self.myDOM)).text();

            self.listen('cartSelector:list', function(event) {
                self.cartRenderList(_.sortBy(event.messageData, 'sort'));
            });





            $(self.myDOM).change(function() {
                // FIXME use strict equality, if possible (verify, before fixing!!)
                // jshint -W116
                if ($(self.myDOM).val() == 0) { // make new list
                    // jshint +W116
                    self.lastSelectBox = $(self.myDOM);
                    self.prompt4NewList();
                }
            });


            $(window).bind('domCartSelector:show:mbNewBasket', function(event, target) {
                self.lastSelectBox = $(target);
                self.prompt4NewList();
            });


            self.listen('mbNewBasket', function(event) {
                if (event.messageData === 'close') {
                    $(self.lastSelectBox).val($('option:first', $(self.lastSelectBox)).attr('value'));
                }
            });

            self.listen('mbNewBasket:send', function(event) {
                var fData = event.messageData,
                    pData = {
                        'name': fData.title
                    };

                self.doNotReadCartList = true;

                self.fire('cart', {
                    'service': 'insert',
                    'pData': pData
                });

                self.doNotReadCartList = false;
                fData.done = true;


                $('option:last', $('.domCartSelector')).before($('<option>').attr('value', pData.id).text(fData.title));

                // init the correct box
                $(self.lastSelectBox).val(pData.id);


                return false;
            });

            self.listen('cart', function(event) {
                if (!self.doNotReadCartList) {
                    switch (event.messageData.service) {
                        case 'insert':
                        case 'delete':
                        case 'undelete':
                        case 'update':
                            self.cartReadList();
                    }
                }
            });

            self.cartReadList();
        },


        prompt4NewList:function() {
            var self = this;
            // self.fire('messageBox', 'mbNewBasket');

            var newListName = window.prompt($('h1:first', $('#mbNewBasket')).text(),
                self.defaultName.replace('{n}', $('option', $(self.myDOM)).size()));

            self.fire('mbNewBasket:send', {
                'title': newListName
            });
        },

        cartReadList:function() {
            var self = this;

            self.fire('cart', {
                'service': 'list',
                'message': 'cartSelector:list',
                'pData': {
                }
            });
        },


        cartRenderList:function(carts) {
            var self = this;

            $('option', $('.domCartSelector')).remove();

            if (carts.length) {
                if (self.newListText) { // newList allowed?
                    $('.domCartSelector').append($('<option>').attr('value', 0).text(self.newListText));
                }

                for (var i = 0; i<carts.length; i++) {
                    $('.domCartSelector').append($('<option>').attr('value', carts[i].id).text(carts[i].name));
                }


                if (localStorage && localStorage.curCart) {
                    $('.domCartSelector').val(localStorage.curCart);
                } else if (carts.length) {
                    if (localStorage) {
                        localStorage.curCart = carts[0].id;
                    }

                    $('.domCartSelector').val(carts[0].id);
                }
            } else { // create cart
                self.fire('cart', {
                        'service': 'insert',
                        'pData': {
                            'name': self.defaultName.replace('{n}', 1)
                        }
                    });
            }
        }
    });



/*
    the contents of document.ready() should be placed in the
    startup of the catalog page, there is the only place to handle
    the correct startup sequence, otherwise it's not guaranteed
    that all initializations are correctly done!
*/

    tcgDOM.domCartSelector.tcgAttach = function() { // only first select box
        $('.domCartSelector:first').each(function() {
            if (typeof $(this).data(tcgConfig.namespace) === 'undefined') {
                new tcgDOM.domCartSelector($(this));  // no need to store the object it's an ecatUI job
            }
        });
    };


    $(window).bind('tcg:attach', function() {
        // tcgDOM.domCartSelector.tcgAttach();

        $('.domCartSelector:not(.attached)').each(function() {
                $(this).unbind('change');
                $(this).change(function() {
                    // FIXME use strict equality, if possible (verify, before fixing!!)
                    // jshint -W116
                    if ($(this).val() == 0) { // make new list
                        // jshint +W116
                        $(window).trigger('domCartSelector:show:mbNewBasket', $(this));
                    }
                });
            });
    });


    $(document).ready(function() {
        tcgDOM.domCartSelector.tcgAttach();
    });




    /**
     * $Log: domCartSelector.js,v $
     * Revision 1.7  2015-06-19 15:52:51  ml
     * fix for 0 element = new list
     *
     * Revision 1.6  2015-06-19 15:36:05  ml
     * new list now at top of selector
     *
     * Revision 1.5  2015-06-15 14:29:15  ml
     * cart selector - without new list
     *
     * Revision 1.4  2015-04-27 19:52:55  ml
     * delete/undelete function
     *
     * Revision 1.3  2015-04-27 13:28:02  ml
     * browser support stuff, ff fixes
     *
     * Revision 1.2  2015-04-17 17:02:57  ml
     * cart selector stuff
     *
     * Revision 1.1  2015-04-06 19:27:07  ml
     * intermediate checkin
     *
    **/
})(tcgConfig, _, $, tcgDOM);
