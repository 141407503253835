/* jshint browser:true */

/**
 * Handles the data-access for the basket
 */
// IIFEs are exluded from maxparams-rule
(function(global, EventService, Promise) { // jshint ignore:line
    'use strict';

    // EXPORTS

    global.BasketService = {
        getBasketItemByVivaConfig: getBasketItemByVivaConfig,
        updateBasketItem: updateBasketItem
    };

    // IMPLEMENTATION DETAILS

    /**
     * Retrieve the first basket-item which matches the viva configuration-String.
     * @param {String}  vivaConfig      Configuration of material as String-representation
     * @returns {Promise<Object|null>}  Promise to a matching basket-item. <code>null</code>, if not found
     */
    function getBasketItemByVivaConfig(vivaConfig) {
        return new Promise(function(resolve) {
            EventService.fire('cartItem', {
                'service': 'list',
                'message': function __handleResponse(matchingItems) {
                    resolve(matchingItems.length > 0 ? matchingItems[0] : null);
                },
                'pData': {
                    'isVIVA': vivaConfig
                }
            });
        });
    }

    /**
     * Updates the basket-item in the data storave
     * @param {Object}  basketItem  The already persistent basket-item, which has to be updated.
     * @returns {Promise<Object>}   Promise to the updated <code>basketItem</code>
     */
    function updateBasketItem(basketItem) {
        return new Promise(function(resolve) {
            EventService.fire('cartItem', {
                'service': 'update',
                'message': function __handleResponse(updatedItem) {
                    resolve(updatedItem);
                },
                'pData': basketItem
            });
        });
    }
})(window, window.EventService, window.Promise);
