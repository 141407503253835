/* All these settings prevent analysis errors but mean
 * deviation from coding standard XXX */
/*jshint maxcomplexity: 25 */
/*jshint browser: true */
/* global $ */

(function($, window) {
    'use strict';

    var tcgPage = null,
        tcgConfig = {};
    window.tcgGetPageProperty = tcgGetPageProperty;
    window.tcgSetPageProperty = tcgSetPageProperty;
    window.tcgConfig = tcgConfig;
    window.debug = debug;

    /**
     * @eCatFieldType tcgConfig
     */


    function tcgInitURLParams(bForce, url) {
        // FIXME use strict equality, if possible (verify, before fixing!!)
        // jshint -W116
        if (tcgURLParams == null || bForce) {
            // jshint +W116
            tcgURLParams = [];
        } else {
            return;
        }

        var hash = '#',
            search = '?';

        if (typeof url === 'string') { // tahe from url parameter
            if (url.indexOf('?') > 0) {
                if (url.indexOf('#') > 0) {
                    search = url.substring(url.indexOf('?'), url.indexOf('#'));
                } else {
                    search = url.substr(url.indexOf('?'));
                }
            }
            if (url.indexOf('#') > 0) {
                hash = url.substr(url.indexOf('#'));
            }
        } else { // take from document location
            hash = document.location.hash;
            search = document.location.search;
        }

        // make url parameter check
        if (search.length > 1) {
            var props = search.substr(1).split('&');
            for (var i = 0; i < props.length; i++) {
                var keyval = props[i].split('=');
                try {
                    if (keyval.length === 2) {
                        tcgURLParams[decodeURIComponent(keyval[0])] = decodeURIComponent(keyval[1]);
                    } else {
                        tcgURLParams.tcgDefault = decodeURIComponent(keyval[0]);
                        tcgURLParams.tcgTarget = decodeURIComponent(keyval[0]);
                    }
                } catch (e) {}
            }
        }

        if (hash.length > 1) {
            try {
                tcgURLParams.tcgDefault = decodeURIComponent(hash.substr(1));
                tcgURLParams.tcgHash = decodeURIComponent(hash.substr(1));
            } catch (e) {}
        }
    }

    var tcgURLParams = null; // jshint ignore: line


    /**
     * @param property string
     * @param prop1 optional where to search default='url, cookie, meta'
     * @param prop2 optional if found in cookie, remove the cookie, default=false
     * @deprecated Use `AppData.getPageProperty(...)` instead
     */
    function tcgGetPageProperty(property, prop1, prop2) {
        var where = 'url,cookie,meta',
            removeCookie = false;

        if (typeof prop1 === 'string') {
            where = prop1;
            if (typeof prop2 === 'boolean') {
                removeCookie = prop2;
            }
        } else if (typeof prop1 === 'boolean') {
            removeCookie = prop1;
        }

        var ret = null,
            where2search = where.split(',');
        while (where2search.length) {
            switch (where2search.shift()) {
                case 'meta':
                    if ($('meta[name="' + property + '"]').is('meta')) {
                        ret = $('meta[name="' + property + '"]').attr('content');
                    }
                    break;

                case 'localStorage':
                    if (typeof localStorage === 'object') {
                        ret = localStorage['tcg-' + property];
                    }
                    // FIXME use strict equality, if possible (verify, before fixing!!)
                    // jshint -W116
                    if (ret != null && removeCookie) {
                        // jshint +W116
                        delete localStorage['tcg-' + property];
                    }
                    break;

                case 'cookie':
                    if (typeof localStorage === 'object') {
                        ret = localStorage['tcg-' + property];

                        // FIXME use strict equality, if possible (verify, before fixing!!)
                        // jshint -W116
                        if (ret != null && removeCookie) {
                            // jshint +W116
                            delete localStorage['tcg-' + property];
                        }
                    }
                    if (!ret) {
                        ret = $.cookie(property);
                        // FIXME use strict equality, if possible (verify, before fixing!!)
                        // jshint -W116
                        if (ret != null && removeCookie) {
                            // jshint +W116
                            $.cookie(property, null, { path: '/' });
                        }
                    }
                    break;
                case 'url':
                    tcgInitURLParams();
                    if (typeof tcgURLParams[property] !== 'undefined') {
                        ret = tcgURLParams[property];
                    }
                    if (!ret && property.indexOf('tcg') === 0) { // try without tcg of tcgXXXXXX
                        if (typeof tcgURLParams[property.substr(4)] !== 'undefined') {
                            ret = tcgURLParams[property.substr(4)];
                        }
                        if (!ret && typeof tcgURLParams[property.substr(4).toLowerCase()]) {
                            ret = tcgURLParams[property.substr(4).toLowerCase()];
                        }
                    } // try without tcg of tcgXXXXXX

                    if (typeof ret !== 'undefined' && !removeCookie && tcgURLParams.cookie === 'set') {// set param into cookie
                        if (document.location.href.indexOf('file:') !== -1 || typeof localStorage === 'object') {
                            if (typeof Storage !== 'undefined' && typeof localStorage !== 'undefined' &&
                                    typeof ret === 'string') {
                                localStorage['tcg-' + property] = ret;
                            } else {
                                $.cookie(property, ret, { path: '/' });
                            }
                        } else {
                            $.cookie(property, ret, { path: '/' });
                        }
                    }
                    break;
            }

            if (ret) {// already found?
                break;
            }
        }

        return ret;
    }

    /**
     * set property ito cookie or local storage if in file mode
     * @param property
     * @param value
     */
    function tcgSetPageProperty(property, value, options) { // jshint ignore: line
        if (document.location.href.indexOf('file:') !== -1) {
            if (typeof Storage !== 'undefined' && typeof localStorage !== 'undefined') {
                localStorage['tcg-' + property] = value;
            }
        } else {
            var cookieOptions = {
                path: '/'
            };


            if (typeof options === 'object') {
                $.extend(cookieOptions, options);
            }

            $.cookie(property, value, cookieOptions);
        }
    }


    tcgPage = tcgGetPageProperty('tcgPage', 'meta');

    // if (tcgPage!=undefined && 'category,application,family,article,statistic'.indexOf(tcgPage)==-1)
    //    throw 'tcgPage is undefined';

    window.tcgTarget = tcgGetPageProperty('tcgTarget', true);

    $(window).bind('tcg:reload', function() {
        tcgPage = tcgGetPageProperty('tcgPage', 'meta');
        window.tcgTarget = tcgGetPageProperty('tcgTarget', true);
    });


    /**
     * main configuration object.
     * @class
     * @name _tcgConfig
     *
     * @description All values explained here with their defaults
     * values are set or get with tcgConfig['key']=value;<br/><br/>
     *
     * <code>
     *   tcgConfig['debug'] = true;<br/>
     *   var debug = tcgConfig['debug'];<br/>
     *   var browserVersion = tcgConfig['browser.version'];
     * </code><br/><br/>
     *
     * <i>Attention the documentation features of jsdoc made it neccessary
     * to name for example browser.version as _tcgConfiguration.browser_version
     * in the following documentation. All underscores will be replaced by points.</i>
     * @eCatFieldType tcgConfig
     *
     */

    /**
     * @name _tcgConfig.debug
     * @example tcgConfig['<b>debug</b>']=true;
     * @default false
     */
    // FIXME use strict equality, if possible (verify, before fixing!!)
    // jshint -W116
    if (tcgGetPageProperty('debug') && tcgGetPageProperty('debug') != 'false') {
        // jshint +W116
        tcgConfig.debug = true;
    } else {
        tcgConfig.debug = false;
    }


    tcgConfig.namespace = 'biz.trinea';


    $.parseJSON.safe = true; // make it faster - but unsafe


    function debug(msg, title) { // jshint ignore: line
        if (tcgConfig.debug === false) {
            return;
        }
        if (window.console && window.console.log) {
            if (typeof(msg) === 'object') {
                window.console.log('------------------------------------------------');
                window.console.log('tcg Array: ' + title);
                $.each(msg, function(key, value) {
                    window.console.log('ArrayValue: ' + key + '=' + value);
                });
                window.console.log('--------');
            } else {
                window.console.log('tcg: ' + msg);
            }
        } else if (msg.indexOf('force alert') !== -1) {
            alert('tcg: ' + msg); // jshint ignore: line
        }
    }


    /* Zm9ybWF0dGVyLmpzCg== */
    /*
     * $Id: formatter.js$
     */


    Number.prototype.icN = function() {
        var thouSeparator,
            decSeparator,
            n         = this,
            decPlaces = isNaN(decPlaces = Math.abs(decPlaces)) ? 2 : decPlaces, // jshint ignore: line
            decSeparator  = decSeparator == undefined ? '.' : decSeparator, // jshint ignore: line
            thouSeparator = thouSeparator == undefined ? ',' : thouSeparator, // jshint ignore: line
            sign          = n < 0 ? '-' : '',
            i             = parseInt(n = Math.abs(+n || 0).toFixed(decPlaces)) + '',
            j             = (j = i.length) > 3 ? j % 3 : 0;
        return sign + (j ? i.substr(0, j) + thouSeparator : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thouSeparator) + (decPlaces ? decSeparator + Math.abs(n - i).toFixed(decPlaces).slice(2) : '');
    };

    Number.prototype.icP = function(decPlaces) {
        return (this * 100.0).icR(decPlaces);
    };

    String.prototype.icN = function() {
        return +(this.replace(/^\s+|\s+$/g, '').replace(/,/g, '.').replace(/^([0-9]*([.][0-9]*){0,1}).*/, '$1'));
    };

    Number.prototype.icR = function(decPlaces) {
        return this.sgn() * (Math.floor(Math.abs(this) * Math.pow(10, decPlaces) + 0.5)) / Math.pow(10, decPlaces);
    };

    Number.prototype.sgn = function() { return this > 0 ? 1 : this < 0 ? -1 : 0; };


    function getHTMLfromXML(xml) { // jshint ignore: line
        return $('<div/>').append($(xml).children().clone()).remove().html().replace(/\/\>/g, '/>\n').replace(/\<\/(\w+)\>/g, '</$1>\n'); // jshint ignore: line
    }

    Number.min = function(x, y) {
        return x > y ? y : x;
    };

    Number.max = function(x, y) {
        return x > y ? x : y;
    };
})($, window);
