/* All these settings prevent analysis errors but mean
 * deviation from coding standard XXX */
/*jshint nonew: false */
/*jshint browser: true */
/* global _, ecatUserProfile, $, tcgDget */

(function(_, ecatUserProfile, $, tcgDget) {
    'use strict';

    /**
     * @class
     * @augments tcgDget
     * @param {Object} param
     */
    /**
     * @lends tcgDget.idgLogin.prototype
     */
    tcgDget.idgPath = tcgDget.extend({
        _init_: function() {
            var self = this;

            self.objName = 'idgPath';
            self.setDgetMode('single');


            // self.listen('family', function(event) {
            //     var family=event.messageData;

            //     var list=$('ul.pathList', $(self.myDOM))
            //     $(list).html('');
            //     $(list).append($('<li>').text(family.level_0_name));
            //     $(list).append($('<li>').text(family.level_1_name));
            //     $(list).append($('<li>').text(family.level_2_name));
            //     // $(list).append($('<li>').text(family.level_3_name));
            // });
        }


    });


/*
    the contents of document.ready() should be placed in the
    startup of the catalog page, there is the only place to handle
    the correct startup sequence, otherwise it's not guaranteed
    that all initializations are correctly done!
*/

    $(document).ready(function() {
        $('[data-tcgDget="idgPath"]').each(function() {
            new tcgDget.idgPath($(this));  // no need to store the object it's an ecatUI job
        });
    });


    /**
     * $Log: idgPath.js,v $
     * Revision 1.3  2016-02-16 08:19:00  ml
     * tree navigation
     *
     * Revision 1.2  2015-04-26 12:06:53  ml
     * initial call objects
     *
     * Revision 1.1  2015-04-07 08:11:57  ml
     * pre Build
     *
     **/
})(_, ecatUserProfile, $, tcgDget);
