/* jshint browser: true */
(function(window) {
    'use strict';

    window.IsVIVA = {
        makeString: function(infoItem) { // jshint ignore: line
            var options = [],
                components = [],
                family = !!infoItem.vivaStringFamily ? infoItem.vivaStringFamily : infoItem.family;

            for (var i = 0; i < infoItem.options.length; i++) {
                // FIXME use strict equality, if possible (verify, before fixing!!)
                // jshint -W116
                if (infoItem.options[i].value !== '' && typeof infoItem.options[i].eid === 'string') {
                    // jshint +W116
                    switch (infoItem.options[i].eid.charAt(0)) {
                        case 'A': // attribute
                            break;
                        case 'E': // extra item
                            options.push((infoItem.options[i].smlCode ?
                                infoItem.options[i].smlCode :
                                infoItem.options[i].value) + ':' + infoItem.options[i].id);
                            break;
                        case 'I': // iso7010
                            options.push(
                                infoItem.options[i].smlCode +
                                '!' + infoItem.options[i].iso7010 +
                                ':' + infoItem.options[i].id
                            );
                            break;
                        case 'C': // component
                            components.push(infoItem.options[i].value + ':' + infoItem.options[i].id);
                            break;

                        default: // unknown
                            alert('undefined item type group ' + infoItem.options[i].id); // jshint ignore: line
                            break;
                    } // type of item
                } // item selected
            } // all options

            var isVIVA = family + ';' +
                options.join('|') + ';' + components.join('|') + ';' +
                (/$[0-9]{8}^/.test(infoItem.coding) ? infoItem.coding : '') + ':' +
                infoItem.prodName + ';' +
                infoItem.baseArticle;
            return isVIVA;
        }, // init()

        /**
         * check's material number to be 8 digits long
         * @param {String} materialNr SAP MATNR
         */
        isValidMaterialNumber: function(materialNr) {
            return /^\d{8}$/.test(materialNr);
        },

        /**
         * replaces/sets the MATNR part of the isVIVA config string
         * isVIVA Format: [FAMILY];[ExtraItems];[Components];[MATNR]:[NAME];[BaseArticleNumber]
         * @param {string} isVIVA           VIVA config string
         * @param {string} MATRN   SAP MATNR
         */
        updateIsVIVAMaterialNumber: function(isVIVA, MATNR) {
            var ret = isVIVA,
                fmt = isVIVA.split(';'); // extract parts

            if (fmt.length >= 5) { // enugh parts available
                var f3 = fmt[3].split(':'); // separate MATNR from NAME
                f3[0] = MATNR; // replace MATNR
                fmt[3] = f3.join(':'); // glue togehter
                ret = fmt.join(';'); // more glue
            }
            return ret;
        }
    };
})(window);
