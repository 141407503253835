// jscs:disable maximumNumberOfLines
/* All these settings prevent analysis errors but mean
 * deviation from coding standard XXX */
/*jshint nonew: false */
/*jshint maxdepth: 5 */
/*jshint maxcomplexity: 27 */
/*jshint browser: true */
/* global tcgDget, jQuery, _ */
(function(window, tcgDget, $, _) {
    'use strict';

    window.iso7070InitSelector = iso7070InitSelector;

    function iso7070InitSelector(dom) {
        var urlSigns = '/PDB/Ressource/web/viva/iso7010/'; // '../css/iso7010signs/';

        $('select', $(dom)).each(function() {
            // Cache the number of options
            var $this = $(this);

            // Wrap the select element in a div
            $this.wrap('<div class="iso7010Select"></div>');

            // copy classes
            $this.closest('.iso7010Select').addClass($this.attr('class'));

            // Hides the select element
            $this.addClass('iso7010-hidden');

            // Insert a styled div to sit over the top of the hidden select element
            $this.after('<div class="iso7010StyledSelect"></div>');


            // Cache the styled div
            var $styledSelect = $this.next('div.iso7010StyledSelect');

            // Show the first select option in the styled div
            $styledSelect.text($this.children('option').eq(0).text());

            $.each($this.children('option').eq(0)[0].attributes, function() {
                // this.attributes is not a plain object, but an array
                // of attribute nodes, which contain both the name and value
                if (this.specified) {
                    $styledSelect.attr(this.name, this.value);
                    if (this.name === 'data-image') {
                        $styledSelect.css('background-image', 'url(' + urlSigns + this.value + ')');
                    }
                }
            });


            // Insert an unordered list after the styled div and also cache the list
            var $list = $('<ul />', {
                'class': 'iso7010options'
            }).insertAfter($styledSelect);


            // Insert a list item into the unordered list for each select option
            $.each($this.children('option'), function() {
                var li = $('<li />', {
                    text: $(this).text(),
                    rel: $(this).val()
                });
                $.each(this.attributes, function() {
                    // this.attributes is not a plain object, but an array
                    // of attribute nodes, which contain both the name and value
                    if (this.specified) {
                        $(li).attr(this.name, this.value);
                        if (this.name === 'data-image') {
                            $(li).css('background-image', 'url(' + urlSigns + this.value + ')');
                        }
                    }
                });
                $(li).appendTo($list);
            });

            // // Insert a list item into the unordered list for each select option
            // for (var i = 0; i < numberOfOptions; i++) {
            //     $('<li />', {
            //         text: $this.children('option').eq(i).text(),
            //         rel: $this.children('option').eq(i).val()
            //     }).appendTo($list);
            // }

            // Cache the list items
            var $listItems = $list.children('li');

            // Show the unordered list when the styled div is clicked (also hides it if the div is clicked again)
            $styledSelect.click(function(e) {
                e.stopPropagation();
                if ($(this).hasClass('.disabled') || $(this).closest('.disabled').length === 1) {
                    return false;
                }

                $('div.styledSelect.active').each(function() {
                    $(this).removeClass('active').next('ul.iso7010options').hide();
                });
                $(this).toggleClass('active').next('ul.iso7010options').toggle();
            });

            // Hides the unordered list when a list item is clicked and updates the styled div to show the selected list item
            // Updates the select element to have the value of the equivalent option
            $listItems.click(function(e) {
                e.stopPropagation();
                $styledSelect.text($(this).text()).removeClass('active');

                var dataAttr = $styledSelect[0].attributes,
                    toBDeleted = [];
                $.each(dataAttr, function() { // remove all data attribs
                    if (this && this.name.indexOf('data-') === 0) {
                        toBDeleted.push(this.name);
                        // $styledSelect.removeAttr(this.name);
                    }
                });
                for (var i = 0; i < toBDeleted.length; i++) {
                    $styledSelect.removeAttr(toBDeleted[i]);
                }

                // var dataAttr = $styledSelect.data();
                // $.each($styledSelect.data(), function(i) {
                //     $styledSelect.removeAttr('data-' + i);
                // });

                $.each(this.attributes, function() {
                    // this.attributes is not a plain object, but an array
                    // of attribute nodes, which contain both the name and value
                    if (this.specified && this.name !== 'class') {
                        $styledSelect.attr(this.name, this.value);
                        if (this.name === 'data-image') {
                            if (this.value !== '') {
                                $styledSelect.css('background-image', 'url(' + urlSigns + this.value + ')');
                            } else {
                                $styledSelect.css('background-image', 'none');
                            }
                        }
                    }
                });


                $this.val($(this).attr('rel'));
                $list.hide();

                $this.trigger('change');
                /* alert($this.val()); Uncomment this for demonstration! */
            });

            $this.on('change', function() {
                // console.log('select changed to ', $(this).val());
                // $('[rel="+$(this).val()+"]', $listItems).trigger('click');
            });

            // Hides the unordered list when clicking outside of it
            $(document).click(function() {
                $styledSelect.removeClass('active');
                $list.hide();
            });
        });
    } // iso7070InitSelector


    function cleanCustomCombo(sel) {
        var dom = $('.iso7010StyledSelect', $(sel));

        $(dom).css('background-image', '');
        $.each($(dom)[0].attributes, function() {
            if (this && this.name && this.name.indexOf('data-') === 0) {
                $(dom).removeAttr(this.name);
            }
        });
    } // cleanCustomCombo

    tcgDget.idgVIVAConfigISO7010 = tcgDget.idgVIVAConfig.extend({

        // $.extend(tcgDget.idgVIVAConfig.Scopes[0].properties, {


        _init_: function() {
            var self = this;
            //  this._super();

            self.objName = 'idgVIVAConfigISO7010';

            self.initVivaListeners();
            /*
                        self.listen('vivaActive', function(e) {

                            if (e.firedBy === self.myDOM) {
                                window.setTimeout(function() {
                                    self.vivaISO7010Extension($('[data-viva-config-type="ISO7010-Signs"]', $(self.myDOM)));
                                }, 1000);
                            } else {
                                console.log('vivaActive from ', e.firedBy.selector);
                            }

                        });

                        */
        },


        // get aspect ration for preview
        calculateAspectRatio: function(aspectRatio) {
            var ar = 1;

            // floating point
            var reFloat = /^[-+]?\d*\.?\d*$/;

            // a:b
            var reAtoB = /^(\d+):(\d+)$/;

            // check if simple aspect ratio
            if (reFloat.test(aspectRatio)) {
                ar = Number.parseFloat(aspectRatio);
            } else if (reAtoB.test(aspectRatio)) { // nice
                var ab = reAtoB.exec(aspectRatio);
                ar = ab[1] / ab[2];
            }

            return ar;
        },

        // get/cycle display mode of preview
        displayMode: function(dm) {
            var self = this;

            // preview
            var dom = $('.iso7010-preview', self.myDOM);

            // display mode
            if (_.isUndefined(dm)) { // query
                dm = $(dom).attr('data-display-mode');
            } else { // cycle
                dm = $(dom).attr('data-display-mode');
                if (dm === '2D') {
                    dm = '3D';
                } else if (dm === '3D') {
                    dm = 'IMAGE';
                } else {
                    dm = '2D';
                }
                $(dom).attr('data-display-mode', dm);
            }
            return dm;
        },


        renderISO7010Preview: function(domParent) {
            $('.iso7010-selector', $(domParent)).append(
                $('<label>').attr('for', 'iso7010-preview').text($('[data-dict-key="iso7010-preview"]').text())
            );
            $('.iso7010-selector', $(domParent))
                .append($('<div>').addClass('iso7010-preview').attr('data-display-mode', '2D')
                    .html(
                        '<div class="space3d">' +
                        '  <div class="_3dbox">' +
                        '    <div class="_3dface _3dface--0"></div>' +
                        '    <div class="_3dface _3dface--front">' +
                        '      <div class="first"></div>' +
                        '      <div class="second"></div>' +
                        '      <div class="iso-logo"></div>' +
                        '    </div>' +
                        '    <div class="_3dface _3dface--top">top</div>' +
                        '    <div class="_3dface _3dface--bottom">bottom</div>' +
                        '    <div class="_3dface _3dface--left">' +
                        '      <div class="first"></div>' +
                        '      <div class="second"></div>' +
                        '      <div class="iso-logo"></div>' +
                        '    </div>' +
                        '    <div class="_3dface _3dface--right">' +
                        '      <div class="first"></div>' +
                        '      <div class="second"></div>' +
                        '      <div class="iso-logo"></div>' +
                        '    </div>' +
                        '    <div class="_3dface _3dface--back">' +
                        '      <div class="first"></div>' +
                        '      <div class="second"></div>' +
                        '      <div class="iso-logo"></div>' +
                        '    </div>' +
                        '  </div>' +
                        '</div>'));
        },

        defaultAspect: 0,

        resizeISO7010Preview: function() {
            var self = this;

            if (self.defaultAspect === 0) { // initialize
                self.defaultAspect = self.calculateAspectRatio(
                    _.has(self.vivaConfig.FamilyProperties, 'AspectRatio') ?
                    self.vivaConfig.FamilyProperties.AspectRatio : '1:1'
                );
            }

            var dimX = 160,
                dimY = dimX / self.defaultAspect,
                dimZ = 5;

            // side count
            var side = self.getISO7010sides();

            if (side === '1' || side === '2') {
                self.iso7010PreviewDimensions(dimX, dimY, dimZ, side);
            } else {
                self.iso7010PreviewDimensions(dimX, dimX, dimX, side);
            }
        },

        defaultSided: 0,

        getISO7010sides: function() {
            var self = this;

            if (self.defaultSided === 0) { // init
                //  # of sides if not configurable
                self.defaultSided = _.has(self.vivaConfig.FamilyProperties, 'DefaultSided') ?
                    self.vivaConfig.FamilyProperties.DefaultSided + '-side' : '4-side';
            }
            // currend side count
            var side = self.defaultSided; // 1-side, 2-side, 3-side or 4-side

            // side
            var domSel = $('[data-viva-attr="Attr3"] .smallButton.selected', $(self.myDOM));
            if ($(domSel).length) {
                var name = $(domSel).attr('data-viva-name');
                if (name.indexOf('-side') === 1) {
                    side = name;
                }
            }

            return side.replace('-side', ''); // returns 1,2,3,4
        },

        vivaISO7010Extension: function(domParent) {
            var self = this;
            if (_.has(self.vivaConfig, 'SafetySigns') && _.isArray(self.vivaConfig.SafetySigns.SafetySign)) {
                var og = $('.vivaOptionGroup[data-viva-config-type="ISO7010-Signs"]', $(domParent));
                if ($(og).length === 1) {
                    var iso7010 = $('<div>').addClass('iso7010-selector iso7010-combo');

                    _.each(_.keys(self.vivaConfig.SafetySigns), function(key) {
                        if (!_.isObject(this[key])) {
                            $(iso7010).attr('data-' + key, this[key]);
                        }
                    }, self.vivaConfig.SafetySigns);

                    var iso7010Categories = $('<select>').attr('name', 'iso7010-category')
                        .addClass('iso7010-category iso7010-combo');

                    $(iso7010Categories).append($('<option>').attr('value', '').text('?')); // empty
                    _.chain(self.vivaConfig.SafetySigns.SafetySign).pluck('Category').uniq().each(function(v) {
                        var cat = _.findWhere(self.vivaConfig.SafetySignCategories.SafetySignCategory, {
                            'Category': v
                        });

                        // category
                        var iso7010category = $('<option>').attr('value', v).text(v);

                        _.each(_.keys(cat), function(key) {
                            if (!_.isObject(this[key])) {
                                $(iso7010category).attr('data-' + key, this[key]);
                            }
                            if (key === 'Description') {
                                $(iso7010category).attr('alt', this[key]);
                            }
                        }, cat);

                        $(iso7010Categories).append(iso7010category);
                    });
                    $(iso7010).append($('<label>').attr('for', 'iso7010-category')
                        .text($('[data-dict-key="iso7010-category"]').text())
                    );
                    $(iso7010).append($(iso7010Categories));

                    var iso7010First = $('<select>').attr('name', 'iso7010-first')
                        .addClass('iso7010-first iso7010-combo disabled');
                    // $(iso7010First).append($('<option>').attr('value', '').text('')); // empty
                    _.chain(self.vivaConfig.SafetySigns.SafetySign).sortBy(function(s) {
                        return parseInt(s.OrderingValue);
                    }).each(function(s) {
                        if (s.IsArrow !== '1') { // no arrows in first
                            var iso7010sign = $('<option>').attr('value', s.ExtraItemCode).text(s.Name1Sided);
                            _.each(_.keys(s), function(key) {
                                if (!_.isObject(this[key])) {
                                    $(iso7010sign).attr('data-' + key, this[key]);
                                }
                                if (key === 'Description') {
                                    $(iso7010sign).attr('alt', this[key]);
                                }
                            }, s);
                            $(iso7010sign).attr('data-viva-id', 'E' + s.ExtraItemId); // to allow not-with/only-with
                            $(iso7010First).append($(iso7010sign));
                        } // no arrows in first
                    });
                    $(iso7010).append($('<label>')
                        .attr('for', 'iso7010-first').text($('[data-dict-key="iso7010-first"]').text())
                    );
                    $(iso7010).append($(iso7010First));

                    var iso7010Second = $('<select>').attr('name', 'iso7010-second')
                        .addClass('iso7010-second iso7010-combo disabled');
                    $(iso7010Second).append($('<option>')
                        .attr({
                            'value': '',
                            'data-isarrow': '0',
                            'data-category': 'E',
                            'data-image': ''
                        })
                        .text('')); // empty
                    $(iso7010Second).append($('<option>')
                        .attr({
                            'value': '',
                            'data-isarrow': '0',
                            'data-category': 'F',
                            'data-image': ''
                        })
                        .text('')); // empty
                    _.chain(self.vivaConfig.SafetySigns.SafetySign).sortBy(function(s) {
                        return parseInt(s.OrderingValue);
                    }).each(function(s) {
                        var iso7010sign = $('<option>').attr('value', s.ExtraItemCode).text(s.Name1Sided);
                        _.each(_.keys(s), function(key) {
                            if (!_.isObject(this[key])) {
                                $(iso7010sign).attr('data-' + key, this[key]);
                            }
                            if (key === 'Description') {
                                $(iso7010sign).attr('alt', this[key]);
                            }
                        }, s);
                        $(iso7010sign).attr('data-viva-id', 'E' + s.ExtraItemId); // to allow not-with/only-with
                        $(iso7010Second).append($(iso7010sign));
                    });
                    $(iso7010).append($('<label>')
                        .attr('for', 'iso7010-second')
                        .text($('[data-dict-key="iso7010-second"]').text())
                    );
                    $(iso7010).append($(iso7010Second));

                    var eitLogo = _.findWhere(self.vivaConfig.EAIT, {
                        CfgType: 'ISO7010-Logo'
                    });
                    if (eitLogo) { // logo option?
                        var iso7010Logo = $('<select>').attr('name', 'iso7010-logo')
                            .addClass('iso7010-logo iso7010-combo');

                        _.each(_.keys(eitLogo), function(key) {
                            $(iso7010Logo).attr('data-' + key, this[key]);
                        }, eitLogo);
                        _.chain(eitLogo.Item).sortBy('OrderingValue').each(function(item) {
                            var opt = $('<option>').attr('value', item.ExtraItemId).text(item.Name);
                            _.each(_.keys(item), function(key) {
                                if (!_.isObject(this[key])) {
                                    $(opt).attr('data-' + key, this[key]);
                                }
                                if (key === 'Description') {
                                    $(opt).attr('alt', this[key]);
                                }
                            }, item);

                            $(iso7010Logo).append($(opt));
                        });

                        // needs ZL as Option?
                        if (_.chain(eitLogo.Item).where({
                                'Name': 'ZL'
                            }).size().value() === 0) {
                            $(iso7010Logo).prepend($('<option>').attr({
                                'value': '',
                                'alt': 'ZUMTOBEL Logo',
                                // 'background-image': 'url(/css/zumtobel.png)',
                                'data-smlcode': ''
                            }).text('ZL')); // zl logo empty
                        }

                        $(iso7010).append($('<label>')
                            .attr('for', 'iso7010-logo')
                            .text($('[data-dict-key="iso7010-logo"]').text())
                        );
                        $(iso7010).append($(iso7010Logo));
                    } // make a select for logo

                    $(og).append($(iso7010));
                    window.iso7070InitSelector($(iso7010));

                    cleanCustomCombo($('.iso7010-first', $(domParent)));
                    cleanCustomCombo($('.iso7010-second', $(domParent)));

                    $('h5', $(og)).on('dblclick', function() {
                        if ($(og).is('.open')) {
                            $('.smallButtonList', $(og)).slideUp('fast', function() {
                                $(og).toggleClass('open');
                            });
                        } else {
                            $('.smallButtonList', $(og)).slideDown('fast', function() {
                                $(og).toggleClass('open');
                            });
                        }
                    });
                    $('ul.smallButtonList', $(og)).hide();

                    $(self.myDOM).find('[data-viva-config-type="ISO7010-Logo"]').hide();
                }


                // preview

                self.renderISO7010Preview($(domParent));

                $('.iso7010-preview', $(domParent)).on('click', function() {
                    self.displayMode($(this).attr('data-display-mode'));
                    self.resizeISO7010Preview();
                });

                self.resizeISO7010Preview();


                // side
                $('[data-viva-attr="Attr3"] .smallButton', $(self.myDOM)).on('click', function() {
                    // side = $(this).attr('data-viva-name');
                    // if (!$(this).hasClass('selected')) {
                    //     side = defaultSided;
                    // }
                    self.resizeISO7010Preview();
                    self.iso7010TriggerResult();
                });

                // dimension
                $('[data-viva-attr="Attr4"] .smallButton', $(self.myDOM)).on('click', function() {
                    // dimX = $(this).attr('data-viva-name');
                    self.resizeISO7010Preview();
                    self.iso7010TriggerResult();
                });

                // availabe options
                $(og).on('viva-option-change', function() {
                    if ($('.smallButton', $(og)).not('.vivaNotAvailable').length === 0) {
                        $(og).addClass('disabled');
                    } else {
                        $(og).removeClass('disabled');
                    }
                });

                $('div.iso7010-category', $(domParent)).append($('<div>').addClass('iso7010description'));
                $('div.iso7010-category', $(domParent)).find('li:first').remove(); // remove the question mark
                $('.iso7010-category', $(domParent)).on('change', function() {
                    var domIso = $(this).next('.iso7010StyledSelect');
                    if ($(domIso).length === 0) {
                        return false;
                    }
                    $('li[data-category!="' + $(this).val() + '"]', $('.iso7010-first', $(domParent))).hide();
                    $('li[data-category!="' + $(this).val() + '"]', $('.iso7010-second', $(domParent))).hide();

                    // independed or empty
                    $('li:not([data-category])', $('.iso7010-first', $(domParent))).show();
                    $('li:not([data-category])', $('.iso7010-second', $(domParent))).show();

                    // show matching category
                    $('li[data-category="' + $(this).val() + '"]', $('.iso7010-first', $(domParent))).show();
                    $('li[data-category="' + $(this).val() + '"]', $('.iso7010-second', $(domParent))).show();

                    // remove background image on selection
                    $(domIso).css('background-image', '');
                    $('.iso7010description', $(this).parent()).text($(domIso).attr('alt'));

                    $('.iso7010-first', $(domParent)).removeClass('disabled');
                    $('.iso7010-second', $(domParent)).addClass('disabled');
                    $(domParent).attr('data-category', $(this).val());

                    $('.iso7010-preview', $(domParent)).addClass('iso7010-single-sign');
                    if (self.defaultAspect < 2) { // reset zoom
                        $('.first, .second', $(domParent)).css({
                            'zoom': 1.0
                        });
                    }


                    // cleanup first // second // preview
                    $('.first', $(domParent)).css('background-image', '');
                    $('.second', $(domParent)).css('background-image', '');
                    $('.iso7010-preview', $(domParent)).removeClass('iso7010-facing-left');
                    $('.iso7010-preview', $(domParent)).removeClass('iso7010-arrow');
                    $('.iso7010-preview', $(domParent)).removeAttr('data-arrow-dir');

                    cleanCustomCombo($('.iso7010-first', $(domParent)));
                    cleanCustomCombo($('.iso7010-second', $(domParent)));

                    self.iso7010TriggerResult();
                    // $('li:visible:first', $('.iso7010-first', $(domParent))).trigger('click');
                    // $('li:first', $('.iso7010-second', $(domParent))).trigger('click');
                });

                $('.iso7010-first', $(domParent)).on('change', function() {
                    var domIso = $(this).next('.iso7010StyledSelect');
                    if ($(domIso).length === 0) {
                        return false;
                    }

                    var dom2nd = $('.iso7010-second', $(domParent)),
                        onlyWithArrow = $(domIso).attr('data-onlywitharrow') === '1',
                        isArrow = $(domIso).attr('data-isarrow') === '1',
                        facing = $(domIso).attr('data-facing');

                    $('li', $(dom2nd)).addClass('off'); // all off

                    if (onlyWithArrow) { // second not arrow
                        switch (facing) {
                            case 'left':
                                $('li[data-facing="left"][data-isarrow="1"]', $(dom2nd)).removeClass('off');
                                break;
                            case 'right':
                                $('li[data-facing="right"][data-isarrow="1"]', $(dom2nd)).removeClass('off');
                                break;
                            default:
                                $('li[data-isarrow="1"]', $(dom2nd)).removeClass('off');
                                break;
                        } // facing
                    } else { // no arrow required
                        $('li', $(dom2nd)).removeClass('off');
                        $('li[data-onlywitharrow="1"]', $(dom2nd)).addClass('off'); // do not show SP/WP
                    } // no arrow required
                    var cat = $('.iso7010StyledSelect', $('div.iso7010-category', $(domParent))).attr('rel');

                    $('li[data-category!="' + cat + '"]', $(dom2nd)).addClass('off'); // all other cat off
                    $('li:not([data-category])', $(dom2nd)).removeClass('off');

                    $('.iso7010-preview', $(domParent)).toggleClass('iso7010-facing-left', facing === 'left');
                    $('.iso7010-preview', $(domParent)).toggleClass('iso7010-arrow', isArrow);


                    $('.first', $(domParent)).css('background-image', $(domIso).css('background-image'));
                    $(dom2nd).removeClass('disabled');
                    self.iso7010TriggerResult();

                    if (onlyWithArrow) {
                        $('li:not(.off):first', $(dom2nd)).trigger('click');
                    }
                });

                $('.iso7010-second', $(domParent)).on('change', function() {
                    var domIso = $(this).next('.iso7010StyledSelect');
                    if ($(domIso).length === 0) {
                        return false;
                    }

                    var isArrow = $(domIso).attr('data-isarrow') === '1',
                        facing = $(domIso).attr('data-facing');

                    $('.iso7010-preview', $(domParent))
                        .toggleClass('iso7010-facing-left', facing === 'left');
                    $('.iso7010-preview', $(domParent))
                        .toggleClass('iso7010-arrow', isArrow);
                    if (isArrow) {
                        $('.iso7010-preview', $(domParent))
                            .attr('data-arrow-dir', $(domIso)
                                .attr('data-extraitemcode'));
                    } else {
                        $('.iso7010-preview', $(domParent)).removeAttr('data-arrow-dir');
                    }

                    if ($(domIso).attr('rel') !== '') {
                        $('.iso7010-preview', $(domParent)).removeClass('iso7010-single-sign');
                        if (self.defaultAspect < 2) { // 2 refs will not fit
                            $('.first, .second', $(domParent)).css({
                                'zoom': self.defaultAspect / 2.0
                            });
                        }
                    } else {
                        $('.iso7010-preview', $(domParent)).addClass('iso7010-single-sign');
                        if (self.defaultAspect < 2) { // 2 refs will not fit
                            $('.first, .second', $(domParent)).css({
                                'zoom': 1.0
                            });
                        }
                    }

                    $('.second', $(domParent)).css('background-image', $(domIso).css('background-image'));

                    if (!isArrow && $(domIso).attr('rel') !== '') { // click no logo if symbol available
                        $('li:eq(1)', $('.iso7010-logo', $(domParent))).trigger('click');
                    }

                    self.iso7010TriggerResult();
                });


                $('div.iso7010-logo', $(domParent)).append($('<div>').addClass('iso7010description'));
                $('.iso7010-logo', $(domParent)).on('change', function() {
                    var domIso = $(this).next('.iso7010StyledSelect');
                    if ($(domIso).length === 0) {
                        return false;
                    }

                    $('.iso7010description', $(this).parent()).text($(domIso).attr('alt'));

                    var val = $(domIso).attr('data-smlcode');
                    switch (val) {
                        case 'NL':
                            $('.iso-logo', $(domParent)).hide();
                            break;
                        default:
                            $('.iso-logo', $(domParent)).show();
                            break;
                    }

                    self.iso7010TriggerResult();
                });

                // 2020-09-07: request to select initial (currently first available item)
                if (_.has(self.vivaConfig.FamilyProperties, 'ISO7010PreSelection')) {
                    var preSel = self.vivaConfig.FamilyProperties.ISO7010PreSelection.split(';');
                    if (preSel.length > 0) {
                        $('li[rel="' + preSel[0] + '"]:not(.off)',
                            $('.iso7010-category', $(domParent))
                        ).trigger('click', 'init');
                        if (preSel.length > 1) {
                            window.setTimeout(function() {
                                $('li[rel="' + preSel[1] + '"]:not(.off):first',
                                    $('.iso7010-first', $(domParent))
                                ).trigger('click', 'init');
                                if (preSel.length > 2) {
                                    window.setTimeout(function() {
                                        $('li[rel="' + preSel[2] + '"]:not(.off):first',
                                            $('.iso7010-second', $(domParent))
                                        ).trigger('click', 'init');
                                    }, 100);
                                } // 2nd ref
                            }, 100);
                        } // 1st ref
                    } // category
                } else { // simple version, just select first available
                    // no init if not specified in family props
                    $.noop();
                    // $('li:not(.off):first', $('.iso7010-category', $(domParent))).trigger('click', 'init');
                    // window.setTimeout(function() {
                    //     $('li:not(.off):first', $('.iso7010-first', $(domParent))).trigger('click', 'init');
                    // }, 100);
                }


                $('.vivaFacetsResult', $(self.myDOM)).on('facetedsearchresultupdate', function() {});
            } // valid signs
        },


        iso7010PreviewDimensions: function(x1, y1, z1, sides) {
            var self = this;

            // code
            var domPreview = $('.iso7010-preview', $(self.myDOM));

            $(domPreview).attr('data-sides', sides);

            var maxdim = Math.max(x1, Math.max(z1, y1)),
                x = 250 * x1 / maxdim,
                y = 250 * y1 / maxdim,
                z = 250 * z1 / maxdim;

            // display mode to cycle
            var dm = self.displayMode();

            if (dm === '3D' || dm === '2D') {
                // jscs:disable maximumLineLength

                // front
                if (dm === '3D') {
                    $('._3dface--front', $(domPreview)).css({
                        'width': x + 'px',
                        'height': y + 'px',
                        'lineheight': (y / 2) + 'px',
                        'transform': 'translate3d(' + (-x / 2) + 'px, ' + (-y / 2) + 'px, 0px) rotateY(0deg) translate3d(0, 0, ' + (z / 2) + 'px)',
                        'opacity': 'inherit'
                    });
                } else { // '2D'
                    $('._3dface--front', $(domPreview)).css({
                        'width': x + 'px',
                        'height': y + 'px',
                        'lineheight': (y / 2) + 'px',
                        'transform': 'translate3d(' + (-x / 2) + 'px, ' + (-y / 2) + 'px, 0px) rotateY(0deg) translate3d(0, 0, ' + (z / 2) + 'px)',
                        'opacity': '1.0'
                    });
                }
                var w = Math.min(x, y);
                $('._3dface--front>.first', $(domPreview)).css({
                    'width': w + 'px',
                    'height': w + 'px'
                });
                $('._3dface--front>.second', $(domPreview)).css({
                    'width': w + 'px',
                    'height': w + 'px'
                });

                // back face
                if (dm === '3D') {
                    $('._3dface--back', $(domPreview)).show().css({
                        'width': x + 'px',
                        'height': y + 'px',
                        'lineheight': (y / 2) + 'px',
                        'transform': 'translate3d(' + (-x / 2) + 'px, ' + (-y / 2) + 'px, 0px) rotateY(180deg) translate3d(0, 0, ' + (z / 2) + 'px)'
                    });
                } else { // 2D
                    $('._3dface--back', $(domPreview)).toggle(sides !== '1').css({
                        'width': x + 'px',
                        'height': y + 'px',
                        'lineheight': (y / 2) + 'px',
                        'transform': 'translate3d(' + (-x / 2) + 'px, ' + (-y / 2) + 'px, 0px) rotateY(0deg) translate3d(' + (x / 4) + 'px, ' + (y / 4) + 'px, ' + (-z / 2) + 'px)'
                    });
                }
                $('._3dface--back>.first', $(domPreview)).css({
                    'width': w + 'px',
                    'height': w + 'px'
                });
                $('._3dface--back>.second', $(domPreview)).css({
                    'width': w + 'px',
                    'height': w + 'px'
                });




                // left side
                $('._3dface--left', $(domPreview)).css({
                    'width': z + 'px',
                    'height': y + 'px',
                    'lineheight': (y / 2) + 'px',
                    'transform': 'translate3d(' + (-z / 2) + 'px, ' + (-y / 2) + 'px, 0px) rotateY(-90deg) translate3d(0, 0, ' + (x / 2) + 'px)'
                }).toggle(dm === '3D');

                w = Math.min(z, y);
                $('._3dface--left>.first', $(domPreview)).css({
                    'width': w + 'px',
                    'height': w + 'px'
                });
                $('._3dface--left>.second', $(domPreview)).css({
                    'width': w + 'px',
                    'height': w + 'px'
                });


                // right side
                $('._3dface--right', $(domPreview)).css({
                    'width': z + 'px',
                    'height': y + 'px',
                    'lineheight': (y / 2) + 'px',
                    'transform': 'translate3d(' + (-z / 2) + 'px, ' + (-y / 2) + 'px, 0px) rotateY(90deg) translate3d(0, 0, ' + (x / 2) + 'px)'
                }).toggle(dm === '3D');

                $('._3dface--right>.first', $(domPreview)).css({
                    'width': w + 'px',
                    'height': w + 'px'
                });
                $('._3dface--right>.second', $(domPreview)).css({
                    'width': w + 'px',
                    'height': w + 'px'
                });




                // top + bottom
                $('._3dface--top', $(domPreview)).css({
                    'width': x + 'px',
                    'height': z + 'px',
                    'lineheight': (z / 2) + 'px',
                    'transform': 'translate3d(' + (-x / 2) + 'px, ' + (-z / 2) + 'px, 0px) rotateX(90deg) translate3d(0, 0, ' + (y / 2) + 'px)'

                }).toggle(dm === '3D');
                $('._3dface--bottom', $(domPreview)).css({
                    'width': x + 'px',
                    'height': z + 'px',
                    'lineheight': (z / 2) + 'px',
                    'transform': 'translate3d(' + (-x / 2) + 'px, ' + (-z / 2) + 'px, 0px) rotateX(-90deg) translate3d(0, 0, ' + (y / 2) + 'px)'
                }).toggle(dm === '3D');

                $(domPreview).css({
                    'background-size': '0'
                });
            } // 2/3D
            else { // image mode
                $(domPreview).css({
                    'background-size': 'contain',
                    'background-repeat': 'no-repeat',
                    'background-position': 'center'
                });
            }
        },


        updatePreviewImage: function(img1, img2, sided, aspect) {
            var self = this;

            // code
            var domPreview = $('.iso7010-preview', $(self.myDOM));

            // url for background
            var url = '/iviews/ip/Image/Process/{sided}/{aspect}/{img1}/{img2}/result';

            url = self.replaceNamePart(url, 'sided', sided);
            // var aspectX = aspect < 1 ? '1x' + (1 / aspect) : aspect + 'x1';
            url = self.replaceNamePart(url, 'aspect', aspect);
            url = self.replaceNamePart(url, 'img1', img1.replace('.png', ''));
            url = self.replaceNamePart(url, 'img2', img2.replace('.png', ''));


            $(domPreview).css({
                'background-image': 'url(' + url + ')'
            });

            return url;
        },

        replaceNamePart: function(name, part, value) {
            // var reg = /{(\W*)(w+)(\W*)}/
            var ret = name,
                reg = new RegExp('\\{(\\W*)(' + part + ')(\\W*)\\}');

            if (_.isUndefined(value) || value === '') {
                ret = name.replace(reg, '');
            } else {
                ret = name.replace(reg, '$1' + value + '$3');
            }

            return ret;
        },


        iso7010TriggerResult: function() {
            var self = this;

            // code
            var domIso = $('.iso7010-selector', $(self.myDOM));

            // select targets
            var domFirst = $('.iso7010-first .iso7010StyledSelect', $(domIso)),
                domSecond = $('.iso7010-second .iso7010StyledSelect', $(domIso)),
                domLogo = $('.iso7010-logo .iso7010StyledSelect', $(domIso));

            // side dom
            var sided = self.getISO7010sides(),
                attrName = 'data-name1sided';
            if (sided === '1' || sided === '2') {
                attrName = 'data-name' + sided + 'sided';
            }

            //             var namePattern = _.has(self.vivaConfig.FamilyProperties, 'ISO7010NamePattern') ? self.vivaConfig.FamilyProperties.ISO7010NamePattern : '{sided}{ref1}{ref2}';
            var isoName = _.has(self.vivaConfig.FamilyProperties, 'ISO7010NamePattern') ?
                self.vivaConfig.FamilyProperties.ISO7010NamePattern : '{sided}{ref1}{ref2}{logo}',
                sml1 = '',
                sml2 = '',
                img1 = '-',
                img2 = '-',
                flipImages = false;


            // side code
            isoName = self.replaceNamePart(isoName, 'sided', sided);

            // isoName = isoName.replace('{sided}', sided);
            // isoName = isoName.replace('{-sided}', '-' + sided);


            var eidLogo = $(domLogo).attr('data-extraitemid');

            // 1st Rev
            var smlPart = $(domFirst).attr(attrName);
            if (!$('.iso7010-first', $(domIso)).is('.disabled')) { // 1st Rev active
                if (_.isString(smlPart)) {
                    // isoName += smlPart;
                    isoName = self.replaceNamePart(isoName, 'ref1', smlPart);

                    // isoName = isoName.replace('{ref1}', smlPart);
                    // isoName = isoName.replace('{-ref1}', '-' + smlPart);
                    if ($(domFirst).is('[data-extraitemcode]')) {
                        sml1 = $(domFirst).attr('data-extraitemcode');
                    }
                    if ($(domFirst).is('[data-image]')) {
                        img1 = $(domFirst).attr('data-image');
                    }
                    if ($(domFirst).attr('data-facing') === 'left') {
                        flipImages = true;
                    }
                } else {
                    isoName = self.replaceNamePart(isoName, 'ref1', '');
                    // isoName = isoName.replace('{ref1}', '');
                    // isoName = isoName.replace('{-ref1}', '');
                    isoName = ''; // 1st not selected - no iso code
                }



                // 2st Rev
                smlPart = $(domSecond).attr(attrName);
                if (!$('.iso7010-second', $(domIso)).is('.disabled') && smlPart) { // 2nd Rev
                    if (_.isString(smlPart)) {
                        isoName = self.replaceNamePart(isoName, 'ref2', smlPart);
                        // isoName = isoName.replace('{ref2}', smlPart);
                        // isoName = isoName.replace('{-ref2}', '-' + smlPart);
                        if ($(domSecond).is('[data-extraitemcode]')) {
                            sml2 = $(domSecond).attr('data-extraitemcode');
                        }
                        if ($(domSecond).is('[data-image]')) {
                            img2 = $(domSecond).attr('data-image');
                        }
                    } else {
                        isoName = self.replaceNamePart(isoName, 'ref2', '');
                        // isoName = isoName.replace('{ref2}', '');
                        // isoName = isoName.replace('{-ref2}', '');
                    }

                    if ($(domSecond).attr('data-isarrow') === '0') { // do not allow a logo
                        $('.iso7010-logo', $(domIso)).addClass('disabled');
                    } else {
                        $('.iso7010-logo', $(domIso)).removeClass('disabled');
                    }
                } // 2nd ref
                else {
                    $('.iso7010-second', $(domIso)).removeClass('disabled');
                    $('.iso7010-logo', $(domIso)).removeClass('disabled');

                    isoName = self.replaceNamePart(isoName, 'ref2', smlPart);
                    // isoName = isoName.replace('{ref2}', '');
                    // isoName = isoName.replace('{-ref2}', '');
                }

                // select old option buttons
                var eidFirst = $(domFirst).attr('data-extraitemid');
                if (_.isString(eidFirst)) {
                    $('.smallButton[data-viva-extraitemid="' + eidFirst + '"]:not(.selected)', $(self.myDOM)).trigger('click');
                }

                if (isoName.indexOf('{logo}') !== -1) { // switch off logo for name
                    $('.vivaOptionGroup[data-viva-config-type="ISO7010-Logo"]', $(self.myDOM)).attr('data-viva-not4name', 'true');
                }

                if (_.isString(eidLogo)) {
                    var codeLogo = $('.smallButton[data-viva-extraitemid="' + eidLogo + '"].selected', $(self.myDOM)).attr('data-viva-name');
                    if (codeLogo === undefined) {
                        codeLogo = '';
                    }

                    isoName = self.replaceNamePart(isoName, 'logo', codeLogo);
                    //                    isoName = isoName.replace('{logo}', codeLogo);
                } else {
                    isoName = self.replaceNamePart(isoName, 'logo', '');
                    //                    isoName = isoName.replace('{logo}', '');
                }



                if (flipImages === true) {
                    var img0 = img1;
                    img1 = img2;
                    img2 = img0;
                }
                var urlPhoto = self.updatePreviewImage(img1, img2, sided, self.defaultAspect);

                $(domIso).closest('.vivaOptionGroup').attr({
                    'data-viva-iso7010-code': isoName,
                    'data-viva-iso7010-isviva': sml1 + '!' + sml2 + '!' + isoName + '!' + sided + '!' + self.defaultAspect + '!' + img1 + '!' + img2,
                    'data-viva-photo-src': urlPhoto
                });
            } // 1st
            else { // valid selection
                $(domIso).closest('.vivaOptionGroup').attr({
                    'data-viva-iso7010-code': '',
                    'data-viva-iso7010-isviva': ''
                }).removeAttr('data-viva-photo-src');
                self.updatePreviewImage('-', '-', sided, self.defaultAspect);
            } // valid selection

            if (_.isString(eidLogo)) {
                $('.smallButton[data-viva-extraitemid="' + eidLogo + '"]:not(.selected').trigger('click');
            }

            self.updateInfo();
        }
    }); // extend


    $(document).ready(function() {
        $('[data-tcgDget="idgVIVAConfigISO7010"]').each(function() {
            new tcgDget.idgVIVAConfigISO7010($(this)); // no need to store the object it's an ecatUI job
        });
    });
})(window, tcgDget, jQuery, _);
