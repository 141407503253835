/* jshint browser:true */

/**
 * Module to create viva-configurations, as new material in SAP. This component is only compatible with the
 * `basket.html.tmpl`-template
 */
// IIFEs are exluded from maxparams-rule
(function(window, VivaUtils, CommUtils, AppData, EventService, BasketService, _, $) { // jshint ignore:line
    'use strict';

    // CONSTANTS

    // Placeholders in template, to figure out if value has been set, or not
    var PLACEHOLDER__MATERIAL_NUMBER = '{matnr}',
        PLACEHOLDER__VIVA_CONFIG = '{isVIVA}';

    // LOCAL VARIABLES

    /** Is user authenticated **/
    var _authenticated = false;

    // EXPORTS

    // Export private functions for automated tests
    VivaUtils.exportForTests('CreateMaterial', {
        // Accessors
        _isAuth: _isAuth,
        _setAuth: _setAuth,
        // Initializers
        _bindCreateMaterial: _bindCreateMaterial,
        // Pure functions
        _$createMaterial: _$createMaterial,
        _$extractMaterialNumberFromBasketItem: _$extractMaterialNumberFromBasketItem,
        _$extractVivaConfigFromBasketItem: _$extractVivaConfigFromBasketItem,
        _$findArticleItemsByVivaConfig: _$findArticleItemsByVivaConfig,
        _$getFilledValue: _$getFilledValue,
        _$handleResponseCreateMaterial: _$handleResponseCreateMaterial,
        _$renderCreateMaterialComponent: _$renderCreateMaterialComponent,
        _$renderCreationInProgress: _$renderCreationInProgress,
        _$saveMaterialNumberToBasketItem: _$saveMaterialNumberToBasketItem,
        _$toggleCreateMaterial: _$toggleCreateMaterial,
        // Callbacks
        __handleCreateMaterialResponse: __handleCreateMaterialResponse,
        __renderCreateMaterialComponent: __renderCreateMaterialComponent,
        __triggerCreateMaterial: __triggerCreateMaterial,
        __updateLoginStatus: __updateLoginStatus
    });
    // Assign to object, which has to be used for private functions, in order to make the private functions mockable
    var Private = VivaUtils.getPropertiesForTests('CreateMaterial');

    // INITIALIZATION

    _init();

    // IMPLEMENTATION DETAILS

    // Private

    function _init() {
        Private._bindCreateMaterial();
    }

    /**
     * Binds handlers to (custom) events
     * @private
     */
    function _bindCreateMaterial() {
        // Login / logout
        $(window).on('login:update', Private.__updateLoginStatus);
        // Update of basket items
        EventService.listen('basket:rendered', Private.__renderCreateMaterialComponent);
        // "create material"-request
        $(window.document).delegate('.vivaCreateMaterial', 'click', Private.__triggerCreateMaterial);
        // Response of "create material"-request
        EventService.listen('viva:MATNR', Private.__handleCreateMaterialResponse);
    }

    // Setter / getter

    function _setAuth(authenticated) {
        _authenticated = authenticated;
    }

    function _isAuth() {
        return _authenticated;
    }

    // Static, pure functions (not dependent on internal state)

    /**
     * Triggers creation of material, in SAP
     * @param {String}  vivaConfig  Configuration of material as String-representation
     * @private
     * @static
     */
    function _$createMaterial(vivaConfig) {
        if (vivaConfig == null || vivaConfig === '') {
            return;
        }

        var CommMode = CommUtils.COMM_MODE,
            data = {
                'contenttype': 'text/xml',
                'app-url': 'viva/createMaterial',
                'isVIVA': vivaConfig,
                'sOrderNo': VivaUtils.getBaseArticle(vivaConfig)
            };
        switch (CommUtils.getCommunicationMode('ecatVivaProxy')) {
            // Access via Plone-proxy
            case CommMode.ploneProxy:
                var jQueryAjaxSettingsTraditional = $.ajaxSettings.traditional,
                    urlProxy = AppData.getPageProperty('ecatVivaProxy');
                $.ajaxSettings.traditional = true;
                $.post(urlProxy, data, function(xml) {
                    if (!$.isXMLDoc(xml)) {
                        throw urlProxy + ' does not return expected xml result';
                    }
                    EventService.fire('viva:MATNR', xml);
                }, 'xml');
                $.ajaxSettings.traditional = jQueryAjaxSettingsTraditional;
                break;
                // Direct access to BC
            case CommMode.businessConnector:
                EventService.fire('bc:load', {
                    'service': 'products/getPrice4',
                    'message': 'viva:MATNR',
                    'pData': data
                });
                break;
        }
    }

    /**
     * Renders progress-indicator (of "create material"-process)
     * @param {String}  vivaConfig  Configuration of material as String-representation
     * @private
     * @static
     */
    function _$renderCreationInProgress(vivaConfig) {
        var jqArticleItem = Private._$findArticleItemsByVivaConfig(vivaConfig);
        $('.vivaCreateMaterialLink', jqArticleItem).addClass('off');
        $('.vivaUpdateSapLink', jqArticleItem).addClass('off');
        $('.vivaCreatingInProgress', jqArticleItem).removeClass('off');
    }

    /**
     * Renders the "create material"-component (and everything it's related to)
     * @param {Boolean} authenticated   `true`, if user is authenticated
     * @private
     * @static
     */
    function _$renderCreateMaterialComponent(authenticated) {
        Private._$findArticleItemsByVivaConfig().each(function(idx, elmt) {
            var jqElmt = $(elmt),
                conf = Private._$extractVivaConfigFromBasketItem(jqElmt),
                matNr = Private._$extractMaterialNumberFromBasketItem(jqElmt);
            Private._$toggleCreateMaterial(authenticated, matNr, conf, jqElmt);
        });
    }

    /**
     * Extract the material number from a basket item (DOM-element)
     * @param {jQuery}  jqBasketItem    jQuery instance, encapsulating the basket item
     * @returns {String|null}   The material number. <code>null</code>, if not avilable.
     * @private
     * @static
     */
    function _$extractMaterialNumberFromBasketItem(jqBasketItem) {
        var materialNr = jqBasketItem.find('[data-viva-matnr]').attr('data-viva-matnr');
        return Private._$getFilledValue(materialNr, PLACEHOLDER__MATERIAL_NUMBER);
    }

    /**
     * Extract the VIVA configuration-String from a basket item (DOM-element)
     * @param {jQuery}  jqBasketItem    jQuery instance, encapsulating the basket item
     * @returns {String|null}   The VIVA configuration-String. <code>null</code>, if not avilable.
     * @private
     * @static
     */
    function _$extractVivaConfigFromBasketItem(jqBasketItem) {
        var vivaConfig = jqBasketItem.attr('data-vivaconfig');
        return Private._$getFilledValue(vivaConfig, PLACEHOLDER__VIVA_CONFIG);
    }

    /**
     * Return the passed value, if it has been set (the placeholder has been replaced).
     * @param {String|null|undefined}   value        Value to check
     * @param {String}                  placeholder  Placeholder-text in template
     * @returns {String|null}   <code>value</code> if set. <code>null</code> otherwise
     * @private
     */
    function _$getFilledValue(value, placeholder) {
        return value != null && value !== placeholder ?
            value :
            null;
    }

    /**
     * Renders the response of the "create material"-request
     * @param {Boolean} authenticated   `true`, if user is authenticated
     * @param {Object}  data            Data of the response
     * @private
     * @static
     */
    function _$handleResponseCreateMaterial(authenticated, data) {
        var vivaConfig = data.isVIVA,
            materialNr = data.MATNR,
            jqArticleItem = Private._$findArticleItemsByVivaConfig(vivaConfig);
        switch (data.STATUS) {
            case 'OK':
                Private._$saveMaterialNumberToBasketItem(vivaConfig, materialNr);
                var templateI18n = $('[data-i18n-template]', jqArticleItem).attr('data-i18n-template');
                // Show material number
                if (templateI18n) {
                    jqArticleItem.find('.vivaMaterialNumber')
                        .attr('data-viva-matnr', materialNr)
                        .text(templateI18n.replace('@@MATNR@@', materialNr));
                }
                if (isValidMaterialNumber(materialNr)) {
                    var isVIVANew = updateIsVIVAMaterialNumber(vivaConfig, materialNr);

                    if (isVIVANew !== vivaConfig) { // replace isVIVA strings
                        /* $('[data-vivaconfig="' + vivaConfig + '"]').attr('data-vivaconfig', isVIVANew); */
                        $('[data-vivacfg="' + vivaConfig + '"]', jqArticleItem).attr('data-vivacfg', isVIVANew);

                        $('.articleDetail', jqArticleItem).remove(); // force reload of detail
                        // trigger price update
                        $('.price[data-load]', jqArticleItem).attr('data-isviva', isVIVANew).removeAttr('data-load');
                        window.setTimeout(function() {
                            EventService.fire('basket:table:update', {
                                isVIVA: null
                            });
                        }, 50);

                        $(jqArticleItem).removeClass('selected');
                    }
                }
                Private._$toggleCreateMaterial(authenticated, materialNr, vivaConfig, jqArticleItem);
                break;
                // TODO handle error case (process hasn't been defined yet)
        }
    }

    /**
     * check's material number to be 8 digits long
     * @param {String} materialNr SAP MATNR
     */
    function isValidMaterialNumber(materialNr) {
        return /^\d{8}$/.test(materialNr);
    }


    /**
     * replaces/sets the MATNR part of the isVIVA config string
     * isVIVA Format: [FAMILY];[ExtraItems];[Components];[MATNR]:[NAME];[BaseArticleNumber]
     * @param {string} isVIVA           VIVA config string
     * @param {string} MATRN   SAP MATNR
     */
    function updateIsVIVAMaterialNumber(isVIVA, MATNR) {
        var ret = isVIVA,
            fmt = isVIVA.split(';'); // extract parts

        if (fmt.length >= 5) { // enugh parts available
            var f3 = fmt[3].split(':'); // separate MATNR from NAME
            f3[0] = MATNR; // replace MATNR
            fmt[3] = f3.join(':'); // glue togehter
            ret = fmt.join(';'); // more glue
        }
        return ret;
    }


    /**
     * Set the material number to the basket item and saves it into the data storage
     * @param {String}  vivaConfig  Configuration of material as String-representation
     * @param {String}  materialNr  Material number of the newly created variation
     * @private
     * @static
     */
    function _$saveMaterialNumberToBasketItem(vivaConfig, materialNr) {
        BasketService.getBasketItemByVivaConfig(vivaConfig).then(function(basketItem) {
            if (isValidMaterialNumber(materialNr)) {
                basketItem.matnr = materialNr;
                if (typeof basketItem.isVIVA === 'string') {
                    basketItem.isVIVA = updateIsVIVAMaterialNumber(basketItem.isVIVA, materialNr);
                }
                BasketService.updateBasketItem(basketItem);
            } // update basket only with valid matnr
        });
    }

    /**
     * Toggles "create material"-link and material number
     * @param {Boolean} authenticated   `true`, if user is authenticated
     * @param {String}  materialNr      Material number
     * @param {String}  configStr       VIVA-configuration as String
     * @param {jQuery}  jqContext       The context for jQuery-selections
     * @private
     * @static
     */
    function _$toggleCreateMaterial(authenticated, materialNr, configStr, jqContext) {
        if (configStr == null || configStr === '') {
            return;
        }
        var jqCreateCont = $('.vivaCreateMaterialContainer', jqContext);
        if (jqCreateCont.size() === 0) {
            return;
        } // The "create material"-link is not available in the template

        var jqCreatMat = $('.vivaCreateMaterialLink', jqContext),
            jqInProgress = $('.vivaCreatingInProgress', jqContext),
            jqMatNr = $('.vivaMaterialNumber', jqContext),
            jqUpdateSap = $('.vivaUpdateSapLink', jqContext),
            authorized = !!authenticated && (materialNr == null || materialNr === '');
        // Toggle by unauthorized
        jqCreateCont.toggleClass('off', !authenticated);

        jqCreatMat.toggleClass('off', !(authenticated && materialNr === ''));
        jqUpdateSap.toggleClass('off', !(authenticated && materialNr !== ''));

        // Toggle by authorized
        jqMatNr.toggleClass('off', authorized);
        // Generally disabled, when not loading
        jqInProgress.addClass('off');

        if (!authenticated) {
            return;
        }
        // Prepare "create"-component (idempotent)
        jqCreatMat.attr('data-vivacfg', configStr);
        jqUpdateSap.attr('data-vivacfg', configStr);
    }

    /**
     * Find "article items" in basket cart by the VIVA-configuration-String
     * @param {String}      [vivaConfig]    Configuration of material as String-representation.
     *                                      All VIVA-articles, if omitted
     * @returns {jQuery}    jQuery-instance, with matching elements
     * @private
     * @static
     */
    function _$findArticleItemsByVivaConfig(vivaConfig) {
        var dataQuery = 'data-vivaconfig' + (vivaConfig != null ? '="' + vivaConfig + '"' : '');
        return $('.articleItem[' + dataQuery + ']');
    }

    // Callbacks

    /**
     * Handles login-status-changes (login/logout)
     * @param {jQuery.Event}    event           jQuery-event
     * @param {Object}          messageData     Event-payload
     * @private
     */
    function __updateLoginStatus(event, messageData) {
        Private._setAuth(messageData.status);
        Private._$renderCreateMaterialComponent(_isAuth());
    }

    /**
     * Trigger a re-rendering
     * @private
     */
    function __renderCreateMaterialComponent() {
        Private._$renderCreateMaterialComponent(_isAuth());
    }

    /**
     * Trigger the "create material"-process
     * @private
     */
    function __triggerCreateMaterial() {
        // jshint -W040
        var vivaConfig = $(this).attr('data-vivacfg'), // Since this is a callback, referring to `this` is ok
            isDisabled = $(this).hasClass('disabled');
        // jshint +W040

        if (!isDisabled) {
            Private._$renderCreationInProgress(vivaConfig);
            Private._$createMaterial(vivaConfig);
        }
    }

    /**
     * Handles the response of the "create material"-request
     * @param {jQuery.Event} event  jQuery-event
     * @private
     */
    function __handleCreateMaterialResponse(event) {
        Private._$handleResponseCreateMaterial(_isAuth(), $.xml2json(event.messageData));
    }
})(window, window.VivaUtils, window.CommUtils, window.AppData, window.EventService, window.BasketService, window._,
    window.jQuery
);
