/* All these settings prevent analysis errors but mean
 * deviation from coding standard XXX */
/*jshint nonew: false */
/*jshint browser: true */

(function(EventService, _, $, tcgDget, tcgTarget, tcgGetPageProperty, ecatUserProfile, AppData) { // jshint ignore:line
    'use strict';


    /**
     * @class
     * @augments tcgDget
     * @param {Object} param
     */
    /**
     * @lends tcgDget.prototype
     */
    tcgDget.idgProduct = tcgDget.extend({
        family: null,

        _init_: function() {
            var self = this;

            self.objName = 'idgProduct';
            self.setDgetMode('single');

            self.company = ecatUserProfile.getCompany();


            var loadString = tcgTarget;

            if (!(/^[0-9S][0-9]{7}$/).test(loadString)) {
                self.redirect(loadString);
                return;
            }

            $('.groupTitle', $(self.myDOM)).text(loadString);

            // self.listen('tcg:load', function() {
            //     $('[data-configurator="' + self.configurator + '"]').each(function() {
            //         var li = $(this).closest('.groupItem');
            //         $(li).removeClass('off');

            //         $(li).addClass('selected');
            //         $('.groupDetail', $(li)).slideDown('slow', function() {
            //             $(window).trigger({
            //                 'type': 'price:update'
            //             });
            //         });
            //     });
            // });

            self.listen('product:detectProduct' + self.id, function(event) {
                var article = self.detectProduct($.parseXML(event.messageData));
                if (article.type !== 'unknown') {
                    self.loadAndRenderProductData(article);
                }
                self.fire('loading', null);
            });

            self.fire('bc:load', {
                'service': 'vivaConfig/xmlIsVIVAProduct',
                // 'service': 'vivaConfig/xmlProductText',
                'message': 'product:detectProduct' + self.id,
                'pData': {
                    // 'isVIVA': json2xml(self.article),
                    'lang': ecatUserProfile.getLanguage(),
                    'sOrderNr': loadString
                }
            });

            EventService.fire('product:loaded');
        },

        // redirect to standard product or search
        redirect: function(url) {
            if (document.location.href.indexOf('file:') !== -1 || document.location.href.indexOf('localhost:') !== -1 ||
                AppData.getPageProperty('redirect') === 'false') {
                $('a', '.redirect').attr('href', url);
                $('.redirect').removeClass('off');
            } else {
                $('head').append($('<meta>').attr({
                    'http-equiv': 'refresh',
                    'content': '1; URL=\'' + url + '\''
                }));
            }
        },

        getArticleData: function(xmlData) {
            var article = {};

            // read product element
            var product = $('product', $(xmlData));
            article.type = $(product).attr('type');
            article.maktx = $(product).attr('maktx');
            article.matnr = $(product).attr('matnr');
            article.isVIVA = $(product).attr('isVIVA');
            article.url = $('product', $(xmlData)).attr('url');

            // read item info
            var vivaItems = $('vivaItems', $(product));
            article.family = $(vivaItems).attr('family');
            article.baseArticle = $(vivaItems).attr('baseordernr');

            return article;
        },

        detectProduct: function(xmlData) {
            var self = this,
                article = self.getArticleData(xmlData);

            switch (article.type) {
                case 'base': // is a base article ... also in pdb
                case 'stdVariant': // should be in pdb
                    article.matnrLoaned = article.matnr;
                    break;

                case 'sapVariant': // product in sap configured
                    article.objectType = 'vivaArticle';
                    break;

                case 'unknown': // product in sap configured
                    article.url = document.location.origin.replace('myproduct', 'www');
                    article.url += '/' + tcgTarget;
                    break;

                default: // all other
                    break;
            }

            if (article.type !== 'sapVariant' && typeof article.url === 'string' && article.url !== '') {
                self.redirect(article.url);
            }

            return article;
        },

        loadAndRenderProductData: function(article) {
            var self = this,
                templateName = 'articleDetail',
                domArticle = $('.articleItem', $(self.myDOM));

            // store info on element
            $(domArticle).data('article', article);

            // only show whenmatner is available
            if (_.has(article, 'matnr')) {
                $('h1').text(_.has(article, 'family') ? article.family : '');

                if (_.has(article, 'maktx')) {
                    $('h2').text(article.matnr + ' ' + article.maktx);
                    $('title').text(article.matnr + ' ' + article.maktx);
                }


                if ($(domArticle).children('.articleDetail').size() === 0) {
                    var domArticleDetail = self.cloneTemplate($('[data-template="' + templateName + '"]'), {
                        'text': 'loading ...'
                    });

                    $(domArticle).append($(domArticleDetail));
                    new tcgDget.idgArticleDetail($(domArticleDetail));
                    $('.groupDetail').show();
                    $('.blkConfig').show();
                    $(domArticle).children('.articleDetail').show();
                }
            }
        }
    });


    /*
        the contents of document.ready() should be placed in the
        startup of the catalog page, there is the only place to handle
        the correct startup sequence, otherwise it's not guaranteed
        that all initializations are correctly done!
    */

    $(document).ready(function() {
        $('[data-tcgDget="idgProduct"]').each(function() {
            new tcgDget.idgProduct($(this)); // no need to store the object it's an ecatUI job
        });
    });
})(window.EventService, window._, window.$, window.tcgDget, window.tcgTarget, window.tcgGetPageProperty,
    window.ecatUserProfile, window.AppData);
