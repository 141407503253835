// jscs:disable maximumNumberOfLines
/*jshint maxcomplexity: 12 */
/*jshint nonew: false */
/*jshint browser: true */
/* global VivaUtils, tcgConfig, _, ecatUserProfile, tcgGetPageProperty, $, tcgDOM */

(function(VivaUtils, tcgConfig, _, ecatUserProfile, tcgGetPageProperty, $, tcgDOM, window) { // jshint ignore: line
    'use strict';

    var tcgCurrencies = {
        'EUR': { symbol: '€', dp: 2 },
        'GPB': { symbol: '£', dp: 2 },
        'YEN': { symbol: '¥', dp: 0 },
        'USD': { symbol: '$', dp: 2 },
        'CHF': { symbol: 'CHF', dp: 2 }
    },
    priceDefaultValue = null,
    currentArticlePriceXML = null;

    function formatPrice(price, currency, isRowItem, acceptZeroValue) {
        if (!priceDefaultValue) {
            priceDefaultValue = dict('onRequest');  // jshint ignore: line
        }

        var priceValue = priceDefaultValue ? priceDefaultValue : 'on Request';

        if (isRowItem) {
            priceValue = dict('notMaintained');  // jshint ignore: line
        }

        // jshint -W116
        if (currency && currency != '' && (price != '' || acceptZeroValue)) {
            // jshint +W116
            if (!tcgCurrencies[currency]) {
                tcgCurrencies[currency] = { 'symbol': currency, dp: 2 }; // add undefined currency
            }
            switch (typeof price) {
                case 'number':
                    if (price > 0.001 || acceptZeroValue) {
                        priceValue = price.tcgN(tcgCurrencies[currency].dp);    // default 2
                    }
                    break;
                case 'string': // assume format 8888888.88  (SAP specifies the decimal places
                    try {
                        priceValue = (+price).tcgN(tcgCurrencies[currency].dp);
                    } catch (e) {
                    }
                    break;
                default:
                    break;
            }
            return priceValue + '\u2002' + tcgCurrencies[currency].symbol;
        }
        return priceValue;
    }

    /**
     * @class
     * @augments tcgDget
     * @param {Object} param
     */
    /**
     * @lends tcgDOM.prototype
     */
    tcgDOM.domPrice = tcgDOM.extend({
        bAuthenticated: false,
        mode: 'bc',
        url: '',
        domMAT: null,

        _init_: function() {
            var self = this;

            self.objName = 'domPrice';

            self.domMAT = $('.vivaBasketAdd');

            self.url = tcgGetPageProperty('ecatPriceProxy');
            if (self.url) {
                self.mode = 'pl';
            }

            self.availabilityText = [];

            $('li', 'ul[data-template="availabilityDefinition"]').each(function() {
                self.availabilityText[$(this).attr('data-availability')] = $(this).text();
            });

            self.headerLabels = [];

            $('li', 'ul[data-template="deliveryTableHeaderLabel"]').each(function() {
                self.headerLabels[$(this).attr('data-label')] = $(this).text();
            });

            $(window).bind('login:update', function(event, messageData) {
                self.bAuthenticated = messageData.status;

                window.setTimeout(function() {  // little bit time to initialize
                    if (self.bAuthenticated && !_.contains(window.configurators, window.tcgTarget)) {
                        $('.vivaConfigInfo').css('display', 'none');
                        $('.vivaConfigInfoTable').css('display', 'inline-table');
                        $('.totalPrice').css('display', 'block');
                        $('.componentHeaderFooter').css('display', 'block');
                        window.setTimeout(function() { // little bit time to make elements visible
                            self.priceUpdate();
                            self.configUpdate();
                        }, 100);
                    } else { // remove price information
                        $('.vivaConfigInfo').css('display', 'inline-block');
                        $('.vivaConfigInfoTable').css('display', 'none');
                        $('.totalPrice').css('display', 'none');
                        $('.componentHeaderFooter').css('display', 'none');
                        var domItemList = $('[data-matnr][data-load="1"]'),
                            onRequest = formatPrice(0.0, '');
                        $(domItemList).each(function() {
                            $(this).removeAttr('data-load').text(onRequest);
                        });
                    }
                }, 100);
            });
            self.listen('price:update', function(event) {
                if (event.messageData) {
                    self.priceVIVAInfo = event.messageData;
                }
                if (self.bAuthenticated) {
                    window.setTimeout(function() {  // little bit time to make elements visible:w
                        self.priceUpdate();
                        self.configUpdate();
                    }, 100);
                }
            });

            self.listen('basket:table:update', function(event) {
                self.priceVIVAInfo = event.messageData;
                self.priceUpdate();
                self.configUpdate(self.priceVIVAInfo.isVIVA);
            });

            self.listen('vivaInfo', function(event) {
                self.priceVIVAInfo = event.messageData;
            });

            self.listen('price:render', function(event) {
                self.priceRender(event.messageData);
            });

            self.listen('price:renderConfig', function(event) {
                self.configRender(event.messageData);
            });

            self.listen('price:recalculate', function() {
                self.recalculatePrices();
            });

            self.listen('price:initPrices', function() {
                self.initPrices();
            });
        },

        priceUpdate: function(config, element) {
            var self = this,
                domItemList = $('[data-matnr]:not([data-load]):visible'),
                matnrList = [];
            $(domItemList).each(function() {
                var matnr = $(this).attr('data-matnr');

                if (matnr.match(/^[0-9S]{8}$/)) {
                    matnrList.push(matnr);
                }
            });

            if (config) {
                matnrList = _.map(config.products, function(product) {
                    return !!product.id ? product.id : product.matnr;
                });

                if (config.accessoryProducts) {
                    var accessoryMatnrList = _.map(config.accessoryProducts, function(product) { return product.id; });
                    matnrList = _.union(matnrList, accessoryMatnrList);
                }
            }

            if (matnrList.length) { // load data
                $(domItemList).attr('data-load', '0');

                var pData = {
                    'contenttype': 'text/xml',
                    'app-url': 'products/getPrice',
                    'matnr': matnrList,
                    'bWithAV': 'false',
                    'useCustPrice': 'true'
                };

                switch (self.mode) {
                    case 'pl':
                        var jQueryAjaxSettingsTraditional = $.ajaxSettings.traditional;
                        $.ajaxSettings.traditional = true;
                        $.post(self.url, pData, function(xml) {
                            if ($.isXMLDoc(xml)) {
                                self.fire('price:render', { config: config, xml: xml, element: element });
                            } else {
                                throw self.url + ' does not return expected xml result';
                            }
                        }, 'xml');
                        $.ajaxSettings.traditional = jQueryAjaxSettingsTraditional;

                        break;
                    case 'bc':
                        self.fire('bc:load', {
                            'service': 'products/getPrice2',
                            'message': 'price:render',
                            'pData': { config: config, xml: pData, element: element }
                        });
                        break;
                }
            } // load data
        },

        priceRender: function(data) {
            var self = this,
                configSum = 0,
                totalPrice = null,
                currency = null,
                prices = [],
                completePrice,
                priceMissing = false;

            if (!data.xml && currentArticlePriceXML) {
                data.xml = currentArticlePriceXML;
            } else {
                currentArticlePriceXML = data.xml;
            }

            $('item', $(data.xml)).each(function() {
                var item = $(this),
                    articleItem = $('[data-matnr="' + $(item).attr('MATNR') + '"]').closest('.articleItem'),
                    createMaterials = $('.vivaCreateMaterial', $(articleItem[0])),
                    qty = $('.configArticle .basketQty .qty', $(articleItem[0]));

                if (!$(qty).size()) {
                    qty = $('.configArticle .accessoryBasketQty input', $(articleItem[0])).val();
                } else {
                    qty = $(qty)[0].textContent;
                }

                if (!totalPrice) {
                    var articleDetail = $('[data-matnr="' + $(item).attr('MATNR') + '"]').closest('.articleDetail');
                    totalPrice = $('.totalPrice', $(articleDetail[0]));
                    completePrice = $('.completePrice', $(articleItem[0]));
                }

                $(createMaterials).each(function() {
                    if (!$(item).attr('KONWA')) {
                        $(this).addClass('disabled');
                        $(this).attr('title', dict('contactPriceManager'));  // jshint ignore: line

                        self.configUpdate($(articleItem).attr('data-vivaconfig'));
                    } else {
                        $(this).removeClass('disabled');
                        $(this).removeAttr('title');
                    }
                });

                $('[data-matnr="' + $(item).attr('MATNR') + '"]').attr('data-load', '1').text(formatPrice($(item)
                    .attr('KBETR'), $(item).attr('KONWA')));

                var sum = 0;
                if (qty) {
                    var totalItems = $('.total', $(articleItem[0]));
                    sum = parseInt(qty) * $(item).attr('KBETR');
                    $(totalItems[0]).text(formatPrice(sum, $(item).attr('KONWA'), false, true));
                } else {
                    sum = $(item).attr('KBETR');
                }

                if (!$(item).attr('KONWA')) {
                    priceMissing = true;
                }
                if (!currency) {
                    currency = $(item).attr('KONWA');
                }

                prices.push({ id: $(item).attr('MATNR'), price: $(item).attr('KBETR') });

                configSum += sum;
            });

            if (priceMissing) {
                configSum = null;
                currency = null;
            }

            if (totalPrice) {
                $(totalPrice[0]).text(formatPrice(configSum, currency));
            }

            if (data.config && data.config.products) {
                var completeSum = 0;
                _.each(data.config.products, function(product) {
                    var priceItem = _.find(prices, function(price) {
                        return !!product.id ? price.id===product.id : price.id === product.matnr;
                    });

                    completeSum += priceItem.price * product.qty;
                });

                completePrice = $('.completePrice', $(data.element[0]));
                $(completePrice[0]).text(formatPrice(completeSum, currency));
                $(completePrice[0]).data('singlePrice', completeSum);
                $(completePrice[0]).data('currency', currency);
            } else if (completePrice) {
                if ($(completePrice[0]).closest('.totalPrice') && $(completePrice[0]).closest('.totalPrice')[0]) {
                    $($(completePrice[0]).closest('.totalPrice')[0]).css('display', 'none');
                }
            }

            self.recalculatePrices();
            $('[data-matnr][data-load="0"]').text(formatPrice(0.0, ''));
        },

        getBaseArtice: function(isVIVA) {
            return VivaUtils.getBaseArticle(isVIVA);
        },

        initPrices: function() {
            var self = this,
            configs = $('.articleItem');

            $(configs).each(function() {
                self.calculateConfigPrice($(this).data('article'), $(this));
            });
        },

        configUpdate: function(configuration) {
            var self = this,
                domItemList = $('[data-isviva]:not([data-load]):visible');
            if (!configuration) {
                $(domItemList).each(function() {
                    $(this).attr('data-load', '0');
                    var isVIVA = $(this).attr('data-isviva');
                    loadItems(isVIVA);
                }); // load data
            } else {
                loadItems(configuration);
            }

            function loadItems(isVIVA) {
                // jshint -W116
                if (isVIVA != '') {
                    // jshint +W116
                    var pData = {
                        'contenttype': 'text/xml',
                        'app-url': 'viva/getProductPriceInfo',
                        'useCustPrice': 'true',
                        'isVIVA': isVIVA,
                        'sLang': ecatUserProfile.getLanguage(),
                        'sOrderNo': self.getBaseArtice(isVIVA)
                    },
                        /*jshint validthis: true */
                        maktx = $(this).attr('data-maktx');
                    if (maktx) {
                        pData.maktx = maktx;
                    }

                    switch (self.mode) {
                        case 'pl':
                            var jQueryAjaxSettingsTraditional = $.ajaxSettings.traditional;
                            $.ajaxSettings.traditional = true;
                            $.post(self.url, pData, function(xml) {
                                if ($.isXMLDoc(xml)) {
                                    self.fire('price:renderConfig', xml);
                                } else {
                                    throw self.url + ' does not return expected xml result';
                                }
                            }, 'xml');
                            $.ajaxSettings.traditional = jQueryAjaxSettingsTraditional;

                            break;
                        case 'bc':
                            self.fire('bc:load', {
                                'service': 'products/getPrice3',
                                'message': 'price:renderConfig',
                                'pData': pData
                            });
                            break;
                    }
                } // valid data
            }
        },

        calculateConfigPrice: function(config, element) {
            var self = this;

            if (config && config.products) {
                self.priceUpdate(config, element);
            }
        },

        configRender: function(xml) {
            var self = this,
                table = null,
                oInfo = $.xml2json(xml);

            if (!oInfo.price) {
                $('[data-isviva="' + oInfo.isVIVA + '"]').attr('data-load', '1').text(formatPrice(0.0, ''));
                return;
            }

            var articleItem = $('[data-vivacfg="' + oInfo.isVIVA + '"]').closest('.articleItem');

            table = articleItem.length === 0 ?
            $('.vivaConfigInfoItemList') : $('.vivaConfigInfoItemList', $(articleItem[0]));

            if (!self.priceVIVAInfo) {
                var items = ($(articleItem[0]).find('.vivaConfigInfoItemList'));
                self.priceVIVAInfo = {
                    prodName: $(items[0]).find('h3').text()
                };
            }

            if (!_.isArray(oInfo.price)) {
                oInfo.price = $.makeArray(oInfo.price);
            }

            var dPrice = null,
                sCurrency = null;

            $(table).empty();
            var itemListHeader = $('<tr>');
            itemListHeader.append($('<th>').text(self.headerLabels.OrderNr));
            itemListHeader.append($('<th>').text(self.headerLabels.Type));
            itemListHeader.append($('<th>').text(self.headerLabels.ABC));
            itemListHeader.append($('<th style=\'text-align: right\'>').text(self.headerLabels.Price));
            $(table).append(itemListHeader);

            var itemListRow = $('<tr>');

            for (var i = 0; i < oInfo.price.length; i++) {
                var entry = oInfo.price[i];

                if (!i) {
                    dPrice = +entry.value;
                    sCurrency = entry.cur;
                } else if (entry.value && entry.cur) {
                    dPrice += +entry.value;
                    sCurrency = entry.cur;
                } else if (entry.value) {
                    dPrice += +entry.value;
                    sCurrency = '';
                } else {
                    sCurrency = '';
                    dPrice = null;
                }
                itemListRow = self.createTableRow(entry, sCurrency, self.priceVIVAInfo);
                $(table).append(itemListRow);
            } // price item

            var sum = self.checkMissingPrice(oInfo, dPrice, sCurrency),
                createMaterials = $('.vivaCreateMaterial', $(articleItem[0]));

            $(createMaterials).each(function() {
                if (!sum.price) {
                    $(this).addClass('disabled');
                    $(this).attr('title', dict('contactPriceManager'));  // jshint ignore: line
                } else {
                    $(this).removeClass('disabled');
                    $(this).removeAttr('title');
                }
            });

            itemListRow = $('<tr style=\'font-weight: bold; border-top: 1px solid black\'>');
            itemListRow.append($('<td>').text(''));
            itemListRow.append($('<td>').text(self.priceVIVAInfo.prodName ?
                self.priceVIVAInfo.prodName : self.priceVIVAInfo.title));
            itemListRow.append($('<td>').text(self.getDeliveryTimeMax(oInfo.price)));
            itemListRow.append($('<td>').text(formatPrice(sum.price, sum.currency)));
            $(table).append(itemListRow);

            self.setAvailability(oInfo, articleItem[0]);
            self.renderPrice(oInfo, sum);
        },

        setAvailability: function(oInfo, articleItem) {
            var self = this,
            availabilityItems = ($(articleItem).find('.availabilityConfig'));

            if (availabilityItems.length === 1) {
                $(availabilityItems[0]).text(self.getDeliveryTimeMax(oInfo.price));
            }
        },

        renderPrice: function(oInfo, sum) {
            var self = this;
            if ($('[data-isviva="' + oInfo.isVIVA + '"]').length > 0) {
                $('[data-isviva="' + oInfo.isVIVA + '"]')
                    .attr('data-load', '1')
                    .text(formatPrice(sum.price, sum.currency));

                var articleItems = $('[data-isviva="' + oInfo.isVIVA + '"]').closest('.articleItem'),
                completePrice = $('.completePrice', $(articleItems[0]));

                $(completePrice[0]).data('singlePrice', sum.price);
                $(completePrice[0]).data('currency', sum.currency);

                self.setTotalPrice($('[data-isviva="' + oInfo.isVIVA + '"]'));
            } else {
                var prices = $('[data-vivaconfig="' + oInfo.isVIVA + '"]').find('.price');
                prices.last().text(formatPrice(sum.price, sum.currency));
            }
        },

        checkMissingPrice: function(oInfo, dPrice, sCurrency) {
            var wrongPrice = _.some(oInfo.price, function(item) {
                return !item.value || !item.cur;
            });

            if (wrongPrice) {
                dPrice = null;
                sCurrency = null;
            }

            return { price: dPrice, currency: sCurrency };
        },

        createTableRow: function(entry, sCurrency, priceVIVAInfo) {
            var self = this,
                itemListRow = $('<tr>'),
                displayName = entry.name;

            if (priceVIVAInfo && priceVIVAInfo.options) {
                var rowOption = priceVIVAInfo.options.filter(function(option) {
                    return option.smlCode && option.smlCode === entry.name && option.id === entry.id;
                });
                if (rowOption.length === 1) {
                    displayName = rowOption[0].value;
                }
            }

            itemListRow.append($('<td>').text(entry.type === 'B' ? entry.id : ''));
            itemListRow.append($('<td>').text(displayName));
            itemListRow.append($('<td>').text(self.getTextForABCStatus(entry.abc)));
            itemListRow.append($('<td style=\'text-align: right\'>').text(formatPrice(entry.value, sCurrency, true)));

            return itemListRow;
        },

        getDeliveryTimeMax: function(prices) {
            var self = this,
                codes = _.pluck(prices, 'abc'),
                maxCode = '';

            if (_.contains(codes, '*') || _.contains(codes, 'C')) {
                maxCode = self.getTextForABCStatus('*');
            } else if (_.contains(codes, 'Q')) {
                maxCode = self.getTextForABCStatus('Q');
            } else if (_.contains(codes, 'T')) {
                maxCode = self.getTextForABCStatus('T');
            } else if (_.contains(codes, 'L')) {
                maxCode = self.getTextForABCStatus('L');
            } else if (_.contains(codes, 'B')) {
                maxCode = self.getTextForABCStatus('B');
            } else if (_.contains(codes, 'V')) {
                maxCode = self.getTextForABCStatus('V');
            } else {
                maxCode = self.getTextForABCStatus('A');
            }
            return maxCode;
        },

        getTextForABCStatus: function(status) {
            var self = this;
            return self.availabilityText[status];
        },

        recalculatePrices: function() {
            var self = this,
            completePrice = $('.completePrice');

            completePrice.each(function() {
                self.setTotalPrice(this);
            });
        },

        setTotalPrice: function(item) {
            var articleItem = $(item).closest('.articleItem'),
            qtys = $('.basketQty .qty', articleItem[0]),
            qty = qtys[0],
            singlePrice = $(item).data('singlePrice');

            if (singlePrice) {
                var value = parseInt(singlePrice) * parseInt(qty.textContent);
                $(item).text(formatPrice(value, $(item).data('currency')));
            }
        }
    });

    tcgDOM.domPrice.tcgInit = function() {
        $('[data-tcgDOM="domPrice"]').each(function() {
            if ($(this).data(tcgConfig.namespace) == null) {
                new tcgDOM.domPrice($(this));  // no need to store the object it's an dom job
            }
        });
    };

    $(window).bind('tcg:initialize', function() {
        tcgDOM.domPrice.tcgInit();
    });

    $(document).ready(function() {
        tcgDOM.domPrice.tcgInit();
    });
})(VivaUtils, tcgConfig, _, ecatUserProfile, tcgGetPageProperty, $, tcgDOM, window);
