/* All these settings prevent analysis errors but mean
 * deviation from coding standard XXX */
/*jshint forin: false */
/*jshint nonew: false */
/*jshint maxcomplexity: 18 */
/*jshint browser: true */

(function(tcgConfig, ecatUserProfile, _, jQuery, $, tcgDOM, AppData) { // jshint ignore: line
    'use strict';

    /**
     * @class
     * @augments tcgDget
     * @param {Object} param
     */
    /**
     * @lends tcgDget.idgOrderStatus.prototype
     */
    tcgDOM.domSOLR = tcgDOM.extend({
        solr: '/solr',

        _init_: function() {
            var self = this;

            self.objName = 'domSOLR';


            if (document.location.href.indexOf('file:') !== -1) {
                self.solr = $(self.myDOM).attr('data-server') + self.solr;
            }

            self.listen('solr:load', function(event) {
                self.loadme(event.messageData);
            });

            self.listen('solr:save', function(event) {
                self.solrSave(event.messageData);
            });

            // self.listen('load', {self: self}, function(what, params) {
            //     alert('load' + what + params);
            // });
        },


        solrSave: function(params) {
            var self = this;

            if (params) {
                var url = self.getSolrUrl(params, ecatUserProfile.getLanguage()),
                    fields = [];

                for (var key in params.pData) {
                    fields.push({
                        '_name': key,
                        '__text': typeof params.pData[key] === 'object' ?
                            JSON.stringify(params.pData[key])
                            : params.pData[key]
                    });
                }

                /*jshint -W117 */
                var x2js = new X2JS(),
                    pLoad = x2js.json2xml_str({
                        'add': {
                            'doc': {
                                'field': fields
                            },
                            '_commitWithin': 1000,
                            '_overwrite': true
                        }
                    });

                // Dropped permissions for netscape browsers. See:
                // https://developer.mozilla.org/en-US/docs/Bypassing_Security_Restrictions_and_Signing_Code

                jQuery.ajax({
                    'url': url + '?wt=json',
                    'type': 'POST',
                    'processData': false,
                    'data': pLoad,
                    'contentType': 'text/xml; charset=UTF-8',
                    'dataType': 'json'
                })
                    .done(function(data, textStatus, jqXHR) {
                        params.pData.result = {
                            'status': jqXHR.status,
                            'statustext': jqXHR.statustext,
                            'response': data
                        };

                        if (typeof params.message === 'function') {
                            params.message(params.pData.result);
                        } else {
                            self.fire(params.message, params.pData);
                        }
                    })
                    .fail(function(jqXHR) {
                        $('#solrStatus').text(jqXHR.status + ' - ' + jqXHR.statusText);
                    });
            } // we have params
        },

        getSolrUrl: function(params, language) {
            var ret=null;
            if (typeof params.core==='string') {
                ret=AppData.getSolrUrl(params.core) + '/' + params.service;
            } else if (typeof language==='string') {
                ret=AppData.getSolrCoreUrl(language) + '/' + params.service;
            }
            return ret;
        },

        loadme: function(params) {
            var self = this;
            if (params) {
                // alert('loadme: ' + params.service);
                var requestType = 'json',
                    url = self.getSolrUrl(params, ecatUserProfile.getLanguage());

                $('#solrRequest').text(params.service + '/' + params.message);
                $('#solrQuery').text(params.pData.q);
                $('#solrNum').text('-');
                $('#solrTime').text('-');
                $('#solrStatus').text('000');

                if (typeof params.pData.clearance === 'string') {
                    if (typeof params.pData.fq === 'string') {// fq already in use
                        params.pData.fq = new Array(params.pData.fq);
                    }
                    if (!_.isArray(params.pData.fq)) {// already array
                        params.pData.fq = [];
                    }
                    params.pData.fq.push(this.clearance());
                    // params.pData['q']+=' AND '+this.clearance();
                    delete params.pData.clearance;
                }

                if (typeof params.pData.portfolio === 'string') {
                    this.portfolio(params.pData, params.pData.portfolio);
                    delete params.pData.portfolio;
                }
                if (_.isArray(params.pData.portfolio)) { // extension for multiple id's
                    var fq = [];
                    for (var i = 0; i < params.pData.portfolio.length; i++) {
                        var ptmp = {};
                        this.portfolio(ptmp, params.pData.portfolio[i]);
                        fq.push(ptmp.fq);
                    }
                    delete params.pData.portfolio;

                    if (typeof params.pData.fq === 'string') {// fq already in use
                        params.pData.fq = new Array(params.pData.fq);
                    }

                    if (typeof params.pData.fq === 'object') {// already array
                        params.pData.fq.push('( ' + fq.join(' OR ') + ' )');
                    } else {
                        params.pData.fq = '( ' + fq.join(' OR ') + ' )';
                    }
                } // extension multiple id's

                if (typeof params.requestType === 'string') {
                    requestType = params.requestType;
                }

                // if ($('#debug:checked').size()) {
                //     params.pData['debug']='true';
                // }

                params.pData.wt = 'json';

                var jQueryAjaxSettingsTraditional = jQuery.ajaxSettings.traditional;
                jQuery.ajaxSettings.traditional = true;

                // Dropped permissions for netscape browsers. See:
                // https://developer.mozilla.org/en-US/docs/Bypassing_Security_Restrictions_and_Signing_Code

                jQuery.get(url, params.pData, function(data) {
                    $('#solrStatus').text('200');
                    if (typeof data.response === 'object') {
                        $('#solrNum').text(data.response.numFound);
                    }
                    if (typeof data.responseHeader === 'object') {
                        $('#solrTime').text(data.responseHeader.QTime);
                    }
                    // if (jQuery.isXMLDoc(xml) || requestType=='text')

                    if (typeof params.message === 'function') {
                        params.message(data);
                    } else {
                        self.fire(params.message, data);
                    }
                    // else
                    //     throw url + ' does not return expected xml result';
                }, requestType === 'text' ? 'html' : requestType)
                    .fail(function(xhr) {
                        $('#solrStatus').text(xhr.status + ' - ' + xhr.statusText);
                    });
                jQuery.ajaxSettings.traditional = jQueryAjaxSettingsTraditional;
            }
        },


        portfolio: function(params, objectID, exSystem, exCountry) {
            return this.portfolio_pf(params, objectID, exSystem, exCountry);
        },

        portfolio_pf: function(params, objectID, exSystem, exCountry) {
            if (objectID.indexOf(',') !== -1) { // objectID hold ID,exportSystem,exportCountry
                var arrObjID = objectID.split(',');
                objectID = arrObjID[0];
                if (typeof exSystem === 'undefined' && arrObjID.length > 1) {
                    exSystem = arrObjID[1];
                }
                if (typeof exCountry === 'undefined' && arrObjID.length > 2) {
                    exCountry = arrObjID[2];
                }
            }

            var exportSystem = typeof exSystem === 'undefined' ? ecatUserProfile.getExportSystem() : exSystem,
                exportCountry = typeof exCountry === 'undefined' ? ecatUserProfile.getExportCountry() : exCountry,
                pf = [objectID, exportSystem, exportCountry],
                perm = [];

            if (objectID === '*') {
                perm.push(objectID + ',' + pf[1] + ',' + pf[2]);
            } else {
                for (var i = 0; i < (pf[0] === '*' ? 1 : 2); i++) {
                    for (var j = 0; j < (pf[1] === '*' ? 1 : 2); j++) {
                        for (var k = 0; k < (pf[2] === '*' ? 1 : 2); k++) {
                            // if (i!=1 && j!=1 && k!=1)
                            perm.push(tcgDOM.domSOLR.escape((i === 0 ? pf[0] : '*') + ',' + (j === 0 ? pf[1] : '*') +
                                ',' + (k === 0 ? pf[2] : '*')));
                        }
                    }
                }
            }
            if (typeof params.fq === 'string') {// fq already in use
                params.fq = new Array(params.fq);
            }

            if (typeof params.fq === 'object') {// already array
                params.fq.push('pf:' + perm.join(' OR pf:') + '');
            } else {
                params.fq = 'pf:' + perm.join(' OR pf:') + '';
            }

            return params;
        },

        clearance: function() {
            var sClearance = '';
            for (var i = 0; i < ecatUserProfile.clearance.length; i++) {
                // FIXME use strict equality, if possible (verify, before fixing!!)
                // jshint -W116
                if (sClearance != '') {
                    // jshint +W116
                    sClearance += ' OR ';
                }
                sClearance += 'clearance:' + ecatUserProfile.clearance[i];
            }

            // params.q+=' AND ('+sClearance+)';
            return '(' + sClearance + ')';
        }

    });


    tcgDOM.domSOLR.solrSpecial = '\\+-&|!() {}[]^~*?:"; ';

    tcgDOM.domSOLR.escape = function(q) {
        var ret = '';
        for (var i = 0; i < q.length; i++) {
            var c = q.charAt(i);
            ret += tcgDOM.domSOLR.solrSpecial.indexOf(c) !== -1 ? '\\' + c : c;
        }
        return ret;
    };


/*
    the contents of document.ready() should be placed in the
    startup of the catalog page, there is the only place to handle
    the correct startup sequence, otherwise it's not guaranteed
    that all initializations are correctly done!
*/

    tcgDOM.domSOLR.tcgInit = function() {
        $('[data-tcgDOM="domSOLR"]').each(function() {
            if ($(this).data(tcgConfig.namespace) == null) {
                new tcgDOM.domSOLR($(this));  // no need to store the object it's an dom job
            }
        });
    };

    $(window).bind('tcg:initialize', function() {
        tcgDOM.domSOLR.tcgInit();
    });

    $(document).ready(function() {
        tcgDOM.domSOLR.tcgInit();
    });


    /* ZG9tQkMuanMK */
    /**
     * $Id: domBC.js,v 1.3 2015-04-27 13:28:02 ml Exp $
     **/


    /**
     * @class
     * @augments tcgDget
     * @param {Object} param
     */
    /**
     * @lends tcgDget.idgOrderStatus.prototype
     */
    tcgDOM.domBC = tcgDOM.extend({
        bc: '/invoke',

        _init_: function() {
            var self = this;

            self.objName = 'domBC';

            if (document.location.href.indexOf('file:') !== -1) {
                self.bc = $(self.myDOM).attr('data-server') + $(self.myDOM).attr('data-bc');
            } else {
                if ($(self.myDOM).attr('data-bc')) {
                    self.bc = $(self.myDOM).attr('data-bc');
                }
            }

            self.listen('bc:load', function(event) {
                self.loadme(event.messageData);
            });


            $('input[name="INFO"]', $(self.myDOM)).click(function() {
                self.fire('messageBox', ['about']);
            });

            // self.listen('load', {self: self}, function(what, params) {
            //     alert('load' + what + params);
            // });
        },


        loadme: function(params) {
            var self = this;
            if (params) {
                // alert('loadme: ' + params.service);
                var requestType = 'text',
                    url = self.bc;
                params.pData['app-url'] = params.service;
                params.pData['web-site'] = ecatUserProfile.getExportCountry().toUpperCase(); // 2012-02-06/ml 'AT';
                params.pData['web-lang'] = ecatUserProfile.getBCLanguage().toUpperCase();
                params.pData.xformat_reload = '1';
                params.pData.contenttype = 'text/xml';

                $('#bcStatus').text(params.service + ' ... ');

                if (typeof params.requestType === 'string') {
                    requestType = params.requestType;
                }

                if ($('#debug:checked').size()) {
                    params.pData.debug = 'true';
                }

                var jQueryAjaxSettingsTraditional = jQuery.ajaxSettings.traditional;
                jQuery.ajaxSettings.traditional = true;

                // Dropped permissions for netscape browsers. See:
                // https://developer.mozilla.org/en-US/docs/Bypassing_Security_Restrictions_and_Signing_Code

                jQuery.post(url, params.pData, function(xml) {
                    if (jQuery.isXMLDoc(xml) || requestType === 'text') {
                        $('#bcStatus').text($('#bcStatus').text() + 'ok');
                        self.fire(params.message, xml);
                    } else {
                        $('#bcStatus').text($('#bcStatus').text() + 'error');
                        throw url + ' does not return expected xml result';
                    }
                }, requestType === 'text' ? 'html' : requestType);
                jQuery.ajaxSettings.traditional = jQueryAjaxSettingsTraditional;
            }
        },


        loadmeDIRECT: function(params) {
            var self = this;
            if (params) {
                // alert('loadme: ' + params.service);
                var requestType = 'text',
                    url = params.service;

                if (false && document.location.href.indexOf('file:') !== -1) {
                    var ext = '';
                    for (var opt in params.pFile) {
                        ext += '.' + params.pData[opt]; // jshint ignore: line
                    }
                    url = 'data/' + url + ext + '.xml';
                } else {
                    url = self.bc + '' + url;
                }

                $('#bcStatus').text(params.service + ' ... ');

                if (typeof params.requestType === 'string') {
                    requestType = params.requestType;
                }

                if ($('#debug:checked').size()) {
                    params.pData.debug = 'true';
                }

                var jQueryAjaxSettingsTraditional = jQuery.ajaxSettings.traditional;
                jQuery.ajaxSettings.traditional = true;

                // Dropped permissions for netscape browsers. See:
                // https://developer.mozilla.org/en-US/docs/Bypassing_Security_Restrictions_and_Signing_Code

                jQuery.post(url, params.pData, function(xml) {
                    if (jQuery.isXMLDoc(xml) || requestType === 'text') {
                        $('#bcStatus').text($('#bcStatus').text() + 'ok');
                        self.fire(params.message, xml);
                    } else {
                        $('#bcStatus').text($('#bcStatus').text() + 'error');
                        throw url + ' does not return expected xml result';
                    }
                }, requestType === 'text' ? 'html' : requestType);
                jQuery.ajaxSettings.traditional = jQueryAjaxSettingsTraditional;
            }
        }


    });


/*
    the contents of document.ready() should be placed in the
    startup of the catalog page, there is the only place to handle
    the correct startup sequence, otherwise it's not guaranteed
    that all initializations are correctly done!
*/

    $(document).ready(function() {
        $('[data-tcgDOM="domBC"]').each(function() {
            new tcgDOM.domBC($(this));  // no need to store the object it's an dom job
        });
    });
})(window.tcgConfig, window.ecatUserProfile, window._, window.jQuery, window.$, window.tcgDOM, window.AppData);
