/* All these settings prevent analysis errors but mean
 * deviation from coding standard XXX */
/* jshint browser: true */
/* global $, tcgGetPageProperty, _ */

(function(window, $, tcgGetPageProperty, _) {
    'use strict';

    // LOCAL VARIABLES
    var ecatUserProfile = _createUserProfile(),
        ecatUI = _createEcatUI();

    // EXPORTS

    window.ecatUserProfile = ecatUserProfile;
    window.ecatUI = ecatUI;
    window.zImageLoader = zImageLoader;
    window.dict = dict;
    window.configuration = {
        level1: [],
        level2: []
    };
    window.adjustabelLumen = {};
    window.showOnlyConfigurator = showOnlyConfigurator;
    window.hideAllGroups = hideAllGroups;
    window.hideAllGroupsButBasketAdd = hideAllGroupsButBasketAdd;
    window.hideBasketAddOfConfigurator = hideBasketAddOfConfigurator;

    // IMPLEMENTATION DETAILS

    function _loadConfiguration() {
        $.getJSON('../levelConfiguration.json', function(json) {
            json.level1.forEach(function(articletype) {
                var newTyp = {
                    parentId: articletype.parentId,
                    order: []
                };

                articletype.order.forEach(function(articleclass) {
                    var values = json.keys.filter(function(key) {
                        return key.key === articleclass;
                    });
                    if (values.length > 0) {
                        newTyp.order.push(values[0].value);
                    } else {
                        newTyp.order.push(articleclass);
                    }
                });
                window.configuration.level1.push(newTyp);
            });

            json.level2.forEach(function(family) {
                var newOrder = {
                    familyName: family.familyName,
                    baseAttributeOrder: family.baseAttributeOrder,
                    optionsOrder: family.optionsOrder
                };

                window.configuration.level2.push(newOrder);
            });
        }).fail(function() {
            console.log('Error reading levelConfiguration.json');
        });
    }

    function _loadAdjustableLumen() {
        $.getJSON('../adjustablelumen.json', function(json) {
            window.adjustabelLumen = json;
        });
    }

    function _loadTectonConfig() {
        $.getJSON('../tecton.json', function(json) {
            window.tectonConfig = json;
        });
    }

    function _loadFamilyNames() {
        $.getJSON('../familyNames.json', function(json) {
            window.familyNames = json;
        });
    }

    _loadConfiguration();
    _loadAdjustableLumen();
    _loadTectonConfig();
    _loadFamilyNames();

    String.prototype.toClassName = function() {
        return this.replace(/[^a-zA-Z0-9-_]/g, '-');
    };

    function _createEcatUI() {
        return {
            _trackEventNT: function() {}
        };
    }

    function _createUserProfile() {
        var clearance = [2];
        if (tcgGetPageProperty('__ac') !== null) {
            clearance.push(5);
        }

        return {
            clearance: clearance,
            getExportSystem: function() {
                return tcgGetPageProperty('ExportSystem');
            },
            getExportCountry: function() {
                return tcgGetPageProperty('ExportCountry');
            },
            getLanguage: function() {
                return tcgGetPageProperty('lang');
            },
            getBCLanguage: function() {
                return tcgGetPageProperty('bclang');
            },
            getCompany: function() {
                var company = tcgGetPageProperty('company');
                return company ? company : 'zl';
            },
            /**
             * @returns {string}
             * @depreacted DON'T USE THIS ANYMORE. USE `AppData.getSolrCoreUrl()` INSTEAD
             */
            getCore: function() {
                throw new Error('ecatUserProfile.getCore() is deprecated!');
                //                return [ecatUserProfile.getCompany(), ecatUserProfile.getLanguage(), 'running'].join('_');
            }
        };
    }


    var missingPhotos = {};

    function zSetPhoto(matnr) {
        if (_.has(missingPhotos, matnr) && _.isString(missingPhotos[matnr])) {
            var photo = missingPhotos[matnr];

            $('img[data-photosrc="' + matnr + '"]').each(function() {
                var dom = $(this),
                    src = $(dom).attr('src');

                $(dom).attr({
                    'src': src.replace(/\/w+\.jpg$/, photo + '.jpg'),
                    'data-photo': photo,
                    'data-photosrc': null
                });
            });
        }
    }

    function zImageLoader(dom, location) { // jshint ignore: line
        var photo = $(dom).attr('data-photo');
        if (photo) {
            $(dom).removeAttr('data-photo');
            if (photo === 'empty' || photo === 'photo') {
                console.log('photo: ' + photo);
            } else if (photo.indexOf('/') !== -1) { // it's a image proc?
                console.log('photo: ' + photo);
                $(dom).attr('src', photo);
                $(dom).removeAttr('data-photosrc'); // to aviod loading of base image
            } else {
                var httx = window.location.protocol;
                switch (ecatUserProfile.getCompany()) {
                    case 'tlx':
                        switch (location) {
                            case 'category':
                                $(dom).attr('src',
                                    httx + '//www.thornlighting.com/img/31/' + photo.toLowerCase() + '/'
                                );
                                break;
                            case 'article':
                                $(dom).attr('src',
                                    httx + '//www.thornlighting.com/img/1/' + photo.toLowerCase() + '/'
                                );
                                break;

                            default:
                                $(dom).attr('src',
                                    httx + '//www.thornlighting.com/img/31/' + photo.toLowerCase() + '/'
                                );
                        }
                        break;
                    case 'tl':
                        switch (location) {
                            case 'category':
                                /* falls through */
                            default:
                                $(dom).attr('src', httx +
                                    '//resources.thornlighting.com/PDB/photo/2011_products_60_L2_L4_L5/' +
                                    photo + '.jpg'
                                );
                        }
                        break;
                    case 'acdc':
                        switch (location) {
                            case 'category':
                                /* falls through */
                            default:
                                $(dom).attr('src', httx +
                                    '//resources.acdclighting.com/PDB/photo/2011_products_60_L2_L4_L5/' +
                                    photo + '.jpg'
                                );
                        }
                        break;

                    default:
                        switch (location) {
                            case 'category':
                                $(dom).attr('src', httx +
                                    '//resources.zumtobel.com/PDB/resources/Symbols/2011_category_80_png/' +
                                    photo + '.png'
                                );
                                break;
                            default:
                                var imageUrl = '';
                                if (photo.startsWith('//')) {
                                    imageUrl = httx + photo;
                                } else if (photo.includes('.png') || photo.includes('.jpg')) {
                                    imageUrl = httx +
                                        '//resources.zumtobel.com/PDB/resources/Photo/2011_products_80_L3/' +
                                        photo;
                                } else {
                                    imageUrl = httx +
                                        '//resources.zumtobel.com/PDB/resources/Photo/2011_products_80_L3/' +
                                        photo + '.jpg';
                                }

                                $(dom).attr('src', imageUrl);
                        }
                        break;
                }
            } // no path
        } else { // try image via basearticle
            var baseArticle = $(dom).attr('data-photosrc');
            if (baseArticle) {
                if (_.has(missingPhotos, baseArticle)) {
                    if (typeof missingPhotos[baseArticle] === 'string') {
                        zSetPhoto(baseArticle);
                    }
                } else {
                    missingPhotos[baseArticle] = true;

                    window.EventService.fire('solr:load', {
                        'service': 'select',
                        'pData': {
                            'q': 'objectType:article',
                            'fq': 'matnr:' + baseArticle,
                            'fl': 'matnr, photo'
                        },
                        'message': function(data) {
                            if (data.response.numFound !== 0) {
                                if (typeof data.response.docs[0].photo !== 'undefined') {
                                    missingPhotos[data.response.docs[0].matnr] = data.response.docs[0].photo[0];
                                    zSetPhoto(data.response.docs[0].matnr);
                                }
                            } else {
                                missingPhotos[baseArticle] = 'empty';
                                zSetPhoto(baseArticle);
                            }
                        }
                    });
                } // already processed
            }
        }
    }


    // read a dict entry from HTML
    function dict(key) { // jshint ignore: line
        return $('[data-dict-key="' + key + '"]', $('#DICT')).text();
    }

    function showOnlyConfigurator() {
        if (window === window.parent) {
            var groups = $('.groupItem', '.config');

            groups.each(function() {
                if (!$(this).hasClass('sysconfig')) {
                    $(this).hide();
                }
            });
        }
    }

    function hideAllGroups() {
        var groups = $('.groupItem', '.config');
        $('body').children().not('.groupList').addClass('off');

        groups.each(function() {
            if ($('.groupDetail', (this)).attr('data-articletype') ||
                $('.groupDetail', (this)).attr('data-configurator')) {
                $(this).hide();
            } else {
                $(this).addClass('selected');
                $('.groupDetail', $(this)).css('display', 'block');
                $('.groupHeader', $(this)).hide();
                $(this).children('.groupDetail').children('.vivaConfig').children('.vivaBasketAdd').hide();
            }
        });
    }

    function hideAllGroupsButBasketAdd() {
        var groups = $('.groupItem', '.config');
        $('body').children().not('.groupList').addClass('off');

        groups.each(function() {
            if ($('.groupDetail', (this)).attr('data-articletype') ||
                $('.groupDetail', (this)).attr('data-configurator')) {
                $(this).hide();
            } else {
                $(this).addClass('selected');
                $('.groupDetail', $(this)).css('display', 'block');
                $('.groupHeader', $(this)).hide();
            }
        });
    }

    function hideBasketAddOfConfigurator() {
        var configuratorGroup = $('.groupItem > .groupDetail[data-configurator]', '.config');
        if ($(configuratorGroup).length) {
            $('.vivaBasketAdd', $(configuratorGroup)).hide();
        }
    }

    $('document').ready(function() {
        // add stylesheet for company
        $('head').append($('<link>').attr({
            'rel': 'stylesheet',
            'type': 'text/css',
            'href': '../css/' + ecatUserProfile.getCompany() + '.css'
        }));


        // menu - dummy

        $('.menuItem').mouseenter(function() {
            $(this).addClass('highlight');
        });

        $('.menuItem').mouseleave(function() {
            $(this).removeClass('highlight');
        });

        $('.menuItem').click(function() {
            $('li', $(this).closest('ul')).removeClass('selected');
            $(this).addClass('selected');
        });


        // group - dummy

        $('.groupItem').mouseenter(function() {
            $(this).addClass('highlight');
        });

        $('.groupItem').mouseleave(function() {
            $(this).removeClass('highlight');
        });

        $('.groupHeader').click(function() {
            var li = $(this).closest('li');

            if ($(li).hasClass('selected')) { // just close
                $('.groupDetail', $(li)).addClass('closing');
                $('.groupDetail', $(li)).slideUp('slow', function() {
                    $(li).removeClass('selected');
                    $(this).removeClass('closing');
                });
            } else { // close other, open self
                $('.groupDetail', $(li)).hide();
                $(li).addClass('selected');
                $('.groupDetail', $(li)).addClass('opening');
                $('.groupDetail', $(li)).slideDown('slow', function() {
                    $('.groupDetail', $(li)).removeClass('opening');
                    // FIXME potential bug. There is no `bind` on `price:update`, there are only `listen`s (on
                    // `price:update.biz.trinea.event`)
                    $(window).trigger({
                        'type': 'price:update'
                    });
                });
            }
        });
    });

    // hypenator loading
    document.write('<script src="../js/lib/patterns/' + // jshint ignore: line
        ecatUserProfile.getLanguage() + '.js" ' +
        'type="text/javascript"></script>');
})(window, $, tcgGetPageProperty, _);
