/* All these settings prevent analysis errors but mean
 * deviation from coding standard XXX */
/*jshint nonew: false */
/*jshint browser: true */
/* global $, tcgDget */

(function($, tcgDget) {
    'use strict';


    /**
     * @class
     * @augments iDget
     * @param {Object} param
     */
    tcgDget.idgLogin = tcgDget.extend({

        /**
         * @lends iDget.idgLogin.prototype
         */

        loginText: 'Login',
        bLoggedIn: false,
        lastState: false,


        _init_: function() {
            var self = this;

            self.objName = 'idgLogin';

            $('[data-action="login"]', $(self.myDOM)).click(function() {
                // alert('n.i. login - interface missing');

                var uid = window.prompt('UID'),
                    pwd = window.prompt('PWD');
                self.doLogin(uid, pwd);
                // self.fire('messageBox', 'mbLogin');
            });

            $('[data-action="logout"]', $(self.myDOM)).click(function() {
                // alert('n.i. logout - interface missing');

                self.doLogout();
                // self.fire('messageBox', 'mbLogin');
            });

            self.listen('login:data', function(event) {
                var xml = event.messageData,
                    uid = $('[name="uid"]', $(xml)).attr('value');
                if (uid) {
                    self.bLoggedIn = true;
                    localStorage.uid = uid;
                } else {
                    self.bLoggedIn = false;
                    delete localStorage.uid;
                    delete localStorage.pwd;
                }

                $('[data-value]', $(self.myDOM)).each(function() {
                    $(this).text($('[name="' + $(this).attr('data-value') + '"]', $(xml)).attr('value'));
                });


                $(window).trigger('login:update', { 'status': self.bLoggedIn });
            });
            self.listen('logout:data', function() {
                delete localStorage.uid;
                delete localStorage.pwd;
                // alert(event.messageData);
                self.bLoggedIn = false;
                $(window).trigger('login:update', { 'status': self.bLoggedIn });
            });


            self.listen('login:update', function() {
                self.showState();
            });

            if (localStorage.uid && localStorage.pwd) {
                self.doLogin(localStorage.uid);
            }

            self.showState(true);
        },


        doLogin: function(uid, pwd) {
            var self = this;

            if (pwd) {
                localStorage.pwd = $.base64Encode(pwd);
            }

            self.fire('bc:load', {
                'service': 'Portal/login',
                'requestType': 'xml',
                'message': 'login:data',
                'pData': {
                    'Username': uid,
                    'PasswordB64': localStorage.pwd
                }
            });
        },

        doLogout: function() {
            var self = this;

            self.fire('logout:data');
            /*
             self.fire('bc:load', {
             'service': 'Portal/logout',
             'requestType': 'xml',
             'message': 'logout:data',
             'pData': {
             }
             });
             */
        },


        isLoggedIn: function() {
            var self = this;
            return self.bLoggedIn;
        },

        showState: function(force) {
            var self = this,
                isLoggedIn = self.isLoggedIn();
            // FIXME use strict equality, if possible (verify, before fixing!!)
            // jshint -W116
            if (force || isLoggedIn != self.lastState) {
                // jshint +W116
                self.lastState = isLoggedIn;
                if (isLoggedIn) {
                    $('[data-status="loggedOut"]', $(self.myDOM)).addClass('off');
                    $('[data-status="loggedIn"]', $(self.myDOM)).removeClass('off');
                    $('body').addClass('authenticated');
                    self.fire('login');
                } else {
                    $('[data-status="loggedIn"]', $(self.myDOM)).addClass('off');
                    $('[data-status="loggedOut"]', $(self.myDOM)).removeClass('off');
                    $('body').removeClass('authenticated');
                    self.fire('logout');
                }
            } // only change of state
        }


    });


/*
    the contents of document.ready() should be placed in the
    startup of the catalog page, there is the only place to handle
    the correct startup sequence, otherwise it's not guaranteed
    that all initializations are correctly done!
*/

    $(document).ready(function() {
        if (document.location.href.indexOf('file:') === 0 || document.location.href.indexOf('.tcg.local') !== -1) {
            $('[data-tcgDget="idgLogin"]').each(function() {
                new tcgDget.idgLogin($(this));  // no need to store the object it's an ecatUI job
            });
        } else { // for remote login of plone
            $(window).bind('login:update', function(event, messageData) {
                var bLoggedIn = messageData.status;
                if (bLoggedIn) {
                    $('body').addClass('authenticated');
                    // FIXME: potential bug? supposed to be `self.fire(...)?`
                    $(window).trigger('login');
                    $('.helpLink').removeClass('off');
                } else {
                    $('body').removeClass('authenticated');
                    // FIXME: potential bug? supposed to be `self.fire(...)?`
                    $(window).trigger('logout');
                    $('.helpLink').addClass('off');
                }
            });
        }
    });


    /**
     * $Log: idgLogin.js,v $
     * Revision 1.4  2016-04-01 15:55:06  ml
     * fixes
     *
     * Revision 1.3  2016-03-09 16:59:49  ml
     * Login Sync w. Plone
     *
     * Revision 1.2  2016-02-25 09:05:45  ml
     * plone proxy edition
     *
     * Revision 1.1  2016-02-16 08:20:21  ml
     * simple login
     *
     **/
})($, tcgDget);
