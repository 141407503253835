/* All these settings prevent analysis errors but mean
 * deviation from coding standard XXX */
/*jshint forin: false */
/*jshint nonew: false */
/*jshint browser: true */
/* global localStorage , _, $, tcgDget */

(function(localStorage, window, _, $, tcgDget) { // jshint ignore: line
    'use strict';

    /**
     * @class
     * @augments tcgDget
     * @param {Object} param
     */
    /**
     * @lends tcgDget.idgLogin.prototype
     */
    tcgDget.idgArticleList = tcgDget.extend({
        articleTypes: [],
        articleTypeNames: {},
        family: {
            'title': 'unknownFamily',
            'id': 'unknoenFamilyID'
        },

        availabilityText: {
            '*': 'unknown {1}'
        },

        ulArticleList: null,        // the DOM list

        sConfigFirst: '',
        sConfigFirstPrice: '',
        matnrText: '',
        photoText: '',

        isIframe: false,


        _init_: function() {
            var self = this;

            self.isIframe = (window.location !== window.parent.location);

            self.objName = 'idgArticleList';
            self.setDgetMode('single');

            var artTypes = $(self.myDOM).attr('data-ArticleType');
            if (artTypes.indexOf(',') !== -1) {// multiple
                self.articleTypes = artTypes.split(',');
            } else {// single
                self.articleTypes.push(artTypes);
            }

            // ABC translation - init
            $('li', 'ul[data-template="availabilityDefinition"]').each(function() {
                self.availabilityText[$(this).attr('data-availability')] = $(this).text();
            });

            self.listen('family', function(event) {
                self.family = event.messageData;
            });


            self.sConfigFirst = $('.availabilityConfig', $('.configItem')).text();
            self.sConfigFirstPrice = $('[data-matnr]', $('.configItem')).text();
            self.matnrText = $('.matnrTemplate', $('.configItem')).text();
            self.photoText = $('.articleImage>img', $('.configItem')).attr('data-src');

            self.listen('article:addByType', function(event) {
                var article = event.messageData;

                if (_.indexOf(self.articleTypes, article.ArtType_s, false) !== -1) { // we will show the article
                    self.updateGroupInformation(article.ArtType_s, article.ArtTypeName_s);

                    if ($('ul.articleList', self.myDOM).size() === 0) { // first product? append the list
                        $(self.myDOM).append(self.cloneTemplate($('[data-template="articleList"]')));

                        self.ulArticleList = $('ul.articleList', self.myDOM);
                    }

                    // ABC translation
                    if (article.abcMark) { // set availability text
                        article.abcText = self.availabilityText[article.abcMark];
                        if (typeof article.abcText === 'undefined') {
                            article.abcText = self.availabilityText['*'].replace('{1}', article.abcMark);
                        }
                    }

                    if (typeof article.CfgID_s === 'string' && typeof article.BasisArt_s === 'string') {
                        self.appendArticleConfig(article);
                    } else {
                        self.appendArticle(article);
                    }
                }
            });


            self.listen('viva:notAvailable', function() { // allow luminiare group to display if viva is not available
                var myGroupItem = $(self.myDOM).closest('.groupItem');
                $(myGroupItem).removeClass('vivaAvailable');
            });

            self.listen('article:hideWhenEmpty', function() {
                if (_.values(self.articleTypeNames).length === 0) { // hide group
                    $(self.myDOM).closest('.groupItem').addClass('off');

                    self.cleanupConfigItems();
                } else { // add listeners
                    $(self.myDOM).closest('.groupItem').removeClass('off');
                    $('.articleOpenClose', $(self.myDOM)).click(function() {
                        var li = $(this).closest('li');

                        if ($(li).hasClass('selected')) { // just close
                            $('.articleDetail', $(li)).slideUp('slow', function() {
                                $(li).removeClass('selected');
                            });
                        } else { // close other, open self
                            $(li).addClass('selected');
                            $('.articleDetail', $(li)).slideDown('slow', function() {
                            });
                        }
                    });

                    $('.articleQty4Cart', $(self.myDOM)).focus(function() {
                        $(this).select();
                    });

                    $('.articleAdd2Cart', $(self.myDOM)).click(function() {
                        if ($(this).closest('.articleBasketAdd').hasClass('disabled')) {
                            return;
                        }

                        var articleItem = $(this).closest('.articleItem'),
                            qtyInput = $(this).closest('.articleBasketAdd').find('.articleQty4Cart'),
                            article = $(articleItem).data('article');
                        article.family = self.family.title;
                        article.familyID = self.family.id;
                        if (/\d+/.test($(qtyInput).val())) {
                            article.qty = +$(qtyInput).val();
                        } else {
                            article.qty = 1;
                        }

                        article.cartID = $(this).closest('.articleBasketAdd').find('.domCartSelector').val();

                        article.pos = null;
                        article.grouping = article.ArtType_s;
                        article.groupsorting = 10;
                        article.sorting = 10;

                        self.fire('cartItem', {
                            'service': 'update/insert',
                            'pData': article
                        });

                        window.setTimeout(function() {
                            $(qtyInput).val('');
                        }, 3500);
                    });

                    if (self.isIframe) {
                        window.setTimeout(function() {
                            $('.addArticleToConfig', $(self.myDOM)).click(function() {
                                if ($(this).hasClass('disabled')) { return; }
                                if (self.isIframe) {
                                    var articleItem = $(this).closest('.articleItem'),
                                        article = $(articleItem).data('article'),
                                        parentFamily = self.getIframeParentConfiguratorName();

                                    self.getIframeParentWindow().trigger(parentFamily + ':' +
                                        parentFamily + 'ArticleChanged', article);

                                    self.getIframeParentWindow().find('#popup,#shroud').addClass('off');
                                    self.getIframeParentWindow().find('.modal').removeClass('selected');

                                    var data = {};
                                    data.family = self.getIframeParentWindow()
                                        .find('.modal').data('family');

                                    data.mounttype = self.getIframeParentWindow()
                                        .find('[data-group="mount-type"]')
                                        .find('.smallButtonList')
                                        .find('li.selected')
                                        .data('mount-type');

                                    data.color = self.getIframeParentWindow()
                                        .find('[data-group="color"]')
                                        .find('.smallButtonList')
                                        .find('li.selected')
                                        .data('color');

                                    self.getIframeParentWindow().trigger(parentFamily + ':addConfig', data);
                                    self.getIframeParentWindow().trigger(parentFamily + ':addArticleFromList');
                                }
                            });
                        }, 3500);
                    }

                    // init all cart selectors to current
                    if (localStorage && localStorage.curCart) {
                        $('.domCartSelector').val(localStorage.curCart);
                    }

                    $(window).trigger('tcg:attach'); // attach cloned object's

                    $('.domCfgSelector', $(self.myDOM)).change(function() {
                        var domArticle = $(this).closest('.articleItem');
                        self.configChanged($(domArticle), $(this));
                    });

                    $('.domCfgSelector', $(self.myDOM)).focus(function() {
                        var domArticle = $(this).closest('.articleItem');
                        self.configValidOptions($(domArticle), $(this));
                    });
                }
            });
        },


        configValidOptions: function(domArticle, domSelect) {
            // var article=$(domArticle).data('article');
            var cfgData = $(domArticle).data('cfgData'),
                myName = $(domSelect).attr('name'),
                myNameS = myName + '_s',
                curSel = {};
            $('select[name]', $(domArticle)).each(function() {
                // FIXME use strict equality, if possible (verify, before fixing!!)
                // jshint -W116
                if ($(this).val() && $(this).attr('name') != myName) { // something selected
                    // jshint +W116
                    curSel[$(this).attr('name') + '_s'] = $(this).val();
                }
            });

            var result = _.isEmpty(curSel) ?
                _.uniq(_.pluck(cfgData, myNameS))
                : _.uniq(_.pluck(_.where(cfgData, curSel), myNameS));

            if (result.length) {
                $('option', $(domSelect)).each(function() {
                    var val = $(this).val();
                    if (val) {
                        if (_.indexOf(result, val) === -1) {
                            $(this).attr('disabled', 'disabled');
                        } else {
                            $(this).removeAttr('disabled');
                        }
                    }
                });
            } else {
                $('option', $(domSelect)).each(function() {
                    $(this).removeAttr('disabled');
                });
            }
        },


        /**
         * config of accessory article changed, show/hide options
         * @param domArticle
         * @param domSelect
         */
        configChanged: function(domArticle) {
            var self = this;
            // var article=$(domArticle).data('article');
            var cfgData = $(domArticle).data('cfgData'),
                curSel = {},
                missingSel = {},
                allOptionsHaveValues = true;
            $('select[name]', $(domArticle)).each(function() {
                if ($(this).val()) { // something selected
                    curSel[$(this).attr('name') + '_s'] = $(this).val();
                } else {
                    missingSel[$(this).attr('name')] = null;
                    allOptionsHaveValues = false;
                }
            });

            var result = _.where(cfgData, curSel);

            // if (!_.isEmpty(missingSel)) {
            //     result=[];
            // }

            if (result.length === 1) { // final
                var article = result[0];

                // if (elementCfgid === article.BasisArt_s) {
                //     console.log('richtig');
                // }

                if (!_.isEmpty(missingSel)) {
                    for (var opt in missingSel) {
                        $('select[name="' + opt + '"]', $(domArticle)).val(article[opt + '_s']);
                    }
                }

                $(domArticle).data('article', article);
                $('.articleBasketAdd', $(domArticle)).removeClass('disabled');
                $('.availabilityConfig', $(domArticle)).text(article.abcText);
                $('.matnrTemplate', $(domArticle)).text(self.matnrText.replace('{matnr}', article.matnr));
                $('.matnrTemplate', $(domArticle)).removeClass('off');

                $('.articleImage>img', $(domArticle)).attr('src', self.photoText.replace('{photo}', article.photo));

                // add price data
                $('[data-matnr]', $(domArticle)).attr('data-matnr', article.matnr).removeAttr('data-load');
                self.fire('price:update');

                if (self.isIframe) {
                    self.getIframeParentWindow().trigger('tecton:tectonArticleChanged', article);
                    $('.addArticleToConfig', $(domArticle)).removeClass('disabled');
                }
            } else {
                // $(domArticle).data('article', result[0]);
                $('.articleBasketAdd', $(domArticle)).addClass('disabled');
                $('.availabilityConfig', $(domArticle)).text(self.sConfigFirst);
                $('.matnrTemplate', $(domArticle)).addClass('off');
                $('[data-matnr]', $(domArticle)).text(self.sConfigFirstPrice);

                if (self.isIframe) {
                    $('.addArticleToConfig', $(domArticle)).addClass('disabled');
                }
            }
        },

        getIframeParentWindow: function() {
            return window.parent.$(window.parent.document);
        },

        getIframeParentConfiguratorName: function() {
            var self = this;

            return self.getIframeParentWindow().find('[data-configurator]').data('configurator');
        },

        /**
         * set group information based on articleTypeNames
         * @param articleType
         * @param typeName
         */
        updateGroupInformation: function(articleType, typeName) {
            var self = this;

            if (typeof self.articleTypeNames[articleType] === 'undefined') {
                self.articleTypeNames[articleType] = typeName;
                $('.groupTitle', $(self.myDOM).parent()).text(_.values(self.articleTypeNames).join(', '));
            }
        },

        /**
         * new article based on standard
         * @param article
         */
        appendArticle: function(article) {
            var self = this;

            $(self.ulArticleList).append(self.cloneTemplate($('[data-template="articleItem"]'), article, 'article'));
        },

        /**
         * new article based on config options
         * @param article
         */
        appendArticleConfig: function(article) {
            var self = this,
                cfgID = article.CfgID_s + ':' + article.BasisArt_s,
                domCfg = $('li[data-cfgid="' + cfgID + '"]', self.ulArticleList),
                cfgData = [];

            if ($(domCfg).size() === 0) { // append new config item
                $(self.ulArticleList).append(self.cloneTemplate($('[data-template="configItem"]'), article, 'article'));

                domCfg = $('li[data-cfgid="' + cfgID + '"]', self.ulArticleList);
            } else {
                cfgData = $(domCfg).data('cfgData');
            }

            cfgData.push(article);
            $(domCfg).data('cfgData', cfgData);

            // append article attributes
            for (var a = 3, i = 0; a > 0; a--) {
                var attr = 'Attr' + a;
                if (typeof article[attr + '_s'] === 'string') {
                    var title = $('.articleConfigTitle:eq(' + i + ')', domCfg);
                    $(title).text(article[attr + 'Caption_s']);

                    var select = $('select.domCfgSelector:eq(' + i + ')', domCfg);
                    $(select).attr('name', attr);
                    if ($('option[value="' + article[attr + '_s'] + '"]', $(select)).size() === 0) {// not attached
                        $(select).append($('<option>').attr('value', article[attr + '_s'])
                            .text(article[attr + 'Name_s']));
                    }
                    i++;
                }
            }
        },

        /**
         * remove select boxes without entries
         */
        cleanupConfigItems: function() {
            var self = this;
            $('.domCfgSelector', self.ulArticleList).each(function() {
                var select = $(this);

                if ($('option', $(select)).size() === 1) { // empty
                    $(select).closest('.articleConfig').addClass('off');
                    $(select).closest('.configItem').addClass('rows2');
                }
            });
        }

    });


/*
    the contents of document.ready() should be placed in the
    startup of the catalog page, there is the only place to handle
    the correct startup sequence, otherwise it's not guaranteed
    that all initializations are correctly done!
*/

    $(document).ready(function() {
        $('[data-tcgDget="idgArticleList"]').each(function() {
            new tcgDget.idgArticleList($(this));  // no need to store the object it's an ecatUI job
        });
    });


    /**
     * $Log: idgArticleList.js,v $
     * Revision 1.17  2016-02-16 08:15:58  ml
     * pricing additions
     *
     * Revision 1.16  2015-12-01 08:29:47  ml
     * changed simpleConfig to use CfgID_s instead of CfgName_s
     *
     * Revision 1.15  2015-09-25 08:31:22  ml
     * Aster Markus (9/10/2015 2:12 PM): Das ist uns schon klar aber es sollte so sein wenn in einer DropDown nur 1
     * Option ist soll diese gleich ausgewühlt sein und nicht stehen bitte wäheln
     *
     * Revision 1.14  2015-09-08 15:01:13  ml
     * auto select article disabled if not all options chosen
     *
     * Revision 1.13  2015-06-19 15:36:34  ml
     * accessory config changes - meeting w. Gerd
     *
     * Revision 1.12  2015-06-15 07:24:25  ml
     * basket update/insert fix
     *
     * Revision 1.11  2015-05-26 15:16:02  ml
     * accessory config
     *
     * Revision 1.10  2015-05-26 09:45:42  ml
     * current cart selection after clone
     *
     * Revision 1.9  2015-05-11 14:05:21  ml
     * accessory config - display only
     *
     * Revision 1.8  2015-04-28 09:41:39  ml
     * delete, send email add to baskte stuff
     *
     * Revision 1.7  2015-04-27 13:29:07  ml
     * slideDown
     *
     * Revision 1.6  2015-04-21 13:38:40  ml
     * loadin, classes
     *
     * Revision 1.5  2015-04-17 17:02:57  ml
     * cart selector stuff
     *
     * Revision 1.4  2015-04-16 05:39:19  ml
     * intermediate checkin
     *
     * Revision 1.3  2015-04-09 19:42:44  ml
     * intermediate checkin
     *
     * Revision 1.2  2015-04-06 21:50:28  ml
     * demo version 7.4.2015
     *
     * Revision 1.1  2015-04-06 19:27:07  ml
     * intermediate checkin
     *
     **/
})(localStorage, window, _, $, tcgDget);
