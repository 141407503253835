/*jshint maxcomplexity: 107 */
/*jshint maxdepth: 5 */
/*jshint forin: false */
/*jshint browser: true */

(function(window) {
    'use strict';

    /**
     * tcgCore is the namespace for the tcgCore library
     * @namespace Global namespace for tcgCore library
     */
    window.tcgCore = function() {};
    var tcgCore = window.tcgCore;

    /**
     * base class for extending
     */
    tcgCore.Class = function() {
    };

    /**
     * A class 'extends' itself into a subclass.
     *
     * @static
     * @param properties The properties of the subclass.
     * @returns A function that represents the subclass.
     */
    tcgCore.Class.extend = function(properties) {
        // Safari dislikes 'class'
        var Klass = this;
        // The subclass is just a function that when called, instantiates itself.
        // Nothing is _actually_ shared between _instances_ of the same class.
        function subClass(options) {
            // 'this' refers to the subclass, which starts life as an empty object.
            // Add its parent's properties, its own properties, and any passed options.
            var moreOpts = arguments[1] == null ? null : arguments[1];
            if (moreOpts) {
                tcgCore.extend(this, new Klass(options), properties, options, moreOpts);    // jshint ignore: line
            } else {
                tcgCore.extend(this, new Klass(options), properties, options);              // jshint ignore: line
            }
        }
        // Allow the subclass to extend itself into further subclasses.
        subClass.extend = this.extend;
        return subClass;
    };


    /**
     * A simplified version of jQuery's extend function.
     *
     * @static
     * @see http://ajax.googleapis.com/ajax/libs/jquery/1.2.6/jquery.js
     */
    tcgCore.extend = function() {
        var target = arguments[0] || {},
            i = 1,
            length = arguments.length,
            options;
        for (; i < length; i++) {
            if ((options = arguments[i]) != null) {
                if (typeof options.jquery === 'undefined') { // do not clone jquery
                    // stuff
                    for (var name in options) {
                        var src = target[name],
                            copy = options[name];
                        if (target === copy) {
                            continue;
                        }
                        if (copy && typeof copy === 'object' && !copy.nodeType) {
                            target[name] = tcgCore.extend(src || (copy.length != null ? [] : {}), copy);
                        } else if (copy && src && typeof copy === 'function' && typeof src === 'function') {
                            target[name] = (function(superfn, fn) {
                                return function() {
                                    var tmp = this._super,
                                        ret;
                                    this._super = superfn;
                                    ret = fn.apply(this, arguments);
                                    this._super = tmp;
                                    return ret;
                                };
                            })(src, copy);  // jshint ignore: line
                        } else if (copy != null) {
                            target[name] = copy;
                        }
                    }
                } else {
                    target = target.length === 0 ? options : target;
                }
            }
        }

        if (typeof target._init_ === 'function') {
            target._init_(options);
        }
        return target;
    };
})(window);
