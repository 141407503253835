// jscs:disable maximumNumberOfLines
/* All these settings prevent analysis errors but mean
 * deviation from coding standard XXX */
/*jshint forin: false */
/*jshint nonew: false */
/*jshint maxcomplexity: 20 */
/*jshint maxparams: 6 */
/*jshint browser: true */
/* global ecatUserProfile, _, $, tcgDget */

(function(ecatUserProfile, _, $, tcgDget) {
    'use strict';

    Number.prototype.tcgN = function() {
        var thouSeparator, decSeparator,
            n         = this,
            decPlaces = isNaN(decPlaces = Math.abs(decPlaces)) ? 2 : decPlaces, // jshint ignore: line
            decSeparator  = decSeparator == undefined ? '.' : decSeparator, // jshint ignore: line
            thouSeparator = thouSeparator == undefined ? ',' : thouSeparator, // jshint ignore: line
            sign          = n < 0 ? '-' : '',
            i             = parseInt(n = Math.abs(+n || 0).toFixed(decPlaces)) + '',
            j             = (j = i.length) > 3 ? j % 3 : 0;
        return sign + (j ? i.substr(0, j) + thouSeparator : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thouSeparator) + (decPlaces ? decSeparator + Math.abs(n - i).toFixed(decPlaces).slice(2) : '');
    };


    /**
     * @class
     * @augments tcgDget
     * @param {Object} param
     */
    /**
     * @lends tcgDget.idgLogin.prototype
     */
    tcgDget.idgPrint = tcgDget.extend({
        printPage2: false,

        familyName: null,        // repeating header
        subTitle: null,         // repeating header

        page: {
            num: 0,
            title: 'basket',
            date: '2015-05-01',


            A4: {
                width: 210,
                height: 297
            }
        },

        margin: {
            left: 22,
            top: 15,
            right: 12,
            bottom: 11
        },

        font: {
            pt2mm: 0.3527,
            h2: {
                face: 'Helvetica',
                size: 11,
                style: 'Bold'
            },
            h3: {
                face: 'Helvetica',
                size: 9,
                style: 'Bold'
            },
            title: {
                face: 'Helvetica',
                size: 11,
                style: 'Bold'
            },
            text: {
                face: 'Helvetica',
                size: 11,
                style: ''
            },
            small: {
                face: 'Helvetica',
                size: 9,
                style: ''
            },
            table: {
                face: 'Helvetica',
                size: 8,
                style: ''
            },
            tableBold: {
                face: 'Helvetica',
                size: 8,
                style: 'Bold'
            },
            tableFooter: {
                face: 'helvetica',
                size: 8,
                style: 'italic'
            },
            tiny: {
                face: 'Helvetica',
                size: 6.5,
                style: ''
            }
        },

        _init_: function() {
            var self = this;

            self.objName = 'idgPrint';
            self.setDgetMode('single');

            self.unitQty = $('.unit', $('.basketQty:eq(0)')).text();
            self.company = dict('company'); // jshint ignore: line


            self.listen('print', function(event) {
                var postData = event.messageData,
                    pData = {};
                if (postData.pData && typeof postData.pData.cartID === 'string') { // single cart
                    pData.cartID = postData.pData.cartID;
                }


                self.fire('cartItem', {
                    'service': 'list',
                    'pData': pData,
                    'message': function(pData) {
                        self.printCarts(pData);
                    }
                });
            });


            self.listen('printX', function(event) {
                self.prepareImages(function() {
                    self.doPrint(event.messageData.pData);
                });
            });
        },

        /**
         * print whole cart from item list
         **/
        printCarts: function(itemList) {
            var self = this,
                items = _.chain(itemList)
                    .filter(function(item) {
                        return _.isNull(item.deleted) || _.isUndefined(item.deleted);
                    })
                    .sortBy(function(article) {
                        var grp = '9';
                        if (typeof article.ArtType_s === 'string') {
                            switch (article.ArtType_s) {
                                case 'G':
                                    grp = '0';
                                    break;
                                case 'H':
                                    grp = '1';
                                    break;
                                case 'A':
                                    grp = '2';
                                    break;
                                case 'C':
                                    grp = '3';
                                    break;
                                case 'F':
                                    grp = '4';
                                    break;
                                case 'D':
                                    grp = '5';
                                    break;
                                case 'B':
                                    grp = '6';
                                    break;
                                default:
                                    grp = '8';
                            }
                        }
                        article.__grp = grp;
                        return [article.cartID, article.family, grp].join('_');
                        // return [article.family, grp].join('_');
                    })
                    .value();


            self.cacheImages(_.uniq(_.flatten(_.pluck(items, 'photo'))), function() {
                self.doPrintList(items);
            });
        },

        /**
         * cache images via list of photo names
         * after all images are loaded, the callback will be executed
         **/
        cacheImages: function(imgList, cb) {
            var self = this;

            self.imgRefresh = 0;

            for (var i = 0; i < imgList.length; i++) {
                if (typeof imgList[i] === 'undefined') {
                    continue;
                }
                if ($('img[data-cache="' + imgList[i] + '"]').size() === 0) { // item not in cache
                    self.imgRefresh++;
                    var img = $('<img>');
                    try {
                        $(img)[0].setAttribute('crossOrigin', 'anonymous');
                    } catch (e) {
                        alert(e); // jshint ignore: line
                    }

                    $(img).attr('data-cache', imgList[i]);
                    $(img).load(function() { // jshint ignore: line
                        self.imgRefresh--;
                        if (self.imgRefresh === 0) {
                            cb();
                        }
                    }); // jshint ignore: line

                    $(img).attr('src', '/PDB/resources/Photo/2011_products_584_L4/' + imgList[i] + '.jpg');
                    $('#imageCache').append($(img));
                }
            }
            if (self.imgRefresh === 0) {
                cb();
            }
        },

        /**
         * load hires images for print in cache
         **/
        prepareImages: function(cb) {
            var self = this,
                imgList = [];
            $('.articleItem:not(.deleted)').each(function() {
                var article = $(this).data('article');

                if (article && typeof article.photo !== 'undefined') {
                    imgList.push(article.photo[0]);
                }
            });
            self.cacheImages(imgList, cb);
        },


        /**
         * print basket from list
         **/
        doPrintList: function(items) {
            var self = this;
            // jscs:disable
            var doc = new jsPDF('p', 'mm', 'a4'); // jshint ignore: line
            // jscs:enable

            self.page.num = 0;
            self.page.max = Math.ceil(items.length / 8);
            // self.page.title=cartData.title;
            self.page.subject = $('title').text();
            self.page.date = new Date().toISOString().substr(0, 10);
            self.page.date2 = self.page.date.replace(/(.{4})-(.{2})-(.{2})/, '$3.$2.$1');

            doc.setProperties({
                title: self.page.title,
                subject: self.page.subject,
                author: self.page.date + ' ' + self.company,
                keywords: _.uniq(_.pluck(items, 'family')).join(', '),
                creator: self.company + ' VIVA WebApp, (C) Zumtobel Group AG'
            });

            self.adjustFonts(doc);

            var posy = 0,
                cartCnt = 0,
                carts = _.groupBy(items, 'cartID');
            for (var cartID in carts) {
                var cart = carts[cartID];

                cartCnt++;

                self.fire('cart', {
                    'service': 'list',
                    'pData': {
                        'id': cartID
                    },
                    'message': function(pData) { // jshint ignore: line
                        if (pData.length === 0) {
                            self.page.title = cartID;
                        } else {
                            self.page.title = pData[0].name;
                        }
                    }
                }); // jshint ignore: line
                // self.page.title=pData.ret;
                if (self.page.num) {
                    posy = self.checkSpaceOnPage(doc, posy, self.page.A4.height, false);        // new page for a new
                                                                                                // cart
                } else {
                    posy = self.printHeader(doc);
                }

                var families = _.groupBy(cart, 'family');
                for (var familyID in families) {
                    var family = families[familyID];

                    posy = self.checkSpaceOnPage(doc, posy, self.font.h2.size * self.font.pt2mm +    // Family
                        5 +    // space
                        self.font.h3.size * self.font.pt2mm + // group
                        self.heightOFblock(family), false);  // 35
                    posy += 5;

                    posy += self.printGroupTitle(doc, posy, familyID);
                    /*
                     var h2 = doc.setFont(self.font['h2'].face, self.font['h2'].style)
                     .setFontSize(self.font['h2'].size)
                     .splitTextToSize(familyID, 210-self.margin.right-self.margin.left);

                     doc.text(self.margin.left, posy, h2);
                     posy+=h2.length*self.font['h2'].size*self.font.pt2mm;
                     */
                    var groups = _.groupBy(family, '__grp');
                    for (var grp in groups) {
                        var group = groups[grp];

                        posy = self.checkSpaceOnPage(doc, posy, self.font.h3.size * self.font.pt2mm + // group
                            self.heightOFblock(group), false); // 30

                        var groupName = 'n/a';
                        groupName = _.uniq(_.pluck(group, 'ArtTypeName_s')).join(', ');

                        posy += self.printSubTitle(doc, posy, groupName);

                        /*
                         var h3 = doc.setFont(self.font['h3'].face, self.font['h3'].style)
                         .setFontSize(self.font['h3'].size)
                         .splitTextToSize(groupName, 210-self.margin.right-self.margin.left);

                         doc.text(self.margin.left, posy, h3);
                         posy+=h3.length*self.font['h3'].size*self.font.pt2mm;
                         */
                        for (var i = 0; i < group.length; i++) {
                            var item = group[i];

                            switch (item.objectType) {
                                case 'cfgResult':
                                    posy = self.printConfiguration(doc, item, posy);
                                    break;


                                case 'vivaArticle':
                                /* falls through */
                                case 'article':
                                /* falls through */
                                default:
                                    posy = self.printArticle(doc, item, posy);
                            }
                        } // articles
                    } // group
                } // family
            } // carts

            // FIXME use strict equality, if possible (verify, before fixing!!)
            // jshint -W116
            if (cartCnt != 1) {
                // jshint +W116
                doc.save('all.pdf');
            } else {
                doc.save(self.page.title + '.pdf');
            }

            return;
            /* old version based on DOM
             $('.groupItem', $(dom)).each(function() {
             //// check if there is enough space for at least one or two items

             posy=self.checkSpaceOnPage(doc, posy, 35);


             var family=$(this);
             var h2 = doc.setFont(self.font['h2'].face, self.font['h2'].style)
             .setFontSize(self.font['h2'].size)
             .splitTextToSize($('h2', $(family)).text(), 210-self.margin.right-self.margin.left);

             doc.text(self.margin.left, posy, h2);
             posy+=h2.length*self.font['h2'].size*self.font.pt2mm;

             $('.groupDetail', $(family)).each(function(){
             posy=self.checkSpaceOnPage(doc, posy, 30);


             var group=$(this);
             var articles=$('.articleList', $(group)).children('li:not(.deleted)');
             if ($(articles).size()>0) { // we have items - show header
             var h3 = doc.setFont(self.font['h3'].face, self.font['h3'].style)
             .setFontSize(self.font['h3'].size)
             .splitTextToSize($('h3:first', $(group)).text(), 210-self.margin.right-self.margin.left);

             doc.text(self.margin.left, posy, h3);
             posy+=h3.length*self.font['h3'].size*self.font.pt2mm;

             $(articles).each(function() {
             posy=self.checkSpaceOnPage(doc, posy, 25);
             var domArticle=$(this);
             posy=self.printArticle(doc, $(domArticle).data('article'), posy);
             });
             posy+=4;    // 4 mm platz
             }
             });

             posy+=8;    // 8 mm platz
             });

             doc.save(cartData.title+'.pdf');
             */
        },

        heightOFblock: function(obj) {
            var self = this,
                blk = _.isArray(obj) ? obj[0] : obj;
            switch (blk.objectType) {
                case 'cfgResult':
                    return 14 * self.font.pt2mm * (3 + blk.products.length);        // 14pt per line, 3 = 1 title and
                                                                                    // 1 table header and 1 table
                                                                                    // footer line
                default:
                    return 25;                            // 25 mm per block
            }
        },


        printShape: function(doc, config, posX, posY, W, H) {
            var self = this; // jshint ignore: line

            var wShape = (W + H) / 25, // jshint ignore: line
                border = (W + H) / 15,
                imageHeight = H - 2 * border,
                imageWidth = W - 2 * border,
                startX = posX + border,
                startY = posY + border;

            switch (config.shape) {
                case 'U':
                    self.setFont(doc, 'tiny').text('C', posX + W - 0.5, posY + H / 2, 'right');
                /* falls through */
                case 'L':
                case 'O':
                    self.setFont(doc, 'tiny').text('B', posX + W / 2, posY + H - 0.5, 'center');
                    self.setFont(doc, 'tiny').text('A', posX + 0.5, posY + H / 2);
                    break;
                case 'T':
                    self.setFont(doc, 'tiny').text('A', posX + W / 2 - wShape * 1.5, posY + H / 2, 'center');
                    self.setFont(doc, 'tiny').text('B', posX + W * 0.75, posY + wShape + 0.5, 'center');
                    self.setFont(doc, 'tiny').text('C', posX + W * 0.25, posY + wShape + 0.5, 'center');
                    break;
                case 'X':
                    self.setFont(doc, 'tiny').text('A', posX + W / 5, posY + H * 0.4, 'center');
                    self.setFont(doc, 'tiny').text('B', posX + W * 0.38, posY + H - H / 6, 'center');
                    self.setFont(doc, 'tiny').text('C', posX + W - W / 6, posY + H * 0.70, 'center');
                    self.setFont(doc, 'tiny').text('D', posX + W - W * 0.35, posY + H / 4, 'center');
                    break;
                case 'I':
                    self.setFont(doc, 'tiny').text('A', posX + W / 2 - 2.0, posY + H / 2, 'right');
            }

            switch (config.shape) {
                case 'I':
                    doc.rect(posX + W / 2 - wShape / 2, posY + border, wShape, H - 2 * border);
                    break;
                case 'L':
                    var A = H - 2 * border,
                        B = W - 2 * border;
                    if (config.dimA > config.dimB) {
                        B = W * .8 - 2 * border; // jshint ignore: line
                    } else if (config.dimA < config.dimB) {
                        A = H * .8 - 2 * border; // jshint ignore: line
                    }

                    doc.line(posX + border, posY + border, posX + border, posY + border + A);
                    doc.line(posX + border, posY + border + A, posX + border + B, posY + border + A);
                    doc.line(posX + border + B, posY + border + A, posX + border + B, posY + border + A - wShape);
                    doc.line(posX + border + B, posY + border + A - wShape, posX + border + wShape,
                        posY + border + A - wShape);
                    doc.line(posX + border + wShape, posY + border + A - wShape, posX + border + wShape, posY + border);
                    doc.line(posX + border, posY + border, posX + border + wShape, posY + border);


                    break;
                case 'U':
                    var Y = H - 2 * border,
                        A = H - 2 * border, // jshint ignore: line
                        B = W - 2 * border, // jshint ignore: line
                        C = H - 2 * border;
                    if (config.dimA > config.dimC) {
                        C = H * .8 - 2 * border; // jshint ignore: line
                    } else if (config.dimA < config.dimC) {
                        A = H * .8 - 2 * border; // jshint ignore: line
                    }

                    if (config.dimB < config.dimA && config.dimB < config.dimC) {
                        B = W * .8 - 2 * border; // jshint ignore: line
                    }

                    doc.line(posX + border, posY + border + Y - A, posX + border, posY + border + Y);
                    doc.line(posX + border, posY + border + Y, posX + border + B, posY + border + Y);
                    // doc.line(posX + border + B, posY + border + A, posX + border + B, posY + border + A - wShape);
                    doc.line(posX + border + B, posY + border + Y, posX + border + B, posY + border + Y - C);
                    doc.line(posX + border + B, posY + border + Y - C, posX + border + B - wShape,
                        posY + border + Y - C);
                    doc.line(posX + border + B - wShape, posY + border + Y - C, posX + border + B - wShape,
                        posY + border + Y - wShape);

                    doc.line(posX + border + B - wShape, posY + border + Y - wShape, posX + border + wShape,
                        posY + border + Y - wShape);
                    doc.line(posX + border + wShape, posY + border + Y - wShape, posX + border + wShape,
                        posY + border + Y - A);
                    doc.line(posX + border, posY + border + Y - A, posX + border + wShape, posY + border + Y - A);


                    break;
                case 'O':
                    var A = H - 2 * border; // jshint ignore: line
                    var B = W - 2 * border; // jshint ignore: line
                    if (config.dimA > config.dimB) {
                        B = W * .8 - 2 * border; // jshint ignore: line
                    } else if (config.dimA < config.dimB) {
                        A = H * .8 - 2 * border; // jshint ignore: line
                    }

                    doc.rect(posX + border, posY + border, B, A);
                    doc.rect(posX + border + wShape, posY + border + wShape, B - 2 * wShape, A - 2 * wShape);
                    break;
                case 'T':
                    var tPoints = [
                        { x: startX, y: startY },
                        { x: startX, y: startY + wShape},
                        { x: startX + imageWidth / 2 - wShape / 2, y: startY + wShape},
                        { x: startX + imageWidth / 2 - wShape / 2, y: startY + imageHeight},
                        { x: startX + imageWidth / 2 + wShape / 2, y: startY + imageHeight},
                        { x: startX + imageWidth / 2 + wShape / 2, y: startY + wShape},
                        { x: startX + imageWidth, y: startY + wShape},
                        { x: startX + imageWidth, y: startY}
                    ];

                    self.printLinesBetweenPoints(doc, tPoints);

                    break;
                case 'X':
                    var sqrtOfWidth = Math.sqrt(wShape); // jshint ignore: line

                    var xPoints = [// jshint ignore: line
                        { x: startX, y: startY + sqrtOfWidth },
                        { x: startX + imageWidth / 2 - sqrtOfWidth, y: startY + imageHeight / 2 },
                        { x: startX, y: startY + imageHeight - sqrtOfWidth },
                        { x: startX + sqrtOfWidth, y: startY + imageHeight },
                        { x: startX + imageWidth / 2, y: startY + imageHeight / 2 + sqrtOfWidth },
                        { x: startX + imageWidth - sqrtOfWidth, y: startY + imageHeight },
                        { x: startX + imageWidth, y: startY + imageHeight - sqrtOfWidth },
                        { x: startX + imageWidth / 2 + sqrtOfWidth, y: startY + imageHeight / 2 },
                        { x: startX + imageWidth, y: startY + sqrtOfWidth },
                        { x: startX + imageWidth - sqrtOfWidth, y: startY },
                        { x: startX + imageWidth / 2, y: startY + imageHeight / 2 - sqrtOfWidth },
                        { x: startX + sqrtOfWidth, y: startY }
                    ];

                    self.printLinesBetweenPoints(doc, xPoints);

                    break;
            }
        },

        printLinesBetweenPoints: function(doc, points) {
            for (var i = 0; i < points.length; i++) {
                var currentPoint = points[i]; // jshint ignore: line
                var indexOfNextPoint = i === points.length - 1 ? 0 : i + 1; // jshint ignore: line
                var nextPoint = points[indexOfNextPoint]; // jshint ignore: line

                doc.line(currentPoint.x, currentPoint.y, nextPoint.x, nextPoint.y);
            }
        },

        printConfiguration: function(doc, config, posy) {
            var self = this;

            posy = self.checkSpaceOnPage(doc, posy, self.heightOFblock(config), true); // 15 cm height required..?

            // config coding and quantity
            self.setFont(doc, 'small').text(config.coding, self.margin.left + 29, posy);
            self.setFont(doc, 'small').text(config.qty + ' ' + self.unitQty, self.page.A4.width - self.margin.right,
                posy, 'right');
            posy += self.getFontHeight(doc, 'small'); // 4pt spaceto the the table

            // if (config.photo)
            //     doc.addImage($('img[data-cache="'+config.photo+'"]', $('#imageCache'))[0], 'JPEG',
            // self.margin.left+0, posy, 22, 22, config.photo);

            self.addIconOrShape(config, doc, posy);

            doc.setLineWidth(0.25 * self.font.pt2mm);
            doc.rect(self.margin.left + 0, posy, 22, 22);    // replace w. image here


            // A/B/C Dimension display

            var tmpLH = 11 * self.font.pt2mm;
            if (config.family === 'SLOTLIGHT infinity') {
                this.setSLOINDimensionInformation(doc, config, posy, tmpLH);
            }
            if (config.family === 'SLOIN2') {
                this.setSLOIN2DimensionInformation(doc, config, posy, tmpLH);
            }

            // Component table


            var cols = [self.margin.left + 29,                                         // left of table, col 0, matnr
                self.margin.left + 29 + 18,                                     // col 1, bezeichnung
                self.margin.left + 29 + 18 + 66,                                 // col 2, beschreibung
                self.page.A4.width - self.margin.right - 12 - 12,            // col 3, Masse
                self.page.A4.width - self.margin.right - 12,                // col 4, Stück
                self.page.A4.width - self.margin.right];                    // right of table


            doc.line(cols[0], posy, self.page.A4.width - self.margin.right, posy);    // /top line

            tmpLH = 3 * self.font.pt2mm; // 4pt space in between in the table

            posy += self.getFontHeight(doc, 'table') + tmpLH; // a liitle space to the line
            self.setFont(doc, 'table').text(dict('MATNR'), cols[0], posy); // jshint ignore: line
            self.setFont(doc, 'table').text(dict('NAME'), cols[1], posy); // jshint ignore: line
            self.setFont(doc, 'table').text(dict('DESCRIPTION'), cols[2], posy); // jshint ignore: line
            self.setFont(doc, 'table').text(dict('DIM'), cols[4], posy, 'right'); // jshint ignore: line
            self.setFont(doc, 'table').text(dict('QTY'), cols[5], posy, 'right'); // jshint ignore: line
            posy += tmpLH;

            for (var i = 0; i < config.products.length; i++) {
                var prod = config.products[i],
                previousGroup = '',
                articleGroup = prod.group;

                if (!prod.CfgID_s) {
                    prod.CfgID_s = '';
                }

                doc.line(cols[0], posy, self.page.A4.width - self.margin.right, posy);    // line top

                posy += self.getFontHeight(doc, 'table') + tmpLH; // a liitle space to the line

                var matnr = '........';
                if (prod.matnr.length === 8) {
                    matnr = prod.matnr;
                } else if (prod.matnr === 'CPD') { // special case for BWS CPD
                    matnr = prod.matnr;
                }

                if (articleGroup !== undefined && articleGroup !== previousGroup) {
                    posy += 1;
                    var headingTranslation = window.dict('basketHeading' + articleGroup);
                    self.setFont(doc, 'tableBold').text(headingTranslation, cols[0], posy);
                    posy += self.getFontHeight(doc, 'table') + tmpLH;
                    posy += tmpLH;
                }

                self.setFont(doc, 'table').text(matnr, cols[0], posy);

                self.setFont(doc, 'tableBold').text(prod.title, cols[1], posy);
                self.setFont(doc, 'table').text(prod.CfgID_s, cols[2], posy);
                if (typeof prod.tag !== 'undefined') {
                    self.setFont(doc, 'table').text(prod.tag, cols[4], posy, 'right');
                } else if (prod.CFHeight_i) {
                    self.setFont(doc, 'table').text(prod.CFHeight_i.tcgN(0), cols[4], posy, 'right');
                } else if (prod.CFLength_i) {
                    self.setFont(doc, 'table').text(prod.CFLength_i.tcgN(0), cols[4], posy, 'right');
                }
                self.setFont(doc, 'table').text(prod.qty + '', cols[5], posy, 'right');
                posy += tmpLH;
                previousGroup = articleGroup;
            }

            doc.line(cols[0], posy, self.page.A4.width - self.margin.right, posy);    // /bottom line

            // table=footer
            posy += self.getFontHeight(doc, 'tableFooter') + tmpLH; // a liitle space to the line
            if (config.infoText) {
                var split = doc.splitTextToSize(config.infoText, 140);
                self.setFont(doc, 'tableFooter').text(split, cols[0], posy);
                posy += tmpLH;
            }

            if (false) {
                doc.setDrawColor(255, 0, 0)
                    .line(self.margin.left, posy, self.page.A4.width - self.margin.right, posy)
                    .setDrawColor(0, 0, 0);
            }

            posy += 10;    // 10 mm platz lassen

            return posy;
        },

        setSLOIN2DimensionInformation: function(doc, config, posy, tmpLH) {
            if (config.family !== 'SLOIN2') {
                return;
            }

            var self = this;

            switch (config.shape) {
                case 'X':
                    self.setFont(doc, 'tiny').text('None D:', self.margin.left + 0, posy + 27 + 11 * tmpLH);
                    self.setFont(doc, 'tiny').text(config.nonIlluminatedLengthD.tcgN(0) +
                        ' mm', self.margin.left + 22, posy + 27 + 11 * tmpLH, 'right');
                    self.setFont(doc, 'tiny').text('Cut D:', self.margin.left + 0, posy + 27 + 10 * tmpLH);
                    self.setFont(doc, 'tiny').text(config.cutLengthD.tcgN(0) +
                        ' mm', self.margin.left + 22, posy + 27 + 10 * tmpLH, 'right');
                    self.setFont(doc, 'small').text('D:', self.margin.left + 0, posy + 27 + 9 * tmpLH);
                    self.setFont(doc, 'small').text(config.lengthD.tcgN(0) +
                        ' mm', self.margin.left + 22, posy + 27 + 9 * tmpLH, 'right');
                /* falls through */
                case 'T':
                case 'U':
                    self.setFont(doc, 'tiny').text('None C:', self.margin.left + 0, posy + 27 + 8 * tmpLH);
                    self.setFont(doc, 'tiny').text(config.nonIlluminatedLengthC.tcgN(0) +
                        ' mm', self.margin.left + 22, posy + 27 + 8 * tmpLH, 'right');
                    self.setFont(doc, 'tiny').text('Cut C:', self.margin.left + 0, posy + 27 + 7 * tmpLH);
                    self.setFont(doc, 'tiny').text(config.cutLengthC.tcgN(0) +
                        ' mm', self.margin.left + 22, posy + 27 + 7 * tmpLH, 'right');
                    self.setFont(doc, 'small').text('C:', self.margin.left + 0, posy + 27 + 6 * tmpLH);
                    self.setFont(doc, 'small').text(config.lengthC.tcgN(0) +
                        ' mm', self.margin.left + 22, posy + 27 + 6 * tmpLH, 'right');
                /* falls through */
                case 'L':
                case 'O':
                    self.setFont(doc, 'tiny').text('None B:', self.margin.left + 0, posy + 27 + 5 * tmpLH);
                    self.setFont(doc, 'tiny').text(config.nonIlluminatedLengthB.tcgN(0) +
                        ' mm', self.margin.left + 22, posy + 27 + 5 * tmpLH, 'right');
                    self.setFont(doc, 'tiny').text('Cut B:', self.margin.left + 0, posy + 27 + 4 * tmpLH);
                    self.setFont(doc, 'tiny').text(config.cutLengthB.tcgN(0) +
                        ' mm', self.margin.left + 22, posy + 27 + 4 * tmpLH, 'right');
                    self.setFont(doc, 'small').text('B:', self.margin.left + 0, posy + 27 + 3 * tmpLH);
                    self.setFont(doc, 'small').text(config.lengthB.tcgN(0) +
                        ' mm', self.margin.left + 22, posy + 27 + 3 * tmpLH, 'right');
                /* falls through */
                case 'I':
                    self.setFont(doc, 'tiny').text('None A:', self.margin.left + 0, posy + 27 + 2 * tmpLH);
                    self.setFont(doc, 'tiny').text(config.nonIlluminatedLengthA.tcgN(0) +
                        ' mm', self.margin.left + 22, posy + 27 + 2 * tmpLH, 'right');
                    self.setFont(doc, 'tiny').text('Cut A:', self.margin.left + 0, posy + 27 + tmpLH);
                    self.setFont(doc, 'tiny').text(config.cutLengthA.tcgN(0) +
                        ' mm', self.margin.left + 22, posy + 27 + tmpLH, 'right');
                    self.setFont(doc, 'small').text('A:', self.margin.left + 0, posy + 27);
                    self.setFont(doc, 'small').text(config.lengthA.tcgN(0) +
                        ' mm', self.margin.left + 22, posy + 27, 'right');
            }
        },

        setSLOINDimensionInformation: function(doc, config, posy, tmpLH) {
            if (config.family !== 'SLOTLIGHT infinity') {
                return;
            }

            var self = this;

            switch (config.shape) {
                case 'U':
                    self.setFont(doc, 'small').text('C:', self.margin.left + 0, posy + 27 + 2 * tmpLH);
                    self.setFont(doc, 'small')
                        .text(config.dimC.tcgN(0) + ' mm', self.margin.left + 22, posy + 27 + 2 * tmpLH, 'right');
                /* falls through */
                case 'L':
                case 'O':
                    self.setFont(doc, 'small').text('B:', self.margin.left + 0, posy + 27 + tmpLH);
                    self.setFont(doc, 'small')
                        .text(config.dimB.tcgN(0) + ' mm', self.margin.left + 22, posy + 27 + tmpLH, 'right');
                /* falls through */
                case 'I':
                    self.setFont(doc, 'small').text('A:', self.margin.left + 0, posy + 27);
                    self.setFont(doc, 'small')
                        .text(config.dimA.tcgN(0) + ' mm', self.margin.left + 22, posy + 27, 'right');
            }
        },

        addIconOrShape: function(config, doc, posy) {
            var self = this;
            if (config.useConfigImage) {
                if (config.photo) {
                    doc.addImage($('img[data-cache="' + config.photo + '"]', $('#imageCache'))[0], 'JPEG',
                    self.margin.left + 0, posy, 22, 22, config.photo);
                }
            } else {
                self.printShape(doc, config, self.margin.left + 0, posy, 22, 22);
            }
        },

        printGroupTitle: function(doc, posy, familyName) {
            var self = this,
                marginTop = 0,
                marginBottom = 1;

            if (typeof familyName === 'undefined') {
                familyName = self.familyName;
            } else {
                self.familyName = familyName;
            }

            posy += marginTop;
            var h2 = doc.setFont(self.font.h2.face, self.font.h2.style)
                .setFontSize(self.font.h2.size)
                .splitTextToSize(familyName, 210 - self.margin.right - self.margin.left);

            doc.text(self.margin.left, posy, h2);
            return h2.length * self.font.h2.size * self.font.pt2mm + marginBottom + marginTop;
        },


        printSubTitle: function(doc, posy, subTitle) {
            var self = this,
                marginTop = 1;
            if (typeof subTitle === 'undefined') {
                subTitle = self.subTitle;
            } else {
                self.subTitle = subTitle;
            }

            posy += marginTop;

            var h3 = doc.setFont(self.font.h3.face, self.font.h3.style)
                .setFontSize(self.font.h3.size)
                .splitTextToSize(subTitle, 210 - self.margin.right - self.margin.left);

            doc.text(self.margin.left, posy, h3);
            return h3.length * self.font.h3.size * self.font.pt2mm + marginTop;
        },


        checkSpaceOnPage: function(doc, posy, space, printGroupInfo) {
            var self = this;

            if (posy + space > self.page.A4.height - self.margin.top - self.margin.bottom) { // new page
                doc.addPage();
                posy = self.printHeader(doc);

                if (printGroupInfo) {
                    if (self.familyName) {
                        posy += self.printGroupTitle(doc, posy);
                    }

                    if (self.subTitle) {
                        posy += self.printSubTitle(doc, posy);
                    }
                }
            }
            return posy;
        },

        printHeader: function(doc) {
            var self = this;
            self.page.num++;
            if (self.page.max < self.page.num) {
                self.page.max = self.page.num;
            }
            var y = self.margin.top;

            // header
            self.setFont(doc, 'text').text(self.page.title, self.margin.left, y);
            if (false) {
                doc.text('Logo here', self.page.A4.width - self.margin.right, y, 'right');
            } else {
                // doc.addImage(atob(pdfImages['logo']), self.page['A4'].width-self.margin.right-46, y-8, 45, 8);
                doc.addImage($('img[data-cache="' + ecatUserProfile.getCompany() + '"]', $('#imageCache'))[0], 'JPEG',
                    self.page.A4.width - self.margin.right - 50, y - 8, 50, 9, 'logo');
            }

            // footer
            y = self.page.A4.height - self.margin.bottom;
            doc.setLineWidth(0.25 * self.font.pt2mm);
            doc.line(self.margin.left, y, self.page.A4.width - self.margin.right, y);
            y += self.getFontHeight(doc, 'tiny') + 1;

            self.setFont(doc, 'tiny')
                .text(self.page.date2 + ' ©  ' + self.company, self.margin.left, y)
                .text('' + self.page.num + '/' + self.page.max, self.page.A4.width - self.margin.right, y, 'right');

            return self.margin.top + 13;
        },

        setFont: function(doc, type) {
            var self = this;
            doc.setFont(self.font[type].face, self.font[type].style).setFontSize(self.font[type].size);
            return doc;
        },

        getFontHeight: function(doc, type) {
            var self = this;
            return self.font[type].size * self.font.pt2mm;
        },

        adjustFonts: function(doc) {
            var fonts = doc.getFontList();
            fonts = fonts;
        },

        printArticle: function(doc, article, posy) {
            var self = this;

            posy = self.checkSpaceOnPage(doc, posy, self.heightOFblock(article), true);

            var posyStart = posy;

            // image
            if (article.photo) {
                doc.addImage($('img[data-cache="' + article.photo + '"]', $('#imageCache'))[0], 'JPEG',
                    self.margin.left + 0, posy, 22, 22, article.photo);
            }

            doc.setLineWidth(0.25 * self.font.pt2mm);
            doc.rect(self.margin.left + 0, posy, 22, 22);    // replace w. image here
            posy += self.getFontHeight(doc, 'title'); // justified with image top

            var matnr = '........';
            if (article.matnr.length === 8) {
                matnr = article.matnr;
            }

            if (typeof article.title === 'undefined') {
                article.title = article.prodName;
            }

            self.setFont(doc, 'small').text(matnr, self.margin.left + 29, posy);
            self.setFont(doc, 'title').text(article.title, self.margin.left + 47, posy);

            posy += 5; // posy+=self.getFontHeight(doc, 'title');

            var sDetail = '',
                sDimension = '';
            switch (article.objectType) {
                case 'article':
                    if (article.txtTypText) {
                        sDetail = article.txtTypText;
                    }
                    if (article.Name2_s) {
                        sDetail = article.Name2_s;
                    }

                    var dim = [];
                    article.dimension ? dim.push(article.dimension + ' mm') : '';
                    article.weight ? dim.push(article.weight + ' kg') : '';

                    sDimension = dim.join(', ');
                    break;
                case 'vivaArticle':
                    sDetail = article.basicTypeCode;
                    break;
                default:
                    break;
            }
            self.setFont(doc, 'text').text(sDetail, self.margin.left + 47, posy);
            posy += 5; // posy+=self.getFontHeight(doc, 'text');; // calculate Line height
            self.setFont(doc, 'small').text(sDimension, self.margin.left + 47, posy);


            self.setFont(doc, 'small').text(article.qty + ' ' + self.unitQty, self.page.A4.width - self.margin.right,
                posy, 'right');

            posy += 5; // posy+=self.getFontHeight(doc, 'text');; // calculate Line height

            // max height
            posy = posyStart + 27;

            if (false) {
                doc.setDrawColor(255, 0, 0).line(self.margin.left, posy, self.page.A4.width - self.margin.right, posy)
                    .setDrawColor(0, 0, 0);
            }

            return posy;
        }

    });


/*
    the contents of document.ready() should be placed in the
    startup of the catalog page, there is the only place to handle
    the correct startup sequence, otherwise it's not guaranteed
    that all initializations are correctly done!
*/


    $(document).ready(function() {
        $('[data-tcgDget="idgPrint"]').each(function() {
            new tcgDget.idgPrint($(this));  // no need to store the object it's an ecatUI job
        });
    });


    /**
     * $Log: idgPrint.js,v $
     * Revision 1.17  2016-03-24 09:51:10  ml
     * cleanup and company logo
     *
     * Revision 1.16  2016-03-09 17:00:39  ml
     * dict 4 print
     *
     * Revision 1.15  2016-02-16 08:19:33  ml
     * col 2 in print of config tables
     *
     * Revision 1.14  2015-12-21 08:29:15  ml
     * deleted items are no longer in pdf output
     *
     * Revision 1.13  2015-11-27 12:48:49  pr
     * config title, config qty
     *
     * Revision 1.12  2015-11-27 11:36:30  ml
     * infoText at end of table
     *
     * Revision 1.11  2015-10-13 11:27:34  ml
     * config object printing
     *
     * Revision 1.10  2015-09-08 15:01:42  ml
     * image source from same server
     *
     * Revision 1.9  2015-07-29 16:29:48  ml
     * ein paar mm nach oben
     *
     * Revision 1.8  2015-07-14 09:54:33  ml
     * fixed mm's
     *
     * Revision 1.7  2015-06-15 12:36:24  ml
     * cross origin issue on images!!
     *
     * Revision 1.6  2015-05-26 09:26:13  ml
     * hires logo
     *
     * Revision 1.5  2015-05-19 17:20:38  ml
     * multi basket print
     *
     * Revision 1.4  2015-05-19 13:22:37  ml
     * image cache, large images
     *
     * Revision 1.3  2015-05-19 10:34:48  ml
     * first useful version
     *
     * Revision 1.2  2015-05-05 19:37:30  ml
     * simple print steps
     *
     * Revision 1.1  2015-04-30 21:17:22  ml
     * PDF modules added
     *
     *
     **/
})(ecatUserProfile, _, $, tcgDget);
