/*jshint browser:true*/
(function(global, $) {
    'use strict';

    // CONSTANTS

    // TODO this dot-separated namespace has to be changed on the long term, as this doesn't make any sense. Especially, because this probably doesn't work as intended
    var NAMESPACE_APP = 'biz.trinea.event';

    // LOCAL VARIABLES

    var _jqWindow = null;

    // EXPORTS

    /**
     * Utilities for communication (with a backend)
     */
    global.EventService = {
        NAMESPACE_APP: NAMESPACE_APP,

        listen: listen,
        fire:   fire
    };

    // INITIALIZATION

    _init();

    // IMPLEMENTATION DETAILS

    // Public

    /**
     * Binds handler to event (within the app-namespace).
     * A handler is mandatory. As third argument, if `data` is passed as well. As second argument, otherwise.
     * @param {String}          type        Event-type (name)
     * @param {Object|Function} [data]      Data, to be passed to handler, if set. If not, handler
     * @param {Function}        [handler]
     */
    function listen(type, data, handler) { _$listen(_jqWindow, type, data, handler); }

    /**
     * Fires an wevent (within the app-namespace).
     * @param {String} type     Event-type (name)
     * @param {Object} [data]   Data to be passed with the event
     */
    function fire(type, data) { _$fire(_jqWindow, type, data); }

    // Private

    function _init() { _jqWindow = $(global); }

    /**
     * @param {jQuery} jqContext
     * @param {String} type
     * @param {Object} [data]
     * @private
     * @static
     */
    function _$fire(jqContext, type, data) {
        // Note: The `firedBy`-property has been dropped, since it was nowhere used
        jqContext.trigger({
            type: type + '.' + NAMESPACE_APP,
            fired: type,
            messageData: data
        });
    }

    /**
     * @param {jQuery}          jqContext
     * @param {String}          type
     * @param {Object|Function} [data]
     * @param {Function}        [handler]
     * @private
     * @static
     */
    function _$listen(jqContext, type, data, handler) { jqContext.bind(type + '.' + NAMESPACE_APP, data, handler); }
})(window, window.jQuery);

