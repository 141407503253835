/*jshint browser:true*/
(function(global, AppData) {
    'use strict';

    // CONSTANTS

    /**
     * Available communication-modes
     */
    var COMM_MODE = {
        businessConnector: 'bc',
        ploneProxy: 'pl'
    };

    // EXPORTS

    /**
     * Utilities for communication (with a backend)
     */
    global.CommUtils = {
        COMM_MODE: COMM_MODE,

        getCommunicationMode: getCommunicationMode,
        getProxyUrl: getProxyUrl
    };

    // IMPLEMENTATION DETAILS

    /**
     * Determine communication-mode with BC (direct or over Plone-proxy). If proxy-property is not found, the comm-mode
     * is going to be direct.
     * @param {String}  proxyProperty   Property-name for the Plone-proxy.
     * @returns {String}    Communication mode (see: COMM_MODE)
     */
    function getCommunicationMode(proxyProperty) {
        return getProxyUrl(proxyProperty) != null ? COMM_MODE.ploneProxy : COMM_MODE.businessConnector;
    }

    /**
     * Get proxy URL for the provided property (eg. "ecatVivaProxy")
     * @param {String}  proxyProperty   Property-name
     * @returns {String|null}   Proxy-URL. null, if not found
     */
    function getProxyUrl(proxyProperty) {
        return AppData.getPageProperty(proxyProperty);
    }
})(window, window.AppData);

