/* $Id: tcgSysCfg.js$ */
/* All these settings prevent analysis errors but mean
 * deviation from coding standard XXX */
/*jshint maxcomplexity: 15 */
/*jshint maxdepth: 5 */
/*jshint forin: false */
/*jshint nonew: false */
/*jshint browser: true */

(function(AppData, tcgConfig, Modernizr, tcgDOM, jQuery, _, $) { // jshint ignore: line
    'use strict';

    if (typeof window.tcgSysCfg === 'undefined') {
        window.tcgSysCfg = { // jshint ignore:line
            id: '$Id: tcgSysCfg.js$',
            version: 1.00,
            logType: 'console, message',

            cfgs: {},

            /**
             * log information to console or log window
             * @param type
             * @param message
             * @param options
             */

            log: function(type, message, options) {
                var typeStr = '' + type;
                if (AppData.isDebug() && this.logType.indexOf('console') !== -1 && console && console.log &&
                        typeof this.configuratorMode === 'string') {
                    console.log(typeStr + ': ' + message);
                    if (options) {
                        console.log(options);
                    }
                }
                if (this.logType.indexOf('message') !== -1) {
                    $(window).trigger({
                        type: 'tcgSysCfg:log',
                        messageData: {
                            'type': typeStr,
                            'message': message,
                            'options': options
                        }
                    });
                }
                if (typeStr === '500') {
                    throw message;
                }
            },

            isRegistered: function(cfgName) {
                var self = this;

                return typeof self.cfgs[cfgName] === 'object';
            },

            addModule: function(cfg) {
                var self = this;
                if (typeof cfg.name !== 'string') {
                    self.log('500', 'tcgSysCfg:addModule() - name not supplied', cfg);
                }

                if (typeof cfg.doConfig !== 'function') {
                    self.log('500', 'tcgSysCfg:addModule(' + cfg.name + ') - doConfig() function missing', cfg);
                }

                if (typeof cfg.setProducts !== 'function') {
                    self.log('500', 'tcgSysCfg:setProducts(' + cfg.name + ') - setProducts() function missing', cfg);
                }

                self.cfgs[cfg.name] = cfg;
                self.log('200', 'addModule(' + cfg.name + ') added to tcgSysConfig.');
                if (typeof self.cfgs[cfg.name].init === 'function') {
                    self.cfgs[cfg.name].init();
                } else {
                    self.log('300', 'addModule(' + cfg.name + ') has no init() method.');
                }


                self.loadData(cfg.name);
            },


            setProducts: function(cfgName, products) {
                var self = this,
                    cfg = self.cfgs[cfgName];

                cfg.setProducts(products);

                if (cfg.dataConverter && cfg.optionGroups) { // convert data
                    for (var key in cfg.dataConverter) {
                        var src = cfg.dataConverter[key];

                        cfg.optionGroups[key] = _.unique(_.pluck(products, src));
                    }
                } // convert data

                self.log('200', cfgName + ' setProducts(categories)', cfg.optionGroups);

                // initialize with attributes
                cfg.setData({});
            },


            loadDataSOLR: function(cfgName) {
                var self = this;
                // self.log('500', 'tcgSysCfg:loadDataSOLR() not implemented yet!');


                if (typeof self.cfgs[cfgName] !== 'object') {
                    self.log('500', cfgName + ' is not registered in tcgSysCfg');
                }

                var cfg = self.cfgs[cfgName],
                    pData = {
                        'q': 'CfgID_s:*',
                        'fq': 'objectType:article',
                        'portfolio': cfg.treeID,
                        'clearance': 'yes',
                        'sort': 'group_sorting asc,grouping asc,sorting asc',
                        'rows': 2499,

                        // for quickaccess name use facet
                        'facet': true,
                        'facet.field': ['CfgID_s'],
                        'facet.mincount': 1,
                        'facet.sort': 'count'
                    };
                if (_.isArray(cfg.fieldList)) {
                    pData.fl = cfg.fieldList.join(',');
                }

                $(window).trigger({
                    type: 'solr:load',
                    messageData: {
                        'service': 'select',
                        'message': function(data) {
                            // $(window).trigger('debug:info', prod.version);
                            self.setProducts(cfgName, data.response.docs);
                        },
                        'pData': pData
                    }
                });
            },

            loadDataBC: function(cfgName) {
                var self = this;

                if (typeof self.cfgs[cfgName] !== 'object') {
                    self.log('500', cfgName + ' is not registered in tcgSysCfg');
                }

                // var url='data/'+cfgName+'.xml';     // '/invoke';
                var url = '/invoke/SysCfg/getProductList';
                if (document.location.href.indexOf('file:') === 0) {
                    url = 'data/' + cfgName + '.xml';     // read local
                }


                var params = {
                    requestType: 'text',
                    pData: {
                        // 'app-url':        'SysCfg/getProductList',
                        // 'web-site':        'AT',
                        // 'web-lang':        'DE',
                        // 'xformat_reload':'1',
                        // 'contenttype':    'text/xml',
                        'friendlyLink': cfgName
                    }
                };

                if (self.debug) {
                    params.pData.debug = 'true';
                }

                var jQueryAjaxSettingsTraditional = jQuery.ajaxSettings.traditional;
                jQuery.ajaxSettings.traditional = true;

                // Dropped permissions for netscape browsers. See:
                // https://developer.mozilla.org/en-US/docs/Bypassing_Security_Restrictions_and_Signing_Code

                jQuery.post(url, params.pData, function(xml) {
                    if (jQuery.isXMLDoc(xml) || params.requestType === 'text') {
                        var prod = $.xml2json(xml);
                        $(window).trigger('debug:info', prod.version);
                        self.setProducts(cfgName, prod.Article);
                    } else {
                        self.log('500', cfgName + ': ' + url + ' is not XML');
                    }
                }, params.requestType === 'text' ? 'html' : params.requestType);
                jQuery.ajaxSettings.traditional = jQueryAjaxSettingsTraditional;
            },


            loadDataFile: function(cfgName) {
                var self = this,
                    url = 'data/' + cfgName + '.json';     // read local

                var jQueryAjaxSettingsTraditional = jQuery.ajaxSettings.traditional;
                jQuery.ajaxSettings.traditional = true;

                // Dropped permissions for netscape browsers. See:
                // https://developer.mozilla.org/en-US/docs/Bypassing_Security_Restrictions_and_Signing_Code

                jQuery.get(url, function(data) {
                    try {
                        $(window).trigger('debug:info', 'json load');
                        self.setProducts(cfgName, data.response.docs);
                    } catch (e) {
                        self.log('500', cfgName + ': ' + url + ' is not JSON');
                    }
                }, 'json');
                jQuery.ajaxSettings.traditional = jQueryAjaxSettingsTraditional;
            },


            init: function() {
                var self = this;
                $(window).bind('tcgSysCfg:uiData', function(event, data) {
                    self.log(event.type);
                    self.log(data);
                });

                $(window).bind('tcgSysCfg:addToBasket', function(event, data) {
                    self.log(event.type);
                    self.log(data);
                });
                self.configuratorMode = $('meta[name="configuratorMode"]').attr('content');
                switch (self.configuratorMode) {
                    case    'preview':
                        self.loadData = self.loadDataBC;
                        self.log('200', 'tcgSysCfg:init() - loding DATA via BC');
                        break;
                    case    'debug':
                        self.loadData = self.loadDataFile;
                        self.log('200', 'tcgSysCfg:init() - loding DATA via JSON');
                        break;
                    default:
                        self.loadData = self.loadDataSOLR;
                        self.log('200', 'tcgSysCfg:init() - loding DATA via SOLR');
                }

                self.log('200', 'tcgSysCfg:init(done)');
            }
        };

        window.tcgSysCfg.init();
    } // single instance init

    /**
     * @class
     * @augments tcgDget
     * @param {Object} param
     */
    /**
     * @lends tcgDget.idgOrderStatus.prototype
     */
    tcgDOM.domBrowserSupport = tcgDOM.extend({
        _init_: function() {
            var self = this;

            self.objName = 'domBrowserSupport';

            $(self.myDOM).click(function() {
                // $(self.myDOM).fadeOut('fast');
                $(self.myDOM).slideUp('fast');
            });

            var bMust = false,
                bNice = false;

            bMust = bMust || !localStorage;
            bMust = bMust || !Modernizr.backgroundsize;
            // bMust=bMust || !(navigator && navigator.msSaveOrOpenBlob && navigator.msSaveOrOpenBlob.bind(navigator));
            bMust = bMust || (navigator && /MSIE [1-8]\./.test(navigator.userAgent));

            bNice = bNice || !Modernizr.borderradius;
            bNice = bNice || !Modernizr.csstransforms;
            bNice = bNice || !Modernizr.opacity;
            bNice = bNice || !Modernizr.input.placeholder;
            bNice = bNice || !Modernizr.inputtypes.number;
            bNice = bNice || !Modernizr.input.min;
            bNice = bNice || !Modernizr.input.max;
            bNice = bNice || (navigator && /Safari/.test(navigator.userAgent) && /Version\/5\./.test(navigator.appVersion));

            // bNice=bNice || !Modernizr.inputtypes.datetime;

            if (bNice || bMust) {
                if (!bMust) {
                    $(self.myDOM).addClass('nice2have');
                }
                window.setTimeout(function() {
                    $(self.myDOM).slideDown('slow');
                }, 500);
            }


            // reuse browser support for debug messages
            $(window).bind('debug:info', function(event, msg) {
                $('[data-dict="browserNotSupported"]').text(msg);
                $(self.myDOM).addClass('nice2have');
                window.setTimeout(function() {
                    $(self.myDOM).slideDown('slow');
                }, 500);
            });
        }
    });


/*
    the contents of document.ready() should be placed in the
    startup of the catalog page, there is the only place to handle
    the correct startup sequence, otherwise it's not guaranteed
    that all initializations are correctly done!
*/

    tcgDOM.domBrowserSupport.tcgInit = function() {
        $('[data-tcgDOM="domBrowserSupport"]').each(function() {
            if ($(this).data(tcgConfig.namespace) == null) {
                new tcgDOM.domBrowserSupport($(this));  // no need to store the object it's an dom job
            }
        });
    };

    $(window).bind('tcg:initialize', function() {
        tcgDOM.domBrowserSupport.tcgInit();
    });

    $(document).ready(function() {
        tcgDOM.domBrowserSupport.tcgInit();
    });
})(window.AppData, window.tcgConfig, window.Modernizr, window.tcgDOM, window.jQuery, window._, window.$);
