/* All these settings prevent analysis errors but mean
 * deviation from coding standard XXX */
/*jshint maxcomplexity: 13*/
/*global window, $, tcgCore*/
(function(window, $, tcgCore) {
    'use strict';

    /**
     * tcgDOM base class for all DOM attached eCAT Objects (lightweight)
     * @description Mother class for {@link tcgUI}
     * @namespace
     * @class
     */
    var tcgDOM = window.tcgDOM = tcgCore.Class.extend(
        /** @lends tcgDOM.prototype */
        {
            /**
             * common namespace for all events, data storage, ...
             *
             * @type string
             * @constant
             */
            nameSpace: 'biz.trinea', // (tcgConfig || tcgConfig['namespace'] || 'biz.trinea'),

            /**
             * name of the object, and subclass, MUST be set in all subclass implementations during _init_()
             *
             * @type string
             */
            objName: 'tcgDOM',

            /**
             * assigned DOM object, passed in object creation (new)
             *
             * @type object
             */
            myDOM: null,

            /**
             * initialization of the object, the tcgDOM object will be attached to the DOM objects data storage
             *
             * @param myDOM
             * @returns
             */
            _init_: function(myDOM) {
                this.myDOM = myDOM;
                this.myDOM.data(this.nameSpace, {
                    core: this
                });
            },

            /**
             * destruction of the object, the tcgDOM will be removed from the DOM objects data storage
             *
             * @returns
             */
            _destroy_: function() {
                if (this.myDOM) {
                    this.myDOM.removeData(this.nameSpace);
                    this.myDOM.unbind('.' + this.nameSpace);
                }
            },

            /**
             * fire event/message to tcgDOM objects
             *
             * @param {_ecatMessages} message    name of the message
             * @param messageData    data of the message
             * @returns
             * @deprecated Use `EventService.fire` instead
             */
            fire: function(message, messageData) {
//        window.status=message+' fired';
                $(window).trigger({
                    type: message + '.' + this.nameSpace + '.event',
                    firedBy: this.myDOM,
                    fired: message,
                    messageData: messageData
                });
            },
            /**
             * listen to events sent by other tcgDOM objects.
             *
             * @function
             * @param {_ecatMessages} message    which event do you want to listen
             * @param data        the static data of the even, will be passed in the event to the method
             * @param method    callback method which will be executed when the event is received
             * @returns
             * @deprecated Use `EventService.listen` instead
             */
            listen: function(message, data, method) {
//                $self = $(this);  // This can't be possibly right
                if (typeof data === 'object') {
                    $(window).bind(message + '.' + this.nameSpace + '.event', data, method);
                } else {
                    $(window).bind(message + '.' + this.nameSpace + '.event', data);
                }

//        if (message=='event')
//            ecatConfig['debugEvents']=true;
//
//        debug( '. registered event ' + message + ' for ' + $(this).attr('id'));
            }

        });


    /**
     * One more base class in the hirarchie.
     *
     * @description Mother Class for all UI classes such as {@link iDget}, ...
     * @namespace
     * @class
     * @augments tcgDOM
     */
    window.tcgUI = tcgDOM.extend(
        /** @lends tcgUI.prototype */
        {


            /**
             * template storage
             */
            templates: {},

            /**
             * initialization of the object, the tcgUI object will be attached to the DOM objects data storage
             * @returns
             */
            _init_: function() {
                this.objName = 'tcgUI';
            },

            /**
             * destruction of the object, the tcgUI will be removed from the DOM objects data storage
             *
             * @returns
             */
            _destroy_: function() {
            },


            /**
             * clones the DOM object returned by findUIMaster(), removes the eCAT_master class,
             * adds eCAT_clone class
             *
             * @returns cloned DOM
             */
            clonePageMaster: function(pageSection) {
                var ret = $(pageSection).find('.eCAT_master').clone(true, true);
                ret.removeClass('eCAT_master');
                ret.addClass('eCAT_clone');
                return ret;
            },

            cloneElem: function(klass) {
                var self = this,
                    ret = $(self.myDOM).find('.' + klass).clone(true, true);
                ret.removeClass(klass);
                ret.addClass('eCAT_clone');
                return ret;
            },

            /**
             * removes all DOM objects within pageSection with the class eCAT_clone
             *
             * @param    pageSection where to search
             * @returns
             */
            removeClonedPageElements: function(pageSection) {
                $(pageSection).find('.eCAT_clone').each(function() {
                    $(this).remove();
                });
            },

            /**
             *
             * @param template
             * @param data
             * @returns
             */
            cloneTemplate: function(template, data, storeData) {
                var self = this,
                    mode = 'html',
                    templateName = typeof template === 'string' ? template : $(template).attr('data-template');

                if (typeof self.templates[templateName] === 'undefined') { // store the template
                    var objTemplate = typeof template === 'object' ?
                            $(template)
                            : $('[data-template="' + template + '"]', $(self.myDOM)),
                        clone = $(objTemplate).clone(true).removeClass('template').removeAttr('data-template')
                            .attr('data-clone', templateName);
                    switch (mode) {
                        case 'object':
                            self.templates[templateName] = $(clone);
                            break;
                        case 'html':
                            self.templates[templateName] = $('<div>').append($(clone));
                            break;
                    }

                    if (typeof template !== 'object'){
                        $(objTemplate).remove();
                    }
                }

                var theClone = null;
                if (typeof self.templates[templateName] !== 'undefined') { // make a clone
                    switch (mode) {
                        case 'object':
                            theClone = $(self.templates[templateName]).clone(true);
                            for (var key in data) {     // jshint ignore: line
                                $('text:contains({' + key + '})', $(theClone)).each(function() { // jshint ignore: line
                                    $(this).text($(this).text().replace('{' + key + '}', $('<span>').text(data[key]))
                                        .html());
                                });
                            }
                            break;
                        case 'html':
                            var cloneHTML = $(self.templates[templateName]).html()
                                .replace(new RegExp('data-src', 'gm'), 'src');
                            for (var key in data) {     // jshint ignore: line
                                cloneHTML = cloneHTML.replace(new RegExp('{' + key + '}', 'gm'), $('<span>')
                                    .text(data[key]).html());
                            }

//                    cloneHTML=cloneHTML.replace(/({.*{.*}.*})/g, ''); // remove empty items { text {id} text}

                            theClone = $(cloneHTML
                                .replace(/({[^\}]*{.*}.*})/gm, '')    // remove empty items { text {id} text} ->
                                .replace(/{([^\}]*)}/gm, '$1')        // remove { text } brackets only -> text
                            );

                            if (typeof storeData === 'string') {
                                $(theClone).data(storeData, data);
                            }
                            break;
                    }
                }
                return theClone;
            },

            /**
             * removes all DOM objects within pageSection with the class eCAT_clone
             *
             * @param    pageSection where to search
             * @returns
             */
            cloneRemove: function(template) {
                var self = this;
                $('[data-clone="' + template + '"]', $(self.myDOM)).remove();
            }


        });
})(window, $, tcgCore);



