/* All these settings prevent analysis errors but mean
 * deviation from coding standard XXX */
/*jshint forin: false */
/*jshint nonew: false */
/*jshint maxcomplexity: 8*/
/*jshint browser: true */
/* global ecatUserProfile, tcgDOM, $, tcgDget, IsVIVA */

(function(ecatUserProfile, tcgDOM, $, tcgDget, IsVIVA) { // jshint ignore: line
    'use strict';


    /**
     * @class
     * @augments tcgDget
     * @param {Object} param
     */
    /**
     * @lends tcgDget.idgLogin.prototype
     */
    tcgDget.idgVIVABasketAdd = tcgDget.extend({
        family: null,
        bAuthenticated: false,

        availabilityText: {
            '*': 'unknown {1}'
        },

        vivaMaterialCreation: '',

        _init_: function() {
            var self = this;

            self.objName = 'idgVIVABasketAdd';
            self.setDgetMode('single');

            self.avNotConfig = $('.availabilityConfig', $(self.myDOM)).text();
            self.priceNotConfig = $('[data-isviva]', $(self.myDOM)).text();
            self.vivaMaterialCreation = $('.vivaMaterialCreation', $(self.myDOM)).text();

            // ABC translation - init
            $('li', 'ul[data-template="availabilityDefinition"]').each(function() {
                self.availabilityText[$(this).attr('data-availability')] = $(this).text();
            });


            self.listen('vivaInfo', function(event) {
                if (self.messageForMe(event)) {
                    self.getProductInfo(event.messageData);
                    self.renderData(event.messageData);
                }
            });

            self.listen('viva:MATNR', function(event) {
                if (self.messageForMe(event)) {
                    var mat = $.xml2json(event.messageData);
                    if (mat.STATUS === 'OK') {
                        self.infoItem.matnr = mat.MATNR;
                    }
                }
            });

            $(window).bind('login:update', function(event, messageData) {
                self.bAuthenticated = messageData.status;
            });

            self.renderData({
                'configOK': false,
                'prodName': 'init'
            });

            $('.articleAdd2Cart', $(self.myDOM)).click(function() {
                var infoItem = self.prepareInfoItem();

                // qty stuff
                var qtyInput = $('.articleQty4Cart', $(self.myDOM));

                infoItem.cartID = $('.domCartSelector', $(self.myDOM)).val();

                if (/\d+/.test($(qtyInput).val())) {
                    infoItem.qty = +$(qtyInput).val();
                } else {
                    infoItem.qty = 1;
                }

                self.fire('cartItem', {
                    'service': 'update/insert',
                    'pData': infoItem
                });
            });
        },

        messageForMe: function(event) {
            var self = this,
                domEmbedded = $(self.myDOM).closest('.vivaConfig');

            if ($(domEmbedded).length === 1) {
                if ($(domEmbedded).get(0) !== $(event.firedBy).get(0)) {
                    return false; // message not for this dget
                }
            }
            return true;
        },


        prepareInfoItem: function() {
            var self = this,
                infoItem = self.infoItem;

            // infoItem.qty=Number($('.articleQty4Cart', $(self.myDOM)).val());

            // default values
            if (!infoItem.title) {
                infoItem.title = infoItem.prodName;
            }
            infoItem.pos = null;
            infoItem.ArtType_s = 'A';
            infoItem.ArtTypeName_s = $('.groupTitle', $(self.myDOM).closest('.groupItem')).text();
            infoItem.abcMark = infoItem.deliveryTimeCode;
            infoItem.isVIVA = self.makeIsVIVA(infoItem);
            infoItem.objectType = 'vivaArticle';

            // if ($('[data-isviva]', $(self.myDOM)).attr('data-load')=='1') { // we have price
            //     infoItem.price=$('[data-isviva]', $(self.myDOM)).text();
            // }

            if (typeof infoItem.matnr === 'undefined') {
                infoItem.matnr = '-';
            } else if (typeof infoItem.solrDoc === 'object' && infoItem.prodName === infoItem.prodNameOrig) {
                switch (infoItem.solrDoc.objectType) {
                    case 'article': // just take the solrDoc
                        var isVIVA = infoItem.isVIVA;
                        infoItem = self.infoItem.solrDoc;
                        infoItem.family = self.infoItem.family;
                        infoItem.isVIVA = isVIVA;
                        break;

                    default:
                        break;
                }
            }

            return infoItem;
        },

        triggerDirectDownload: function() {
            var self = this,
                infoItem = self.prepareInfoItem();

            self.fire('vivaInfoOK', infoItem); // inform others that we have all data
        },



        renderData: function(infoItem) {
            var self = this;

            self.infoItem = infoItem;

            $('.vivaConfigInfo', $(self.myDOM)).text(infoItem.prodName);

            if (infoItem.wrongData && self.bAuthenticated) {
                $('.vivaConfigInfoItemList', $(self.myDOM)).text(dict('noConfiguration')); // jshint ignore: line
                $('.vivaConfigInfoItemList', $(self.myDOM)).addClass('noConfiguration');
            } else {
                $('.vivaConfigInfoItemList', $(self.myDOM)).text('');
                $('.vivaConfigInfoItemList', $(self.myDOM)).removeClass('noConfiguration');
            }

            if (infoItem.configOK) {
                // ABC translation
                if (infoItem.deliveryTimeCode) { // set availability text
                    infoItem.abcText = self.availabilityText[infoItem.deliveryTimeCode];
                    if (typeof infoItem.abcText === 'undefined') {
                        infoItem.abcText = self.availabilityText['*'].replace('{1}', infoItem.deliveryTimeCode);
                    }

                    // add price and availability data
                    $('[data-isviva]', $(self.myDOM)).attr('data-isviva', self.makeIsVIVA(infoItem))
                        .attr('data-maktx', infoItem.prodName)
                        .attr('data-base', infoItem.baseArticle).removeAttr('data-load');
                    self.fire('price:update');
                }
            } else {
                $('.availabilityConfig', $(self.myDOM)).text(self.avNotConfig);
                $('[data-isviva]', $(self.myDOM)).text(self.priceNotConfig);

                self.fire('vivaInfoNOK', self.infoItem); // inform others that we no longer have the data
            }


            if (infoItem.configOK) {
                $('.articleBasketAdd', $(self.myDOM)).removeClass('disabled');
                $('.articleAdd2Cart', $(self.myDOM)).removeAttr('disabled');
            } else { // disable
                $('.articleBasketAdd', $(self.myDOM)).addClass('disabled');
                $('.articleAdd2Cart', $(self.myDOM)).attr('disabled', true);
            }
        },


        // check product if it has a sap number, or is a standard variant
        getProductInfo: function(infoItem) {
            var self = this;

            if (!infoItem.configOK) {
                return;
            }

            // return; /////////////////////////////////////////////////////////// TODO viva data defect!!!!
            var fq = [];

            self.infoItem = infoItem;
            // 2015-11-23 disabled due to missing data in SOLR article objects
            // for (var i=0; i<infoItem.options.length; i++) {
            //     if (typeof infoItem.options[i].smlName != 'undefined' && infoItem.options[i].value!='')
            //         fq.push('viva'+tcgDOM.domSOLR.escape(infoItem.options[i].smlName)+':'+tcgDOM.domSOLR.escape(infoItem.options[i].value));
            // }

            fq.push('vivaKurztext:' + tcgDOM.domSOLR.escape(infoItem.prodNameOrig) +
                ' OR title:' + tcgDOM.domSOLR.escape(infoItem.prodNameOrig)
            );

            infoItem.matnr = '';
            infoItem.title = null;
            infoItem.doc = null;


            if (infoItem.prodNameOrig === infoItem.prodName) {
                self.fire('solr:load', {
                    'service': 'select',
                    'pData': {
                        'q': 'objectType:article OR objectType:vivaSAPVariant OR objectType:vivaStdVariant',
                        'fq': fq,
                        'sort': 'objectType desc'
                    },
                    'message': function(data) {
                        if (data.response.numFound !== 0) {
                            infoItem.matnr = data.response.docs[0].matnr;

                            for (var i = 0; i < data.response.docs.length; i++) {
                                var doc = data.response.docs[i];
                                switch (doc.objectType) {
                                    case 'vivaSAPVariant':
                                        infoItem.title = doc.title;
                                        break;
                                    case 'vivaStdVariant':
                                        if (typeof infoItem.solrDoc === 'undefined') {
                                            infoItem.solrDoc = doc;
                                        }
                                        break;

                                    case 'article':
                                        infoItem.solrDoc = doc;
                                        break;

                                    default: // ???
                                        break;
                                }
                            }
                            self.triggerDirectDownload();
                        } // products found
                        else { // get data from base article

                            self.fire('solr:load', {
                                'service': 'select',
                                'pData': {
                                    'q': 'objectType:article',
                                    'fq': 'matnr:' + infoItem.baseArticle
                                },
                                'message': function(data) {
                                    if (data.response.numFound !== 0) {
                                        if (typeof infoItem.photo !== 'string') {
                                            if (typeof data.response.docs[0].photo !== 'undefined') {
                                                infoItem.photo = data.response.docs[0].photo;
                                            }
                                        }
                                    }
                                    self.triggerDirectDownload();
                                }
                            });
                        } // no prod found
                    }
                });
            } else {
                self.fire('solr:load', {
                    'service': 'select',
                    'pData': {
                        'q': 'objectType:article OR objectType:vivaSAPVariant OR objectType:vivaStdVariant',
                        'fq': fq,
                        'sort': 'objectType desc'
                    },
                    'message': function(data) {
                        if (data.response.numFound !== 0) {
                            // got result
                            if (infoItem.optionsOrig[1].title === 'Load') {
                                // check finetuning
                                var infoItemLoad = infoItem.optionsOrig[1];

                                if (infoItemLoad.smlCode === infoItemLoad.text) {
                                    // no fine tuning
                                    infoItem.matnrLoaned = data.response.docs[0].matnr;
                                }
                            } else {
                                // assign when found and no Load on 2nd place
                                infoItem.matnrLoaned = data.response.docs[0].matnr;
                            }
                        }
                        self.fire('solr:load', {
                            'service': 'select',
                            'pData': {
                                'q': 'objectType:article',
                                'fq': 'matnr:' + infoItem.baseArticle
                            },
                            'message': function(data) {
                                if (data.response.numFound !== 0) {
                                    if (typeof infoItem.photo !== 'string') {
                                        if (typeof data.response.docs[0].photo !== 'undefined') {
                                            infoItem.photo = data.response.docs[0].photo;
                                        }
                                    }
                                }
                                self.triggerDirectDownload();
                            }
                        });
                    }
                });
            }
        },

        // TODO `ecatConfig` has nowhere been declared. Possible dead code. Verify!
        //     /********************************************************
        // * TODO!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
        // */

        // getDatasheetURL:function(orderNo, isVIVA) {
        //     var url = ecatConfig.objectURL + '/PDF/Datasheet.aspx?number=' + orderNo +
        //         //          '&DatasheetType='+(typeof param.type != 'undefined'?param.type:'1')+
        //         //          '&ModeID='+info.modeID+
        //         '&ISO2=' + ecatUserProfile.getExportCountry() +
        //         //          '&CF='+ecatUserProfile.clearance[0]+
        //         //          (typeof param.template != 'undefined'?'&templateLitech='+param.template:'')+
        //         '&lang=' + ecatUserProfile.getLanguage() +
        //         '&CompanyID=1' +
        //         '&isVIVA=' + encodeURIComponent(isVIVA) +
        //         '&templateLitech=Standard_web';
        //     return url;
        // },

        // get isVIVA string
        makeIsVIVA: function(infoItem) {
            var self = this;

            if (typeof infoItem === 'undefined') {
                infoItem = self.infoItem;
            }

            // jscs:disable requireCapitalizedConstructorsNew
            return IsVIVA.makeString(infoItem); // jshint ignore: line
        } // isVIVA()


        // TODO: Probably dead code. This method seems unused. Verify! It has been disabled due to the access to the
        // undeclared `ecatGetPageProperty` // check product if it has a sap number, or is a standard variant
        // showProductInformation:function(infoItem) { var self = this;

        //     if (typeof infoItem === 'undefined') {
        //         infoItem = self.infoItem;
        //     }

        //     var isVIVA = self.makeIsVIVA(infoItem);

        //     var backend = null;
        //     var callParams = {
        //         'isVIVA': isVIVA,
        //         'sLang': ecatUserProfile.getLanguage(),
        //         'sOrderNo': infoItem.baseArticle
        //     };


        //     self.url = ecatGetPageProperty('ecatPriceProxy');
        //     if (self.url != null) {
        //         backend = new zagBackend.plone(); // jshint ignore: line
        //         callParams['app-url'] = 'viva/getProductPriceInfo';
        //     }
        //     else {
        //         backend = new zagBackend.bc(); // jshint ignore: line
        //         callParams['app-url'] = 'vivaConfig/xmlProductInfo';
        //     }


        //     $('#vivaProductText').val('...');
        //     $('.vivaAvailability').val('...');
        //     $('#vivaDatasheetLink').attr('href', self.getDatasheetURL(infoItem.baseArticle, isVIVA, {}));

        //     backend.xml(callParams, function(data) {
        //         var oInfo = $.xml2json(data);
        //         // check, if xmlProductText returned valid text
        //         if (typeof oInfo.text !== 'undefined') {
        //             $('#vivaProductText').val(oInfo.text);
        //         }
        //         else {
        //             $('#vivaProductText').val('');
        //         }

        //         // availability

        //         $('.vivaAvailability').each(function() {
        //             switch ($(this)[0].tagName) {
        //                 case 'INPUT':
        //                     $(this).val(infoItem.getDeliveryTimeCode(oInfo.MAABC));
        //                 break;
        //             default:
        //                 $(this).text(infoItem.getDeliveryTimeCode(oInfo.MAABC));
        //             }

        //         });

        //         // loop the price stuff
        //         if (oInfo.price) {
        //             if (!zagBackend.isArray(oInfo.price)) { // jshint ignore: line
        //                 oInfo.price = $.makeArray(oInfo.price);
        //             }

        //             // now the loop
        //             for (var i = 0; i<oInfo.price.length; i++) {
        //                 var entry = oInfo.price[i];

        //                 // type ... B=Base, E=ExtraItem, C=Component
        //                 // id ... matnr/extraitem type id
        //                 // name .. extra item code/component code,
        //                 // value ... price,
        //                 // cur .. currency

        //                 switch (entry.type) {
        //                     case 'B':
        //                         break;

        //                     case 'E':
        //                     case 'C':
        //                         break;
        //                 }
        //             } // price item
        //         } // valid price data


        //     });

        //     callParams['app-url'] = 'vivaConfig/dlProductText';
        //     callParams.contenttype = 'text/plain'; //'application/octet-stream';
        //     callParams['web-site'] = ecatUserProfile.getExportCountry().toUpperCase();
        //     callParams['web-lang'] = ecatUserProfile.getLanguage().toUpperCase();
        //     callParams.xformat_reload = '1';
        //     callParams.downfile = infoItem.prodName + '.txt';

        //     var url = ecatConfig.xfmtPath + '?';
        //     var addAmp = false;
        //     for (var param in callParams) {
        //         url+=addAmp?'&':'';
        //         addAmp = true;
        //         url+=param + '=' + encodeURIComponent(callParams[param]);
        //     }

        //     $('#vivaProductTextDownload').attr('href', url);
        // }


    });


    /*
        the contents of document.ready() should be placed in the
        startup of the catalog page, there is the only place to handle
        the correct startup sequence, otherwise it's not guaranteed
        that all initializations are correctly done!
    */

    $(document).ready(function() {
        $('[data-tcgDget="idgVIVABasketAdd"]').each(function() {
            new tcgDget.idgVIVABasketAdd($(this)); // no need to store the object it's an ecatUI job
        });
    });


    /**
     * $Log: idgVIVABasketAdd.js,v $
     * Revision 1.16  2016-04-01 15:54:53  ml
     * material anlage fixes
     *
     * Revision 1.15  2016-03-22 09:01:35  ml
     * ArtTypeName_s missing in Basket
     *
     * Revision 1.14  2016-02-23 08:04:14  ml
     * before structure change
     *
     * Revision 1.13  2016-02-16 08:15:58  ml
     * pricing additions
     *
     * Revision 1.12  2015-11-27 17:51:08  ml
     * fix of fix - it's too late
     *
     * Revision 1.11  2015-11-27 17:47:26  ml
     * fixed std article
     *
     * Revision 1.10  2015-06-29 11:50:14  ml
     * detect existion products, adding photo for viva articles
     * fiexed missing smlName for groups
     *
     * Revision 1.9  2015-06-15 07:24:25  ml
     * basket update/insert fix
     *
     * Revision 1.8  2015-04-28 09:41:39  ml
     * delete, send email add to baskte stuff
     *
     * Revision 1.7  2015-04-27 13:30:38  ml
     * intermediate checkin
     *
     * Revision 1.6  2015-04-26 20:52:15  ml
     * viva data load
     *
     * Revision 1.5  2015-04-24 15:49:00  ml
     * config reset stuff
     *
     * Revision 1.4  2015-04-21 13:38:40  ml
     * loadin, classes
     *
     * Revision 1.3  2015-04-14 06:47:28  ml
     * intermediate checkin
     *
     * Revision 1.2  2015-04-07 08:11:57  ml
     * pre Build
     *
     * Revision 1.1  2015-04-06 19:27:07  ml
     * intermediate checkin
     *
     **/
})(ecatUserProfile, tcgDOM, $, tcgDget, IsVIVA);
