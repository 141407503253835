/* All these settings prevent analysis errors but mean
 * deviation from coding standard XXX */
/*jshint browser: true */
/* global $, tcgDOM */
/*jshint nonew: false */

(function($, tcgDOM) {
    'use strict';

    /**
     * @class
     * @augments tcgDOM
     * @param {Object} param
     */
    /**
     * @lends tcgDOM.idgLogin.prototype
     */
    tcgDOM.domCartStatus = tcgDOM.extend({
        timer: null,


        _init_: function() {
            var self = this;

            self.objName = 'domCartStatus';

            self.defaultName = $('option:first', $(self.myDOM)).text();


            // self.listen('cart', function(event) {
            //     self.cartUpdateItemCount();
            // });
            self.listen('cartItem', function(event) {
                var action = event.messageData;
                switch (action.service) {
                    case 'update/insert':
                    case 'insert':
                    case 'delete':
                    case 'undelete':
                    case 'copy':
                        self.cartUpdateItemCount();

                }
            });


            self.listen('cartStatus:count', function(event) {
                self.cartRenderCount(event.messageData);
            });



            $(self.myDOM).click(function() {
                self.fire('loading', 'off');

                window.parent.dispatchEvent(new CustomEvent('navigateToBasket', {
                    detail: true
                }));

                document.location.href = 'basket.html';
            });

            self.cartUpdateItemCount();
        },


        cartUpdateItemCount:function() {
            var self = this;

            if (self.timer) {
                window.clearTimeout(self.timer);
            }

            window.setTimeout(function() {
                // self.fire('cartItem', {
                //     'message': 'cartStatus:count',
                //     'service': 'count',
                //     pData: {}
                // });

                self.fire('cartItem', {
                    'message': 'cartStatus:count',
                    'service': 'sum',
                    'key': 'qty',
                    pData: {}
                });
            }, 500);
        },

        cartRenderCount:function(cnt) {
            var self = this;

            self.cartRenderCountOfDom(cnt, self.myDOM);

            var parentWindow = self.getIframeParentWindow();
            if (parentWindow) {
                self.cartRenderCountOfDom(cnt, parentWindow);
            }
        },

        cartRenderCountOfDom:function(cnt, myDOM) {
            $('.cartButtonCounter', $(myDOM)).text(cnt);
            $(myDOM).addClass('glow');
            window.setTimeout(function() {
                $(myDOM).removeClass('glow');
            }, 500);
        },

        getIframeParentWindow: function() {
            return window.parent.$(window.parent.document);
        }

    });



/*
    the contents of document.ready() should be placed in the
    startup of the catalog page, there is the only place to handle
    the correct startup sequence, otherwise it's not guaranteed
    that all initializations are correctly done!
*/

    $(document).ready(function() {
        $('.domCartStatus').each(function() {
            new tcgDOM.domCartStatus($(this));  // no need to store the object it's an ecatUI job
        });
    });




    /**
     * $Log: domCartStatus.js,v $
     * Revision 1.7  2015-06-15 07:24:25  ml
     * basket update/insert fix
     *
     * Revision 1.6  2015-05-13 11:59:55  ml
     * fixed delete cart, added sum function
     *
     * Revision 1.5  2015-04-27 19:52:55  ml
     * delete/undelete function
     *
     * Revision 1.4  2015-04-21 13:38:40  ml
     * loadin, classes
     *
     * Revision 1.3  2015-04-17 17:02:57  ml
     * cart selector stuff
     *
     * Revision 1.2  2015-04-16 05:39:19  ml
     * intermediate checkin
     *
     * Revision 1.1  2015-04-06 19:27:07  ml
     * intermediate checkin
     *
    **/
})($, tcgDOM);
