/* All these settings prevent analysis errors but mean
 * deviation from coding standard XXX */
/*jshint nonew: false */
/*jshint browser: true */
/* global _, ecatUserProfile, $, tcgDget */

(function(_, ecatUserProfile, $, tcgDget) {
    'use strict';

    /**
     * @class
     * @augments tcgDget
     * @param {Object} param
     */
    /**
     * @lends tcgDget.idgLogin.prototype
     */
    tcgDget.idgPortfolio = tcgDget.extend({
        // display mode: one of private or public
        mode: 'public',

        // VKORG - country code map - viva
        vkorgCC: {},
        // VKORG - export country map - ZL/TL
        vkorgEC: {},
        // i18n names of viva countries (initialized at initial fill)
        ccNames: {},
        // logged in user vkorg
        vkorgUser: '0000',

        // mapping export country to vkorg (ecVKORG.be = '0005')
        ecVKORG: {},

        _init_: function() {
            var self = this;

            self.objName = 'idgPortfolio';
            self.setDgetMode('single');

            // dom of country combo
            var domCC=$('#viva_country', $(self.myDOM));
            // dom of vkorg combo
            var domVK=$('#viva_vkorg', $(self.myDOM));

            if (document.cookie.indexOf('__ac=') >= 0) { // authenticated
                self.displayMode('private');
            } else { // public
                self.displayMode('public');
                self.initPublicCombo();
            }

            // handle public mode switch
            $(domCC).on('change', function() {
                // val has to be VKORG
                var vkorg = $(this).val();

                // write current selection to session
                window.tcgSetPageProperty('vivaVKORG', vkorg);

                // remove existing cookie, maybe set on ecat side (domain = .zumtobel.com)
                var cookiePart = {
                    expires: -1,
                    path: '/',
                    domain: document.location.hostname.replace(/[^.]*\.(.*)/gm, '$1')
                };
                $.cookie('ExportCountry', '', cookiePart);

                // save export country for base articles
                var exportCountry = self.vkorgEC[vkorg];
                window.tcgSetPageProperty('ExportCountry', exportCountry);

                // reload page
                document.location.reload(true);
            });

            // handle private mode switch
            $(domVK).on('change', function() {
                // write current selection to session
                var pf=$(this).val();
                window.tcgSetPageProperty('vivaPortfolio', pf);

                // update export country
                var vkorg=pf.split(',')[0];
                window.tcgSetPageProperty('vivaVKORG', vkorg);

                // export country via vkorg
                var exportCountry=self.vkorgEC[vkorg];
                window.tcgSetPageProperty('ExportCountry', exportCountry);

                // reload page
                document.location.reload(true);
            });

            // handle login
            $(window).on('login:update', function(event, messageData) {
                if (messageData.status === true) {
                    self.loadVkorgPortfolio(function(portfolios) {
                        $(domVK).html(''); // clear combo
                        for (var i=0; i<portfolios.length; i++) { // fill vkorgs
                            $(domVK).append($('<option>').attr('value', portfolios[i]).text(portfolios[i]));
                        }
                        var vivaPF=window.tcgGetPageProperty('vivaPortfolio');
                        if (vivaPF) {
                            $(domVK).val(vivaPF);
                        } else {
                            // update value in combo box
                            window.tcgSetPageProperty('vivaPortfolio', $(domVK).val());
                        }
                        if (self.mode === 'public') { // switched from public
                            document.location.reload(true);
                        }
                        self.displayMode('private');
                    });
                } else { // public
                    if (self.mode === 'private') { // switched from private
                        window.tcgGetPageProperty('vivaPortfolio', true); // remove cookie
                        document.location.reload(true);
                    }
                    self.displayMode('public');
                }
            });
        },

        // init country selector
        initPublicCombo: function() {
            var self=this;

            // dom of country combo
            var domCC=$('#viva_country', $(self.myDOM));

            if (_.isEmpty(self.vkorgCC)) {
                self.loadVkorgPortfolio(function() {
                    // select current export country
                    var vkorg=window.tcgGetPageProperty('vivaVKORG');
                    if (typeof vkorg !== 'string') {
                        // try to align to eCat settings, eCat user clicked on myProduct
                        var exportCountry = window.tcgGetPageProperty('ExportCountry');
                        if (exportCountry) {
                            exportCountry = exportCountry.toLowerCase();
                            vkorg = self.ecVKORG[exportCountry];
                        }
                    }
                    if (typeof vkorg === 'string') {
                        $(domCC).val(vkorg);
                    }
                }); // load
            } // if empty vkorgCC
        },

        // show/hide combos
        displayMode: function(mode) {
            var self = this;

            // country
            var domCC = $('#viva_country', $(self.myDOM));

            // vkorg
            var domVK = $('#viva_vkorg', $(self.myDOM));

            switch (mode) {
                case 'private':
                    $(domVK).parent().hide();  // disabled .show()
                    $(domCC).parent().hide();
                    if ($('.vkorg-display').length === 0) { // no vkorg display
                        $('.grossprice-fullname').after($('<span>').addClass('vkorg-display'));
                    }
                    // set vkorg in field
                    $('.vkorg-display').text('['+self.vkorgUser+']');
                    break;

                default:
                    $(domCC).parent().show();
                    $(domVK).parent().hide();
            }

            self.mode = mode;
        },

        // load possible values from backend
        loadVkorgPortfolio: function(fn) {
            var self=this;

            // post data
            var pData = {
                'contenttype': 'text/xml',
                'app-url': 'viva/getUserPriceLists'
            };

            // user logged in?
            var uid = $('#grossprice-login-header').data('userid');

            // service url
            // var url = window.tcgGetPageProperty('ecatPriceProxy');
            var url = window.tcgGetPageProperty('ecatVivaProxy');
            if (typeof uid === 'undefined') { // anonymous access
                url = '/iViews/xformat/xformat.asp';
                pData.contenttype = 'text/xml';
                pData['web-site'] = 'AT';
                pData.xformat_reload = 1;   // to avoid redirection
            } // not logged in
            else {
                pData.uid = uid;
            }

            // portfolio array
            var portfolios = [];

            if (url !== null) {
                var jQueryAjaxSettingsTraditional = $.ajaxSettings.traditional;
                $.ajaxSettings.traditional = true;
                $.post(url, pData, function(xml) {
                    if ($.isXMLDoc(xml)) {
                        // user VKORG
                        self.vkorgUser = $(xml).find('account').first().attr('vkorg');

                        // extract available portfolios for user
                        $(xml).find('portfolio').each(function() {
                            portfolios.push($(this).attr('value'));
                        });

                        // update VOKRG - export country map
                        var company=ecatUserProfile.getCompany();
                        $(xml).find('vkorgcc').each(function() {
                            self.vkorgCC[$(this).attr('vkorg')]=$(this).attr('cc').toLocaleUpperCase();
                            try {
                                self.vkorgEC[$(this).attr('vkorg')]=$(this).attr(company).toLocaleUpperCase();
                            } catch (e) {
                            }

                            // mapping export country to vkorg (ecVKORG.be = '0005')
                            if (!self.ecVKORG[$(this).attr(company).toLowerCase()]) {
                                self.ecVKORG[$(this).attr(company).toLowerCase()] = $(this).attr('vkorg');
                            }
                        });
                        fn(portfolios);
                    } else {
                        fn([]);
                    }
                }, 'xml');
                $.ajaxSettings.traditional = jQueryAjaxSettingsTraditional;
            } else { // no proxy defined
                fn([]);
            }
        }
    });


/*
    the contents of document.ready() should be placed in the
    startup of the catalog page, there is the only place to handle
    the correct startup sequence, otherwise it's not guaranteed
    that all initializations are correctly done!
*/

    $(document).ready(function() {
        $('[data-tcgDget="idgPortfolio"]').each(function() {
            new tcgDget.idgPortfolio($(this));  // no need to store the object it's an ecatUI job
        });
    });
})(_, ecatUserProfile, $, tcgDget);
