/* All these settings prevent analysis errors but mean
 * deviation from coding standard XXX */
/*jshint browser: true*/
/*global window, tcgDOM, $, tcgConfig*/

(function(window, tcgDOM, $, tcgConfig) {
    'use strict';

    /**
     * @class
     * @augments tcgDOM
     */
    tcgDOM.messageBox = tcgDOM.extend(
        /**
         * @lends tcgDOM.prototype
         */
        {


            messageData: null,

            _init_: function() {
                var self = this;
                self.objName = 'messageBox';
                self.listen('language', {
                    self: self
                }, self.listenerLanguage);
                return self;
            },


            listenerLanguage: function() {
                // jshint unused: false
                // jscs:disable disallowUnusedVariables
                var staticData = event.data,
                    messageData = event.messageData, // lang iso2 lowercase :-)
                    self = staticData.self;
            },


            listenerMessageBox: function(event) {
                var staticData = event.data,
                    messageData = event.messageData; // name of box
                var self = staticData.self;

                // FIXME use strict equality, if possible (verify, before fixing!!)
                // jshint -W116
                if (messageData === self.objName || event.fired == self.objName) {
                    // jshint +W116
                    if ($(self.myDOM).hasClass('off')) {
                        self.messageData = messageData;
                        self.openBox();
                        self.initBox();
                    } else {
                        self.closeBox();
                    }
                }
            },

            openBox: function() {
                var self = this;

                $('#popup').find('.messageBox:not(.off)').addClass('off'); // close all other boxes

                $(self.myDOM).removeClass('off');
                $('#popup,#shroud').removeClass('off');

                if ($(self.myDOM).attr('data-fastclose') === 'yes') {
                    // close box on ESC key
                    $(document).on('keyup', function(e) {
                        if (e.key === 'Escape')
                            self.closeBox();

                        return true;
                    });

                    // close on shroud click
                    $('#shroud').on('click', function(e) {
                        self.closeBox();

                        return true;
                    });
                } // fast close
            },

            closeBox: function() {
                var self = this;

                if (!$(self.myDOM).hasClass('off')) {
                    $('#popup,#shroud').addClass('off');
                    $(self.myDOM).addClass('off');
                    $(document).off('keyup');
                    $('#shroud').off('click');
                }
            },

            initBox: function() {
                var self = this;

                if (self.objName === 'messageBox') { // global message box catcher
                    self.objName = $(self.myDOM).attr('id'); // overwrite w. id !
                    self.form = $(self.myDOM).find('form');
                    self.listen('messageBox', {
                        self: self
                    }, self.listenerMessageBox);

                    $(self.myDOM).find('[data-btn="btn-close"]').click(function() { // check standard close button
                        self.fire(self.objName, ['close']);
                        self.closeBox();
                    });


                    if ($(self.form).size()) {
                        $(self.myDOM).find('[data-btn="btn-ok"]').click(function() {
                            if ($(this).hasClass('disabled')) {
                                return;
                            }
                            var formData = {};
                            $('input[name]', $(self.myDOM)).each(function() {
                                formData[$(this).attr('name')] = $(this).val();
                            });

                            self.fire(self.objName + ':send', formData);

                            self.closeBox();
                            return false;
                        });

                        // function to handle disable/enable reset button
                        $(self.myDOM).find('input[name]').on('keypress', function() {
                            if ($(self.form)[0].checkValidity && $(self.form)[0].checkValidity()) {
                                $(self.myDOM).find('[data-btn="btn-ok"]').removeClass('disabled');
                            } else {
                                $(self.myDOM).find('[data-btn="btn-ok"]').addClass('disabled');
                            }
                        });
                    } // form available
                }
            }

        });


    $(window.document).ready(function() {
        window.setTimeout(function() { // late init of message boxes without special handling
            // initialize missing page objects
            $('.messageBox').each(function() {
                if (typeof $(this).data(tcgConfig.namespace) === 'undefined') {
                    var box = new tcgDOM.messageBox($(this));
                    box.initBox();
                }
            });
        }, 500);
    });
})(window, tcgDOM, $, tcgConfig);
