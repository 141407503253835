// jscs:disable maximumNumberOfLines
/* All these settings prevent analysis errors but mean
 * deviation from coding standard XXX */
/*jshint esversion: 6 */
/*jshint nonew: false */
/*jshint maxdepth: 6 */
/*jshint maxcomplexity: 40 */
/*jshint browser: true */
/* jscs:disable maximumLineLength */
/* global Hyphenator, ecatUI, _, tcgDOM, tcgConfig, $, ecatUserProfile, tcgDget, facetserach */
(function(Hyphenator, ecatUI, _, tcgDOM, tcgConfig, $, ecatUserProfile, tcgDget, facetserach, global) { // jshint ignore: line
    'use strict';

    /**
     * @class
     * @augments tcgDget
     * @param {Object} param
     */
    /**
     * @lends tcgDget.idgLogin.prototype
     */
    tcgDget.idgVIVAConfig = tcgDget.extend({
        vivaActive: false,

        vivaConfig: null, // config info
        activeBaseArticles: [], // base articles used for config

        attr1Last: null, // wattage, line break detection
        clickCnt: 0,
        processInClick: true,
        forceDeselect: false,

        trackingType: null, // type for tracking purpose
        trackingName: null, // name for tracking purpose
        bAuthenticated: false,
        lan: 'en',

        isIframe: false,

        dictEntry_notselected: {
            name: 'CAT_viva_notselected',
            value: 'keines'
        },
        dictEntry_required: {
            name: 'CAT_viva_required',
            value: 'required'
        },

        textSelectLampFirst: 'placeholder',

        ExtraItemTranslationIsLoaded: false,

        dict: null, // will be seted on event 'dictLoaded'


        /********************************************************************************************
         * Init functions block start
         ********************************************************************************************/

        _init_: function() {
            var self = this;

            self.isIframe = (window.location !== window.parent.location);

            self.objName = 'idgVIVAConfig';
            self.setDgetMode('single');

            var guuid = _.createId();
            $(self.myDOM).attr('data-viva-guuid', guuid);
            self.myDOM = $('.vivaConfig[data-viva-guuid="' + guuid + '"]');

            self.lang = ecatUserProfile.getLanguage();

            self.textSelectLampFirst = $('.vivaSelectLampFirst', $(self.myDOM)).html();

            if ($(self.myDOM).attr('data-tcgDget') === 'idgVIVAConfig') {
                self.initVivaListeners();
            }
        },

        waitCnt: 0,

        waitStart: function() {
            var self = this;
            self.waitCnt++;
            if (self.waitCnt === 1) {
                $(self.myDOM).closest('.groupItem').addClass('wait');
            }
        },

        waitEnd: function() {
            var self = this;
            self.waitCnt--;
            if (self.waitCnt <= 0) {
                $(self.myDOM).closest('.groupItem').removeClass('wait');
                self.waitCnt = 0;
            }
        },

        initVivaListeners: function() {
            var self = this;

            if (self.isMaster()) {
                self.listen('family', function(event) {
                    var family = event.messageData;
                    if (typeof family.loadString === 'string') {
                        self.loadVIVA(family.loadString);
                        self.loadVIVABaseArticles(family.ids);
                    } else {
                        self.hideMyGroup();
                    }
                });
            } // only master is listening to family request

            self.listen('viva:load', function(event) {
                var family = event.messageData;
                self.loadVIVA(family);
            });

            self.listen('viva:loaded', function(event) {
                var data = event.messageData;
                self.waitEnd(); // 2021-03-31
                if (parent.addZubehoer) {
                    self.hideMyGroup();
                    parent.addZubehoer = undefined;
                } else {
                    if (self.extractVIVAConfig(data)) { // read base articles
                        //                self.loadVIVABaseArticles(data.response.docs[0].vivaLuminaireFamilyName);

                        self.fillData();
                        //                 self.loadVIVABaseArticles('6AB7DB9A-9C97-44BE-9E77-33D83DD4FA73');

                        // add  triggers

                        $('.vivaFacetsAttr', $(self.myDOM)).on('facetuicreated', function() {
                            // MYPV-1718 PANOS III dropdowns for dimension attributes
                            // fillData()
                            //    |----> renderVIVA()
                            //               |---> appendVIVAfacets()
                            //                        |---> new facetsearch()
                            //                                   |----> createFacetUI()
                            //                                            |----> trigger 'facetuicreated'
                            // self.groupDimensionAttribsIntoCombos();
                        });

                        $('.vivaFacetsResult', $(self.myDOM)).on('facetedsearchresultupdate', function() {
                            self.afterFacetItemClick();
                        });

                        var configuratorName = self.getIframeParentConfiguratorName();

                        if (self.isIframe  && configuratorName === 'mirel_ref') {
                            $('[data-viva-name="LED"]').trigger('click');
                        }

                        if (self.isIframe && configuratorName === 'claris_evo') {
                            var configuration = parent.window.possibleArticleConfig;

                            if (configuration && configuration.mountingType) {
                                if (configuration.mountingType === 'msp') {
                                    $('[data-viva-name="CLEVO ID"]').trigger('click');
                                }

                                if (configuration.mountingType === 'msc' || configuration.mountingType === 'tec') {
                                    $('[data-viva-name="CLEVO MSC"]').trigger('click');
                                }

                                if (configuration.mountingType === 'tec') {
                                    $('[data-viva-name="TEC"]').trigger('click');
                                    // $('[data-viva-name="TEC"]').addClass('disabled');
                                }
                            }
                        }
                    } else {
                        self.hideMyGroup();
                    }
                }
            });

            self.listen('vivaInfo', function() { // triggered at the end of self.updateInfo()
                // MYPV-1728 PANOS III combos
                self.updateDimensionAttribCombos(); // sets the correct state on special dimension dropdowns
            });

            self.listen('viva:off', function() {
                self.hideMyGroup();
            });

            self.listen('viva:basearticle', function(event) {
                if (_.isArray(self.BasisArticles)) {
                    console.log('viva:basearticle:: localhost simulate');
                } else {
                    var data = event.messageData;

                    if (window.location.hostname === 'localhost') { // local only - data missing
                        if (self.isMaster()) {
                            if (data.response.numFound > 0) {
                                self.baseArticles = data.response.docs;
                                self.fillData();
                                // self.fire('viva:render', self.vivaConfig);
                            } else {
                                self.baseArticles = [];
                                self.hideMyGroup();
                            }
                        } // master
                        else {
                            self.baseArticles = self.getBaseArticlesFromConfig();
                            console.log('viva:basearticle:: simulate base article');
                            self.fillData();
                        } // currently only master deliveres base article
                    } else {
                        if (data.response.numFound > 0) {
                            self.baseArticles = data.response.docs;
                            self.fillData();
                            // self.fire('viva:render', self.vivaConfig);
                        } else {
                            self.baseArticles = [];
                            self.hideMyGroup();
                        }
                    }
                }
            });

            $(window).bind('login:update', function(event, messageData) {
                self.bAuthenticated = messageData.status;
                self.initAdjustable();
            });
        },

        // -----------------------------------------------------------------------------
        // MYPV-1718
        //
        // special dropdown solution for PANOS III
        // see https://lighttrack.zumtobelgroup.com/browse/MYPV-1728
        //
        // the following functions are part of the implementation
        // - groupDimensionAttribsIntoCombos
        // - updateDimensionAttribCombos
        // - resetDimensionAttribsComboSelected
        //
        // the combos are renderd in the function groupDimensionAttribsIntoCombos() which is called in
        // the function renderVIVA() after facets and option groups are created.
        //
        // the function groupDimensionAttribsIntoCombos() handles also the logic, when the user
        // clicks on the smallButton as well as on a dropdown item.
        //
        // the function updateDimensionAttribCombos() handles the color (black or red) of the group title and
        // adds or removes the correct css classes to the combose (smallButton) depending on the selected items.
        //
        // the function resetDimentionAttribsComboSelected() is called when the user clicks on a smallButton
        // that is enabled but 'notselected'. The function is also called, when the user clicks on the
        // empty list item in the dropdown. Then the selection text will be removed.

        /**
         * enable/disable the manually created small buttons for dimensions
         * 'Square', 'Round' and 'Linear'.
         */
        updateDimensionAttribCombos: function() {
            var self = this,
                elemAttr4 = $('#Attr4', $(self.myDOM)),
                elemDimCombos = $('#DimCombo', $(self.myDOM));
            if (elemDimCombos.length) { // special group is present
                // mustNotSelected handling: reads the status 'mustNotSelected' from the original vivaOptionGroup vivaAttr4
                $('[alt="CAT_viva_Dimension"]', $(elemDimCombos)).toggleClass('mustNotSelected', $('.mustNotSelected', $(elemAttr4)).length !== 0);

                $('.smallButton', elemDimCombos).each(function() { // loop every small button in the dimension group
                    var dimType = $('ul.customSelectDropdown', $(this)).data('dimension-type'); // 'Q', 'R' or 'L'
                    // check if smallButton has dropdown options to be selected
                    var isDisabled = ($('.smallButton[data-viva-extraitemid^="' + dimType + '"]', $(elemAttr4)).not('.disabled').length === 0);
                    $(this).toggleClass('disabled', isDisabled);
                    // handle notselected
                    if (!$('.smallButton[data-viva-extraitemid^="' + dimType + '"]', $(elemAttr4)).hasClass('selected')) {
                        var hasNotselected = $('.smallButton[data-viva-extraitemid^="' + dimType + '"]:not(.disabled)', $(elemAttr4)).hasClass('notselected');
                        $(this).toggleClass('notselected', hasNotselected);
                    }
                });
            }
        },

        /**
         * renders the special dropdowns for the Attr4 (Dimension)
         */
        groupDimensionAttribsIntoCombos: function() {
            var self = this;

            // feature only for PANOS III available
            if (self.vivaConfig.LuminaireFamilyName !== 'panos') {
                return false;
            }

            var basisAttribute = _.findWhere(self.vivaConfig.BasisAttributes.BasisAttribute, {id: 'Attr4'}),
                elemAttr4 = $('#Attr4', $(self.myDOM)),
                templDimCombos = _.template('<div class="facetsearch vivaOptionGroup" id="<%= id %>">' +
            '<h5 class="facettitle"><span alt="CAT_viva_Dimension"><%= title %></span>' +
                '<span class="required off">required</span>' +
            '</h5>' +
            '<div class="vivaInfoText off"></div>' +
            '<ul class="smallButtonList">' +
                '<li class="smallButton comboButton">' +
                    '<span class="smallButtonImage" style="font-size: 0px;">(1)</span>' +
                    '<span class="smallButtonText" lang="de">Q</span>' +
                    '<div class="customSelect">' +
                        '<span class="smallButtonText"></span>' +
                        '<ul class="customSelectDropdown" data-dimension-type="Q">' +
                            '<li value=""></li>' +
                        '</ul>' +
                    '</div>' +
                '</li>' +
                '<li class="smallButton comboButton">' +
                    '<span class="smallButtonImage" style="font-size: 0px;">(1)</span>' +
                    '<span class="smallButtonText" lang="de">R</span>' +
                    '<div class="customSelect">' +
                        '<span class="smallButtonText"></span>' +
                        '<ul class="customSelectDropdown" data-dimension-type="R">' +
                            '<li value=""></li>' +
                        '</ul>' +
                    '</div>' +
                '</li>' +
                '<li class="smallButton comboButton">' +
                    '<span class="smallButtonImage" style="font-size: 0px;">(1)</span>' +
                    '<span class="smallButtonText" lang="de">L</span>' +
                    '<div class="customSelect">' +
                        '<span class="smallButtonText"></span>' +
                        '<ul class="customSelectDropdown" data-dimension-type="L">' +
                            '<li value=""></li>' +
                        '</ul>' +
                    '</div>' +
                '</li>' +
                '<li class="smallButton comboButton">' +
                    '<span class="smallButtonImage" style="font-size: 0px;">(1)</span>' +
                    '<span class="smallButtonText" lang="de">T</span>' +
                    '<div class="customSelect">' +
                        '<span class="smallButtonText"></span>' +
                        '<ul class="customSelectDropdown" data-dimension-type="T">' +
                            '<li value=""></li>' +
                        '</ul>' +
                    '</div>' +
                '</li>' +
            '</ul>' +
            '</div>'),
            groupTitle = 'Dimension';
            if (basisAttribute && _.has(basisAttribute, 'title')) {
                groupTitle = basisAttribute.title;
            }
            $(elemAttr4).before($(templDimCombos({
                id: 'DimCombo',
                title: groupTitle
            })));
            // elemAttr4:       vivaOptionGroup rendered by base article attribute 'Dimension' (hidden section)
            // elemDimCombos:   vivaOptionGroup with manually created combos 'Square', 'Round', 'Linear'
            var elemDimCombos = $('#DimCombo', $(self.myDOM)),
                isValid4Combo = true,
                templDimComboLi = _.template('<li value="<%= value %>"><%= text %></li>');

            // enable debug mode: displays the hidden facetted group Attr4
            $('span[alt="CAT_viva_Dimension"]', $(elemDimCombos)).on('dblclick', function() {
                $(elemAttr4).toggleClass('off');
            });

            // generate custom combos
            $(elemAttr4).find('.facetitem').each(function() {
                var extraItemId = $(this).data('viva-extraitemid'),
                    regExDim = /^([RQL])(\d+)$/;
                if (regExDim.test(extraItemId)) {
                    var arrMatches = extraItemId.match(regExDim),
                        elemUL = $('[data-dimension-type="' + arrMatches[1] + '"]', $(elemDimCombos));
                    if (elemUL.length !== 0) {
                        var itemName = extraItemId,
                            itemTitle = arrMatches[1]; // R, Q or L
                        if (basisAttribute && _.has(basisAttribute, 'Item')) {
                            var basisAttributeItem = _.findWhere(basisAttribute.Item, {id: extraItemId});
                            if (basisAttributeItem && _.has(basisAttributeItem, 'name')) {
                                itemName = basisAttributeItem.name;
                            }
                            if (basisAttributeItem && _.has(basisAttributeItem, 'title')) {
                                itemTitle = basisAttributeItem.title;
                            }
                        }
                        $(elemUL).append($(templDimComboLi({
                            value: extraItemId,
                            text: itemName
                        })));
                        // combo name
                        $(elemUL).closest('.smallButton').children('.smallButtonText').text(itemTitle);
                    } else {
                        isValid4Combo = false;
                    }
                } else {
                    isValid4Combo = false;
                }
            });
            if (isValid4Combo) {
                // hide small buttons without any dropdown option
                $('ul[data-dimension-type]', elemDimCombos).each(function() {
                    if ($('li', this).length === 1) {
                        $(this).closest('.smallButton').hide();
                    }
                });

                // user clicked on small button 'Square', 'Round' or 'Linear'
                $('.smallButton', elemDimCombos).on('click', function() {
                    var dimType = $('.customSelectDropdown', $(this)).data('dimension-type');
                    // close first all other dim dropdowns
                    $('.customSelectDropdown:not([data-dimension-type="'+dimType+'"])', $(elemDimCombos)).removeClass('customSelectShowDropdown');
                    var elemCombo = $(this);
                    $('li', elemCombo).each(function() {
                        // hide/show options in the dropdown, that are disabled/vivaNotAvailable in vivaAttr4 by facet
                        var isDisabled = $('.smallButton[data-viva-extraitemid="'+$(this).attr('value')+'"]').is('.disabled, .vivaNotAvailable');
                        $(this).toggle(!isDisabled);
                    });
                    // open/close dropdown
                    $('.customSelectDropdown', $(this)).toggleClass('customSelectShowDropdown');

                    // handle 'notselected' dropdown
                    // the user clicks on a smallButton that is enabled but notselected
                    if ($(this).hasClass('notselected')) {
                        $(this).removeClass('notselected');
                        self.resetDimensionAttribsComboSelected();
                    }
                });

                // user has dropdown item selected
                $('.smallButton li', elemDimCombos).on('click', function(e) {
                    e.stopPropagation();

                    // first remove selected class from special smallButton's to prevent
                    // 'Uncaught error, multiple options selected in Dimension'
                    $('.smallButton', $(elemDimCombos)).removeClass('selected');

                    // deselect 'activefacet' item in 'vivaAttr4'
                    var facetContainer = $('.vivaFacetsAttr', $(self.myDOM));
                    $('.vivaAttr4', $(facetContainer)).find('li').each(function() {
                        if ($(this).hasClass('activefacet')) { // deselect small button
                            $(this).trigger('click');
                        }
                    });

                    // handle dropdown list item selection
                    var value = $(this).attr('value');
                    if (!!value) { // user has selected a value
                        $(this).closest('.smallButton').toggleClass('selected', true); // mark smallButton as selected
                        $('.smallButton[data-viva-extraitemid="'+$(this).attr('value')+'"]').trigger('click');
                        $(this).closest('.customSelect').find('.smallButtonText').text(value); // indicate selected value in the smallButton
                    } else { // user has selected the first (empty) dropdown item, close dropdown
                        self.resetDimensionAttribsComboSelected();
                    }

                    // close dropdown
                    $(this).closest('.customSelectDropdown').toggleClass('customSelectShowDropdown');
                });
            }
            $(elemAttr4).toggleClass('off', isValid4Combo);
            $(elemDimCombos).toggleClass('off', !isValid4Combo);
        },

        /**
         * resets the selected dimension combo
         */
        resetDimensionAttribsComboSelected: function() {
            var self = this,
                elemDimCombos = $('#DimCombo', $(self.myDOM)),
                facetContainer = $('.vivaFacetsAttr', $(self.myDOM));
            $('.vivaAttr4', $(facetContainer)).find('li').each(function() {
                if ($(this).hasClass('activefacet')) { // deselect small button
                    $(this).trigger('click');
                }
            });
            $('.smallButton', $(elemDimCombos)).each(function() {
                $(this).toggleClass('selected', false); // make smallButton as unselected
                $('.customSelect', $(this)).find('.smallButtonText').text('  ');  // reset label
            });
            $('.smallButton.notselected', $(elemDimCombos)).removeClass('notselected');
        },
        // MYPV-1718
        // -----------------------------------------------------------------------------

        isMaster: function() {
            var self = this;
            return $(self.myDOM).attr('data-viva') === 'master';
        },

        hideMyGroup: function() {
            var self = this,
                myGroupItem = $(self.myDOM).closest('.groupItem');

            if (self.isMaster()) {
                $(myGroupItem).addClass('off');
                self.fire('viva:notAvailable', {});
            } else {
                $(self.myDOM).hide(); // hide only vivaConfig
            } // optics, ...
        },

        loadVIVA: function(what) {
            var self = this;

            if (!self.isMaster()) {
                return;
            }
            // get portfolio (private connection)
            var vivaPF = window.tcgGetPageProperty('vivaPortfolio');

            // private load ok
            var bLoadOK = false;

            // post data struct
            var pData = {};

            if (typeof vivaPF === 'string') { // private mode - load from BC
                // post data
                pData = {
                    'contenttype': 'text/xml',
                    'app-url': 'viva/getFamily',
                    'LuminaireFamilyName': what,
                    'version': 3,
                    'lang': ecatUserProfile.getLanguage()
                };
                self.waitStart(); // 2021-03-31

                // split portfolio
                var pf = vivaPF.split(',');

                if (pf.length === 3) {
                    pData.vkorg = pf[0];
                    pData.vtweg = pf[1];
                    pData.pl = pf[2];

                    // service url
                    // var url = window.tcgGetPageProperty('ecatPriceProxy');
                    var url = window.tcgGetPageProperty('ecatVivaProxy');

                    // ajax settings
                    var jQueryAjaxSettingsTraditional = $.ajaxSettings.traditional;
                    $.ajaxSettings.traditional = true;
                    $.post(url, pData, function(xml) {
                        if ($.isXMLDoc(xml)) {
                            var data = { // solr compatible structure
                                response: {
                                    numFound: 1,
                                    docs: [{
                                        vivaLuminaireFamilyCode: '',
                                        vivaLuminaireFamilyName: what,
                                        vivaConfig: $(xml).find('doc').attr('vivaConfig')
                                    }]
                                }
                            };

                            self.fire('viva:loaded', data);
                        }
                    }, 'xml');
                    $.ajaxSettings.traditional = jQueryAjaxSettingsTraditional;
                    bLoadOK = true;
                }
            }
            if (bLoadOK === false) { // public - solr load
                pData = {
                    'q': 'objectType:viva',
                    'rows': 2
                };

                if (what.match(/^[0-9A-F\-]{36}/i)) {
                    pData.portfolio = what;
                } else { // friendly link name
                    pData.fq = 'vivaLuminaireFamilyName:' + tcgDOM.domSOLR.escape(what);
                }


                self.fire('solr:load', {
                    'service': 'select',
                    'message': 'viva:loaded',
                    'pData': pData
                });
            }
        },

        loadVIVABaseArticles: function(id) {
            var self = this,
                pData = null;
            if (_.isArray(id)) {
                var q = '';
                for (var i = 0; i < id.length; i++) {
                    if (q !== '') {
                        q += ' OR ';
                    }
                    q += '( level_4_id:' + id[i] + ' OR level_3_id:' + id[i] + ' OR level_2_id:' + id[i] + ' )';
                }


                pData = {
                    'q': '( ' + q + ' ) AND vivaBasisartikel-SML:*',
                    'clearance': 'yes',
                    'fq': 'objectType:vivaBaseArticle OR objectType:article',
                    'fl': 'vivaBasisartikel-SML,vivaLuminaireFamilyCode',
                    'rows': 999
                };
            } else if (id.match(/^[0-9A-F\-]{36}/i)) {
                pData = {
                    'q': '( level_4_id:' + id + ' OR level_3_id:' + id + ' OR level_2_id:' + id +
                        ' ) AND vivaBasisartikel-SML:*',
                    'clearance': 'yes',
                    'fq': 'objectType:vivaBaseArticle OR objectType:article',
                    'fl': 'vivaBasisartikel-SML,vivaLuminaireFamilyCode',
                    'rows': 999
                };
            } // via guuid
            else {
                pData = {
                    'q': 'vivaBasisartikel-SML:*',
                    'fq': 'objectType:vivaBaseArticle OR objectType:article',
                    'portfolio': id,
                    'clearance': 'yes',
                    'fl': 'vivaBasisartikel-SML,vivaLuminaireFamilyCode',
                    'rows': 999
                };
            }
            if (pData) {
                self.fire('solr:load', {
                    'service': 'select',
                    'message': 'viva:basearticle',
                    'pData': pData
                });
            }
        },

        getBaseArticlesFromConfig: function() {
            var self = this;

            // return
            var baseArticles = [];
            if (self.vivaConfig) {
                _.each(self.vivaConfig.BasisArticles.BasisArticle, function(ba) {
                    baseArticles.push({
                        'vivaBasisartikel-SML': ba.ArticleId,
                        'vivaLuminaireFamilyCode': ba.LuminaireFamilyCode
                    });
                });
            }

            return baseArticles;
        },

        extractVIVAConfig: function(data) {
            var self = this;

            try {
                if (data.response.numFound === 0) {
                    return false;
                }

                if (window.location.hostname === 'localhost') { // local only - data missing
                    try {
                        self.vivaConfig = $.xml2json($.base64Decode($('#' + data.response.docs[0].vivaLuminaireFamilyName).text()));
                    } catch (e) {
                        console.log(e);
                    }
                }
                if (_.isEmpty(self.vivaConfig)) {
                    if (_.isString(data.response.docs[0].vivaConfig)) { // only one version fits all
                        self.vivaConfig = $.xml2json(data.response.docs[0].vivaConfig);
                        window.vivaConfig = self.vivaConfig;
                    } else { // fallback to old
                        return false;
                    }
                }

                if (typeof self.vivaConfig.group !== 'undefined') { // version 2020
                    if (!_.isArray(self.vivaConfig.group)) {
                        self.vivaConfig.group = [self.vivaConfig.group];
                    }

                    var vivaArticleType = $(self.myDOM).closest('.groupDetail').attr('data-ArticleType');
                    // more vars
                    var _cc = self.vivaConfig.cc,
                        _lfn = self.vivaConfig.LuminaireFamilyName,
                        _dt = self.vivaConfig.DeliveryTimes;

                    self.vivaConfig = _.find(self.vivaConfig.group, function(grp) {
                        if (self.isMaster()) {
                            if (!_.has(grp, 'type')) {
                                return true;
                            }
                        } else {
                            if (_.has(grp, 'type') && grp.type === vivaArticleType) {
                                return true;
                            }
                        }
                        return false;
                    });
                    if (self.vivaConfig) {
                        self.vivaConfig.cc = _cc;
                        self.vivaConfig.LuminaireFamilyName = _lfn;
                        self.vivaConfig.DeliveryTimes = _dt;

                        if (!_.isArray(self.baseArticles)) { // maybe portfolio will not work well
                            if (!_.isArray(self.vivaConfig.BasisArticles.BasisArticle)) {
                                self.vivaConfig.BasisArticles.BasisArticle = [self.vivaConfig.BasisArticles.BasisArticle];
                            }
                        }
                        if (window.location.hostname === 'localhost') { // local only - data missing
                            self.baseArticles = self.getBaseArticlesFromConfig();
                        }
                    } // valid group config
                    else {
                        self.vivaConfig = null;
                    }
                } else { // single viva
                    if (!self.isMaster()) { // only visible in luminaires
                        return false;
                    }
                }

                if (self.vivaConfig === null) {
                    return false;
                }

                // self.vivaConfig.LuminaireFamilyCode = data.response.docs[0].vivaLuminaireFamilyCode;
                self.vivaConfig.LuminaireFamilyName = data.response.docs[0].vivaLuminaireFamilyName;

                self.makeNiceArrays(self.vivaConfig);

                // switch off the articke list inside my group
                $('ul.articleList', $(self.myDOM).parent()).hide();

                if (self.isIframe) {
                    var config,
                    configuratorName = self.getIframeParentConfiguratorName(),
                    configuration;

                    if (configuratorName === 'tecton') {
                        if (self.vivaConfig.LuminaireFamilyName === 'tecton') {
                            var family = parent.window.tectonConfig.tectonFamily;
                            config = parent.window.tectonConfig[family];
                        } else {
                            config = _.find(parent.window.tectonConfig, function(item) {
                                return item.familyLink === self.vivaConfig.LuminaireFamilyName ||
                                    item.familyName === self.vivaConfig.LuminaireFamilyName;
                            });
                        }

                        parent.window.tectonConfig.tectonFamily = null;

                        var color = _.find(self.vivaConfig.EAIT, function(item) {
                            return item.Id === 'G23';
                        });

                        if (color) {
                            color.Item = _.filter(color.Item, function(colorItem) {
                                return colorItem.SmlCode === parent.window.selectedColor.replace('V_', '');
                            });

                            // change must defaults for color
                            var firstColor = _.first(color.Item),
                                defaults = _.filter(self.vivaConfig.MustDefault.Item, function(mustDefault) {
                                    return mustDefault.GroupId === 'G23';
                                });

                            if (firstColor) {
                                _.each(defaults, function(mustDefault) {
                                    mustDefault.DefaultItemId = firstColor.Id;
                                    mustDefault.ItemCode = firstColor.SmlCode;
                                });
                            }
                        }

                        if (config.baseArticles) {
                            self.vivaConfig.BasisArticles.BasisArticle =
                            _.filter(self.vivaConfig.BasisArticles.BasisArticle, function(item) {
                                return _.some(config.baseArticles, function(baseArticle) {
                                    return baseArticle === item.ArticleId;
                                });
                            });
                        }

                        if (config.optionId) {
                            var eait = _.find(self.vivaConfig.EAIT, function(item) {
                                return _.some(item.Item, function(group) {
                                    return group.ExtraItemId === config.optionId;
                                });
                            });

                            eait.Item = _.filter(eait.Item, function(item) {
                                if (item.ExtraItemId === config.optionId) {
                                    item.preselect = true;
                                    return true;
                                }
                                return false;
                            });
                        }
                    } else if (configuratorName === 'ecoos') {
                        configuration = parent.window.possibleArticleConfig;

                        if (!!configuration) {
                            var notPossibleOptions = [];

                            if (!!configuration.baseType) {
                                self.vivaConfig.BasisArticles.BasisArticle = _.filter(self.vivaConfig.BasisArticles.BasisArticle, function(baseArticle) {
                                    return baseArticle.Attr3 === configuration.baseType;
                                });
                            }

                            if (!!configuration.possibleL12Lumen && !!configuration.possibleL15Lumen) {
                                self.vivaConfig.BasisArticles.BasisArticle = _.filter(self.vivaConfig.BasisArticles.BasisArticle, function(baseArticle) {
                                    return (baseArticle.Attr4 === 'L12' && _.contains(configuration.possibleL12Lumen, baseArticle.Attr6)) ||
                                            (baseArticle.Attr4 === 'L15' && _.contains(configuration.possibleL15Lumen, baseArticle.Attr6));
                                });
                            }

                            if (!!configuration.lightColor && configuration.lightColor !== '*') {
                                var lightColorEAITId = 'G17',
                                    lightColorsEAITGroup = self.getEAITGroupById(lightColorEAITId);

                                // removeAllOtherOptionsOfGroup(lightColorsEAITGroup, configuration.lightColor);        // It should be possible to choose other lightColors
                                self.setDefaultItemOfGroup(lightColorsEAITGroup, lightColorEAITId, configuration.lightColor);
                            }

                            if (!!configuration.mountingType && configuration.mountingType !== '*') {
                                var mountingEAITId = 'G6',
                                    mountingType = configuration.mountingType.toUpperCase(),
                                    mountingEAITGroup = self.getEAITGroupById(mountingEAITId);

                                self.removeAllOtherOptionsOfGroup(mountingEAITGroup, mountingType);
                                self.setDefaultItemOfGroup(mountingEAITGroup, mountingEAITId, mountingType);
                            }

                            if (configuration.mountingType === 'tec') {
                                var tectonColorEAITId = 'G23',
                                    tectonColorEAITGroup = self.getEAITGroupById(tectonColorEAITId),
                                    value = configuration.tectonColor.replace('V_', '');

                                self.removeAllOtherOptionsOfGroup(tectonColorEAITGroup, value);
                                self.setDefaultItemOfGroup(tectonColorEAITGroup, tectonColorEAITId, value);
                            } else {
                                notPossibleOptions.push('WH', 'SR', 'BK');
                            }

                            self.vivaConfig.AvailableOptions.Item = _.filter(self.vivaConfig.AvailableOptions.Item, function(item) {
                                return !_.contains(notPossibleOptions, item.ItemCode);
                            });
                        }
                    } else if (configuratorName === 'claris_evo') {
                        configuration = parent.window.possibleArticleConfig;

                        if (!!configuration && !!configuration.mountingType) {
                            if (configuration.mountingType === 'msp') {
                                self.vivaConfig.BasisArticles.BasisArticle = _.filter(self.vivaConfig.BasisArticles.BasisArticle, function(baseArticle) {
                                    return baseArticle.Attr3 === 'CLEVO ID';
                                });
                            } else if (configuration.mountingType === 'msc') {
                                self.vivaConfig.BasisArticles.BasisArticle = _.filter(self.vivaConfig.BasisArticles.BasisArticle, function(baseArticle) {
                                    return baseArticle.Attr3 === 'CLEVO MSC';
                                });
                                self.vivaConfig.EAIT = _.filter(self.vivaConfig.EAIT, function(eait) {
                                    return eait.ExtraItemTypeId !== '6';
                                });
                            } else if (configuration.mountingType === 'tec') {
                                self.vivaConfig.BasisArticles.BasisArticle = _.filter(self.vivaConfig.BasisArticles.BasisArticle, function(baseArticle) {
                                    return baseArticle.Attr3 === 'CLEVO MSC';
                                });
                            }
                        }
                        if (!!configuration && !!configuration.suspensionSystem) {
                            if (configuration.suspensionSystem === 'refurbSystem') {
                                self.vivaConfig.BasisArticles.BasisArticle = _.filter(self.vivaConfig.BasisArticles.BasisArticle, function(baseArticle) {
                                    return baseArticle.Attr4 !== 'L3080';
                                });
                            }
                        }
                    }

                    // only display possible basic types
                    if (config && config.onlyBasicTypeCodes) {
                        var filteredBasicTypes = _.filter(self.vivaConfig.BasicTypes.BasicType, function(item) {
                            return _.some(config.onlyBasicTypeCodes, function(basicTypeCodeItem) {
                                return basicTypeCodeItem === item.BasicTypeCode;
                            });
                        });

                        if (filteredBasicTypes.length > 0) {
                            self.vivaConfig.BasicTypes.BasicType = filteredBasicTypes;

                            self.vivaConfig.BasisArticles.BasisArticle = _.filter(self.vivaConfig.BasisArticles.BasisArticle, function(baseArticle) {
                                return _.some(filteredBasicTypes, function(basicTypeCodeItem) {
                                    return basicTypeCodeItem.BasicTypeCode === baseArticle.Attr3;
                                });
                            });
                        }
                    }
                }
            } catch (e) {
                self.vivaConfig = null;
            }
            return self.vivaConfig != null;
        },

        getEAITGroupById: function(groupId) {
            var self = this;
            return _.find(self.vivaConfig.EAIT, function(eait) {
                return eait.Id === groupId;
            });
        },

        setDefaultItemOfGroup: function(eaitGroup, eaitId, option) {
            var self = this,
                eaitItem = _.find(eaitGroup.Item, function(eait) {
                    return eait.SmlCode === option;
                });

            _.forEach(self.vivaConfig.MustDefault.Item, function(item) {
                if (item.GroupId === eaitId) {
                    item.DefaultItemId = eaitItem.Id;
                    item.ItemCode = option;
                }
            });
        },

        removeAllOtherOptionsOfGroup: function(eaitGroup, option) {
            var self = this,
                notAllowedLightColors = _.difference(_.map(eaitGroup.Item, function(item) {
                    return item.SmlCode;
                }), option);

            self.vivaConfig.AvailableOptions.Item = _.filter(self.vivaConfig.AvailableOptions.Item, function(item) {
                return !_.contains(notAllowedLightColors, item.ItemCode);
            });
        },

        /**
         * viva config xml cleaning
         */
        makeNiceArrays: function(vc) {
            // Make Array LuminaireBases
            if (typeof vc.LuminaireBases !== 'undefined' &&
                typeof vc.LuminaireBases.LuminaireBasis !== 'undefined') {
                if (typeof vc.LuminaireBases.LuminaireBasis.length === 'undefined') {
                    // single item no array
                    vc.LuminaireBases.LuminaireBasis =
                        new Array(vc.LuminaireBases.LuminaireBasis);
                }
            }
            // Make Array BasisArticles
            if (typeof vc.BasisArticles !== 'undefined' &&
                typeof vc.BasisArticles.BasisArticle !== 'undefined') {
                if (typeof vc.BasisArticles.BasisArticle.length === 'undefined') {
                    // single item no array
                    vc.BasisArticles.BasisArticle =
                        new Array(vc.BasisArticles.BasisArticle);
                }
            }

            // make arrays for EAIT and EAIT/Item
            if (typeof vc.EAIT.length === 'undefined') {
                vc.EAIT = new Array(vc.EAIT);
            }

            for (var i = 0; i < vc.EAIT.length; i++) {
                if (typeof vc.EAIT[i].Item.length === 'undefined') {
                    vc.EAIT[i].Item = new Array(vc.EAIT[i].Item);
                }
            }

            // Make Array Compatibility
            if (typeof vc.Compatibility !== 'undefined' &&
                typeof vc.Compatibility.Item !== 'undefined') {
                if (typeof vc.Compatibility.Item.length === 'undefined') { // single item no array
                    vc.Compatibility.Item = new Array(vc.Compatibility.Item);
                }
            }
            // Make Array MustDefault
            if (typeof vc.MustDefault !== 'undefined' &&
                typeof vc.MustDefault.Item !== 'undefined') {
                if (typeof vc.MustDefault.Item.length === 'undefined') { // single item no array
                    vc.MustDefault.Item = new Array(vc.MustDefault.Item);
                }
            }
            // Make Array AvailableOptions
            if (typeof vc.AvailableOptions !== 'undefined' &&
                typeof vc.AvailableOptions.Item !== 'undefined') {
                if (typeof vc.AvailableOptions.Item.length === 'undefined') { // single item no array
                    vc.AvailableOptions.Item = new Array(vc.AvailableOptions.Item);
                }
            }

            // Make Array SafetySigns
            if (typeof vc.SafetySigns !== 'undefined' &&
                typeof vc.SafetySigns.SafetySign !== 'undefined') {
                if (typeof vc.SafetySigns.SafetySign.length === 'undefined') { // single item no array
                    vc.SafetySigns.SafetySign = new Array(vc.SafetySigns.SafetySign);
                }
            }

            if (typeof vc.FamilyProperties !== 'undefined') {
                var fp = vc.FamilyProperties;
                vc.FamilyProperties = {};
                if (!_.isArray(fp.Property)) { // make array of single
                    fp.Property = [fp.Property];
                }
                _.each(fp.Property, function(p) {
                    vc.FamilyProperties[p.name] = p.value;
                }, vc.FamilyProperties);
            } else {
                vc.FamilyProperties = {};
            }
        },

        fillData: function() {
            var self = this;

            if (_.isArray(self.baseArticles) && !_.isEmpty(self.baseArticles)) {
                var listOfArticles = _.pluck(self.baseArticles, 'vivaBasisartikel-SML');
                // var listOfArticles=_.pluck(self.dataObj.elements, 'matnr');

                if (self.vivaConfig !== null && self.vivaConfig !== undefined) {
                    self.activeBaseArticles = _.filter(self.vivaConfig.BasisArticles.BasisArticle, function(art) {
                        return _.indexOf(listOfArticles, art.ArticleId) !== -1;
                    });
                }

                if (self.activeBaseArticles.length > 0) {
                    self.vivaActive = true;

                    // ----------------------------------------------------------------------
                    // MYPV-1731
                    // BasisAttributes
                    if (!_.has(self.vivaConfig, 'BasisAttributes')) { // fallback if not delivered from bc and not included in solr
                        var basisAttributes = [];
                        basisAttributes.push({
                            id: 'Attr1',
                            name: 'Load',
                            title: 'Load',
                            selector: 'button'
                        });
                        basisAttributes.push({
                            id: 'Attr2',
                            name: 'Lamp',
                            title: 'Lamp',
                            selector: 'button'
                        });
                        basisAttributes.push({
                            id: 'Attr3',
                            name: 'Type',
                            title: 'Type',
                            selector: 'button'
                        });
                        basisAttributes.push({
                            id: 'Attr4',
                            name: 'Dimension',
                            title: 'Dimension',
                            selector: 'button'
                        });
                        basisAttributes.push({
                            id: 'Attr5',
                            name: 'Lighting',
                            title: 'Lighting',
                            selector: 'button'
                        });
                        basisAttributes.push({
                            id: 'Attr6',
                            name: 'Lumen',
                            title: 'Lumen',
                            selector: 'button'
                        });
                        // build object
                        self.vivaConfig.BasisAttributes = {};
                        self.vivaConfig.BasisAttributes.BasisAttribute = basisAttributes;
                    }


                    // MYPV-1731
                    // ----------------------------------------------------------------------
                } // config ok
            } // base articles

            self.fire('vivaActive' + self.trackingType, self.vivaActive);
            self.fire('vivaActive', self.vivaActive);
            if (!self.vivaActive) {
                return;
            }


            self.renderVIVA();

            if (!tcgConfig.tcgMode) {
                $('.smallButtonImage', $(self.myDOM)).css({
                    'font-size': '0'
                });
                $('.vivaOptionInfo', $(self.myDOM)).text(''); // remove character 'i'
            }


            // Hyphenator automatically inserts separators in the content
            Hyphenator.config({
                remoteloading: false,
                onwarninghandler: function() {},
                selectorfunction: function() {
                    return $('.smallButtonText', $(self.myDOM)).get();
                }
            });
            Hyphenator.run();
        },

        renderVIVA: function() {
            var self = this;

            self.cleanVIVAOptionGroup();
            self.appendVIVAFacets();
            self.appendVIVAConfigOptions();
            self.applyLogic();
            self.groupDimensionAttribsIntoCombos();

            $('.facetsearch', $(self.myDOM)).each(function() {
                switch ($('li', this).size()) {
                    case 0: // do not show group
                        $(this).addClass('off');
                        break;

                    case 1: // check for empty value
                        if ($('li', this).attr('data-viva-extraitemid') === '') { // hide the stuff
                            $(this).addClass('off');
                        }
                        if ($('li', this).hasClass('adjustable_off')) { // hide the stuff
                            $(this).addClass('off');
                        }
                        break;
                }
                $(this).after($('<div>').addClass('css_clearfix'));
            });

            $('.vivaOption', $(self.myDOM)).click(function() {
                var val = self.smallButtonClick(this, false);
                self.updateInfo();
                return val;
            });

            $('H5', $(self.myDOM)).click(function() {
                if (tcgConfig.tcgMode) {
                    $(this).toggleClass('eCAT_close');
                    $('ul', $(this).parent()).toggleClass('off');
                }
            });

            $('.vivaOptionInfo', $(self.myDOM)).click(function() {
                var og = $(this).closest('.vivaOptionGroup'),
                    eais = [];
                $('.vivaOption', $(og)).each(function() {
                    eais.push($(this).attr('data-viva-extraitemid'));
                });
                $('.facetitem', $(og)).each(function() {
                    eais.push($(this).attr('data-viva-extraitemid'));
                });
                self.fire('vivaExtraItemInfo', {
                    'LuminaireFamilyName': self.vivaConfig.LuminaireFamilyName,
                    'ExtraItemTypeId': $(this).attr('data-viva-extraitemtypeid'),
                    'elem': $(this),
                    'eais': eais
                });
                return false;
            });

            self.updateInfo();
        },

        cleanVIVAOptionGroup: function() {
            var self = this;
            $('.vivaOptionGroup', $('.vivaConfig', $(self.myDOM))).remove();
        },

        applyLogic: function() {
            var self = this;

            if (self.vivaActive) {
                if (typeof self.vivaConfig.Compatibility !== 'undefined' &&
                    typeof self.vivaConfig.Compatibility.Item !== 'undefined') {
                    // OnlyWith OW_, NotWith NW_
                    for (var i = 0; i < self.vivaConfig.Compatibility.Item.length; i++) {
                        var compatItem = self.vivaConfig.Compatibility.Item[i],
                            special = '';
                        if (compatItem.BasicTypeCode !== '*') {
                            special += '_' + compatItem.BasicTypeCode.toClassName();
                        }
                        if (compatItem.LuminaireBasisId !== '*') {
                            special += '_' + compatItem.LuminaireBasisId.toClassName();
                        }
                        // global
                        switch (compatItem.State) {
                            case 'NotWith':
                                $('[data-viva-id="' + compatItem.Id1 + '"]').addClass('NW_' + compatItem.Id2 + special);
                                $('[data-viva-id="' + compatItem.Id2 + '"]').addClass('NW_' + compatItem.Id1 + special);
                                break;
                            case 'OnlyWith':
                                // $('[data-viva-id="' + compatItem.Id1 + '"]').addClass('OW_'  + compatItem.Id2);
                                $('[data-viva-id="' + compatItem.Id2 + '"]').addClass('OW_' + compatItem.Id1 + special);
                                // 2016-11-08 fku - not needed any more (Aster, Lecher)
                                // if (($('[data-viva-id="' + compatItem.Id2 + '"]').size() === 0 &&
                                //         $('[data-viva-id="' + compatItem.Id1 + '"]').size() !== 0) ||
                                //         ($('[data-viva-id="' + compatItem.Id1 + '"]').size() === 0 &&
                                //         $('[data-viva-id="' + compatItem.Id2 + '"]').size() !== 0)) {
                                //     // we have an option with missing OnlyWith
                                //     debug('vivaConfig:applyLogic(' + // jshint ignore: line
                                //         compatItem.Id1 + '/' + compatItem.Id2 +
                                //         ') missing OnlyWith');
                                //     alert('VIVA invalid requirement: ' + $.toJSON(compatItem)); // jshint ignore: line
                                // }
                                break;
                            default:
                                throw ('undefined viva compatibility state: ' + compatItem.State);
                        }
                    } // all compatibility issues
                } // compatibility available

                // MustDefault MD_
                if (typeof self.vivaConfig.MustDefault !== 'undefined' &&
                    typeof self.vivaConfig.MustDefault.Item !== 'undefined') {
                    for (var i = 0; i < self.vivaConfig.MustDefault.Item.length; i++) { // jshint ignore: line
                        var mustDefaultItem = self.vivaConfig.MustDefault.Item[i],
                            special = ''; // jshint ignore: line

                        var mdGroup = $('.vivaOptionGroup' + mustDefaultItem.GroupId), // $('[id="' + mustDefaultItem.GroupId + '"]'),
                            mdItem = $('[data-viva-id="' + mustDefaultItem.DefaultItemId + '"]');

                        if (mustDefaultItem.LuminaireBasisId !== '*') { // special version for selecte luminaire types
                            special += '_' + mustDefaultItem.LuminaireBasisId.toClassName();

                            $(mdGroup).addClass('vivaMustDefault' + special);
                            $(mdItem).addClass('vivaMustDefault' + special);
                            if (mustDefaultItem.IsMandatory === '1') {
                                $(mdGroup).addClass('vivaMandatory' + special);
                            }
                        } else { // global edition
                            if (mustDefaultItem.IsMandatory === '1') {
                                $(mdGroup).attr('data-viva-mustdefault', 'true')
                                    .find('h5').append($('<span>').addClass('required off'));
                            }
                            // select default item
                            // 2013-11-06 avoid selection of a default item
                            if (0) {
                                $(mdItem).addClass('selected');
                            }
                        }

                        // 'MustDefault':
                        // $('[data-viva-id="'+mustDefaultItem.DefaultItemId+'"]').addClass('MD' +special);
                    } // all must default values
                } // must default available
            } // vivaConfig OK
        }, // applyLogic()

        /********************************************************************************************
         * Init functions block end
         ********************************************************************************************/

        /********************************************************************************************
         * Active functions block start
         ********************************************************************************************/
        actualFacetItem: '',

        appendNewSelect: true,
        diselect: false,
        openDropdown: false,

        afterFacetItemClick: function() {
            var self = this;
            debug('faceted search result update'); // jshint ignore: line

            $('a[data-viva-extraitemtypeid="Load"]').hide();

            // dict for basis attributes
            if (self.dict != null && typeof self.dict === 'object') {
                self.replaceTemplate(self.dict);
            }

            if (!tcgConfig.tcgMode) {
                $('.smallButtonImage', $('.vivaFacetsAttr', $(self.myDOM))).css({
                    'font-size': '0'
                });
            }

            $('.vivaFacetCount', $(self.myDOM)).each(function() {
                if ($(this).text() === '(0)') {
                    $(this).parent().addClass('disabled');
                } else if ($(this).text() === '(+)') {
                    // $(this).parent().addClass('disabled');   2020-09-07/ml enhance facet select
                    $(this).text('(+)'); // delete this line if line above is enabled
                } else {
                    $(this).parent().removeClass('disabled');
                }
            });

            self.selectMustDefaults();

            if (self.appendNewSelect === true) {
                $('.vivaAttr2', $('.vivaFacetsAttr', $(self.myDOM))).find('li').each(function() {
                    self.appendSelectValues(this, $('.vivaFacetsAttr', $(self.myDOM)));
                });

                if (self.openDropdown === true) {
                    self.openDropdown = false;
                    $('.vivaAttr2', $('.vivaFacetsAttr', $(self.myDOM))).find('li').each(function() {
                        if ($(this).hasClass('activefacet')) {
                            var select = $('.customSelect', $(this)),
                                dropdown = $('.customSelectDropdown', $(select));
                            $(dropdown).toggleClass('customSelectShowDropdown');
                        }
                    });
                }
            }

            self.updateAvailableOptions();

            self.updateInfo();
        },


        selectedValues: [],

        toggleCustomSelectDropdown: function(select) {
            var dropdown = $('.customSelectDropdown', $(select));
            $(dropdown).toggleClass('customSelectShowDropdown');
        },

        initAdjustable: function() {
            var self, item, facetContainer;
            self = this;
            facetContainer = $('.vivaFacetsAttr', $(self.myDOM));
            $('.vivaAttr1, .vivaAttr6', $(facetContainer)).find('li').each(function() {
                if ($(this).hasClass('activefacet')) {
                    item = this;
                }
            });

            var adjustableFacetItem, familyRanges, range;

            $('.vivaAttr2', $(facetContainer)).find('li').each(function() {
                if ($(this).attr('id') === 'adjustable') {
                    if ($(this).hasClass('notselected')) {
                        $(this).removeClass('notselected');
                    }
                    adjustableFacetItem = this;
                }
            });

            if (item && adjustableFacetItem) {
                // always set the rangeValue
                var value, lumen, prefix;
                value = $(item).attr('data-viva-extraitemid');

                familyRanges = global.adjustabelLumen[self.vivaConfig.LuminaireFamilyName];
                if (familyRanges) {
                    range = familyRanges[value];
                }

                if (value != null && range != null && self.bAuthenticated) {
                    // get lumen value from string
                    var splitValue = value.match(/\d+/g);
                    lumen = splitValue[splitValue.length - 1];

                    // set prefix attr
                    prefix = value.replace(lumen, '');
                    $(adjustableFacetItem).attr('prefix', prefix);
                    $('.rangeValue', $(adjustableFacetItem)).text(value);
                    $('.rangeValue', $(adjustableFacetItem)).attr('value', value);
                }

                if (range == null || !self.bAuthenticated) {
                    // hide adjustable and return
                    if (!$(adjustableFacetItem).hasClass('adjustable_off')) {
                        $(adjustableFacetItem).addClass('adjustable_off');
                    }
                } else {
                    if ($(adjustableFacetItem).hasClass('adjustable_off')) {
                        $(adjustableFacetItem).removeClass('adjustable_off');
                    }

                    // set range slider boundaries
                    $('#adjustableRange', $(adjustableFacetItem)).attr('min', lumen - range);
                    $('#adjustableRange', $(adjustableFacetItem)).attr('max', lumen);
                    $('#adjustableRange', $(adjustableFacetItem)).attr('value', lumen).change();
                    $('#adjustableRange', $(adjustableFacetItem)).val(lumen).change();

                    // set min and max limits
                    $('.maxLimit', $(adjustableFacetItem)).text(value);
                    $('.maxLimit', $(adjustableFacetItem)).attr('value', value);
                    var minLimit = prefix + (lumen - range).toString();
                    $('.minLimit', $(adjustableFacetItem)).text(minLimit);
                    $('.minLimit', $(adjustableFacetItem)).attr('value', minLimit);

                    // append input event listener
                    $('#adjustableRange', $(adjustableFacetItem)).on('input change', function() {
                        var actualValue, prefix;
                        actualValue = this.value;
                        prefix = $(this).closest('li').attr('prefix');

                        // set actual value
                        $('.rangeValue', $(this).closest('li')).attr('value', prefix + actualValue);
                        $('.rangeValue', $(this).closest('li')).text(prefix + actualValue);

                        self.updateInfo();
                    });
                }

                self.updateInfo();
            }
        },

        hideAdjustable: function(facetItem) {
            var adjustableFacet = $('#adjustable', $(facetItem).closest('ul'));

            if (!$(adjustableFacet).hasClass('adjustable_off')) {
                $(adjustableFacet).addClass('adjustable_off');
            }

            $('.rangeValue', $(adjustableFacet)).text('');
            $('.rangeValue', $(adjustableFacet)).attr('value', '');
        },

        clickOnCustomSelectDropdownItem: function(item, facetContainer) {
            var self = this;

            $('.vivaAttr1, .vivaAttr6', $(facetContainer)).find('li').each(function() {
                if ($(this).hasClass('activefacet')) {
                    $(this).trigger('click');
                }
            });

            if (!!$(item).attr('value')) {
                $('[data-viva-extraitemid="' + $(item).attr('value') + '"]').trigger('click');
            } else {
                var dropdown = $(item).closest('.customSelectDropdown');
                $(dropdown).toggleClass('customSelectShowDropdown');
            }

            self.initAdjustable();
        },

        appendSelectValues: function(item, facetContainer) {
            var self = this,
                value = '',
                select = '';

            if ($(item).hasClass('smallButton')) {
                if ($(item).hasClass('activefacet')) {
                    $('.vivaAttr1, .vivaAttr6', $(facetContainer)).find('li').each(function() {
                        if ($(this).hasClass('activefacet')) {
                            value = $(this).attr('data-viva-extraitemid');
                            self.appendNewSelect = false;
                            $(this).trigger('click');
                        }
                    });

                    self.selectedValues = [];
                    $('.vivaAttr1, .vivaAttr6', $(facetContainer))
                        .find('li:not(.disabled,.vivaNotAvailable)').each(function() {
                            self.selectedValues.push($(this).attr('data-viva-extraitemid'));
                        });

                    if (!!value) {
                        $('[data-viva-extraitemid="' + value + '"]').trigger('click');
                        self.appendNewSelect = true;
                        if ($(item).hasClass('customSelectNotActive')) {
                            $(item).removeClass('customSelectNotActive');
                        }
                    } else {
                        if (!$(item).hasClass('customSelectNotActive')) {
                            $(item).addClass('customSelectNotActive');
                        }
                    }

                    if (!$(item).hasClass('comboButton')) {
                        $(item).addClass('comboButton');
                    }

                    $(item).append($('<div class="customSelect" >'));
                    select = $('.customSelect', $(item));
                    $(select).click(function(e) {
                        e.stopPropagation();
                        self.toggleCustomSelectDropdown(this);
                    });

                    $(select).append($('<ul class="customSelectDropdown" >'));
                    var selectItemList = $('.customSelectDropdown', $(select));
                    $(selectItemList).append($('<li>')
                        .attr('value', '')
                        .text('').click(function(e) {
                            e.stopPropagation();
                            self.clickOnCustomSelectDropdownItem(this, facetContainer);
                        })
                    );
                    /* jshint loopfunc:true */
                    for (var i = 0; i < self.selectedValues.length; i++) {
                        $(selectItemList).append($('<li>')
                            .attr('value', self.selectedValues[i])
                            .text(self.selectedValues[i]).click(function(e) {
                                e.stopPropagation();
                                self.clickOnCustomSelectDropdownItem(this, facetContainer);
                            })
                        );
                    }

                    $(window).click(function(event) {
                        if (event.target.className.indexOf('customSelect') === -1 &&
                            event.target.className.indexOf('smallButton') === -1) {
                            var dropdowns = $('.customSelectDropdown', $(document));
                            for (var i = 0; i < $(dropdowns).length; i++) {
                                var openDropdown = $(dropdowns)[i];
                                if ($(openDropdown).hasClass('customSelectShowDropdown')) {
                                    $(openDropdown).removeClass('customSelectShowDropdown');
                                }
                            }
                        }
                    });

                    if (!!value) {
                        $(select).prepend($('<span class="smallButtonText">' + value + '' + '</span>'));
                    } else {
                        $(select).prepend($('<span class="smallButtonText">' + '  ' + '' + '</span>'));
                    }
                } else {
                    if (!$(item).hasClass('comboButton')) {
                        $(item).addClass('comboButton');
                    }

                    $(item).append($('<div class="customSelect">'));
                    select = $('.customSelect', $(item));
                    if ($(item).hasClass('disabled')) {
                        $(select).attr('disabled', true);
                        $(select).addClass('disabled');
                    }
                    $(select).click(function(e) {
                        e.stopPropagation();
                        self.clickOnNotActivSelect(this);
                        $(this).trigger('click');
                    });
                }

                var configuratorName = self.getIframeParentConfiguratorName();

                if (self.isIframe && configuratorName === 'mirel_ref' && !window.dataClickTriggered) {
                    window.dataClickTriggered = true;
                    var refurbishmentConfiguration = parent.window.possibleArticleConfig;

                    if (refurbishmentConfiguration) {
                        if (!!refurbishmentConfiguration.basisArticleId && !!this.vivaConfig.BasisArticles.BasisArticle) {
                            var basicArticle = _.find(this.vivaConfig.BasisArticles.BasisArticle, function(basicArticle) {
                                return basicArticle.ArticleId === refurbishmentConfiguration.basisArticleId;
                            });

                            if (basicArticle) {
                                var lampValue = basicArticle.Attr6;
                                $(select).trigger('click');
                                $('.customSelectDropdown > li[value="' + lampValue + '"]', $(item)).trigger('click');

                                var typeValue = basicArticle.Attr3;
                                $('[data-viva-name="' + typeValue + '"]').trigger('click');

                                var dimensionValue = basicArticle.Attr4;
                                $('[data-viva-name="' + dimensionValue + '"]').trigger('click');
                            }
                        }
                        setTimeout(function() {
                            if (!!refurbishmentConfiguration.LampIncluded && refurbishmentConfiguration.LampIncluded.length > 0) {
                                var lightColorValue = refurbishmentConfiguration.LampIncluded[0];

                                $('[data-viva-name="' + lightColorValue + '"]').trigger('click');
                            }

                            if (!!refurbishmentConfiguration.title) {
                                var lastSpaceIndex = refurbishmentConfiguration.title.lastIndexOf(' '),
                                    electronicValue = refurbishmentConfiguration.title.substring(lastSpaceIndex + 1);

                                $('[data-viva-name="' + electronicValue + '"]').trigger('click');
                            }
                        }, 500);
                    }
                }
            }
        },

        clickOnNotActivSelect: function(select) {
            var self = this,
                comboButton = $(select).closest('.comboButton');
            if (!comboButton.hasClass('activefacet')) {
                self.openDropdown = true;
                $(comboButton).trigger('click');
            }
        },

        beforeFacetItemClick: function(facetItem) {
            var self = this;

            // var facetItem  = $('li[data-viva-extraitemid=\'' + self.actualFacetItem +'\']');
            self.smallButtonClick(facetItem, true);
            // self.actualFacetItem = $(facetItem).attr('data-viva-extraitemid');

            if ($(facetItem).hasClass('disabled') || $(facetItem).hasClass('vivaNotAvailable')) {
                return false;
            }

            if ($(facetItem).closest('.vivaOptionGroup').hasClass('vivaAttr2') &&
                !$(facetItem).hasClass('activefacet')) {
                self.openDropdown = true;
            }

            if ($(facetItem).closest('.vivaOptionGroup').hasClass('vivaAttr2') &&
                $(facetItem).hasClass('activefacet')) {
                $('.vivaAttr1, .vivaAttr6', $('.vivaFacetsAttr', $(self.myDOM))).find('li').each(function() {
                    if ($(this).hasClass('activefacet')) {
                        $(this).trigger('click');
                    }
                });
            }
            return true;
        },



        smallButtonClick: function(smallButton) {
            var self = this;

            if ($(smallButton).hasClass('smallButton')) {
                if ($(smallButton).hasClass('disabled') || $(smallButton).hasClass('vivaNotAvailable') ||
                    $(smallButton).hasClass('preselected')) {
                    return false;
                }

                if ($(smallButton).hasClass('selected')) {
                    $(smallButton).toggleClass('selected');
                } else {
                    self.clickCnt++;

                    var group = $(smallButton).closest('.vivaOptionGroup');

                    // 2020-09-07/ml enhance facet select

                    // unselect current button
                    $('.smallButton.selected', $(group)).trigger('click');

                    // 2020-09-07/ml enhance facet select

                    ecatUI._trackEventNT(['_trackEvent', 'Productconfiguration', 'Selected Option',
                        $(group).attr('data-viva-name') + ':' + $(smallButton).attr('data-viva-name')
                    ]);

                    if ($(smallButton).hasClass('notselected')) {
                        $('li.selected', $(smallButton).parent()).removeClass('selected');
                        $(smallButton).removeClass('notselected');
                    }

                    $(smallButton).toggleClass('selected');
                }

                if ($(smallButton).hasClass('selected')) {
                    $('li:not(.selected)', $(smallButton).parent()).addClass('notselected');
                } else {
                    $('li', $(smallButton).parent()).removeClass('notselected');
                }

                // // select all other  OnlyWith Items
                $('.selected[data-viva-id]', $(self.myDOM)).each(function() {
                    var selItem = $(this),
                        owClass = self.getOWNWClasses('.OW_' + $(selItem).attr('data-viva-id'));

                    $('.vivaOptionGroup', $(self.myDOM)).each(function() {
                        var group = $(this),
                            vivaOW = $(owClass, $(group));
                        if ($(vivaOW).filter('.selected').size() === 0 && $('.selected', $(group)).size() === 0) { // select first available
                            $(vivaOW).filter(':not(.disabled)').first().addClass('selected');
                        }
                    });
                });


                // ensure correct NotWith itemes to be disabled
                $('[data-viva-id]', $(self.myDOM)).removeClass('disabled');
                $('.selected[data-viva-id]', $(self.myDOM)).each(function() {
                    var vivaNW = $(self.getOWNWClasses('.NW_' + $(this).attr('data-viva-id')));
                    $(vivaNW).addClass('disabled');
                });

                self.selectMustDefaults();
                self.disableNotWithItems();
                self.updateAvailableOptions();
            }
        },

        selectMustDefaults: function() {
            var self = this,
                classMD = self.getMDClasses('.vivaMustDefault'),
                vivaMD = $(classMD, $(self.myDOM));

            $(vivaMD).each(function() {
                if ($(this).hasClass('vivaOptionGroup') && $('.selected', $(this)).size() === 0 &&
                    $('.vivaNotAvailable', $(classMD, $(this))).size() === 0) { // select li item
                    $(classMD, $(this)).addClass('selected'); // select default option for selected luminaire
                }
            });
        },

        disableNotWithItems: function() {
            // ensure correct NotWith itemes to be disabled
            var self = this;
            $('[data-viva-id]').removeClass('disabled');
            $('.selected[data-viva-id]').each(function() {
                var vivaNW = $(self.getOWNWClasses('.NW_' + $(this).attr('data-viva-id')));
                $(vivaNW).addClass('disabled');
            });
        },

        selectChange: function(select, facetContainer) {
            // var self = this;

            $('.vivaAttr1, .vivaAttr6', $(facetContainer)).find('li').each(function() {
                if ($(this).hasClass('activefacet')) {
                    $(this).trigger('click');
                }
            });

            $('[data-viva-extraitemid="' + $(select).val() + '"]').trigger('click');
        },

        updateInfo: function() {
            var self = this,
                infoItem = {
                    prodName: '', // product name
                    prodNameOrig: '',
                    family: '', // viva family
                    configOK: true,
                    baseArticle: '', // base article # if cfg = OK
                    basicTypeCode: '',
                    LuminaireBasisId: '',
                    deliveryTimeCode: '',
                    options: [],
                    optionsOrig: [],
                    message: [],


                    // ABC calculation stuff
                    deliveryTimes: '', // delivery time string from viva config represents order of delivery times

                    initDeliveryTime: function(dtc) {
                        this.deliveryTimes = dtc;
                    },

                    /**
                     * calculate availability letter for two options
                     *
                     * @param d1
                     * @param d2
                     * @returns least availabable option
                     */
                    deliveryTimeMerge: function(d1, d2) {
                        return this.deliveryTimes.indexOf(d1) > this.deliveryTimes.indexOf(d2) ? d1 : d2;
                    },


                    setDeliveryTimeCode: function(abc) {
                        // FIXME use strict equality, if possible (verify, before fixing!!)
                        // jshint -W116
                        if (this.deliveryTimeCode == '') {
                            // jshint +W116
                            this.deliveryTimeCode = abc;
                        } else {
                            this.deliveryTimeCode = this.deliveryTimeMerge(this.deliveryTimeCode, abc);
                        }
                    },

                    getDeliveryTimeCode: function(abc) {
                        if (abc == null) {
                            return this.deliveryTimeCode;
                        }

                        // FIXME use strict equality, if possible (verify, before fixing!!)
                        // jshint -W116
                        if (this.deliveryTimeCode == '') {
                            // jshint +W116
                            return abc;
                        }
                        return this.deliveryTimeMerge(this.deliveryTimeCode, abc);
                    }
                };

            if (!self.vivaActive) {
                return;
            }

            infoItem.family = self.vivaConfig.LuminaireFamilyName;
            infoItem.initDeliveryTime(self.vivaConfig.DeliveryTimes);


            var optNames = [];

            $('.vivaOptionGroup', $(self.myDOM)).filter(':not(.facetsearch)').find('li.selected').each(function() {
                optNames.push($(this).attr('data-viva-name'));
                infoItem.setDeliveryTimeCode($(this).attr('data-viva-deliverytimecode'));
            });


            // try to disable not available facet items for the selected options
            $('.facetitem', $(self.myDOM)).removeClass('vivaNotAvailable'); // enable all

            if (optNames.length > 0) {
                var arrAvailableLuminaireBasisId = [];
                for (var i = 0; i < optNames.length; i++) {
                    var arrAvailableLuminaireBasisId4Opt = _.uniq(_.pluck(_.where(
                        self.vivaConfig.AvailableOptions.Item, {
                            ItemCode: optNames[i]
                        }), 'LuminaireBasisId'));
                    if (i === 0) {
                        arrAvailableLuminaireBasisId = arrAvailableLuminaireBasisId4Opt;
                    } else {
                        arrAvailableLuminaireBasisId = _.intersection(arrAvailableLuminaireBasisId,
                            arrAvailableLuminaireBasisId4Opt);
                    }
                }

                // var arrAvailOptions=_.filter(self.vivaConfig.AvailableOptions.Item, function(avo) {
                //     return _.contains(opts, avo.ItemCode);
                // });

                if (true || arrAvailableLuminaireBasisId.length !== 0) {
                    // var arrAvailableLuminaireBasisId=_.uniq(_.pluck(arrAvailOptions, 'LuminaireBasisId'));
                    var arrAvailableBasisArticles = _.filter(self.vivaConfig.BasisArticles.BasisArticle, function(ba) {
                        return _.contains(arrAvailableLuminaireBasisId, ba.LuminaireBasisId);
                    });


                    for (var i = 1; i < 6 + 1; i++) { // jshint ignore: line
                        var arrUnavailAttr = _.difference(_.uniq(_.pluck(self.vivaConfig.BasisArticles.BasisArticle,
                                'Attr' + i)),
                            _.uniq(_.pluck(arrAvailableBasisArticles, 'Attr' + i)));
                        _.each(arrUnavailAttr, function(val) { // jshint ignore: line
                            if (typeof val === 'string') {
                                var domUnavail = $('[data-viva-extraitemid="' + val + '"]:first:not(.selected)', $(self.myDOM)),
                                    numAvailInGroup = $(domUnavail).parent()
                                    .find('[data-viva-extraitemid]:not(.disabled)').length;
                                if (numAvailInGroup > 1) { // do not make unavailable  the last item in group
                                    $(domUnavail).addClass('vivaNotAvailable'); // 2015-03/ml
                                }
                                // only
                                // :first
                                // - for
                                // lamp
                                // selector
                            }
                        }); // jshint ignore: line
                    }
                }
            } // we have selected option

            // try to disable not available facet items for the selected options

            // Basic Type Code Check
            var elemBasicTypeCode = $('li.selected', $('.vivaBasicTypeCode', $(self.myDOM)));
            if ($(elemBasicTypeCode).size() === 0) {
                elemBasicTypeCode = $('li:not(.disabled)', $('.vivaBasicTypeCode', $(self.myDOM)));
            }

            switch ($(elemBasicTypeCode).size()) {
                case 0:
                    infoItem.configOK = false;
                    infoItem.message.push({
                        id: 'noBasicTypeCode',
                        value: ''
                    });
                    break;

                case 1: // OK
                    infoItem.basicTypeCode = $(elemBasicTypeCode).attr('data-viva-extraitemid');
                    break;

                default:
                    var baList = [];
                    $(elemBasicTypeCode).each(function() {
                        baList.push($(this).text());
                    });
                    infoItem.configOK = false;
                    infoItem.message.push({
                        id: 'multipleBasicTypes',
                        value: baList.join(', ')
                    });
            }

            // autoselect, options info

            var optionMissing = false;

            $('.vivaOptionGroup', $(self.myDOM)).each(function() {
                $('.smallButton.disabled', $(this)).removeClass('selected');

                var itemSelected = $('li.smallButton.selected', $(this)),
                    selectionOK = $(itemSelected).size() === 1,
                    groupTitle = $('h5>span:first', $(this)).text();
                // groupTitle = groupTitle.substr(0, groupTitle.length - 1);
                switch ($(itemSelected).size()) {
                    case 0:
                        $('li.smallButton', $(this)).removeClass('notselected');
                        break;

                    case 1:
                        $('li.smallButton:not(.selected)', $(this)).addClass('notselected');
                        break;

                    default:
                        throw ('error, multiple options selected in ' + groupTitle);
                }

                if (!($(this).hasClass('off') || $(this).hasClass('disabled') || $(this).hasClass('vivaBasicTypeCode') ||
                        $(this).hasClass('vivaLuminaireBasisId') || $(this).hasClass('vivaLuminaireFamilyCode'))) {
                    var vivaOption = {
                        title: groupTitle,
                        id: $(this).attr('data-viva-extraitemtypeid'),
                        must: $(this).attr('data-viva-mustdefault') === 'true',
                        value: selectionOK ? $(itemSelected).attr('data-viva-smlcode') : '',
                        eid: selectionOK ? $(itemSelected).attr('data-viva-id') : '',
                        text: selectionOK ? $('.smallButtonText', itemSelected).text() : '',
                        smlCode: selectionOK ? $(itemSelected).attr('data-viva-name') : '',
                        smlName: selectionOK ? $(this).attr('data-viva-name') : '',
                        is4name: $(this).attr('data-viva-not4name') === 'true' ? false : true, // just reverse it
                        hide: $(this).attr('data-viva-mustdefault') === 'hide'
                    };

                    // FIXME use strict equality, if possible (verify, before fixing!!)
                    // jshint -W116
                    if (vivaOption.must && vivaOption.value == '' && typeof(vivaOption.eid) != null &&
                        vivaOption.eid != '') { // 2015-03 avoid facet's to be taken into account
                        // jshint +W116
                        infoItem.configOK = false;
                        infoItem.message.push({
                            id: 'requiredOptionMissing',
                            value: vivaOption.id
                        });
                        console.log('requiredOptionMissing: ', vivaOption.id);
                    }

                    // iso7010 extension
                    if ($(this).attr('data-viva-config-type') === 'ISO7010-Signs') { // place specials here
                        vivaOption.value = $(this).attr('data-viva-iso7010-code');

                        if (vivaOption.smlCode !== '' && vivaOption.value === '') { // no escape sign selected
                            infoItem.configOK = false;
                            infoItem.message.push({
                                id: 'requiredOptionMissing',
                                value: vivaOption.id
                            });
                            console.log('ISO7010: no signs selected.', vivaOption.smlCode);
                            $('h5>span:first', $(this)).addClass('mustNotSelected');
                            optionMissing = true;
                        } else {
                            $('h5>span:first', $(this)).removeClass('mustNotSelected');
                        }

                        // vivaOption.smlName = $(this).attr('data-viva-name');
                        vivaOption.eid = 'I';
                        vivaOption.iso7010 = $(this).attr('data-viva-iso7010-isviva');

                        if (_.has(self.vivaConfig.FamilyProperties, 'ISO7010Photo') &&
                            self.vivaConfig.FamilyProperties.ISO7010Photo === 'yes') {
                            if ($(this).is('[data-viva-photo-src]')) { // image specified
                                infoItem.photo = $(this).attr('data-viva-photo-src');
                            }
                        }
                    } // iso7010 extension
                    else {
                        // custom select lumen not selected
                        var customSelectNotActive = $(itemSelected).hasClass('customSelectNotActive');

                        if (customSelectNotActive) {
                            $('h5>span:first', $(this)).addClass('mustNotSelected');
                            optionMissing = true;
                        } else if (!vivaOption.text && vivaOption.must) {
                            $('h5>span:first', $(this)).addClass('mustNotSelected');
                            optionMissing = true;
                        } else {
                            $('h5>span:first', $(this)).removeClass('mustNotSelected');
                        }
                    }


                    // dynamic extension (outdoor)
                    if ($(this).attr('data-viva-config-type') === 'dynamic') { // place specials here
                        vivaOption.smlCode = $(this).attr('data-viva-smlcode');
                    }
                    // dynamic extension (outdoor)

                    infoItem.optionsOrig.push(vivaOption);

                    // override the basis code if the adjustable value is set -- just a temporary solution
                    var custumSelect, smallButtonText;
                    custumSelect = $('.customSelect', itemSelected);
                    smallButtonText = $('.smallButtonText', custumSelect).text();
                    if (vivaOption.id === 'Attr2' && $('.rangeValue', $('#adjustable')).attr('value')) {
                        vivaOption.text = vivaOption.text.replace(smallButtonText,
                            $('.rangeValue', $('#adjustable')).attr('value'));
                    }

                    if ((vivaOption.id === 'Attr1' || vivaOption.id === 'Attr6') &&
                        $('.rangeValue', $('#adjustable')).attr('value')) {
                        vivaOption.text = $('.rangeValue', $('#adjustable')).attr('value');
                    }

                    if ($(itemSelected).size() === 1) {
                        var extraItemId = $(itemSelected).attr('data-viva-id');

                        if (extraItemId && self.vivaConfig.Compatibility && self.vivaConfig.Compatibility.Item) {
                            var filtered = _.filter(self.vivaConfig.Compatibility.Item, function(item) {
                                return item.Id1 === extraItemId && item.State === 'OnlyWith' &&
                                    (item.BasicTypeCode === '*' || item.BasicTypeCode === infoItem.basicTypeCode);
                            });

                            if (filtered.length > 0) {
                                var isSelected = _.some(filtered, function(extraItemId) {
                                    return $('[data-viva-id="' + extraItemId.Id2 + '"]').hasClass('selected');
                                });

                                if (!isSelected) {
                                    infoItem.configOK = false;
                                }
                            }
                        }
                    }

                    infoItem.options.push(vivaOption);
                }
            });

            var anythingSelected = _.some(infoItem.options, function(option) {
                return option.text;
            });

            if (!anythingSelected) {
                self.removeAllRequiredMarks();
                infoItem.configOK = false;
            }

            // Luminaire Family Code Check
            var elemLuminaireFamilyCode = $('li.selected', $('.vivaLuminaireFamilyCode', $(self.myDOM)));
            if ($(elemLuminaireFamilyCode).size() === 0) {
                elemLuminaireFamilyCode = $('li:not(.disabled)', $('.vivaLuminaireFamilyCode', $(self.myDOM)));
            }

            switch ($(elemLuminaireFamilyCode).size()) {
                case 0:
                    infoItem.configOK = false;
                    infoItem.message.push({
                        id: 'noLuminaireFamilyCode',
                        value: ''
                    });
                    break;

                case 1: // OK
                    infoItem.family = $(elemLuminaireFamilyCode).attr('data-viva-extraitemid');

                    break;

                default:
                    var faList = [];
                    $(elemLuminaireFamilyCode).each(function() {
                        faList.push($(this).text());
                    });
                    infoItem.configOK = false;
                    infoItem.message.push({
                        id: 'multipleLuminaireFamilyCodes',
                        value: faList.join(', ')
                    });
            }

            // Luminaire Basis Code Check
            var elemLuminaireBasisId = $('li.selected', $('.vivaLuminaireBasisId', $(self.myDOM)));
            if ($(elemLuminaireBasisId).size() === 0) {
                elemLuminaireBasisId = $('li:not(.disabled)', $('.vivaLuminaireBasisId', $(self.myDOM)));
            }

            switch ($(elemLuminaireBasisId).size()) {
                case 0:
                    infoItem.configOK = false;
                    infoItem.message.push({
                        id: 'noLuminaireBasisId',
                        value: ''
                    });
                    break;

                case 1: // OK
                    infoItem.LuminaireBasisId = $(elemLuminaireBasisId).attr('data-viva-extraitemid');
                    break;

                default:
                    var baList = []; // jshint ignore: line
                    $(elemLuminaireBasisId).each(function() {
                        baList.push($(this).text());
                    });
                    infoItem.configOK = false;
                    infoItem.message.push({
                        id: 'multipleLuminaireBasisId',
                        value: baList.join(', ')
                    });
            }

            if ($(elemLuminaireBasisId).size() === 1 && anythingSelected) {
                $('.vivaOptionGroup', $(self.myDOM)).each(function() {
                    var itemSelected = $('.smallButton.selected', $(this)),
                        selectionOK = $(itemSelected).size() === 1,
                        vivaOption = {
                            text: $('.smallButtonText', itemSelected).text(),
                            value: selectionOK ? $(itemSelected).attr('data-viva-smlcode') : '',
                            isMandatory: $(this).hasClass('vivaMandatory_' + infoItem.LuminaireBasisId),
                            isISO7010: $(this).attr('data-viva-config-type') === 'ISO7010-Signs',
                            iso7010Code: $(this).attr('data-viva-iso7010-code')
                        };

                    if ((!vivaOption.text && !vivaOption.value) && vivaOption.isMandatory) {
                        $('h5>span:first', $(this)).addClass('mustNotSelected');
                    } else if (selectionOK && vivaOption.isISO7010 === true &&
                        vivaOption.iso7010Code === '') { // no ISO code but slected ExtraItem
                        $('h5>span:first', $(this)).addClass('mustNotSelected');
                    } else {
                        $('h5>span:first', $(this)).removeClass('mustNotSelected');
                    }
                });
            }

            // Base Article Check
            var baseArticle = $('li', $('.vivaFacetsResult', $(self.myDOM)));

            switch ($(baseArticle).size()) {
                case 0:
                    infoItem.configOK = false;
                    infoItem.message.push({
                        id: 'noBaseArticle',
                        value: ''
                    });
                    break;

                case 1: // OK
                    infoItem.baseArticle = $(baseArticle).text();
                    break;

                default:
                    var baList = []; // jshint ignore: line
                    $(baseArticle).each(function() {
                        baList.push($(this).text());
                    });
                    infoItem.configOK = false;
                    infoItem.message.push({
                        id: 'multipleBaseArticle',
                        value: baList.join(', ')
                    });
            }

            if (optionMissing) {
                infoItem.configOK = false;
            }

            var options = [],
                sorted = [];

            if (infoItem.configOK) {
                var prodNameList = [],
                    prodNameOrigList = [],
                    basisCode;

                prodNameList.push(infoItem.basicTypeCode);
                prodNameOrigList.push(infoItem.basicTypeCode);

                infoItem.LuminaireBasis = _.first(_.where(self.vivaConfig.LuminaireBases.LuminaireBasis, {
                    'LuminaireBasisId': infoItem.LuminaireBasisId
                }));

                prodNameOrigList.push(infoItem.LuminaireBasis.BasisCode);

                // override the basis code if the adjustable value is set -- just a temporary solution
                if ($('.rangeValue', $('#adjustable')).attr('value')) {
                    basisCode = $('.rangeValue', $('#adjustable')).attr('value');
                } else {
                    basisCode = infoItem.LuminaireBasis.BasisCode;
                }

                prodNameList.push(basisCode);

                options = infoItem.options.filter(function(option) {
                    return option.smlName;
                });

                sorted = _.sortBy(self.vivaConfig.EAIT, function(option) {
                    return option.OrderingValue;
                });

                sorted.forEach(function(option) {
                    var infoItemOption = _.find(options, function(item) {
                        return option.SmlName === item.smlName;
                    });

                    if (infoItemOption && infoItemOption.is4name) {
                        prodNameList.push(infoItemOption.value);
                        prodNameOrigList.push(infoItemOption.value);
                    }
                });

                // for (var i = 0; i < infoItem.options.length; i++) { // jshint ignore: line
                //     // FIXME use strict equality, if possible (verify, before fixing!!)
                //     // jshint -W116
                //     if (infoItem.options[i].value != '' && infoItem.options[i].id.indexOf('Attr') === -1) {
                //         // jshint +W116
                //         prodNameList.push(infoItem.options[i].value);
                //     }
                // } // all info items

                infoItem.prodName = prodNameList.join(' ').replace(' -', '-');
                infoItem.prodNameOrig = prodNameOrigList.join(' ');

                self.removeAllRequiredMarks();
            }

            if (!optionMissing && !infoItem.configOK) {
                infoItem.wrongData = true;
            }

            var opts = self.addOptions(options, sorted, infoItem);

            // detect all selected viva option
            // var opts = [];
            // for (var i = 0; i < infoItem.options.length; i++) { // jshint ignore: line
            //     // if (infoItem.options[i].value != '' && infoItem.options[i].id.indexOf('Attr')==-1)
            //     //     searchPart2List.push(infoItem.options[i].value);
            //
            //     // FIXME use strict equality, if possible (verify, before fixing!!)
            //     // jshint -W116
            //     if (typeof infoItem.options[i].eid !== 'undefined' && infoItem.options[i].value != '') {
            //         // jshint +W116
            //         opts.push(infoItem.options[i].value);
            //     }
            // }

            var optsText = '';
            if (opts.length > 0) {
                optsText = ' + ' + opts.join(' + ');
            }
            infoItem.coding = infoItem.baseArticle + optsText;

            self.iFrameLogic(infoItem);

            self.fire('vivaInfo', infoItem);
        },

        addOptions: function(options, sorted, infoItem) {
            var self = this,
                opts = [];

            options = infoItem.options.filter(function(option) {
                return option.smlName;
            });

            sorted = _.sortBy(self.vivaConfig.EAIT, function(option) {
                return option.OrderingValue;
            });

            sorted.forEach(function(option) {
                var infoItemOption = _.find(options, function(item) {
                    return option.SmlName === item.smlName;
                });

                if (infoItemOption) {
                    opts.push(infoItemOption.value);
                }
            });

            return opts;
        },

        iFrameLogic: function(infoItem) {
            var self = this;

            if (self.isIframe) {
                var configuratorName = self.getIframeParentConfiguratorName();
                self.getIframeParentWindow().trigger(configuratorName + ':' + configuratorName + 'ArticleChanged', infoItem);
                window.parent.dispatchEvent(new CustomEvent('modal:articleChanged', {
                    detail: infoItem
                }));

                var data = {};
                if (infoItem.configOK) {
                    data.prodName = infoItem.prodName;
                    data.enabled = true;
                    self.getIframeParentWindow().trigger(configuratorName + ':manageAddToConfigButton', data);
                    window.parent.dispatchEvent(new CustomEvent('modal:manageAddToConfigButton', {
                        detail: data
                    }));
                } else {
                    data.enabled = false;
                    self.getIframeParentWindow().trigger(configuratorName + ':manageAddToConfigButton', data);
                    window.parent.dispatchEvent(new CustomEvent('modal:manageAddToConfigButton', {
                        detail: data
                    }));
                }
            }
        },

        getIframeParentWindow: function() {
            return window.parent.$(window.parent.document);
        },

        getIframeParentConfiguratorName: function() {
            var self = this;

            return self.getIframeParentWindow().find('[data-configurator]').data('configurator');
        },

        removeAllRequiredMarks: function() {
            var self = this;
            $('.vivaOptionGroup', $(self.myDOM)).each(function() {
                $('h5>span:first', $(this)).removeClass('mustNotSelected');
            });
        },

        updateAvailableOptions: function() {
            var self = this,
                visibleOptions = [];

            $('.vivaOption', $(self.myDOM)).each(function() {
                visibleOptions.push($(this).attr('data-viva-id')); // was: data-viva-name
                $(this).removeClass('vivaNotAvailable');
            });

            var availableOptions = _.uniq(_.pluck(self.getAvailableOptions(false), 'Id'), false), // was: ItemCode
                options2hide = _.difference(visibleOptions, availableOptions);

            _.each(options2hide, function(elem) {
                $('.vivaOption[data-viva-id="' + elem + '"]', $(self.myDOM)).addClass('vivaNotAvailable');
                // was: data-viva-name
            });


            $('.vivaOptionGroup', $(self.myDOM)).each(function() {
                var og = $(this);

                $('.selected.vivaNotAvailable', $(og)).each(function() {
                    debug('vivaConfig:updateAvailableOption(' + // jshint ignore: line
                        $(this).attr('data-viva-name') +
                        '): already selected, but no longer available!');
                    $(this).removeClass('selected');
                });
                $(og).trigger('viva-option-change');
            });

            return true;
        },


        // generate class names to enable/disable other items
        // .OW_NT1, OW_NT1_TECTONIP, OW_NT1_530, OW_NT1_TECTONIP_530 ... with all permuations!!!
        getOWNWClasses: function(prefix) {
            var self = this;

            // the code
            var elemBasicTypeCode = $('li.selected', $('.vivaBasicTypeCode', $(self.myDOM)));
            if ($(elemBasicTypeCode).size() === 0) {
                elemBasicTypeCode = $('li:not(.disabled)', $('.vivaBasicTypeCode', $(self.myDOM)));
            }
            var elemLuminaireBasisId = $('li.selected', $('.vivaLuminaireBasisId', $(self.myDOM)));
            if ($(elemLuminaireBasisId).size() === 0) {
                elemLuminaireBasisId = $('li:not(.disabled)', $('.vivaLuminaireBasisId', $(self.myDOM)));
            }


            var special = [];
            // basic type code und luminaire basis permutieren\

            special.push(prefix);

            $(elemBasicTypeCode).each(function() {
                var btc = $(this).attr('data-viva-extraitemid');
                special.push(prefix + '_' + btc.toClassName());
                $(elemLuminaireBasisId).each(function(j) {
                    var lbi = $(this).attr('data-viva-extraitemid');
                    if (j === 0) {
                        special.push(prefix + '_' + lbi.toClassName());
                    }

                    special.push(prefix + '_' + btc.toClassName() + '_' + lbi.toClassName());
                });
            });

            return special.join(',');
        },

        // generate class names to enable/disable other items
        // .MD_345, ...
        getMDClasses: function(prefix) {
            var self = this;

            // the code
            var elemLuminaireBasisId = $('li.selected', $('.vivaLuminaireBasisId', $(self.myDOM)));
            if ($(elemLuminaireBasisId).size() === 0) {
                elemLuminaireBasisId = $('li:not(.disabled)', $('.vivaLuminaireBasisId', $(self.myDOM)));
            }

            if ($(elemLuminaireBasisId).size() === 1) {
                var lbi = $(elemLuminaireBasisId).attr('data-viva-extraitemid');
                return prefix + '_' + lbi.toClassName();
            }
            return prefix;
        },

        /********************************************************************************************
         * Active functions block end
         ********************************************************************************************/

        /********************************************************************************************
         * Facets functions block start
         ********************************************************************************************/

        appendVIVAFacets: function() {
            var self = this;


            if (self.vivaActive) {
                self.facetSettings = {
                    state: {
                        orderBy: false,
                        filters: {}
                    },
                    items: self.activeBaseArticles, // vivaConfig.BasisArticles.BasisArticle,
                    facets: {
                        'LuminaireFamilyCode': 'FamilyCD',
                        'BasicTypeCode': 'TypeCD',
                        'Attr2': 'Lamp',
                        'Attr1': 'Load',
                        'Attr6': 'Lumen',
                        'Attr3': 'Type',
                        'Attr4': 'Dimension',
                        'Attr5': 'Lighting',
                        'LuminaireBasisId': 'BasID'
                    },
                    facetTitleTemplate: '<h5 class="facettitle"><span ' +
                        'alt="CAT_viva_<%= title %>"><%= title %></span><a ' +
                        'data-viva-extraitemtypeid="<%= title %>" class="vivaOptionInfo">i</a> <span ' +
                        'class="required off">required</span></h5>' +
                        '<div class="vivaInfoText  off">' + self.textSelectLampFirst + '</div>',
                    facetContainer: '<div class="facetsearch vivaOptionGroup viva<%= id %>" ' +
                        'id=<%= id %> data-viva-attr="<%= id %>" data-viva-extraitemtypeid="<%= id %>" ' +
                        'data-viva-mustdefault="true"></div>',
                    resultSelector: $('.vivaFacetsResult', $(self.myDOM)),
                    facetSelector: $('.vivaFacetsAttr', $(self.myDOM)),
                    resultTemplate: '<li class = facetresultbox><%= ArticleId %></li>',
                    orderByOptions: {
                        'ArticleId': 'OrderNr',
                        'RANDOM': 'Random'
                    },
                    enablePagination: false,
                    facetListContainer: '<ul class="smallButtonList"></ul>',
                    listItemTemplate: '<li class="smallButton facetitem" id="<%= id %>"' +
                        'data-viva-extraitemid="<%= name %>" data-viva-name="<%= name %>"><span ' +
                        'class="smallButtonImage vivaFacetCount" <%= image %>>' +
                        '(<%= count %>)</span><span class="smallButtonText" lang="' + self.lang +
                        '"><%= text %></span> </li>',
                    adjustableItemTemplate: '<li class="facetitem_adjustable adjustable_off" id="adjustable">' +
                        '<div class="slidecontainer">' +
                        '<input min="51" max="70" value="65" class="slider" id="adjustableRange" type="range">' +
                        '<span class="rangeLimits minLimit">12L51</span>' +
                        '<span class="rangeLimits maxLimit">12L70</span>' +
                        '</div>' +
                        '<div class="rangeValue">12L65</div>' +
                        '</li>',
                    beforeClick: function() {
                        return self.beforeFacetItemClick(this);
                    }
                };

                if (!global.configuration) {
                    return;
                }

                var currentType = global.configuration.level2.filter(function(type) {
                    return type.familyName === self.vivaConfig.LuminaireFamilyName;
                });

                if (currentType.length > 0) {
                    self.facetSettings.facets = {
                        'LuminaireFamilyCode': 'FamilyCD',
                        'BasicTypeCode': 'TypeCD',
                        'Attr1': 'Load',
                        'LuminaireBasisId': 'BasID',
                        'Attr6': 'Lumen'
                    };

                    currentType[0].baseAttributeOrder.forEach(function(baseAttribute) {
                        if (baseAttribute === 'Dimension') {
                            self.facetSettings.facets.Attr4 = baseAttribute;
                        } else if (baseAttribute === 'Type') {
                            self.facetSettings.facets.Attr3 = baseAttribute;
                        } else if (baseAttribute === 'Lamp') {
                            self.facetSettings.facets.Attr2 = baseAttribute;
                        } else if (baseAttribute === 'Lighting') {
                            self.facetSettings.facets.Attr5 = baseAttribute;
                        }
                    });
                }

                // ---------------------------------------------------------------------
                // MYPV-1731
                // apply translations on facet group title
                if (_.has(self.vivaConfig, 'BasisAttributes') && _.has(self.vivaConfig.BasisAttributes, 'BasisAttribute')) {
                    _.each(self.facetSettings.facets, function(value, key, obj) {
                        var attr = _.findWhere(self.vivaConfig.BasisAttributes.BasisAttribute, {id: key});
                        if (_.isObject(attr)) {
                            obj[key] = attr.title;
                        }
                    });
                }
                // MYPV-1731
                // ---------------------------------------------------------------------
                // jscs:disable requireCapitalizedConstructors
                self.facetserach = new facetserach();
                self.facetserach.facetelize(self.facetSettings);
            }
        },

        /********************************************************************************************
         * Facets functions block end
         ********************************************************************************************/

        /********************************************************************************************
         * Options functions block start
         ********************************************************************************************/

        /*
         * This function appends Options based on the vivaConfig.EAIT entries
         */
        appendVIVAConfigOptions: function() {
            var self = this;

            self.getAvailableOptions(true);

            if (self.vivaActive) {
                var ui = $('.vivaConfigOptions', $(self.myDOM));
                $(ui).html('');

                var currentType = global.configuration.level2.filter(function(type) {
                    return type.familyName === self.vivaConfig.LuminaireFamilyName;
                });

                if (currentType.length > 0) {
                    currentType[0].optionsOrder.reverse().forEach(function(option) {
                        var eaitItems = self.vivaConfig.EAIT.filter(function(eait) {
                            return eait.SmlName === option;
                        });

                        if (eaitItems.length > 0) {
                            self.vivaConfig.EAIT.unshift(self.vivaConfig.EAIT
                                .splice(self.vivaConfig.EAIT.indexOf(eaitItems[0]), 1)[0]);
                        }
                    });
                }

                for (var i = 0; i < self.vivaConfig.EAIT.length; i++) {
                    self.appendOptionGroup(ui, self.vivaConfig.EAIT[i]);
                }

                // iso7010
                if (_.isFunction(self.vivaISO7010Extension)) {
                    self.vivaISO7010Extension(ui);
                }
            }
        },

        /*
         * This function is searching for availableOptions in the #BasicTypeCode's and
         * in the #LuminaireBasisId's
         */
        getAvailableOptions: function(init) {
            var self = this,
                elemBasicTypeCode = $('li.selected', $('.vivaBasicTypeCode', $(self.myDOM)));

            // default when page is fresh loaded
            if ($(elemBasicTypeCode).size() === 0) {
                elemBasicTypeCode = $('li:not(.disabled)', $('.vivaBasicTypeCode', $(self.myDOM)));
            }

            var elemLuminaireBasisId = $('li.selected', $('.vivaLuminaireBasisId', $(self.myDOM)));

            // default when page is fresh loaded
            if ($(elemLuminaireBasisId).size() === 0) {
                elemLuminaireBasisId = $('li:not(.disabled)', $('.vivaLuminaireBasisId', $(self.myDOM)));
            }


            var BasicTypeCode = [];
            $(elemBasicTypeCode).each(function() {
                BasicTypeCode.push($(this).attr('data-viva-extraitemid'));
            });

            var LuminaireBasisId = [];
            $(elemLuminaireBasisId).each(function() {
                LuminaireBasisId.push($(this).attr('data-viva-extraitemid'));
            });


            var activeOptions = _.filter(self.vivaConfig.AvailableOptions.Item, function(item) {
                return _.indexOf(LuminaireBasisId, item.LuminaireBasisId) !== -1 &&
                    (item.BasicTypeCode === '*' || _.indexOf(BasicTypeCode, item.BasicTypeCode) !== -1);
            });

            if (init) {
                self.activeOptions = activeOptions;
            }

            return activeOptions;
        },

        /*
         * This function adds "vivaOptionGroup"'s to the "vivaConfigOptions"
         */
        appendOptionGroup: function(domParent, data) {
            var self = this,
                cnt = 0;

            // missing translation fix
            if (typeof data.Name === 'undefined') {
                data.Name = '[' + data.ExtraItemTypeId + ']';
            }

            $(domParent).append(
                $('<div>').addClass('vivaOptionGroup')
                .attr('data-viva-extraitemtypeid', data.ExtraItemTypeId)
                .attr('data-viva-name', data.SmlName)
                .addClass('vivaOptionGroup' + data.Id)
                .append(
                    $('<h5>').append($('<span>').text(data.Name))
                    .append(
                        $('<a>')
                        .attr('data-viva-extraitemtypeid', data.ExtraItemTypeId)
                        .addClass('vivaOptionInfo')
                        .text('i')
                    )
                ).append(
                    // $('<div>').addClass('vivaInfoText vivaSelectLampFirst off').html(self.textSelectLampFirst)
                ).append(
                    $('<ul>').addClass('smallButtonList')
                )
            );

            var og = $('.vivaOptionGroup' + data.Id, $(domParent));
            if (typeof data.CfgType === 'string') {
                $(og).attr('data-viva-config-type', data.CfgType);
            }

            for (var i = 0; i < data.Item.length; i++) {
                cnt += !!self.appendOption($('ul:last', og), data.Item[i]) ? 1 : 0;
            }

            // If there are added no option the
            if (cnt > 0) {
                $(domParent).append($('<div>').addClass('css_clearfix'));
                self.odeRenderDynamicSelections(og, data);
            } else { // disable optiongroup
                $(og).remove();
                // $(og).hide();
            }
        },

        /*
         * This function adds "vivaOption"'s to the "vivaOptionGroup"
         */
        appendOption: function(domParent, data) {
            var self = this;

            // check if the option already exists
            if ($('[data-viva-extraitemid="' + data.ExtraItemId + '"]', domParent).size()) {
                return false;
            }

            // check if the option is a activeOption
            if (_.findWhere(self.activeOptions, {
                    'ExtraItemId': data.ExtraItemId
                }) == null) {
                return false;
            }

            // check only extra items, components do not have price and portfolio
            if (data.Id.indexOf('C') === -1) {
                // check portfolio
                var priceItem = _.find(self.vivaConfig.Price.ExtraItem, function(ei) {
                    // FIXME use strict equality, if possible (verify, before fixing!!)
                    // jshint -W116
                    return ei.Id == data.Id;
                    // jshint +W116
                });

                if (typeof priceItem !== 'undefined' && typeof priceItem.pf !== 'undefined') {
                    if (typeof self.vivaConfig.cc === 'string') {
                        if (priceItem.pf.length === 0) {
                            return; // portfolio does not match
                        }
                    } else if (_.indexOf(priceItem.pf, self.getExportCountry()) === -1) {
                        return; // portfolio does not match
                    }
                } else {
                    return false; // portfolio not available
                }
            }

            var url = data.Image ? '/PDB/Ressource/web/viva/pictos/' + data.Image : '',
                preSelectClass = '';

            if (data.preselect) {
                preSelectClass = 'selected preselected';
            }

            $(domParent).append($('<li>').addClass('smallButton vivaOption').addClass(preSelectClass).attr({
                'data-viva-id': data.Id,
                'data-viva-extraitemid': data.ExtraItemId,
                'data-viva-name': data.SmlCode,
                'data-viva-smlcode': data.Name,
                'data-viva-deliverytimecode': data.DeliveryTimeCode
            }).append(
                $('<span>').addClass('smallButtonImage')
                .css('background-image', 'url(' + url + ')')
                .css('background-image', 'transparent')
                .css('background-size', '59px 59px')
                .text(data.Name)
            ).append(
                $('<span>').addClass('smallButtonText').attr('lang', self.lang).text(data.Description)
            ));
            return true;
        },


        /*
         * This function returns the export country code for viva (VKORG or CC for options)
         */
        getExportCountry: function() {
            var ret = window.tcgGetPageProperty('vivaVKORG');

            if (typeof ret !== 'string') { // vkorg not set, use pdb export country
                ret = ecatUserProfile.getExportCountry();
            }
            return ret;
        }
        /********************************************************************************************
         * Options block end
         ********************************************************************************************/
    });


    /*
     the contents of document.ready() should be placed in the
     startup of the catalog page, there is the only place to handle
     the correct startup sequence, otherwise it's not guaranteed
     that all initializations are correctly done!
     */


    $(document).ready(function() {
        $('[data-tcgDget="idgVIVAConfig"]').each(function() {
            new tcgDget.idgVIVAConfig($(this)); // no need to store the object it's an ecatUI job
        });
    });
})(Hyphenator, ecatUI, _, tcgDOM, tcgConfig, $, ecatUserProfile, tcgDget, facetserach, window);
