/* All these settings prevent analysis errors but mean
 * deviation from coding standard XXX */
/*jshint browser: true */
/*jshint maxcomplexity: 12 */
/*global window, tcgUI, $, debug */
(function(window, tcgUI, $, debug) {
    'use strict';

    /**
     * One more base class in the hirarchie.
     *
     * @description Mother Class for all UI tcgDget classes such as {@link idgLanguage}, ...
     * @namespace
     * @class
     * @augments tcgUI
     */
    window.tcgDget = tcgUI.extend(
        /**
         * @lends tcgDget.prototype
         */
        {
            /**
             * @public    holds the ecat object returned by createCatalogObject() of subclass
             * @see        createCatalogObject()
             */
            dataObj: null,

            /**
             * @private    handling of the rendering and object display mode
             *
             * 'single':     only a single item will be shown. There will be NO duplication of eCAT_master
             *                 DOM objects, fillData() will be called without the DOM element.
             *
             * 'multi':        for each item, a eCAT_master DOM object will be cloned, and has to filled with
             *                 data in fillData() method of subclass. The DOM element has to be added to the
             *                 visible DOM by fillData() itself
             *
             * 'custom':    all rendering has to be done in fillData(), there will be no support from this
             *                 base class
             *
             *
             * @see setDgetMode()
             * @see fillData()
             */
            dgetMode: 'single',

            /**
             * @public    set to false if you want to see the Dget even when there are no objects to show
             */
            hideWhenEmpty: true,

            /**
             * @public    holds the last event recieved
             */
            lastEvent: null,


            /**
             * @public    holds the message information of the last event received
             */
            lastMessage: null,

            /**
             *
             * @public  holds the structure of all template elements and the corresponding data element
             */
            replaceStructure: null,

            /**
             * initialization of the class
             *
             * @param myDOM
             * @returns
             */
            _init_: function(/*myDOM*/) {
                var self = this;
                self.objName = 'tcgDget';
            },

            loadTemplate: function(fn) {
                var self = this;

                $('div.template', $(self.myDOM)).each(function() {
                    $(this).load('template/' + $(this).attr('data-name') + '.html', fn);
                });
            },


            /**
             * initializes the open/closeDget callback, this has to be done in the subclass if it wants to get the
             * open/close events
             * @returns
             */
            initOpenClose: function() {
                var self = this;
                $(self.myDOM).click(function() {
                    if (self.isOpen()) {
                        self.openDget();
                    } else {
                        self.closeDget();
                    }
                    return true;
                });
            },

            /**
             * check wether the Dget is open or not
             * works only for Media Column Dget's
             *
             * ATTENTION: uses CSS class from Atrivio!
             *
             * @returns boolean
             */
            isOpen: function() {
                var self = this;

                return $(self.myDOM).find('h4').hasClass('css_products_mediabox_div_wrap_open');
            },


            open: function() {
                var self = this;
                $(self.myDOM).find('h4').addClass('css_products_mediabox_div_wrap_open')
                    .removeClass('css_products_mediabox_h4_headline_closed');
                $(self.myDOM).find('.css_products_mediabox_div_content_wrap').show();
            },

            close: function() {
                var self = this;
                $(self.myDOM).find('h4').removeClass('css_products_mediabox_div_wrap_open')
                    .addClass('css_products_mediabox_h4_headline_closed');
                $(self.myDOM).find('.css_products_mediabox_div_content_wrap').hide();
            },


            /**
             * placeholder for creation of the catalog object, needs to be overridden in subclass
             *
             * @returns
             */
            createCatalogObject: function() {
            },

            /**
             * placeholder called at the end when filling of ui objects, can be overridden in subclass
             *
             * @returns
             */
            fillDataBegin: function() {
                var self = this;
                self.unitTest('preRender');
            },

            /**
             * placeholder for filling of ui objects, needs to be overridden in subclass
             *
             * @returns
             */
            fillData: function() {
            },


            /**
             * placeholder called at the end when filling of ui objects, can be overridden in subclass
             *
             * @returns
             */
            fillDataEnd: function() {
                var self = this;
                self.unitTest('postRender');
            },

            /**
             * reads the data from the backend, creates the catalog object, if createCatalogObject()
             * does not return a new object, no load operation will be performed just the internal
             * render() method will be executed.
             *
             * @see    createCatalogObject()
             * @see    ecatObj.load()
             * @see    render()
             *
             * @param what    will be passed to the load() method of the catalog object
             * @returns
             */
            read: function(what) {
                var self = this;

                if (!what || (typeof what === 'undefined')) { // no what - hide dGet
                    if (self.hideWhenEmpty) {
//                 window.setTimeout(function() {$(self.myDOM).hide();}, ecatConfig['iDgHideDelay']);
// TODO `ecatConfig` has nowhere been declared. Probably dead code! Verify! Used `1000`, which is the value, used in
// the Zumtobel-eCat
                        window.setTimeout(function() {$(self.myDOM).hide();}, 1000);
                    }
                    self.dataObj = null;    // throw away the data obj
                    return;
                } // hide dGet

                // read data

                var newDataObj = self.createCatalogObject();
                // FIXME use strict equality, if possible (verify, before fixing!!)
                // jshint -W116
                if (self.dataObj == newDataObj) { // no reload necessary
                    // jshint +W116
                    self.render(self);
                } else { // reload data
                    self.dataObj = newDataObj;
                    if (self.dataObj == null) {
                        throw self.objName + ':createCatalogObject() does not return a valid object.';
                    }
                    if (typeof self.dataObj.load !== 'function') {
                        throw self.objName + ':createCatalogObject() the object does not have a load() method.';
                    }
                    self.dataObj.load(what, self.render, self);
                } // reload data
            },


            /**
             * if something changes it will be notified here, lastEvent and messageData will be assigned here
             *
             * @see tcgDget.render()
             * @see tcgDget.lastEvent
             * @see tcgDget.lastMessage
             *
             * @event the static event data needs to hold the self object reference,
             *           the dynamic data will hold the messageData
             */
            eventReceived: function(event) {
                var staticData = event.data,
                    messageData = event.messageData,
                    self = staticData.self;

                self.lastEvent = event;
                self.lastMessage = messageData;

                var newData = messageData;
                debug(self.objName + ':eventReceived() received event ' + event.fired + ' with ' + newData);

                self.read(newData);
            },

            /**
             * find the DOM object with the eCAT_master class assigned
             *
             * @returns    the DOM object
             */
            findUIMaster: function() {
                var self = this,
                    ret = $(self.myDOM).find('.eCAT_master:first');
                if ($(ret).length == 0) {   // jshint ignore: line
                    throw self.objName + ':findUIMaster() master element not found';
                }
                return ret;
            },

            /**
             * clones the DOM object returned by findUIMaster(), removes the eCAT_master class,
             * adds eCAT_clone class
             *
             * @returns cloned DOM
             */
            cloneUIMaster: function() {
                var self = this,
                    ret = self.findUIMaster().clone(true, true);
                ret.removeClass('eCAT_master');
                ret.addClass('eCAT_clone');
                return ret;
            },

            /**
             * removes all DOM objects with the class eCAT_clone
             *
             * @returns
             */
            removeClonedUIElements: function() {
                var self = this;

                $(self.myDOM).find('.eCAT_clone').each(function() {
                    $(this).remove();
                });
            },

            /**
             * set the display/rendering mode of the tcgDget class
             *
             * @param mode    a choice of 'single', 'multi' or 'custom'
             * @throw    unsupported mode
             * @throw    missing eCAT_master class in 'multi' mode
             *
             * @returns old mode
             */
            setDgetMode: function(mode) {
                var self = this;
                switch (mode) {
                    case 'custom':
                    case 'single':
                        break;

                    case 'multi':
                        if (self.findUIMaster().length === 0) {
                            throw self.objName + ':setDgetMode() ' + mode + ' requires an eCAT_master class.';
                        }
                        break;

                    default:
                        throw self.objName + ':setDgetMode() to an unsupported mode: ' + mode;
                }

                var ret = self.dgetMode;
                self.dgetMode = mode;
                return ret;
            },

            /**
             * renders the tcgDget object
             * depending on the dgetMode the rendering will clone eCAT_master DOM object, and calls fillData()
             * of the subclass. Take care that the dgetMode also defines the number of parameter of the
             * fillData() method. If there is no data in the dataObj the parent class myDOM object will be
             * hidden, after a timeout (ecatConfig['iDGHideDelay'].
             *
             *
             * 'single': fillData(ecatDataObj)
             * 'multi':  fillData(domObj, ecatDataObj)
             * 'custom': fillData()
             *
             * @see fillData()
             * @see dgetMode
             *
             * @param self
             * @returns
             */
            render: function(self) {
                var elem;
                self.fillDataBegin();

                if (!self.dataObj || self.dataObj.count == 0) {     // jshint ignore: line
                    // we do not have any data
                    debug(self.objName + ' no data items found');

                    if (self.dgetMode === 'multi') {
                        self.removeClonedUIElements();
                    }
                    // display notification
                    switch (self.dgetMode) {
                        case 'custom':
                            self.fillData();
                            break;
                        case 'single':
                            self.fillData(null);
                            break;
                        case 'multi':
                            elem = self.cloneUIMaster();
                            self.fillData(elem, null);
                            break;
                    }

                    // hide tcgDget
                    if (self.hideWhenEmpty) {
//                 window.setTimeout(function() {$(self.myDOM).hide();}, ecatConfig['iDgHideDelay']);
// TODO `ecatConfig` has nowhere been declared. Probably dead code! Verify! Used `1000`, which is the value, used in
// the Zumtobel-eCat
                        window.setTimeout(function() {$(self.myDOM).hide();}, 1000);
                    }
                    self.fillDataEnd();
                    return;
                }

                var dataItem;
                switch (self.dgetMode) {
                    case 'custom': { // subclass decides how and what to render
                        self.fillData();
                    }
                        break;

                    case 'single': { // just fill single items
                        dataItem = self.dataObj.elements[0];
                        self.fillData(dataItem);

                        $(self.myDOM).show().removeClass('eCAT_off');
                    }
                        break;

                    case 'multi': { // we need to clone and add items
                        self.removeClonedUIElements();
                        for (var i = 0; i < self.dataObj.elements.length; i++) {
                            dataItem = self.dataObj.elements[i];
                            elem = self.cloneUIMaster();
                            self.fillData(elem, dataItem);
                        }

                        $(self.myDOM).show().removeClass('eCAT_off');
                    }
                        break;
                } // mode

                self.fillDataEnd();
            }, // render()

            /**
             * to be overridden when Dget want's to know when user closes the Dget
             * @returns boolean
             */
            closeDget: function() {
            },

            /**
             * to be overridden when Dget want's to know when user opening the Dget
             * @returns boolean
             */
            openDget: function() {
            }

        });
})(window, tcgUI, $, debug);

