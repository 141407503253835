/* All these settings prevent analysis errors but mean
 * deviation from coding standard XXX */
/*jshint nonew: false */
/*jshint browser: true */

(function(tcgDOM, _, $, tcgTarget, ecatUserProfile, tcgDget, FamilyService, AppData) { // jshint ignore: line
    'use strict';


    /**
     * @class
     * @augments tcgDget
     * @param {Object} param
     */
    /**
     * @lends tcgDget.idgLogin.prototype
     */
    tcgDget.idgFamily = tcgDget.extend({
        family: null,

        _init_: function() {
            var self = this;

            self.objName = 'idgFamily';
            self.setDgetMode('single');

            self.company = ecatUserProfile.getCompany();


            var loadString = tcgTarget;

            $('.groupTitle', $(self.myDOM)).text(loadString);

            self.listen('family:loaded', function(event) { FamilyService.handleSolrResponseEvent(self, event); });

            // FIXME potential bug. There is no `fire(tcg:load)`, only `trigger(tcg:load)` -> probably should be `bind(tcg:load, ...)`
            self.listen('tcg:load', function() {
                $('[data-configurator="' + self.configurator + '"]').each(function() {
                    var li = $(this).closest('.groupItem');
                    $(li).removeClass('off');

                    // open configurator on load (request for Slotlight)
                    $(li).addClass('selected');
                    $('.groupDetail', $(li)).slideDown('slow', function() {
                        $(window).trigger({
                            'type': 'price:update'
                        });
                    });
                });
            });

            self.listen('article:loaded', function(event) {
                var data = event.messageData;
                self.distributeArticlesToGroups(data.response.docs);
                self.fire('loading', null);
            });

            self.loadFamily(loadString);
        },


        distributeArticlesToGroups: function(articles) {
            var self = this;

            for (var i = 0; i < articles.length; i++) {
                if (typeof articles[i].ArtType_s === 'undefined') { // old solr data
                    articles[i].ArtType_s = '*';
                    articles[i].ArtTypeName_s = 'N/A';
                }
                self.fire('article:addByType', articles[i]);
            }

            self.fire('article:hideWhenEmpty', {});
        },


        loadFamily: function(family) {
            var self = this,
                query = '';

            if (family != null) {
                if (family.match(/^[0-9A-F\-]{36}/i)) {
                    query = 'id:' + family;
                } else {
                    query = 'friendlyLink:' + tcgDOM.domSOLR.escape(family);
                }
            } else {
                query = 'objectType:root';
            }

            var pData = {
                'q': query,
                'portfolio': '*',
                'clearance': 'yes',
                'sort': 'group_sorting asc,grouping asc,sorting asc',
                'rows': 199,

                // for quickaccess name use facet
                'facet': true,
                'facet.field': ['quickAccess', 'designer', 'familyTreeID', 'bulletsDifferent', 'bulletsDifferent_high',
                    'grouping', 'friendlyLink'],
                'facet.mincount': 1,
                'facet.sort': 'count'
            };
            if (family == null) {
                delete pData.clearance;
                delete pData.portfolio;
            }

            self.fire('solr:load', {
                'service': 'select',
                'message': 'family:loaded',
                'pData': pData
            });
        },


        loadArticle: function(docs) {
            var self = this;

            if (parent.possibleArticles && parent.possibleArticles.length > 0) {
                var articles = $.extend(true, [], parent.possibleArticles);

                parent.possibleArticles = [];

                _.each(articles, function(item) {
                    item.CfgID_s = '(keine)';
                    item.CfgName_s = '(keine)';
                    item.objectType = 'article';
                });

                var data = {
                    response: {
                        docs: articles
                    }
                };
                self.fire('article:loaded', data);
            } else {
                var level = AppData.getLevelOfArticleIdInSolr(),
                pData = {
                    'q': 'level_' + level + '_id:' + _.pluck(docs, 'id').join(' OR level_' + level + '_id:'),
                    'fq': 'objectType:article',
                    'fl': 'objectType,matnr,title, ArtType_s,ArtTypeName_s,weight,abcMark,dimension,txtTypText,' +
                        'Name2_s,photo,grouping,CfgName_s,CfgID_s, BasisArt_s, BasisArtName_s, Attr1Name_s, Attr1_s, ' +
                        'Attr1Caption_s, Attr2Name_s, Attr2_s, Attr2Caption_s, Attr3Name_s, Attr3_s, Attr3Caption_s, ' +
                        'quantity',
                    'portfolio': '*',
                    'clearance': 'yes',
                    'sort': 'ArtType_s asc, BasisArtRanking_i asc, BasisArt_s asc, CfgOrder_i asc, CfgID_s asc, ' +
                        'title asc',
                    'rows': 999,

                    // for quickaccess name use facet
                    'facet': true,
                    'facet.field': ['ArtType_s', 'ArtTypeName_s', 'ArtClass_s', 'ArtClassName_s'],
                    //                      'facet.mincount': 1,
                    'facet.sort': 'count'
                };

                self.fire('solr:load', {
                    'service': 'select',
                    'message': 'article:loaded',
                    'pData': pData
                });
            }
        },

        loadTree: function(docs) {
            var self = this,
                pData = {
                    'q': 'parentTreeID:' + _.uniq(_.flatten(_.pluck(docs, 'id'))).join(' OR parentTreeID:'),
                    'fl': 'objectType,id,title, photo,grouping',
                    'portfolio': '*',
                    'clearance': 'yes',
                    'sort': 'sorting asc, title asc',
                    'rows': 999
                };
            self.fire('solr:load', {
                'service': 'select',
                'message': 'tree:loaded',
                'pData': pData
            });
        }

    });


/*
    the contents of document.ready() should be placed in the
    startup of the catalog page, there is the only place to handle
    the correct startup sequence, otherwise it's not guaranteed
    that all initializations are correctly done!
*/

    $(document).ready(function() {
        $('[data-tcgDget="idgFamily"]').each(function() {
            new tcgDget.idgFamily($(this));  // no need to store the object it's an ecatUI job
        });
    });


    /**
     * $Log: idgFamily.js,v $
     * Revision 1.17  2016-03-22 09:01:09  ml
     * hide viva config in tree mode
     *
     * Revision 1.16  2016-02-16 08:19:00  ml
     * tree navigation
     *
     * Revision 1.15  2015-12-01 08:29:47  ml
     * changed simpleConfig to use CfgID_s instead of CfgName_s
     *
     * Revision 1.14  2015-11-24 07:27:26  ml
     * list of tree ids for product loading (quickaccess)
     *
     * Revision 1.13  2015-11-17 15:07:40  ml
     * use friendlyLink name for family:loaded to enforce viva to load the correct config-data
     *
     * Revision 1.12  2015-09-29 07:31:19  ml
     * load configurator information
     *
     * Revision 1.11  2015-09-08 15:01:27  ml
     * sorting option fixed
     *
     * Revision 1.10  2015-06-19 15:31:55  ml
     * added title for accessory combos
     *
     * Revision 1.9  2015-06-15 07:25:27  ml
     * no family parameter supplied
     *
     * Revision 1.8  2015-05-11 14:05:21  ml
     * accessory config - display only
     *
     * Revision 1.7  2015-04-26 12:06:54  ml
     * initial call objects
     *
     * Revision 1.6  2015-04-21 15:11:58  ml
     * article group switch
     *
     * Revision 1.5  2015-04-21 13:38:40  ml
     * loadin, classes
     *
     * Revision 1.4  2015-04-17 15:10:03  pr
     * getCore
     *
     * Revision 1.3  2015-04-07 08:11:57  ml
     * pre Build
     *
     * Revision 1.2  2015-04-06 21:50:28  ml
     * demo version 7.4.2015
     *
     * Revision 1.1  2015-04-06 19:27:07  ml
     * intermediate checkin
     *
     **/
})(window.tcgDOM, window._, window.$, window.tcgTarget, window.ecatUserProfile, window.tcgDget, window.FamilyService,
    window.AppData);
