/* All these settings prevent analysis errors but mean
 * deviation from coding standard XXX */
/*jshint nonew: false */
/*jshint browser: true */

(function(tcgDOM, _, $, tcgTarget, ecatUserProfile, tcgDget) { // jshint ignore: line
    'use strict';

    tcgDget.idgDirectDownloads = tcgDget.extend({
        _init_: function() {
            var self = this;

            self.objName = 'idgDirectDownloads';
            self.setDgetMode('single');


            self.listen('vivaInfoOK', function(event) {
                var infoItem = event.messageData;

                // remove stuff first
                self.hideDownloads();

                // enable open
                $('.openDownloads', $(self.myDOM)).removeClass('disabled');
                $('#downloads-list-placeholder').data('article', infoItem);
            });

            self.listen('vivaInfoNOK', function() {
                $('.openDownloads', $(self.myDOM)).addClass('disabled');
                $('#downloads-list-placeholder').data('article', null);
                self.hideDownloads();
            });

            $('.openDownloads', $(self.myDOM)).on('click', function(event) {
                if ($(this).hasClass('disabled')) { // force the section to be closed
                    event.preventDefault();
                    event.stopPropagation();
                    return false;
                }

                self.showDownloads();
                self.fire('messageBox', 'downloads-lightbox');
                return false;
            });
        },

        hideDownloads: function() {
            $('.articleDetail', $('#downloads-list-placeholder')).remove();
        },

        showDownloads: function() {
            var self = this;

            if ($('.articleDetail', $('#downloads-list-placeholder')).length === 0) {
                // where to add the download dget
                var domDownloadDetail = self.cloneTemplate($('[data-template="downloadDetail"]'), {
                    'text': 'loading ...'
                });

                $('#downloads-list-placeholder').append($(domDownloadDetail));
                new tcgDget.idgArticleDetail($(domDownloadDetail));
                $(domDownloadDetail).show();
            }
        }
    });


    /*
        the contents of document.ready() should be placed in the
        startup of the catalog page, there is the only place to handle
        the correct startup sequence, otherwise it's not guaranteed
        that all initializations are correctly done!
    */

    $(document).ready(function() {
        $('[data-tcgDget="idgDirectDownloads"]').each(function() {
            new tcgDget.idgDirectDownloads($(this)); // no need to store the object it's an ecatUI job
        });
    });
})(window.tcgDOM, window._, window.$, window.tcgTarget, window.ecatUserProfile, window.tcgDget);
