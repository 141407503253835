/* global _, tcgDOM, tcgConfig, $, tcgDget */
(function(_, tcgDOM, tcgConfig, $, tcgDget) { // jshint ignore: line
    'use strict';

    tcgDget.idgVIVAConfig = tcgDget.idgVIVAConfig.extend({
        toNiceArray: function(obj) {
            if (typeof obj !== 'undefined') {
                if (!_.isArray(obj)) {
                    obj = new Array(obj);
                    return obj;
                }
            }
            return obj;
        },

        odeRenderDynamicSelections: function(optionGroup, eait) {
            var self = this;

            self.vivaConfig.DynamicSelections = self.toNiceArray(self.vivaConfig.DynamicSelections);
            var dynSelection = _.findWhere(self.vivaConfig.DynamicSelections, {
                ExtraItemTypeId: eait.ExtraItemTypeId
            });

            if (_.isObject(dynSelection)) { // got a config
                // to build up the extration pattern for Full
                var patternExtract = dynSelection.Pattern;

                // to build up the extration pattern for ExtraItems (relevant only)
                var patternExtraItem = dynSelection.PatternCombination;

                $(optionGroup).attr('data-viva-config-type', 'dynamic');
                $(optionGroup).attr('data-viva-option-pattern', dynSelection.Pattern);
                $(optionGroup).attr('data-viva-extraitem-pattern', dynSelection.PatternCombination);

                // init comatibility - not with stuff
                var compatibility = [];
                if (_.isObject(dynSelection.Compatibility) && _.isObject(dynSelection.Compatibility.Item)) {
                    compatibility = self.toNiceArray(dynSelection.Compatibility.Item);
                }

                var domUL = $('<ul>');
                $(domUL).addClass('dynamicSelection');

                dynSelection.DynamicSelection = self.toNiceArray(dynSelection.DynamicSelection);
                _.each(dynSelection.DynamicSelection, function(combo) {
                    var domLI = $('<li>'),
                        domLabel = $('<label>');

                    $(domLabel).text(combo.SelectionDisplay);

                    $(domLI).append(domLabel);

                    var domCombo = $('<select>');
                    $(domCombo).attr({
                        'name': combo.SelectionName,
                        'data-viva-selection-id': combo.SelectionId
                    }).addClass('viva-dynamic-select');
                    if (combo.Relevant === '1') {
                        $(domCombo).attr('data-relevant', combo.Relevant);
                    }

                    var pattItems = [];
                    combo.Item = self.toNiceArray(combo.Item);
                    _.each(combo.Item, function(item) {
                        var domOPT = $('<option>');
                        domOPT.text(item.Name);
                        domOPT.attr({
                            'sort': item.Sort,
                            'value': item.Value,
                            'data-viva-selection-value-id': item.SelectionValueId
                        });
                        if (combo.IsDefault === item.SelectionValueId) {
                            domOPT.attr('selected', 'selected');
                        }
                        $(domCombo).append(domOPT);
                        pattItems.push(item.Value);
                    });

                    $(domLI).append(domCombo);
                    $(domUL).append(domLI);

                    // make the extraction pattern regex
                    patternExtract = patternExtract.replace(
                        '{' + combo.SelectionName + '}',
                        '(?<' + combo.SelectionName + '>' + pattItems.join('|') + ')?'
                    );
                    if (combo.Relevant === '1') {
                        patternExtraItem = patternExtraItem.replace(
                            '{' + combo.SelectionName + '}',
                            '(?<' + combo.SelectionName + '>' + pattItems.join('|') + ')'
                        );
                    } else {
                        patternExtraItem = patternExtraItem.replace(
                            '{' + combo.SelectionName + '}',
                            ''
                        );
                    }
                });

                $(optionGroup).append(domUL);
                $(optionGroup).attr('data-extraction-pattern-full', '^' + patternExtract + '$');
                $(optionGroup).attr('data-extraction-pattern-extraItem', '^' + patternExtraItem + '$');

                // handle change of selection
                $('select', $(optionGroup)).on('change', function() {
                    self.odeUpdateCompatibility(
                        optionGroup,
                        compatibility
                    );
                    self.odeUpdateSelection(optionGroup, true);
                });

                // handle activation of selection
                $('select', $(optionGroup)).on('click', function(event) {
                    if ($('.smallButton.selected', $(optionGroup)).length === 0) {
                        // currently nothing selected
                        if ($(this).attr('data-relevant') === '1') {
                            // just activate
                            self.odeUpdateSelection(optionGroup, true);
                            event.preventDefault();
                            return false;
                        }
                    }
                    return true;
                });

                // info in my Product
                $('h5', $(optionGroup)).on('dblclick', function() {
                    if ($(optionGroup).is('.open')) {
                        $('.smallButtonList', $(optionGroup)).slideUp('fast', function() {
                            $(optionGroup).toggleClass('open');
                        });
                    } else {
                        $('.smallButtonList', $(optionGroup)).slideDown('fast', function() {
                            $(optionGroup).toggleClass('open');
                        });
                    }
                });
                $('.smallButtonList', $(optionGroup)).hide();

                // check for long lists -> make searchable box
                $('select', $(optionGroup)).each(function() {
                    if ($('option', $(this)).length > 20) {
                        $(this).select2();
                    }
                });

                // handle change of available options
                $(optionGroup).on('viva-option-change', function() {
                    console.log('2do - update relevant combo info ....');
                    var linkedTo = $(optionGroup).attr('data-viva-linked-to');
                    if (typeof linkedTo === 'string') { // update linked groups
                        self.odeUpdateSelectionFromExtraItem(optionGroup);
                    } else { // standalone group
                        self.odeUpdateSelectionFromExtraItem(optionGroup);
                        self.odeUpdateCompatibility(
                            optionGroup,
                            compatibility
                        );
                    } // linked/standalone groups
                    self.odeUpdateSelection(optionGroup);
                });
                console.log(eait.Name);
                self.odeInitExtraItems(optionGroup); // init extra items

                self.odeUpdateCompatibility(
                    optionGroup,
                    compatibility
                );
                self.odeUpdateSelection(optionGroup); // initial update
            } // got a config
        },

        // init extra items
        odeInitExtraItems: function(optionGroup) {
            var self = this;
            // just copy smlcode to pattern attrib
            $('.smallButton', $(optionGroup)).each(function() {
                // save the pattern
                $(this).attr('data-viva-code', $(this).attr('data-viva-smlcode'));
                // save the text
                $(this).attr('data-viva-ori-text', $('.smallButtonText', $(this)).text());
            });

            // prepare init of revers link eit -> selections
            var pattern = $(optionGroup).attr('data-viva-extraitem-pattern');
            // space for combos
            var combos = [];
            $('select[data-relevant="1"]', $(optionGroup)).each(
                function() {
                    combos.push($(this));
                }
            );
            var vals = [];
            self.odeInitExtraItemToSelection(optionGroup, pattern, combos, vals, 0);
        },

        /*jshint maxparams: 5 */
        odeInitExtraItemToSelection: function(optionGroup, pattern, domCombo, domVal, level) {
            var self = this;

            // name of selection for pattern
            var selName = $(domCombo[level]).attr('name');
            // option based on level
            var domOpt = $('option', $(domCombo[level]));


            $(domOpt).each(function() {
                domVal.push($(this));
                var selValue = $(this).attr('value');
                // extra item code - pattern will be replaced
                var eiCode = pattern;
                eiCode = eiCode.replace('{' + selName + '}', selValue);
                if (level < domCombo.length - 1) { // not the last one
                    self.odeInitExtraItemToSelection(optionGroup, eiCode, domCombo, domVal, level + 1);
                } // not the last one
                else {
                    // clean pattern
                    eiCode = eiCode.replace(/{.+}/g, '');
                    var eiID = $('[data-viva-name="' + eiCode + '"]', $(optionGroup)).attr('data-viva-id');

                    _.each(domVal, function(dom) {
                        $(dom).addClass('vivaEI-' + eiID);
                    });
                } // last
                domVal.pop();
            });
        },

        // get options which are not disabled due to notwith
        odeGetAvailableOptions: function(optionGroup) {
            var availableOptions = [];

            $('.smallButton', $(optionGroup)).each(function() {
                if (!($(this).is('.vivaNotAvailable') || $(this).is('.disabled'))) {
                    availableOptions.push($(this).attr('data-viva-code'));
                }
            });

            return availableOptions;
        },

        // get options which are disabled due to notwith
        odeGetUnavailableOptions: function(optionGroup) {
            var unavailableOptions = [];

            $('.smallButton', $(optionGroup)).each(function() {
                if ($(this).is('.vivaNotAvailable') || $(this).is('.disabled')) {
                    unavailableOptions.push($(this).attr('data-viva-code'));
                }
            });

            return unavailableOptions;
        },

        // enable/disable values/combos based on selection and compatibility
        odeUpdateCompatibility: function(optionGroup, comatibility) {
            var self = this;
            // try to disable options which are excluded
            var availableOptions = self.odeGetAvailableOptions(optionGroup);


            // enable all (2d0: except the combos with extra Items)
            $('select', $(optionGroup)).each(function() {
                $(this).removeAttr('disabled');
            });

            // enable all options (2d0: except the combos/options with extra Items)
            $('select>option', $(optionGroup)).each(function() {
                $(this).removeAttr('disabled');
            });


            // disable all items which are not available due to notWith
            var reg = new RegExp($(optionGroup).attr('data-extraction-pattern-extraitem'));

            // resulting available
            var resAvailable = [];

            _.each(availableOptions, function(opt) {
                var split = reg.exec(opt);
                if (_.isObject(split) && _.has(split, 'groups')) {
                    resAvailable.push(split.groups);
                }
            });
            // now we should have each availableOption as object

            $('select', $(optionGroup)).each(function() {
                var domCombo = $(this);
                if ($(domCombo).is('[disabled]')) { // no need to check
                    return;
                }

                var selID = $(domCombo).attr('data-viva-selection-id'),
                    selValue = $(domCombo).val(),
                    selName = $(domCombo).attr('name');
                // selValueID = $('option:selected', $(domCombo)).attr('data-viva-selection-value-id'),

                if ($(domCombo).attr('data-relevant') === '1') {
                    $('option', $(domCombo)).each(function() {
                        var domOpt = $(this),
                            optVal = $(this).attr('value');

                        // find if option is available
                        var optOK = _.find(resAvailable, function(elem) {
                            return elem[selName] === optVal;
                        });
                        if (!_.isObject(optOK)) {
                            $(domOpt).attr('disabled', 'disabled');
                        }
                    });
                } // disable relevant only

                // 2021-03-30 also handle combos outsid curren scope (IsaroPro)
                _.chain(comatibility).where({
                    'SelectionId1': selID
                }).pluck('SelectionId2').uniq().each(function(sel) {
                    // remove disabled stuff from related select boxes
                    var domSel = $('select[data-viva-selection-id="' + sel + '"]', $(optionGroup));
                    if (domSel.length === 0) { // not in same group!
                        domSel = $('select[data-viva-selection-id="' + sel + '"]', $(optionGroup).parent());
                        if (domSel.length !== 0) {
                            $(domSel).removeAttr('disabled');
                            $('option', $(domSel)).each(function() {
                                $(this).removeAttr('disabled');
                            });

                            var otherOptionGroup = $(domSel).closest('.vivaOptionGroup ');
                            $(otherOptionGroup).attr('data-viva-linked-to', $(optionGroup)
                                .attr('data-viva-extraitemtypeid'));
                        }
                    }
                });

                _.chain(comatibility).where({
                    'SelectionId1': selID,
                    'SelectionValue1': selValue
                }).each(function(item) {
                    if (item.State !== 'NotWith') {
                        console.log(item.State + ' in compatibility is not implemented yet.');
                        return;
                    }
                    if (_.isString(item.SelectionValue2)) { // disable option , 2021-03-30 also in other groups parent()
                        $('select[data-viva-selection-id="' + item.SelectionId2 +
                                '"]>option[value="' + item.SelectionValue2 + '"]', $(optionGroup).parent())
                            .attr('disabled', 'disabled');
                    } else { // disable combo, 2021-03-30 also in other gropus paretnt()
                        $('select[data-viva-selection-id="' + item.SelectionId2 + '"]', $(optionGroup).parent())
                            .attr('disabled', 'disabled');
                    }
                });

                if ($('option[disabled]:selected', $(domCombo)).length === 1) { // disabled option selected
                    // select valid option
                    $(domCombo).val($('option:not([disabled])', $(domCombo)).first().attr('value'));
                }
            });
        },

        // get current Extra Item based on the combos
        odeGetCurrentSelection: function(optionGroup) {
            // init sml code w. option pattern
            var smlCode = $(optionGroup).attr('data-viva-option-pattern');


            // init ei - code with extraitem pattern
            var eiCode = $(optionGroup).attr('data-viva-extraitem-pattern');

            // build new sml code
            var countActive = 0;
            $('.dynamicSelection select:not([disabled])', $(optionGroup)).each(function() {
                // selected entry
                var value = $(this).val();
                // name part
                var name = $(this).attr('name');

                smlCode = smlCode.replace('{' + name + '}', value);

                // combo relevant for extra item?
                var relevant = $(this).attr('data-relevant');
                if (relevant === '1') {
                    eiCode = eiCode.replace('{' + name + '}', value);
                    countActive++;
                }
            });

            smlCode = smlCode.replace(/{.+}/g, ''); // remove all empty items
            eiCode = eiCode.replace(/{.+}/g, ''); // remove all empty items

            // find correct extra item based on eiCode
            var domEI = $('[data-viva-code="' + eiCode + '"]', $(optionGroup));
            if (domEI.length === 1) {
                // modify values
                $('.smallButtonText', $(domEI)).text(smlCode); // to verify

                $(domEI).attr({
                    'data-viva-smlcode': smlCode
                    // ,'data-viva-name': smlCode
                });

                var linkedTo = $(optionGroup).attr('data-viva-linked-to');
                if (typeof linkedTo === 'string') {
                    $('[data-viva-code]', $(optionGroup)).removeClass('selected');
                    $(domEI).addClass('selected');
                }
            } else {
                if (countActive === 0) { // nothing active
                    $('[data-viva-code]', $(optionGroup)).removeClass('selected');
                } else {
                    console.log('404: extra item for selection not found: ' + eiCode);
                }
            }

            return domEI;
        },

        // update extra item based on combo values
        odeUpdateSelection: function(optionGroup, triggerClick) {
            var self = this;

            // get current extra item
            var domEI = self.odeGetCurrentSelection(optionGroup);

            if (domEI.length === 1) {
                var smlCode = $(domEI).attr('data-viva-smlcode');

                // nam of extra item
                var eiCode = $(domEI).attr('data-viva-code');

                // modify values
                $('.smallButtonText', $(domEI)).text(smlCode); // to verify

                $(domEI).attr({
                    'data-viva-smlcode': smlCode
                    // ,'data-viva-name': smlCode
                });

                if ($(domEI).is('.vivaNotAvailable')) {
                    console.log('500: ' + eiCode + ' is disabled');
                } else if (triggerClick === true) {
                    if ($(domEI).is(':not(.selected)')) {
                        // restore currently selected item
                        var curSel = $('.smallButton.selected', $(optionGroup));
                        if ($(curSel).length === 1) {
                            // restore code
                            var oriCode = $(curSel).attr('data-viva-code');
                            $(curSel).attr({
                                'data-viva-smlcode': oriCode
                                // , 'data-viva-name': oriCode
                            });

                            // restore text
                            var oriText = $(curSel).attr('data-viva-ori-text');
                            $('.smallButtonText', $(curSel)).text(oriText);
                            console.log('100: ' + oriCode + ' restored w. ' + oriText);
                        } // restore currently selected

                        console.log('200: ' + eiCode + ' activate with click');

                        $('.dynamicSelection select', $(optionGroup)).removeClass('selected');
                        $('.dynamicSelection select[data-relevant="1"]:not(disabled)', $(optionGroup))
                            .addClass('selected');
                        $(domEI).trigger('click');
                        // $(domEI).addClass('selected');
                    } else {
                        console.log('100: ' + eiCode + ' already selected - updated');
                        self.updateInfo();
                    }
                } // click / update the code
            } // extra item found
        },

        // update selection combos based on extra item
        odeUpdateSelectionFromExtraItem: function(optionGroup) {
            // var self = this;

            // get current extra item
            // var domEI = self.odeGetCurrentSelection(optionGroup);
            // if (domEI.length === 1) { // we have a selected extra item
            // is it the same as selected in the combos?
            var domCurSel = $('.smallButton.selected', $(optionGroup));

            if ($(domCurSel).length !== 0) {
                var eiID = $(domCurSel).attr('data-viva-id');
                $('.dynamicSelection select[data-relevant="1"]', $(optionGroup)).each(function() {
                    var value = $('option.vivaEI-' + eiID, $(this)).attr('value');

                    $(this).val(value);
                    $(this).addClass('selected');
                });
            }
            // }
        }
    });
})(_, tcgDOM, tcgConfig, $, tcgDget);
