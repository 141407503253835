/* All these settings prevent analysis errors but mean
 * deviation from coding standard XXX */
/*jshint forin: false */
/*jshint nonew: false */
/*jshint maxcomplexity: 8 */
/*jshint browser: true */
/* global ecatUserProfile, tcgDOM, $, tcgDget
 */
(function(ecatUserProfile, tcgDOM, $, tcgDget) { // jshint ignore: line
    'use strict';

    /**
     * @class
     * @augments tcgDget
     * @param {Object} param
     */
    /**
     * @lends tcgDget.idgLogin.prototype
     */
    tcgDget.idgCFGBasketAdd = tcgDget.extend({
        family: null,

        availabilityText: {
            '*': 'unknown {1}'
        },

        _init_: function() {
            var self = this;

            self.objName = 'idgCFGBasketAdd';
            self.setDgetMode('single');

            self.avNotConfig = $('.availabilityConfig', $(self.myDOM)).text();

            // ABC translation - init
            $('li', 'ul[data-template="availabilityDefinition"]').each(function() {
                self.availabilityText[$(this).attr('data-availability')] = $(this).text();
            });

            $(window).bind('tcgSysCfg:result', function(event, cfgData) {
                // self.getProductInfo(event.messageData);
                // self.renderData(event.messageData);
                self.renderData(cfgData);
            });

            self.listen('family', function(event) {
                self.family = event.messageData;
            });

            self.renderData();


            $('.articleAdd2Cart', $(self.myDOM)).click(function() {
                var infoItem = JSON.parse(JSON.stringify(self.infoItem));

                infoItem.pos = null;
                infoItem.ArtType_s = 'X'; // ??????????????????????????????????
                infoItem.abcMark = '-';

                if (infoItem.createIsVivaString) {
                    infoItem.isVIVA = self.makeIsVIVA(infoItem);
                }

                if (typeof infoItem.matnr === 'undefined') {
                    infoItem.title = self.infoItem.family;
                    infoItem.matnr = '-';
                } else if (typeof infoItem.solrDoc === 'object') {
                    switch (infoItem.solrDoc.objectType) {
                        case 'article': // just take the solrDoc
                            infoItem = self.infoItem.solrDoc;
                            infoItem.family = self.infoItem.family;
                            break;

                        default:
                            break;
                    }
                }


                // qty stuff
                var qtyInput = $('.articleQty4Cart', $(self.myDOM));

                infoItem.cartID = $('.domCartSelector', $(self.myDOM)).val();

                if (/\d+/.test($(qtyInput).val())) {
                    infoItem.qty = +$(qtyInput).val();
                } else {
                    infoItem.qty = 1;
                }


                self.fire('cartItem', {
                    'service': 'update/insert',
                    'pData': infoItem
                });
            });
        },


        renderData: function(cfgData) {
            var self = this;

            if (cfgData && cfgData.cfgOK) {
                var infoText = cfgData.hideFamilyInInfoText ? cfgData.title : self.family.title + ' ' + cfgData.title;

                $('.vivaConfigInfo', $(self.myDOM)).text(infoText);
                $('.articleBasketAdd', $(self.myDOM)).removeClass('disabled');
                $('.articleAdd2Cart', $(self.myDOM)).removeAttr('disabled');
                $('.availabilityConfig', $(self.myDOM)).text(cfgData.cfg);

                self.infoItem = $.extend(cfgData, {
                    'objectType': cfgData.objectType ? cfgData.objectType : 'cfgResult',
                    'pos': null,
                    'family': cfgData.family ? cfgData.family : self.family.title
                });
            } else {
                $('.vivaConfigInfo', $(self.myDOM)).text('');
                $('.articleBasketAdd', $(self.myDOM)).addClass('disabled');
                $('.articleAdd2Cart', $(self.myDOM)).attr('disabled', true);
                $('.availabilityConfig', $(self.myDOM)).text(self.avNotConfig);
                self.infoItem = null;
            }
        },


        // check product if it has a sap number, or is a standard variant
        getProductInfo: function(infoItem) {
            var self = this;

            if (!infoItem.configOK) {
                return;
            }

            var fq = [];

            self.infoItem = infoItem;
            // 2015-11-23 disabled due to missing data in SOLR article objects
            // for (var i=0; i<infoItem.options.length; i++) {
            //     if (typeof infoItem.options[i].smlName != 'undefined' && infoItem.options[i].value!='')
            //         fq.push('viva'+tcgDOM.domSOLR.escape(infoItem.options[i].smlName)+':'+tcgDOM.domSOLR.escape(infoItem.options[i].value));
            // }


            fq.push('vivaKurztext:' + tcgDOM.domSOLR.escape(infoItem.prodName) +
                ' OR title:' + tcgDOM.domSOLR.escape(infoItem.prodName)
            );

            infoItem.matnr = '';
            infoItem.title = null;
            infoItem.doc = null;

            self.fire('solr:load', {
                'service': 'select',
                'pData': {
                    'q': 'objectType:article OR objectType:vivaSAPVariant OR objectType:vivaStdVariant',
                    'fq': fq,
                    'sort': 'objectType desc'
                },
                'message': function(data) {
                    if (data.response.numFound !== 0) {
                        infoItem.matnr = data.response.docs[0].matnr;

                        for (var i = 0; i < data.response.docs.length; i++) {
                            var doc = data.response.docs[i];
                            switch (doc.objectType) {
                                case 'vivaSAPVariant':
                                    infoItem.title = doc.title;
                                    break;

                                case 'vivaStdVariant':
                                    if (typeof infoItem.solrDoc === 'undefined') {
                                        infoItem.solrDoc = doc;
                                    }
                                    break;

                                case 'article':
                                    infoItem.solrDoc = doc;
                                    break;

                                default: // ???
                                    break;
                            }
                        }
                    } // products found
                    else { // get data from base article

                        self.fire('solr:load', {
                            'service': 'select',
                            'pData': {
                                'q': 'objectType:article',
                                'fq': 'matnr:' + infoItem.baseArticle
                            },
                            'message': function(data) {
                                if (data.response.numFound !== 0) {
                                    if (typeof data.response.docs[0].photo !== 'undefined') {
                                        infoItem.photo = data.response.docs[0].photo;
                                    }
                                }
                            }
                        });
                    } // no prod found
                }
            });
        },

        // TODO `ecatConfig` has nowhere been declared. Possible dead code. Verify!
        //     /********************************************************
        // * TODO!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
        // */

        // getDatasheetURL:function(orderNo, isVIVA) {
        //     var url = ecatConfig.objectURL + '/PDF/Datasheet.aspx?number=' + orderNo +
        //         //          '&DatasheetType='+(typeof param.type != 'undefined'?param.type:'1')+
        //         //          '&ModeID='+info.modeID+
        //         '&ISO2=' + ecatUserProfile.getExportCountry() +
        //         //          '&CF='+ecatUserProfile.clearance[0]+
        //         //          (typeof param.template != 'undefined'?'&templateLitech='+param.template:'')+
        //         '&lang=' + ecatUserProfile.getLanguage() +
        //         '&CompanyID=1' +
        //         '&isVIVA=' + encodeURIComponent(isVIVA) +
        //         '&templateLitech=Standard_web';
        //     return url;
        // },

        // get isVIVA string
        makeIsVIVA: function(infoItem) {
            var self = this;

            if (typeof infoItem === 'undefined') {
                infoItem = self.infoItem;
            }
            return window.IsVIVA.makeString(infoItem);


            // var options = [],
            //     components = [];

            // for (var i = 0; i < infoItem.options.length; i++) {
            //     // FIXME use strict equality, if possible (verify, before fixing!!)
            //     // jshint -W116
            //     if (infoItem.options[i].value != '' && typeof infoItem.options[i].eid === 'string') {
            //         // jshint +W116
            //         switch (infoItem.options[i].eid.charAt(0)) {
            //             case 'A': // attribute
            //                 break;
            //             case 'E': // extra item
            //                 options.push(infoItem.options[i].value + ':' + infoItem.options[i].id);
            //                 break;

            //             case 'I': // iso7010
            //                 options.push(infoItem.options[i].value + ':' + infoItem.options[i].id + '[' + infoItem.options[i].iso7010 + ']');
            //                 break;

            //             case 'C': // component
            //                 components.push(infoItem.options[i].value + ':' + infoItem.options[i].id);
            //                 break;

            //             default: // unknown
            //                 alert('undefined item type group ' + infoItem.options[i].id); // jshint ignore: line
            //                 break;
            //         } // type of item
            //     } // item selected
            // } // all options

            // var isVIVA = infoItem.family + ';' + options.join('|') + ';' + components.join('|') +
            //     ';' + (/$[0-9]{8}^/.test(infoItem.coding) ? infoItem.coding : '') + ':' + infoItem.prodName + ';' +
            //     infoItem.baseArticle;

            // return isVIVA;
        } // isVIVA()


        // TODO: Probably dead code. This method seems unused. Verify! It has been disabled due to the access to the
        // undeclared `ecatGetPageProperty` // check product if it has a sap number, or is a standard variant
        // showProductInformation:function(infoItem) { var self = this;

        //     if (typeof infoItem === 'undefined') {
        //         infoItem = self.infoItem;
        //     }

        //     var isVIVA = self.makeIsVIVA(infoItem);

        //     var backend = null;
        //     var callParams = {
        //         'isVIVA': isVIVA,
        //         'sLang': ecatUserProfile.getLanguage(),
        //         'sOrderNo': infoItem.baseArticle
        //     };


        //     self.url = ecatGetPageProperty('ecatPriceProxy');
        //     if (self.url != null) {
        //         backend = new zagBackend.plone(); // jshint ignore: line
        //         callParams['app-url'] = 'viva/getProductPriceInfo';
        //     }
        //     else {
        //         backend = new zagBackend.bc(); // jshint ignore: line
        //         callParams['app-url'] = 'vivaConfig/xmlProductInfo';
        //     }


        //     $('#vivaProductText').val('...');
        //     $('.vivaAvailability').val('...');
        //     $('#vivaDatasheetLink').attr('href', self.getDatasheetURL(infoItem.baseArticle, isVIVA, {}));

        //     backend.xml(callParams, function(data) {
        //         var oInfo = $.xml2json(data);
        //         // check, if xmlProductText returned valid text
        //         if (typeof oInfo.text !== 'undefined') {
        //             $('#vivaProductText').val(oInfo.text);
        //         }
        //         else {
        //             $('#vivaProductText').val('');
        //         }

        //         // availability

        //         $('.vivaAvailability').each(function() {
        //             switch ($(this)[0].tagName) {
        //                 case 'INPUT':
        //                     $(this).val(infoItem.getDeliveryTimeCode(oInfo.MAABC));
        //                 break;
        //             default:
        //                 $(this).text(infoItem.getDeliveryTimeCode(oInfo.MAABC));
        //             }

        //         });

        //         // loop the price stuff
        //         if (oInfo.price) {
        //             if (!zagBackend.isArray(oInfo.price)) {// jshint ignore: line
        //                 oInfo.price = $.makeArray(oInfo.price);
        //             }

        //             // now the loop
        //             for (var i = 0; i<oInfo.price.length; i++) {
        //                 var entry = oInfo.price[i];

        //                 // type ... B=Base, E=ExtraItem, C=Component
        //                 // id ... matnr/extraitem type id
        //                 // name .. extra item code/component code,
        //                 // value ... price,
        //                 // cur .. currency

        //                 switch (entry.type) {
        //                     case 'B':
        //                         break;

        //                     case 'E':
        //                     case 'C':
        //                         break;
        //                 }
        //             } // price item
        //         } // valid price data


        //     });

        //     callParams['app-url'] = 'vivaConfig/dlProductText';
        //     callParams.contenttype = 'text/plain'; //'application/octet-stream';
        //     callParams['web-site'] = ecatUserProfile.getExportCountry().toUpperCase();
        //     callParams['web-lang'] = ecatUserProfile.getLanguage().toUpperCase();
        //     callParams.xformat_reload = '1';
        //     callParams.downfile = infoItem.prodName + '.txt';

        //     var url = ecatConfig.xfmtPath + '?';
        //     var addAmp = false;
        //     for (var param in callParams) {
        //         url+=addAmp?'&':'';
        //         addAmp = true;
        //         url+=param + '=' + encodeURIComponent(callParams[param]);
        //     }

        //     $('#vivaProductTextDownload').attr('href', url);
        // }
    });


    /*
        the contents of document.ready() should be placed in the
        startup of the catalog page, there is the only place to handle
        the correct startup sequence, otherwise it's not guaranteed
        that all initializations are correctly done!
    */

    $(document).ready(function() {
        $('[data-tcgDget="idgCFGBasketAdd"]').each(function() {
            new tcgDget.idgCFGBasketAdd($(this)); // no need to store the object it's an ecatUI job
        });
    });


    /**
     * $Log: idgCFGBasketAdd.js,v $
     * Revision 1.4  2016-02-16 08:15:58  ml
     * pricing additions
     *
     * Revision 1.3  2015-11-27 17:47:26  ml
     * fixed std article
     *
     * Revision 1.2  2015-11-24 07:26:40  ml
     * disable vivaAttribs since they are not in SOLR anymore
     *
     * Revision 1.1  2015-09-29 07:31:45  ml
     * initial version - configurator add basket
     *
     **/
})(ecatUserProfile, tcgDOM, $, tcgDget);
