/**
 * $Id: loader.js,v 1.3 2016-02-16 08:20:40 ml Exp $
 * ATTENTION - shared code between VIVA 2.0 and System Configurator
 */

var tcgLoadCount=0;

function parseHTML(html) {
	  return $.parseHTML(html, document, true);
};


function executeScriptTags(scripts) {
	  if (!scripts) return

	  var existingScripts = $('script[src]');

	  scripts.each(function() {
	    var src = this.src;
	    var matchedScripts = existingScripts.filter(function() {
	      return this.src === src;
	    });
	    if (matchedScripts.length) return;

	    var script = document.createElement('script');
	    var type = $(this).attr('type');
	    if (type) script.type = type;
	    script.src = $(this).attr('src');
	    document.head.appendChild(script);
	  });
};
	
function findAll(elems, selector) {
	  return elems.filter(selector).add(elems.find(selector));
};
		
		


$(window).bind('tcg:load', function(event, obj) {
	$(obj).attr('data-loaded', 'OK');
	tcgLoadCount--;
	
//	scripts = findAll($(obj), 'script[src]');
//	executeScriptTags(scripts);
	
	if(tcgLoadCount<=0)
		$(window).trigger('tcg:initialize');
});


$(window).bind('tcg:dynamicContentInit', function() {
	tcgLoadCount=$('[data-load]').size();
	
	$('[data-load]').each(function() {
		var name=$(this).attr('data-load');
		if(name!='')
			$(this).load($(this).attr('data-load'), function(response, status, xhr) {
				if(status=='error') {
					
				}
				else {
					var cnt = $(this).contents();
					$(this).parent().attr('data-status', 'loaded');
					$(this).replaceWith(cnt);
					
					
					$(window).trigger('tcg:load', [$(this)]);
				}
			});
	});

});


$(document).ready(function() {
	$('[data-include]').each(function() {
		$(this).load($(this).attr('data-include'), function(response, status, xhr) {
			if(status=='error') {
				
			}
			else {
				var cnt = $(this).contents();
				
				$(this).parent().attr('data-status', 'loaded');
				$(this).replaceWith(cnt);
			}
		});
	});	
});



/**
  * $Log: loader.js,v $
  * Revision 1.3  2016-02-16 08:20:40  ml
  * loader for static login extension
  *
  * Revision 1.4  2015-11-27 07:57:11  ml
  * sandbox stuff for debugging
  *
  * Revision 1.2  2015-10-13 11:28:39  ml
  * no preview handling - data already placed by jquery in dom
  *
  * Revision 1.3  2015-10-09 08:53:44  ml
  * fixed issued
  *
  * Revision 1.1  2015-09-29 07:32:43  ml
  * loader for dynamic content
  *
  **/