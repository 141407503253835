/*jshint browser:true*/
(function(global) {
    'use strict';

    // CONSTANTS

    var NAMESPACE__TEST_EXPORTS = '_EXPORTS_FOR_TESTS';

    // EXPORTS

    global.VivaUtils = {
        NAMESPACE__TEST_EXPORTS: NAMESPACE__TEST_EXPORTS,

        getBaseArticle: getBaseArticle,
        // Dev / Test utilities
        exportForTests: exportForTests,
        getPropertiesForTests: getPropertiesForTests
    };

    // IMPLEMENTATION DETAILS

    /**
     * @param {String}          vivaConfig  Viva-configuration, represented by a String
     * @returns {String|null}   Material number of the base article.<ul>
     *                            <li><code>vivaConfig</code>, if the configuration is invalid.
     *                            <li><code>null</code>, if null or undefined has been passed.
     */
    function getBaseArticle(vivaConfig) {
        if (vivaConfig == null) { return null; }
        // TODO consider returning null, for invalid configurations (verify first that this won't break the code)...
        // ...as this makes more sense than:
        // - the input-string, for invalid configs
        // - empty string, for empty string-input
        return vivaConfig.replace(/^([^;]+);([^;]*);([^;]*);([^;]+);([Ss0-9]+)$/, '$5');
    }

    // Dev / Test utilities

    /**
     * Export passed properties into global space (under own namespace), to make them available for testing / debugging.
     * The name of the parent-namespace, which will contain the exposed sub-namespaces is
     * <code>_EXPORTS_FOR_TESTS</code>
     * @param {String}  namespace   Custom namespace (preferably name of object, containing the properties, eg.
     *                              <code>EventService</code>)
     * @param {Object}  properties  Object, containing the properties which have to be exported (like private functions)
     */
    function exportForTests(namespace, properties) {
        var exports = global[NAMESPACE__TEST_EXPORTS] = global[NAMESPACE__TEST_EXPORTS] || {};
        exports[namespace] = exports[namespace] || {};
        Object.keys(properties).forEach(function(propName) {
            exports[namespace][propName] = properties[propName];
        });
    }

    /**
     * Gets the (private) properties, which have been exported to global space for tests.
     * @param {String}  namespace   Namespace of the exported properties, eg. <code>EventService</code>
     * @returns {Object|null}   Private properties, or <code>null</code>, if the namespace doesn't exist.
     */
    function getPropertiesForTests(namespace) {
        if (global[NAMESPACE__TEST_EXPORTS] == null) { return null; }
        return global[NAMESPACE__TEST_EXPORTS][namespace] || null;
    }
})(window);

