/*jshint browser:true*/
(function(global, $, VivaUtils) {
    'use strict';

    // CONSTANTS

    var PROP_APP_CONFIG = {
        isDebug: 'debug',
        hasCustomConfigurators: 'hasCustomConfigurators',
        levelArticleIdSolr: 'levelArticleIdSolr',
        objectTypeOfArticles: 'objectTypeOfArticles',
        pdbCompanyId: 'pdbCompanyId',
        solrCoreUrl: 'solrCoreUrl',
        solrUrl: 'solrUrl'
    };

    // LOCAL VARIABLES

    var _cacheParams = null;


    // EXPORTS

    /**
     * Accessor for local application data
     */
    global.AppData = {
        hasCustomConfigurators: hasCustomConfigurators,
        isDebug: isDebug,
        getLevelOfArticleIdInSolr: getLevelOfArticleIdInSolr,
        getObjectTypeOfArticles: getObjectTypeOfArticles,
        getPdbCompanyId: getPdbCompanyId,
        getSolrCoreUrl: getSolrCoreUrl,
        getSolrUrl: getSolrUrl,
        getPageProperty: getPageProperty
    };

    // Export private functions for automated tests
    VivaUtils.exportForTests('AppData', {
        APP_CONFIG_PROP: PROP_APP_CONFIG,

        _getAppConfig: _getAppConfig,
        _getUrlHash: _getUrlHash,
        _getUrlParameters: _getUrlParameters,
        _isLocalFileLocation: _isLocalFileLocation,
        _mergeHash: _mergeHash,
        _mergeParams: _mergeParams,
        _mergeUrlParams: _mergeUrlParams
    });
    // Assign to object, which has to be used for private functions, in order to make the private functions mockable
    var Private = VivaUtils.getPropertiesForTests('AppData');

    // IMPLEMENTATION DETAILS

    // Public

    /*jshint maxcomplexity:25*/
    /**
     * @returns {Boolean}   <code>true</code>, if debug-mode is enabled
     */
    function isDebug() { return !!_getAppConfig(PROP_APP_CONFIG.isDebug); }

    /**
     * Get the level in which the article-ID resides in Solr for the company. This may be required for Solr-requests,
     * to search the right property, when searching with article-IDs
     * @example
     * solrQuery = 'level_' + AppData.getLevelOfArticleIdInSolr() + '_id:' + articleId
     * @returns {Number}    Level
     */
    function getLevelOfArticleIdInSolr() { return +_getAppConfig(PROP_APP_CONFIG.levelArticleIdSolr); }

    /**
     * @returns {String}    The `objectType` of a Solr-document which represents an article, for the company
     */
    function getObjectTypeOfArticles() { return ''+_getAppConfig(PROP_APP_CONFIG.objectTypeOfArticles); }

    /**
     * @returns {Boolean}   `true`, if the company has custom configurators (like the `slotlightinfinity`-configurator)
     */
    function hasCustomConfigurators() { return !!_getAppConfig(PROP_APP_CONFIG.hasCustomConfigurators); }

    /**
     * @returns {Number}    Get the ID of the company for the PDB
     */
    function getPdbCompanyId() { return +_getAppConfig(PROP_APP_CONFIG.pdbCompanyId); }

    /**
     * Get URL to the Solr-core
     * @param {String}  language    Language-code (lowercase)
     * @returns {String}    URL
     */
    function getSolrCoreUrl(language) {
        return _getAppConfig(PROP_APP_CONFIG.solrCoreUrl).replace(/@@LANGUAGE@@/g, language);
    }

    /**
     * Get URL to the Solr url w. core
     * @returns {String}    URL
     */
    function getSolrUrl(core) {
        return _getAppConfig(PROP_APP_CONFIG.solrUrl).replace(/@@CORE@@/g, core);
    }

    /**
     * Get property value for current page. Default lookup locations are (in that order):
     * <ul>
     *     <li>URL-parameter
     *     <li>Cookie
     *     <li>Meta-tag
     * </ul>
     *
     * The first match will be returned, if it's been found. <code>null</code> otherwise
     *
     * @param {String}              property    Property-name
     * @param {String|Boolean}      [prop1]     where to search default='url, cookie, meta'. If the value is a boolean
     *                                          and <code>true</code> the matching (existing) cookie value will be
     *                                          removed
     * @param {Boolean}             [prop2]     If found in cookie, remove the cookie, default=false
     * @return {String|null}        Value of property. `null`, if not found
     */
    function getPageProperty(property, prop1, prop2) {
// TODO Refactor! Cyclomatic complexity is 25!!
        var where = 'url,cookie,meta',
            removeCookie = false;

        if (typeof prop1 === 'string') {
            where = prop1;
            if (typeof prop2 === 'boolean') {
                removeCookie = prop2;
            }
        } else if (typeof prop1 === 'boolean') {
            removeCookie = prop1;
        }

        var ret = null,
            where2search = where.split(',');
        while (where2search.length) {
            switch (where2search.shift()) {
                case 'meta':
                    if ($('meta[name="' + property + '"]').is('meta')) {
                        ret = $('meta[name="' + property + '"]').attr('content');
                    }
                    break;

                case 'localStorage':
                    if (typeof global.localStorage === 'object') {
                        ret = global.localStorage['tcg-' + property];
                    }
                    if (ret != null && removeCookie) {
                        delete global.localStorage['tcg-' + property];
                    }
                    break;

                case 'cookie':
                    if (typeof global.localStorage === 'object') {
                        ret = global.localStorage['tcg-' + property];

                        // FIXME use strict equality, if possible (verify, before fixing!!)
                        // jshint -W116
                        if (ret != null && removeCookie) {
                            // jshint +W116
                            delete global.localStorage['tcg-' + property];
                        }
                    }
                    if (!ret) {
                        ret = $.cookie(property);
                        // FIXME use strict equality, if possible (verify, before fixing!!)
                        // jshint -W116
                        if (ret != null && removeCookie) {
                            // jshint +W116
                            $.cookie(property, null, { path: '/' });
                        }
                    }
                    break;
                case 'url':
                    _cacheParams = Private._mergeParams(_cacheParams);
                    if (typeof _cacheParams[property] !== 'undefined') {
                        ret = _cacheParams[property];
                    }
                    if (!ret && property.indexOf('tcg') === 0) { // try without tcg of tcgXXXXXX
                        if (typeof _cacheParams[property.substr(4)] !== 'undefined') {
                            ret = _cacheParams[property.substr(4)];
                        }
                        if (!ret && typeof _cacheParams[property.substr(4).toLowerCase()]) {
                            ret = _cacheParams[property.substr(4).toLowerCase()];
                        }
                    } // try without tcg of tcgXXXXXX

                    if (typeof ret !== 'undefined' && !removeCookie && _cacheParams.cookie === 'set') {// set param into cookie
                        if (Private._isLocalFileLocation(global.document.location) ||
                                typeof global.localStorage === 'object') {
                            if (typeof global.Storage !== 'undefined' && typeof global.localStorage !== 'undefined' &&
                                typeof ret === 'string') {
                                global.localStorage['tcg-' + property] = ret;
                            } else {
                                $.cookie(property, ret, { path: '/' });
                            }
                        } else {
                            $.cookie(property, ret, { path: '/' });
                        }
                    }
                    break;
            }

            if (ret) {// already found?
                break;
            }
        }

        return ret;
    }

    // Private

    /**
     * Get property of company-specific configuration.
     * @param {String}  property    Property-name
     * @returns {Object}    Value of property
     * @see {@link app-config-zl.js}, {@link app-config-tl.js}, {@link app-config-acdc.js}
     * @private
     */
    function _getAppConfig(property) { return window._APP_CONFIG[property]; }

    /**
     * @returns {Boolean}   <code>true</code>, if URL is pointing to a local file. <code>false</code> otherwise
     * @private
     */
    function _isLocalFileLocation(location) {
        if (location == null) { return null; }
        return location.href.indexOf('file:') !== -1;
    }

    /**
     * Merges parameters, passed in URL or location-hash, into the target
     * @param {Object}  target  The target, where there parameters are merged into
     * @return {Object} <code>target</code>, a filled target, if <code>null</code> has been passed
     * @private
     */
    function _mergeParams(target) {
        if (target != null) { return target; }
        target = {};
        Private._mergeUrlParams(target);
        Private._mergeHash(target);
        return target;
    }

    /**
     * Extracts the URL-parameter pairs from the URL.
     * @param {Location}    location    Location-object, containing information about the URL
     * @returns {Array<String>|null}    Array of Key-/Value-pair(s). Empty Array, if no parameters are present.
     *                                  <code>null</code>, if <code>location</code> is <code>null</code> or
     *                                  <code>undefined</code>
     * @private
     */
    function _getUrlParameters(location) {
        if (location == null) { return null; }
        return location.search.substring(1).split('&');
    }

    /**
     * Merge URL-parameters into passed object. If the value for a parameter is missig, it will be set as `tcgDefault`
     * and `tcgTarget`-property
     * @param {Object}  target  Target-object, in which the parameters have to be merged into
     * @private
     */
    function _mergeUrlParams(target) {
        Private._getUrlParameters(global.document.location).reduce(function(result, keyVal) {
            var segs = keyVal.split('='),
                key = decodeURIComponent(segs[0]),
                val = segs.length > 1 ? decodeURIComponent(segs[1]) : null;
            if (val === null) {
                result.tcgDefault = key;
                result.tcgTarget = key;
                return result;
            }
            result[key] = val;
            return result;
        }, target);
    }

    /**
     * Extracts the URL-hash (without the hash-character)
     * @param {Location}    location    Location-object, containing information about the URL
     * @returns {String}    Hash of the URL. <code>null</code>, if <code>location</code> is <code>null</code> or
     *                                  <code>undefined</code>
     * @private
     */
    function _getUrlHash(location) {
        if (location == null) { return null; }
        return location.hash.substring(1);
    }

    /**
     * Merge location-hash into passed object, as `tcgDefault` and `tcgTarget`-property, if set.
     * @param {Object}  target  Target-object, in which the parameters have to be merged into
     * @private
     */
    function _mergeHash(target) {
        var val = decodeURIComponent(Private._getUrlHash(global.document.location));
        if (val === '') { return; }
        target.tcgDefault = val;
        target.tcgHash = val;
    }
})(window, window.jQuery, window.VivaUtils);

