/* All these settings prevent analysis errors but mean
 * deviation from coding standard XXX */
/*jshint nonew: false */
/*jshint browser: true */
/* global confirm, _, $, tcgDget */

(function(confirm, _, $, tcgDget) {
    'use strict';

    /**
     * @class
     * @augments tcgDget
     * @param {Object} param
     */
    tcgDget.idgBasketMenu = tcgDget.extend(
        /**
         * @lends tcgDget.idgLogin.prototype
         */{


            defaultName: 'Basket {n}',
            cartCount: 0,

            _init_: function() {
                var self = this;

                self.objName = 'idgBasketMenu';
                self.setDgetMode('single');

                self.defaultName = $(self.myDOM).attr('data-defaultName');


                $('#basketClose').click(function(event) {
                    self.fire('loading', 'off');
                    window.history.back(); // jshint ignore: line
                    event.preventDefault();
                    return false;
                });

                self.listen('cartSelector:list', function(event) {
                    self.cartRenderList(_.sortBy(event.messageData, 'sort'));
                });

                self.listen('basket:select', function(event) {
                    localStorage.curCart = event.messageData;
                });

                $('#cartNew').click(function() {
                    self.cartNew();
                });

                $('#cartDel').click(function() {
                    self.cartDel();
                });

                $('#cartRen').click(function() {
                    if ($('#cartRen').hasClass('disabled')) {
                        return;
                    }
                    self.cartRename();
                });


                $('#cartCpy').click(function() {
                    var curCartLI = $('li.selected', $(self.myDOM)),
                        name = $(this).attr('data-name').replace('{s}', $('.name', $(curCartLI)).text());
                    self.cartCopy(name);
                });


                self.listen('cartSelector:update', function() {
                    self.cartReadList();
                });

                self.listen('cartSelector:update_select', function(event) {
                    $('li.selected', $(self.myDOM)).removeClass('selected');
                    localStorage.curCart = event.messageData.id;

                    // self.cartReadList();

                    window.setTimeout(function() {
                        self.cartRename();
                    }, 100);
                });

                self.listen('cartSelector:update_select_copy', function(event) {
                    $('li.selected', $(self.myDOM)).removeClass('selected');
                    localStorage.curCart = event.messageData.id;

                    self.cartReadList();

                    window.setTimeout(function() {
                        self.cartRename();
                    }, 100);
                });


                self.listen('cartSelector:updateCounter', function() {
                    self.cartReadList();
                });

                self.listen('cartSelector:itemMove', function(event) {
                    var data = event.messageData,
                        cartFrom = $('li.selected', $(self.myDOM)),
                        cntFrom = $('.cartCount', $(cartFrom)),
                        cartTo = $('li[data-cartid="' + data.to + '"]', $(self.myDOM)),
                        cntTo = $('.cartCount', $(cartTo));

                    $(cartFrom).addClass('glow');
                    window.setTimeout(function() {
                        $(cntFrom).text(Number.parseInt($(cntFrom).text()) - 1);
                        $(cartFrom).removeClass('glow');
                        $(cartTo).addClass('glow');
                    }, 500);
                    window.setTimeout(function() {
                        $(cntTo).text(Number.parseInt($(cntTo).text()) + 1);
                        $(cartTo).removeClass('glow');
                    }, 1000);
                });

                self.cartReadList();

                $(self.myDOM).on('sortupdate', function() { // drag&drop finished
                    // save sort
                    self.cartUpdateSort();
                });

                //        self.fire('basket:change', ????????????????);
            },

            cartUpdateSort: function() {
                var self = this;

                $('li', $(self.myDOM)).each(function(idx) {
                    self.fire('cart', {
                        'service': 'update',
                        'pData': {
                            'id': $(this).attr('data-cartId'),
                            'sort': idx
                        }
                    });
                });
            }, // cartUpdateSort

            cartSelected: function() {
                var self = this,
                    curCartLI = $('li.selected', $(self.myDOM));
                if ($(curCartLI).size() === 0) {
                    if (typeof localStorage.curCart === 'string') {
                        curCartLI = $('li[data-cartID="' + localStorage.curCart + '"]', $(self.myDOM));
                    }

                    if ($(curCartLI).size() === 0) {
                        curCartLI = $('li:first', $(self.myDOM));
                    }

                    if ($(curCartLI).size() === 0) {
                        return null;
                    }
                    $(curCartLI).addClass('selected');
                    window.setTimeout(function() {
                        self.fire('basket:select', $(curCartLI).attr('data-cartID'));
                    }, 1000);
                }

                localStorage.curCart = $(curCartLI).attr('data-cartID');

                return localStorage.curCart;
            },


            cartNew: function() {
                var self = this;

                self.cartCount++;
                self.fire('cart', {
                    'service': 'insert',
                    'message': 'cartSelector:update_select',
                    'pData': {
                        'name': self.defaultName.replace('{n}', self.cartCount),
                        'sort': self.cartCount
                    }
                });
            },

            cartDel: function() {
                var self = this,
                    curCartLI = $('li.selected', $(self.myDOM)),
                    cartName = $('.name', $(curCartLI)).text(),
                    cartID = self.cartSelected();

                if (confirm(window.dict('tecton_configDeleteRoomList').replace('{r}', cartName))) {
                    self.fire('cart', {
                        'service': 'remove',
                        'message': 'cartSelector:update',
                        'pData': {
                            'id': cartID
                        }
                    });

                    self.fire('cartItem', {
                        'service': 'remove',
                        'pData': {
                            'cartID': cartID
                        }
                    });
                }
            },

            cartRename: function() {
                var self = this,
                    curCartLI = $('li.selected', $(self.myDOM)),
                    edit = $('input.edit', $(curCartLI)),
                    count = $('.cartCount', $(curCartLI)),
                    label = $('span.name', $(curCartLI));

                if ($(edit).is(':visible')) {// already visible -> hide
                    var newName = $(edit).val();
                    if (newName === '') {
                        newName = self.defaultName.replace('{n}', $('li', $(self.myDOM)).index($(curCartLI)) + 1);
                    }

                    $(label).text(newName);
                    // safe to db

                    self.fire('cart', {
                        'service': 'update',
                        'pData': {
                            'id': $(curCartLI).attr('data-cartID'),
                            'name': newName
                        }
                    });
                    $(edit).unbind('blur');

                    $(edit).addClass('off');
                    $(count).text(self.countSave); // $(count).removeClass('off');
                    $(label).removeClass('off');
                    window.setTimeout(function() {
                        $('#cartRen').removeClass('disabled');
                    }, 500);
                } else {
                    $('#cartRen').addClass('disabled');

                    var width = $(label).width(); // $(curCartLI).width();
                    if (width < 20) {
                        width = 20;
                    }
                    //            width=$(curCartLI).width();

                    $(label).addClass('off');
                    self.countSave = $(count).text();
                    $(count).text('OK');  // $(count).addClass('off');
                    $(edit).width(width).removeClass('off');
                    $(edit).focus().select();

                    $(edit).blur(function() {
                        self.cartRename();
                    }).keypress(function(event) {
                        if (event.which === 13) {
                            $(this).blur();
                        }
                    });
                }
            },


            cartCopy: function(name) {
                var self = this;

                self.fire('cart', {
                    'service': 'copy',
                    'message': 'cartSelector:update_select_copy',
                    'pData': {
                        'id': self.cartSelected(),
                        'name': name
                    }
                });
            },

            cartReadList: function() {
                var self = this;

                self.fire('cart', {
                    'service': 'list',
                    'message': 'cartSelector:list',
                    'pData': {}
                });
            },

            updateCounter: function() {
                var self = this,
                    cartID = self.cartSelected(),
                    cartCall = {
                        'service': 'count',
                        'pData': {
                            'cartID': cartID
                        }
                    };

                self.fire('cartItem', cartCall);
                cart.count = cartCall.result; // jshint ignore: line

                var curCartLI = $('li.selected', $(self.myDOM));
                $('.cartCount', $(curCartLI)).text(cart.count); // jshint ignore: line
            },


            cartRenderList: function(carts) {
                var self = this,
                    curCartLI = $('li.selected', $(self.myDOM)),
                    curSel = null;
                if ($(curCartLI).size()) {
                    curSel = $(curCartLI).attr('data-cartID');
                }

                if (carts.length) {
                    self.cartCount = carts.length;
                    self.cloneRemove('basketMenuItem');
                    for (var i = 0; i < carts.length; i++) {
                        var cart = carts[i],
                            cartCall = {
                                'service': 'count',
                                'pData': {
                                    'cartID': cart.id
                                }
                            };

                        self.fire('cartItem', cartCall);
                        cart.count = cartCall.result;

                        $(self.myDOM).append(self.cloneTemplate($('[data-template="basketMenuItem"]'), cart, 'cart'));
                    }
                    if (curSel) {
                        $('li[data-cartID="' + curSel + '"]').addClass('selected');
                    }

                    if ($('li.selected', $(self.myDOM)).size() === 0) {
                        // $('li:first', $(self.myDOM)).addClass('selected');
                        // self.fire('basket:select', $(this).attr('data-cartID'));
                        self.cartSelected();
                        self.cartSelectedVisible();
                    } else {
                        self.cartSelectedVisible();
                    }


                    // add listener
                    $('li', $(self.myDOM)).click(function() {
                        if ($(this).hasClass('selected')) {
                            false;
                            //                    if ($('#cartRen').hasClass('disabled'))
                            //                        return;
                            //                    self.cartRename();
                        } else {
                            if ($('#cartRen').hasClass('disabled')) {
                                return;
                            }
                            $('li', $(self.myDOM)).removeClass('selected');
                            $(this).addClass('selected');
                            self.fire('basket:select', $(this).attr('data-cartID'));
                        }
                    });

                    $('li span.name', $(self.myDOM)).click(function() {
                        var li = $(this).closest('li');
                        if ($(li).hasClass('selected')) {
                            if ($('#cartRen').hasClass('disabled')) {
                                return;
                            }
                            self.cartRename();
                        }
                    });

                    $('li span.cartCount', $(self.myDOM)).click(function() {
                        var li = $(this).closest('li');
                        if ($(li).hasClass('selected')) {
                            if ($('#cartRen').hasClass('disabled')) {
                                self.cartRename();
                            }
                        }
                    });
                } else { // create cart
                    self.cartNew();
                }

                $(window).trigger('resize');

                $(self.myDOM).sortable();
            }, // cartRenderList

            cartSelectedVisible: function() {
                var self = this,
                    listLeft = $('.list').position().left,
                    liPos = $('li.selected', $(self.myDOM)).position().left,
                    liWidth = $('li.selected', $(self.myDOM)).outerWidth(),
                    viewWidth = $('.wrapper').outerWidth() - $('.scroller-right').outerWidth();


                if (liPos >= -listLeft && liPos + liWidth <= -listLeft + viewWidth) {
                    true;
                    // alles ok - list visible
                } else {
                    var newOffset = (liPos + listLeft + liWidth / 2 - viewWidth / 2); // center

                    if (liPos >= 0 && liPos + liWidth <= viewWidth) { // adjust scroller to 0
                        newOffset = listLeft;
                    } else if (liPos + liWidth > viewWidth) { // adjust item to be visible at right of scroller
                        newOffset = (liPos + listLeft + liWidth - viewWidth);
                    } else { // adjust item to be visible at left of scroller
                        newOffset = (liPos + listLeft);
                    }


                    if (true) {
                        $('.list').animate({ left: '-=' + newOffset + 'px' }, 'fast');
                    } else {
                        $('.list').css({
                            'left': (-newOffset) + 'px'
                        });
                    }
                }
            } // cartSelectedVisible

        });


/*
    the contents of document.ready() should be placed in the
    startup of the catalog page, there is the only place to handle
    the correct startup sequence, otherwise it's not guaranteed
    that all initializations are correctly done!
*/


    $(document).ready(function() {
        var scrollBarWidths = 40;

        $('[data-tcgDget="idgBasketMenu"]').each(function() {
            new tcgDget.idgBasketMenu($(this));  // no need to store the object it's an ecatUI job
        });

        function widthOfList() {
            var itemsWidth = 0;
            $('.list li').each(function() {
                var itemWidth = $(this).outerWidth();
                itemsWidth += itemWidth;
            });
            return itemsWidth;
        }

        function widthOfView() {
            return $('.wrapper').outerWidth();
        }

        function max(x, y) {
            if (x > y) {
                return x;
            }
            return y;
        }

        function getLeftPosi() {
            return $('.list').position().left;
        }
        function widthOfHidden() {
            return (($('.wrapper').outerWidth()) - widthOfList() - getLeftPosi()) - scrollBarWidths;
        }

        function reAdjust() {
            if (($('.wrapper').outerWidth()) < widthOfList()) {
                $('.scroller-right').show();
            } else {
                $('.scroller-right').hide();
            }

            if (getLeftPosi() < 0) {
                $('.scroller-left').show();
            } else {
                $('.item').animate({ left: '-=' + getLeftPosi() + 'px' }, 'slow');
                $('.scroller-left').hide();
            }
        }

        if ($('[data-tcgDget="idgBasketMenu"]').size()) {
            reAdjust();

            $(window).on('resize', function() {
                reAdjust();
            });

            $('.scroller-right').click(function() {
                $('.scroller-left').fadeIn('slow');

                $('.list').animate({ left: '+=' + max(widthOfHidden(), -widthOfView()) + 'px' }, 'slow', function() {
                });
                if (widthOfHidden() > -widthOfView()) {
                    $('.scroller-right').fadeOut('slow');
                }
            });

            $('.scroller-left').click(function() {
                $('.scroller-right').fadeIn('slow');

                $('.list').animate({ left: '-=' + max(getLeftPosi(), -widthOfView()) + 'px' }, 'slow', function() {
                });

                if (getLeftPosi() > -widthOfView()) {
                    $('.scroller-left').fadeOut('slow');
                }
            });
        }
    });


    /**
     * $Log: idgBasketMenu.js,v $
     * Revision 1.15  2015-06-19 16:15:20  ml
     * fix for new/copy to avoid duplicate readlist
     *
     * Revision 1.14  2015-06-19 15:36:57  ml
     * scroll adjust ... next try
     *
     * Revision 1.13  2015-06-15 12:35:41  ml
     * items zwischen den Listen verschieben
     *
     * Revision 1.12  2015-05-28 20:40:39  ml
     * set focus to copy/duplicate list
     *
     * Revision 1.11  2015-05-26 15:26:16  pr
     * sort cart list, cart rename
     *
     * Revision 1.10  2015-05-13 11:59:55  ml
     * fixed delete cart, added sum function
     *
     * Revision 1.9  2015-04-28 09:41:39  ml
     * delete, send email add to baskte stuff
     *
     * Revision 1.8  2015-04-21 15:11:58  ml
     * article group switch
     *
     * Revision 1.7  2015-04-21 13:38:40  ml
     * loadin, classes
     *
     * Revision 1.6  2015-04-20 14:44:27  pr
     * send as email
     *
     * Revision 1.5  2015-04-17 15:09:33  pr
     * share cart
     *
     * Revision 1.4  2015-04-16 05:39:19  ml
     * intermediate checkin
     *
     * Revision 1.3  2015-04-14 06:47:28  ml
     * intermediate checkin
     *
     * Revision 1.2  2015-04-09 19:42:44  ml
     * intermediate checkin
     *
     * Revision 1.1  2015-04-06 21:50:28  ml
     * demo version 7.4.2015
     *
     * Revision 1.1  2015-04-06 19:27:07  ml
     * intermediate checkin
     *
     **/
})(confirm, _, $, tcgDget);
