/* jshint browser:true */
(function(global, $, _, AppData, VivaUtils) { // jshint ignore:line
    'use strict';

    // EXPORTS

    global.FamilyService = {
        handleSolrResponseEvent: handleSolrResponseEvent
    };
    // Export private functions for automated tests
    VivaUtils.exportForTests('FamilyService', {
        _$augmentFamily: _$augmentFamily,
        _$exportFamily: _$exportFamily,
        _$fillProductIds: _$fillProductIds,
        _$getFamily: _$getFamily,
        _$handleCustomConfigurators: _$handleCustomConfigurators,
        _$handleSolrResponse: _$handleSolrResponse,
        _$notifyLoadChildren: _$notifyLoadChildren,
        _$notifyLoading: _$notifyLoading,
        _$notifyStopLoading: _$notifyStopLoading,
        _handleNotFound: _handleNotFound,
        _renderGroupTitle: _renderGroupTitle,
        _reorderArticleClasses: _reorderArticleClasses
    });
    // Assign to object, which has to be used for private functions, in order to make the private functions mockable
    var Private = VivaUtils.getPropertiesForTests('FamilyService');

    // IMPLEMENTATION DETAILS

    // Public

    /**
     * Handle the event, containing the Solr-response of a family-request
     * @param {Object}  context Context in which this module is called
     * @param {Object}  event   Event, containing the Solr-response
     */
    function handleSolrResponseEvent(context, event) {
        Private._$handleSolrResponse(context, event.messageData);
    }

    // Private

    // Pure functions (no dependency on local state)

    /**
     * Process the Solr-Response
     * @param {Object} context
     * @param {Object} solrResponse
     * @private
     */
    function _$handleSolrResponse(context, solrResponse) {
        var tcgTarget = AppData.getPageProperty('tcgTarget', true);

        if (!solrResponse.response.numFound) {
            Private._handleNotFound(context, tcgTarget);
            return;
        }

        var family = Private._$getFamily(solrResponse);

        Private._$exportFamily(context, solrResponse);
        Private._$augmentFamily(family, tcgTarget);
        Private._$fillProductIds(family, solrResponse);
        Private._renderGroupTitle(context, family);
        Private._$notifyLoading(context);
        Private._$notifyLoadChildren(context, solrResponse, family);
        Private._$handleCustomConfigurators(context, family);
        Private._reorderArticleClasses(family.parentTreeID);
    }

    /**
     * Prepare / trigger the loading of custom configurators (if available for the company)
     * @param {Object}  context         Context in which this module is called
     * @param {Object}  family
     * @private
     */
    function _$handleCustomConfigurators(context, family) {
        if (!AppData.hasCustomConfigurators()) {
            return;
        }

        global.configurators = ['slotlightinfinity', 'slotlightinfinity-slim',
                                'equaline', 'tecton', 'epuria', 'cieluma', 'system_components',
                                'contus', 'ecoos', 'tramao', 'vaero', 'claris_evo', 'artelea',
                                'slotlightinfinity_II', 'mirel_ref'];

        if (!family.friendlyLink ||
            (_.isArray(family.friendlyLink) && global.configurators.indexOf(family.friendlyLink[0]) === -1)) {
            return;
        }

        $('[data-configurator]').each(function() {
            if (_.isArray(family.friendlyLink)) {
                context.configurator = family.friendlyLink[0];

                if (context.configurator === 'system_components') {
                    context.configurator = 'circle';
                }

                $(this).attr('data-configurator', context.configurator);
                var load = $('[data-load]', $(this)).attr('data-load');
                if (typeof load === 'string') {
                    $('[data-load]', $(this)).attr('data-load', load.replace('{configuratorName}',
                        context.configurator));
                }
            }
        });
        $(global).trigger('tcg:dynamicContentInit');
    }

    /**
     * Notify application to load the children. If the children are the articles, notify app about the family/-ies.
     * Disable VIVA, otherwise
     *
     * @param {Object}  context         Context in which this module is called
     * @param {Object}  solrResponse
     * @param {Object}  family
     * @private
     */
    function _$notifyLoadChildren(context, solrResponse, family) {
        if (solrResponse.response.docs[0].objectType === AppData.getObjectTypeOfArticles()) {
            context.fire('family', family);
            context.fire('families', solrResponse.response.docs);
            context.loadArticle(solrResponse.response.docs);
            return;
        }
        context.fire('viva:off');
        context.loadTree(solrResponse.response.docs);
    }

    /**
     * Notify the application to start the loading-indication
     * @param {Object}  context     Context in which this module is called
     * @private
     */
    function _$notifyLoading(context) {
        context.fire('loading', 'wait');
    }

    /**
     * Notify application to stop the loading-indication
     * @param {Object}  context     Context in which this module is called
     * @private
     */
    function _$notifyStopLoading(context) {
        context.fire('loading', null);
    }

    /**
     * Renders the family-title into the "group title"-section
     * @param {Object}  context     Context in which this module is called
     * @param {Object}  family
     * @private
     */
    function _renderGroupTitle(context, family) {
        if (family && family.loadString && global.familyNames && global.familyNames[family.loadString]) {
            var familyName = global.familyNames[family.loadString];
            $('.groupTitle', $(context.myDOM)).text(familyName);
            family.title = familyName;
        } else {
            $('.groupTitle', $(context.myDOM)).text(family.title);
        }
    }

    /**
     * Get family from Solr-Response
     * @param {Object}      solrResponse
     * @returns {Object}    Family
     * @private
     */
    function _$getFamily(solrResponse) {
        return solrResponse.response.docs[0];
    }

    /**
     * Export family into the context
     * @param {Object}  context     Context in which this module is called
     * @param {Object}  solrResponse
     * @private
     */
    function _$exportFamily(context, solrResponse) {
        context.family = solrResponse;
    }

    /**
     * Augment family with data
     * @param {Object}  family
     * @param {String}  tcgTarget
     * @private
     */
    function _$augmentFamily(family, tcgTarget) {
        family.loadString = tcgTarget;
        if (family.friendlyLink == null || !family.friendlyLink.length) {
            return;
        }
        family.loadString = family.friendlyLink[0];
    }

    /**
     * Fills all product-IDs of a Solr-response into a single Solr-document, which represents a product-family
     * @param {Object}  family  Solr-document, representing a family
     * @param {Object}  data    Solr-response
     * @private
     */
    function _$fillProductIds(family, data) {
        family.ids = data.response.docs.map(function(doc) {
            return doc.id;
        });
    }

    /**
     * Handler which will be called, when target has not been found
     * @param {Object}  context     Context in which this module is called
     * @param {String}  tcgTarget   Target (URL-parameter in this case)
     * @private
     */
    function _handleNotFound(context, tcgTarget) {
        var notFound = tcgTarget + ' NOT FOUND!';
        if (tcgTarget !== 'Spoon') { // test case  can not handle that
            if (global.tcgGetPageProperty('__ac') === null) { // not logged in
                if (global.tcgGetPageProperty('lang') === 'de') {
                    notFound =
                        'Die Konfiguration dieser Produktfamilie ist aktuell nur möglich wenn sie angemeldet sind.';
                } else {
                    notFound =
                        'The configuration  of this product family is currently only possible if you are logged in.';
                }
            }
        }
        $('.groupTitle', $(context.myDOM)).text(notFound);
        Private._$notifyStopLoading(context);
    }

    function _reorderArticleClasses(parentTreeID) {
        if (!global.configuration) {
            return;
        }
        var currentType = global.configuration.level1.filter(function(type) {
            return type.parentId === parentTreeID;
        });

        if (currentType.length > 0) {
            var parentContainerList = $('.groupList'),
                parentContainer = parentContainerList[0],
                theFirstChild = parentContainer.firstChild;

            currentType[0].order.forEach(function(item) {
                var elements = $(item);

                if (elements.length !== 0) {
                    // in case of the configurator, we need the else
                    if (elements[0].parentElement.classList.contains('groupItem')) {
                        parentContainer.insertBefore(elements[0].parentElement, theFirstChild);
                    } else {
                        parentContainer.insertBefore(elements[0].parentElement.parentElement, theFirstChild);
                    }
                }
            });
        }
    }
})(window, window.jQuery, window._, window.AppData, window.VivaUtils);
