/* All these settings prevent analysis errors but mean
 * deviation from coding standard XXX */
/*jshint nonew: false */
/*jshint browser: true */
/* global $, tcgDOM */

(function($, tcgDOM) {
    'use strict';

    /**
     * @class
     * @augments tcgDOM
     */
    /**
     * @lends tcgDOM.messageBox.prototype
     */
    tcgDOM.messageBox.mbSendAsEmail = tcgDOM.messageBox.extend({
        form: null,

        _init_: function() {
            var self = this;

            self.objName = 'popupSendAsEmail';
            self.form = $(self.myDOM).find('form');
            self.listen('messageBox', { self: self }, self.listenerMessageBox);
            $(self.myDOM).find('[data-btn="btn-cancel"]').click(function() {
                self.closeBox();
            });
            $(self.myDOM).find('[data-btn="btn-send"]').click(function() {
                if ($(this).hasClass('disabled')) { return; }
                var formData = {};
                $('input[name]', $(self.myDOM)).each(function() {
                    formData[$(this).attr('name')] = $(this).val();
                });
                self.fire(self.objName + ':send', formData);
                self.closeBox();
                return false;
            });

            // function to handle disable/enable reset button
            $(self.myDOM).find('input[name]').on('keypress', function() {
                if ($(self.form)[0].checkValidity && $(self.form)[0].checkValidity()) {
                    $(self.myDOM).find('[data-btn="btn-send"]').removeClass('disabled');
                } else {
                    $(self.myDOM).find('[data-btn="btn-send"]').addClass('disabled');
                }
            });
        }, // _init_

        initBox: function() {
            var self = this;
            window.setTimeout(function() { // generate pdf
                self.fire('mbSendAsEmail', { 'transferData': true });
            }, 1);
        }
    });

    $(document).ready(function() {
        $('#popupSendAsEmail').each(function() {
            new tcgDOM.messageBox.mbSendAsEmail($(this));
        });
    });
})($, tcgDOM);
