/* jshint nonew: false */
/* global tcgConfig, window, $, localStorage, tcgDOM */
(function(document, tcgConfig, window, $, localStorage, tcgDOM) { // jshint ignore: line
    'use strict';

    /**
     * @class
     * @augments tcgDOM
     * @param {Object} param
     */
    /**
     * @lends tcgDOM.idgLogin.prototype
     */
    tcgDOM.domLoading = tcgDOM.extend({

        classOld: 'start',
        startTime: null,


        _init_: function() {
            var self = this;

            self.objName = 'domLoading';
            self.startTime = new Date();

            switch (localStorage.domLoading) {
                case 'off':
                    delete localStorage.domLoading;
                    $(self.myDOM).removeClass(self.classOld, 'fast');
                    self.classOld = null;
                    self.startTime = 0;
                    break;
                default:
                    $(self.myDOM).addClass(self.classOld);
                    $(self.myDOM).show();
                    break;
            }


            self.listen('loading', function(event) {
                var classNew = event.messageData;

                if (classNew === 'off') {
                    localStorage.domLoading = classNew;
                    return;
                }

                var delay = 0;
                if (self.classOld === 'start') {
                    var now = new Date();
                    delay = Number.max(2500 - (now - self.startTime), 0);
                }

                if (self.timer && classNew == null) {
                    window.clearTimeout(self.timer);
                    self.timer = null;
                }


                self.timer = window.setTimeout(function() {
                    self.timer = null;
                    switch (classNew) {
                        case null:
                            var class2Remove = self.classOld;
                            $(self.myDOM).fadeOut('slow', function() {
                                $(self.myDOM).removeClass(class2Remove);
                            });
                            self.classOld = null;
                            break;

                        default:
                            if (self.classOld) {
                                $(self.myDOM).removeClass(self.classOld);
                                self.classOld = null;
                            }
                            $(self.myDOM).addClass(classNew);
                            self.classOld = classNew;
                            $(self.myDOM).fadeIn('fast', function() {
                            });
                            break;
                    }
                }, delay);
            });
        }


    });


/*
    the contents of document.ready() should be placed in the
    startup of the catalog page, there is the only place to handle
    the correct startup sequence, otherwise it's not guaranteed
    that all initializations are correctly done!
*/

    tcgDOM.domLoading.tcgAttach = function() { // only first select box
        $('[data-tcgDOM="domLoading"]').each(function() {
            if (typeof $(this).data(tcgConfig.namespace) === 'undefined') {
                new tcgDOM.domLoading($(this));  // no need to store the object it's an ecatUI job
            }
        });
    };


    $(window).bind('tcg:attach', function() {
        tcgDOM.domLoading.tcgAttach();
    });


    $(document).ready(function() {
        tcgDOM.domLoading.tcgAttach();
    });


    /**
     * $Log: domLoading.js,v $
     * Revision 1.4  2016-02-16 08:19:59  ml
     * cosmetics
     *
     * Revision 1.3  2015-04-26 12:05:44  ml
     * fixed loading
     *
     * Revision 1.2  2015-04-24 15:48:33  ml
     * fixed initial waits
     *
     * Revision 1.1  2015-04-21 13:38:40  ml
     * loadin, classes
     *
     **/
})(document, tcgConfig, window, $, localStorage, tcgDOM);
