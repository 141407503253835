// jscs:disable maximumNumberOfLines
/* All these settings prevent analysis errors but mean
 * deviation from coding standard XXX */
/*jshint maxcomplexity: 9 */
/*jshint nonew: false */
/*jshint browser: true */

/**
 * @class
 * @augments tcgDget
 * @param {Object} param
 */
/**
 * @lends tcgDget.idgLogin.prototype
 */
(function(window, Hyphenator, tcgGetPageProperty, ecatUserProfile, document, $, _, tcgDget, AppData) { // jshint ignore: line
    'use strict';

    function toXml(v, name, ind) {
        /*jshint maxcomplexity:15 */
        var xml = '';
        if (v instanceof Array) {
            for (var i = 0, n = v.length; i < n; i++) {
                xml += ind + toXml(v[i], name, ind + '\t') + '\n';
            }
        } else if (typeof(v) === 'object') {
            var hasChild = false;
            xml += ind + '<' + name;
            for (var m in v) {
                if (m.charAt(0) === '@') {
                    xml += ' ' + m.substr(1) + '=\"' + v[m].toString() + '\"';
                } else {
                    hasChild = true;
                }
            }
            xml += hasChild ? '>' : '/>';
            if (hasChild) {
                /* jshint shadow:true */
                for (var n in v) {
                    if (n === '#text') {
                        xml += v[n];
                    } else if (n === '#cdata') {
                        xml += '<![CDATA[' + v[n] + ']]>';
                    } else if (m.charAt(0) !== '@') {
                        xml += toXml(v[n], n, ind + '\t');
                    }
                }
                xml += (xml.charAt(xml.length - 1) === '\n' ? ind : '') + '</' + name + '>';
            }
        } else {
            xml += ind + '<' + name + '>' + v.toString().replace('&', '&amp;') + '</' + name + '>';
        }
        return xml;
    }

    function json2xml(o, tab) {
        var xml = '';
        for (var mm in o) {
            if (typeof mm === 'string') {
                xml += toXml(o[mm], mm, '');
            }
        }
        return tab ? xml.replace(/\t/g, tab) : xml.replace(/\t|\n/g, '');
    }

    tcgDget.idgArticleDetail = tcgDget.extend({
        article: null,

        id: '',

        _init_: function() {
            var self = this;

            self.objName = 'idgArticleDetail';
            self.setDgetMode('single');
            self.id = _.createId(self, {});

            self.article = $(self.myDOM).closest('.articleItem').data('article');

            self.listen('articleDetail:renderDL' + self.id, function(event) {
                self.renderDownloadables(event.messageData);
            });

            self.listen('articleDetail:zipDL' + self.id, function(event) {
                var url = event.messageData;
                self.fire('loading', null);

                var domLink = $('.downloadLink', $(self.myDOM));

                if (document.location.href.indexOf('file:') === 0) {
                    url = $('[data-tcgDOM="domBC"]').attr('data-server') + url;
                }

                $(domLink).attr('href', url);

                // $(domLink).attr('download', encodeURIComponent(self.article.title)+'.zip');
                // $(domLink).trigger("click");

                document.location.href = url;

                $('[data-action="downloadAsZIP"]', $(self.myDOM)).removeClass('disabled');
            });

            self.listen('articleDetail:renderDetail', function(event) {
                self.renderArticleDetail(event.messageData);
            });

            self.listen('articleDetail:renderVIVAText' + self.id, function(event) {
                self.renderVIVAText(event.messageData);
            });

            if (self.article !== null && typeof self.article === 'object') {
                if ($('.blkText', $(self.myDOM)).length !== 0) {
                    // do not load details if not needed
                    self.getArticleDetail();
                }
                if (self.article.hideDownloads) {
                    $('.blkDownload', $(self.myDOM)).html(''); // remove loading
                    $('.downloadsContainer', $(self.myDOM)).hide(); // hide DownloadContainer
                    $('.downloadZipContainer', $(self.myDOM)).hide(); // hide download zip button
                } else {
                    self.getDownloadables();
                }
            }

            $('.edit', $('.accessoryBasketQty', self.myDom)).on('change', function() {
                var accessory = $(this).parents('.articleItem:first').data('accessory'),
                    quantity = parseInt($(this).val());

                accessory.qty = quantity;
                self.changeAccessoryQty(accessory, self.myDOM);
            });
        },

        checkTextTemplateInfo: function() {
            var self = this;

            if (typeof self.article['template-option'] === 'undefined') {
                self.generateTemplateMode();
            } // configurator did not set template

            // configurator did not set template mode
            if (typeof self.article['template-mode'] === 'undefined') {
                // 1. light color
                self.article['template-mode'] = self.article['light-color'] + ';';

                // 2. optic
                self.article['template-mode'] += self.article.optic ? self.article.optic + ';' : ';';

                if (self.article.family === 'Equaline') {
                    // 3. luminousFlux
                    var luminousflux = self.article.luminousFlux ? self.article.luminousFlux.replace('V_', '') : '';
                    self.article['template-mode'] += luminousflux + ';';
                }
                if (self.article.family === 'SLOTLIGHT infinity slim' &&
                    self.article.system === '122' &&
                    !self.article.minicell) {     // SLOIN SLIM Request from Gerd Lecher 24.11.2023

                    self.article['template-mode'] += self.article.color ? self.article.color + ';' : ';';
                }
            } // configurator did not set template mode
        },

        generateTemplateMode: function() {
            var self = this;

            // 1. system
            self.article['template-option'] = self.article.system + ';';

            // 2. system extension (pp1)
            self.getPp1Template();

            // 3. mounting type (pp2)
            if (self.article.system === '164') {
                self.article['template-option'] += self.article.headColor + ';;';
            } else if (typeof self.article['mount-type'] === 'string') {
                var mtype = self.article['mount-type'];
                self.article['template-option'] += 'C_' + mtype + ';';

                // 4. mount type extension (pp3)
                if (_.isObject(self.article.directIndirect) && self.article.minicell) {
                    self.article['template-option'] += 'V_MC;';
                } else if (_.isObject(self.article[mtype]) && self.article[mtype].code) {
                    self.article['template-option'] += 'C_' + self.article[mtype].code + ';';
                } else if (self.article.frame) {
                    self.article['template-option'] += self.article.frame + ';';
                } else {
                    self.article['template-option'] += ';';
                }
            } else {
                self.article['template-option'] += ';;';
            }
        },

        getPp1Template: function() {
            var self = this;

            // jscs:disable
            if (self.article.familyCode && self.article.familyCode === 'slotlightinfinity-slim' &&
                _.isObject(self.article.directIndirect)) {
                self.article['template-option'] += 'C_ID_Slim;';
            } else if (_.isObject(self.article.directIndirect)) {
                self.article['template-option'] += 'C_' + self.article.directIndirect.code.replace('_MC', '') + ';';
            } else if (self.article.minicell) {
                self.article['template-option'] += 'V_MC;';
            } else if (self.article.system === '164') {
                self.article['template-option'] += self.article.singleHead ? 'V_Single;' : 'V_Double;';
            } else if (self.article.system === '165') {
                self.article['template-option'] += 'V_WH;';
            } else {
                self.article['template-option'] += ';';
            }
            // jscs:enable
        },

        getArticleDetail: function() {
            var self = this;

            switch (self.article.objectType) {
                case 'cfgResult':
                    self.appendArticleProducts();

                    self.fire('price:update');

                    if (typeof self.article.shape === 'string') {
                        $('.sketch', $(self.myDOM)).append($('<img>')
                            .attr('src', '../css/configurator-shape-' + self.article.shape + '.png'));
                    }


                    $('textarea', $(self.myDOM)).val('');
                    if (self.article.options) {
                        self.renderArticleConfig(self.article.options);
                    } else {
                        self.renderArticleConfig([]);
                    }

                    self.checkTextTemplateInfo();


                    self.fire('bc:load', {
                        'service': 'vivaConfig/xmlProductText',
                        'message': 'articleDetail:renderVIVAText' + self.id,
                        'pData': {
                            'isVIVA': json2xml(self.article),
                            'sLang': ecatUserProfile.getLanguage(),
                            'sOrderNo': self.article.baseArticle
                        }
                    });
                    break;

                case 'vivaArticle':
                    if (self.article.options) {
                        self.renderArticleConfig(self.article.options);
                    } else {
                        self.renderArticleConfig([]);
                    }

                    self.appendArticleProducts();

                    var pData = {
                        'isVIVA': self.article.isVIVA,
                        'sLang': ecatUserProfile.getLanguage(),
                        'sOrderNo': self.article.baseArticle
                    };

                    self.fire('bc:load', {
                        'service': 'vivaConfig/xmlProductInfo',
                        'message': 'articleDetail:renderVIVAText' + self.id,
                        'pData': pData
                    });
                    break;

                default:
                    false;
            }

            if (self.article.hideBasketPriceList) {
                var configInfoTable = $('.vivaConfigInfoTable', self.myDOM);
                if ($(configInfoTable).size()) {
                    $(configInfoTable).remove();
                }
            }
        },

        appendArticleProducts: function() {
            var self = this,
                domComponents = $('.components', $(self.myDOM));

            if (_.isArray(self.article.products)) {
                var domComponentsHeaderFooter = $('.componentHeaderFooter', $(self.myDOM)),
                    previousGroup = '';

                $(domComponents).removeClass('off');
                $(domComponentsHeaderFooter).removeClass('off');

                self.article.products = _.sortBy(self.article.products, function(o) {
                    return o.group;
                }).reverse();

                for (var i = 0; i < self.article.products.length; i++) {
                    var prod = self.article.products[i];

                    if (self.article.chooseAccessoriesSeperate && prod.CfgID_s === 'Accessory') {
                        continue;
                    }

                    var domProd = self.cloneTemplate($('[data-template="componentArticle"]'), prod),
                        articleGroup = self.article.products[i].group,
                        headingTranslation = window.dict('basketHeading' + articleGroup);

                    if (articleGroup !== undefined && articleGroup !== previousGroup && !!headingTranslation) {
                        var groupHeading = '<li class="groupHeading" data-group="' +
                            articleGroup + '">' + headingTranslation + '</li>';
                        $(domComponents).append($(groupHeading));
                    }

                    $(domComponents).append($(domProd));

                    previousGroup = articleGroup;
                }
            }
            if (self.article.chooseAccessoriesSeperate && _.isArray(self.article.accessoryProducts)) {
                var heading = window.dict('basketHeadingAccessory'),
                    groupHeader = '<li class="groupHeading">' + heading + '</li>';
                $(domComponents).append($(groupHeader));

                for (var j = 0; j < self.article.accessoryProducts.length; j++) {
                    var accessory = self.article.accessoryProducts[j],
                        domAccessory = self.cloneTemplate($('[data-template="componentAccessory"]'), accessory);

                    $(domAccessory).data('accessory', accessory);

                    $(domComponents).append($(domAccessory));
                }
            }
        },

        renderArticleDetail: function(data) {
            if (data.response.numFound) {
                var detail = data.response.docs[0];

                detail = detail;
            }
        },


        renderArticleConfig: function(options) {
            var self = this;

            if (options.length) {
                var ul = $('.articleConfigList', $(self.myDOM)),
                    templ = $('[data-template="articleConfigItem"]');
                for (var i = 0; i < options.length; i++) {
                    if (i === 0 && options[0].id === 'Attr2' && options.length > 1 &&
                        (options[1].id === 'Attr6' || options[1].id === 'Attr1')) {
                        // combine LampType and Lumen/Load
                        options[0].value += ' ' + options[1].value;
                        options[1].value = null;
                    }

                    if (!options[i].value) {
                        continue;
                    }

                    var value = options[i].value;

                    if (options[i].value !== options[i].text) {
                        if (options[i].text && options[i].showOnlyText) {
                            value = options[i].text;
                        } else if (options[i].text) {
                            value = options[i].text + ' (' + options[i].value + ')';
                        }
                    }

                    $(ul).append(self.cloneTemplate($(templ), {
                        'id': options[i].id,
                        'title': options[i].title,
                        'value': value
                    }));
                }
            } else {
                $('.blkConfig', $(self.myDOM)).hide();
            }
        },


        getDownloadables: function() {
            var self = this,
                pData = {};

            switch (self.article.objectType) {
                case 'cfgResult':
                    if (self.article.matnr && self.article.matnr !== '-') {
                        pData = {
                            'sOrderNr': self.article.matnr
                        };
                    } else {
                        pData = {
                            'sOrderNr': self.article.baseArticle
                        };
                    }
                    pData.isVIVA = json2xml(self.article);
                    break;
                case 'vivaArticle':
                    if (self.article.matnrLoaned) {
                        pData = {
                            'sOrderNr': self.article.matnrLoaned
                        };
                    } else {
                        pData = {
                            'sOrderNr': self.article.baseArticle,
                            'isVIVA': self.article.isVIVA
                        };
                    }
                    break;
                default:
                    pData = {
                        'sOrderNr': self.article.matnr
                    };
            }

            pData.iCompanyID = AppData.getPdbCompanyId();

            self.fire('bc:load', {
                'service': 'vivaConfig/xmlProductDownloads',
                'message': 'articleDetail:renderDL' + self.id,

                'pData': pData
            });
        },

        renderDownloadables: function(xmlData) {
            var self = this;

            if (typeof xmlData === 'string') {
                xmlData = $.parseXML(xmlData);
            }

            self.article.articleID = $('Download', $(xmlData)).attr('ArticleID');
            // FIXME use strict equality, if possible (verify, before fixing!!)
            // jshint -W116
            if (typeof self.article.articleID === 'undefined' || self.article.articleID == '') {
                // jshint +W116
                self.article.articleID = $('Download', $(xmlData)).attr('CfgID');
            }

            $('.blkDownload', $(self.myDOM)).html(''); // remove loading

            $('Group', $(xmlData)).each(function() {
                var grp = $(this),
                    domGrp = self.cloneTemplate($('[data-template="downloadGroup"]'), {
                        'Name': $(grp).attr('Name')
                    });

                $('.blkDownload', $(self.myDOM)).append($(domGrp));

                $('Item', $(grp)).each(function() {
                    var item = $(this),
                        url = $(item).attr('URL'),
                        skip = false;
                    // if (self.article.objectType === 'cfgResult') {
                    //     switch ($(item).attr('ColID')) {
                    //         case '14': // product photo
                    //         case '17': // description
                    //         case '80': // zpf
                    //         case '8': // data sheet
                    //         case '25': //
                    //             skip = true;
                    //     }
                    // }

                    if (!skip) {
                        // FIXME use strict equality, if possible (verify, before fixing!!)
                        if ($(item).attr('ColID') === '17') {
                            // jshint +W116
                            url = 'data:text/html;charset=utf-8,' + encodeURIComponent($(item).text());
                            $('textarea', $(self.myDOM)).val($(item).text());
                            $('a[data-action="download"]', $(self.myDOM)).attr('href', url)
                                .attr('download', self.article.title + '.txt');
                        }
                        if ($(item).attr('ColID') === '14') { // product photo
                            // jshint +W116
                            $('#product-photo').attr({
                                'src': url
                            });
                            $('#product-photo-title').text($(item).attr('ItemName'));
                        }

                        var domItem = self.cloneTemplate($('[data-template="downloadItem"]'), {
                            'URL': url,
                            'ItemName': $(item).attr('ItemName'),
                            'ColID': $(item).attr('ColID'),
                            'AttachmentID': $(item).attr('AttachmentID'),
                            'Icon': $(item).attr('Icon'),
                            'lang': ecatUserProfile.getLanguage()
                        });
                        $('ul', $(domGrp)).append($(domItem));
                        // FIXME use strict equality, if possible (verify, before fixing!!)
                        // jshint -W116
                        if ($(item).attr('ColID') == '17') {
                            // jshint +W116
                            $('a', $(domItem)).attr('download', $(item).attr('ItemName') + '.txt');
                        }
                        // if ($(item).attr('ColID')=='17') {
                        //     $('textarea', $(self.myDOM)).val($(item).text());

                        //     $('a', $(domItem)).removeAttr('href');
                        //     $('a', $(domItem)).click(function() {
                        //         SaveAs('')
                        //     });

                        // }
                    } // not skip
                });
            });


            Hyphenator.config({
                remoteloading: false,
                onwarninghandler: function() {},
                selectorfunction: function() {
                    return $('.hyphenate', $(self.myDOM)).get();
                }
            });
            Hyphenator.run();

            $('.iconCheck', $(self.myDOM)).click(function() {
                $(this).closest('li').toggleClass('checked');

                // FIXME use strict equality, if possible (verify, before fixing!!)
                // jshint -W116
                if (typeof(self.article.articleID) === 'string' && self.article.articleID != '') { // 2015.12 only articles w. clearance 2
                    if ($('li.checked', $(self.myDOM)).size() && typeof(self.article.articleID) === 'string' &&
                        self.article.articleID != '') {
                        // jshint +W116
                        $('[data-action="downloadAsZIP"]', $(self.myDOM)).removeClass('disabled');
                    } else {
                        $('[data-action="downloadAsZIP"]', $(self.myDOM)).addClass('disabled');
                    }
                } else {
                    $('[data-action="downloadAsZIP"]', $(self.myDOM)).addClass('off');
                }
            });

            $('[data-action="copy"]', $(self.myDOM)).click(function() {
                if (window.clipboardData && window.clipboardData.setData) {
                    window.clipboardData.setData('text', $('textarea', $(self.myDOM)).val());
                } else {
                    $('textarea', $(self.myDOM)).select();
                }
            });
            if (!(window.clipboardData && window.clipboardData.setData)) {
                $('[data-action="copy"]', $(self.myDOM)).addClass('disabled');
            }


            $('[data-action="downloadAsZIP"]', $(self.myDOM)).click(function() {
                if ($(this).hasClass('disabled')) {
                    return false;
                }
                var items = [];

                $(this).addClass('disabled');
                $('li.checked[data-colID]').each(function() {
                    items.push($(this).attr('data-colID'));
                });

                self.fire('loading', 'wait');
                self.zipDownloadables(items);
            });
        },

        zipDownloadables: function(items) {
            var self = this,
                iCompanyID = AppData.getPdbCompanyId(),
                pData = {
                    'ColID': items,
                    'nArticleID': self.article.articleID,
                    'iCompanyID': iCompanyID
                };

            if (self.article.objectType === 'vivaArticle' && typeof(self.article.matnrLoaned) === 'undefined') {
                pData.isVIVA = self.article.isVIVA;
            }

            self.fire('bc:load', {
                'service': 'vivaConfig/zipProductDownloads',
                'message': 'articleDetail:zipDL' + self.id,
                'pData': pData
            });
        },

        renderVIVAText: function(xmlData) {
            var self = this,
                item = $(xmlData),
                text = self.article.title + '\r\n' + $(item).text(),
                url = 'data:text/html;charset=utf-8,' + encodeURIComponent(text);
            if (!self.article.noProductText) {
                $('textarea', $(self.myDOM)).val(text);
                $('a[data-action="download"]', $(self.myDOM)).attr('href', url)
                    .attr('download', self.article.title + '.txt');
            }
        },

        changeAccessoryQty: function(accessory, element) {
            var self = this;

            for (var i = 0; i < self.article.products.length; i++) {
                var product = self.article.products[i];
                if (product.id === accessory.id) {
                    if (accessory.qty === 0) {
                        self.article.products.splice(i, 1);
                    } else {
                        self.article.products[i] = accessory;
                    }
                    break;
                }
                if (self.article.products.length - 1 === i) {
                    self.article.products.push(accessory);
                }
            }

            var articleItem = $(element).parents('.articleItem.configResult:first');

            self.fire('cartItem', {
                'service': 'update',
                'pData': self.article,
                'element': articleItem
            });
        }
    });

    /*
        the contents of document.ready() should be placed in the
        startup of the catalog page, there is the only place to handle
        the correct startup sequence, otherwise it's not guaranteed
        that all initializations are correctly done!
    */

    $(document).ready(function() {
        $('[data-tcgDget="idgArticleDetail"]').each(function() {
            new tcgDget.idgArticleDetail($(this)); // no need to store the object it's an ecatUI job
        });
    });


    /**
     * $Log: idgArticleDetail.js,v $
     * Revision 1.16  2016-03-16 13:55:32  ml
     * downloadables for TL with correct logo
     *
     * Revision 1.15  2016-02-16 08:15:58  ml
     * pricing additions
     *
     * Revision 1.14  2015-12-02 14:20:00  ml
     * remove ZIP Button if getDownLoadables does not return a valid ArticleID
     *
     * Revision 1.13  2015-11-30 09:55:14  ml
     * text output f. config items
     * no product text
     *
     * Revision 1.12  2015-11-24 16:52:33  ml
     * separation of solr/bc requests
     *
     * Revision 1.11  2015-09-29 07:29:49  ml
     * configurator result display
     *
     * Revision 1.10  2015-07-29 16:37:49  ml
     * nun ohne window open
     *
     * Revision 1.9  2015-07-14 12:48:17  ml
     * xml translation sometimes missing
     *
     * Revision 1.8  2015-06-02 12:08:21  ml
     * download link, copy clipboard
     *
     * Revision 1.7  2015-06-02 12:04:21  ml
     * download link, copy clipboard
     *
     * Revision 1.6  2015-04-27 20:45:53  ml
     * ux, design
     *
     * Revision 1.5  2015-04-26 20:52:15  ml
     * viva data load
     *
     * Revision 1.4  2015-04-26 12:06:24  ml
     * loading, downloads
     *
     * Revision 1.3  2015-04-24 15:49:14  ml
     * hyphenation
     *
     * Revision 1.2  2015-04-16 05:39:19  ml
     * intermediate checkin
     *
     * Revision 1.1  2015-04-14 14:31:36  ml
     * intermediate checkin
     *
     **/
})(window, window.Hyphenator, window.tcgGetPageProperty, window.ecatUserProfile, window.document, window.$, window._,
    window.tcgDget, window.AppData);
